import LoginComponent from "../component/LoginComponent/LoginComponent";

import React from "react";
import Header from "../component/Header/Header";
import Styled from "styled-components";
import AuthLayout from "../component/AuthLayout/AuthLayout";
import SignUpImage from "../assets/reseeet.png";
import SignUpComponent from "../component/ResetPasswords/ResetPasswords";

import { NavLink } from "react-router-dom";

const SignupWrapper = Styled.div`
  background: #FFF9F1;
  height: 100vh;

  .auth {
    // background: red;
    // height:100%;
  padding:4.7rem;

  @media only screen and (max-width: 600px) {
    padding:4.7rem 2.4rem;
  }
  }
`;

const ResetPassword = () => {
  return (
    <SignupWrapper>
      <div>
        <Header />
        <AuthLayout className="auth" src={SignUpImage} isImage>
          <SignUpComponent />
        </AuthLayout>
      </div>
    </SignupWrapper>
  );
};

export default ResetPassword;
