import React from "react";
import Input from "../Input/Input";
import Styled from "styled-components";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import Fb from "../../assets/fb.svg";
import Google from "../../assets/google.svg";
import Twitter from "../../assets/twitter.svg";
import { NavLink } from "react-router-dom";

const SignupWrapper = Styled.div`
// background-color:green;
padding:2.8rem 6.4rem 2.8rem 2.4rem;
height:100%;
background:#fff;
border-top-left-radius: 14px;
border-bottom-left-radius: 14px;


.account{
font-size: 3.4rem;
font-weight: 400;
line-height: 1.2;
color:#4C4D4F;
margin-bottom:1.4rem;

}
@media only screen and (max-width: 600px) {
  padding:2.8rem;
  .firstname{grid-column: 1 / span 2;}
  .lastname{grid-column: 1 / span 2;}
}
@media only screen and (max-width: 900px) {
  border-radius: 14px;
}

.paragraph{
  font-size: 1.4rem;
  font-weight: 400; 
  line-height: 1.5;
  margin:0 !important;
  }
  
  .already-exist{
    font-size: 1.4rem;
  font-weight: 400; 
  line-height: 1.5;
  color:#aaabac;
  margin-bottom:3.9rem;
  }
  
  .span-login{

a{
  font-size: 1.4rem;
  font-weight: 400; 
  line-height: 1.5;
  margin-left:3.4px;
  display:inline-block;    color: #4C4D4F;
  text-decoration: underline;
  color:#4C4D4F;
}

  }

  .email{
    grid-column: 1 / span 2;
  }

  .password{
    grid-column: 1 / span 2;
  }
  .already{
    grid-column: 1 / span 2;
    font-size: 1.2rem;
    font-weight: 400; 
    line-height: 1.5;
    text-align:center;
    color:#a5a6a7;

    span{
      text-decoration: underline;
      color:#4c4d4f;
    }
  }
  .btn{
    grid-column: 1 / span 2;
    margin-bottom:.8rem;
  }
  .policy-text{
    grid-column: 1 / span 2;
    margin-bottom:1.4rem;

    font-size: 1.2rem;
    font-weight: 400; 
    line-height: 1.5;
    color:#b0b1b2;

    span{
      text-decoration: underline;
      color:#4C4D4F;
    }
  }
  .checkk{
    grid-column: 1 / span 2;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    margin-bottom:2.4rem;
input{
  width:2rem;
  height:2rem;
}

    // margin: -10px;
  label{
    color:#4C4D4F;
    font-size:1.2rem;
  }
  }
`;

const SignupForm = Styled.form`
// background:yellow;

display: grid;
grid-gap: 32px;
grid-template-columns: repeat(2,1fr);
margin-bottom:5.5rem;
`;

const AuthButton = Styled.button`
all: unset;
width:100%;
box-sizing: border-box;
color: #4C4D4F;
border-radius: 24px;
border: 1px solid #4C4D4F;
padding:.8rem;
display: flex;
justify-content: center;
align-items: center;
gap:1.2rem;
margin-bottom:1.4rem;

`;

const WrapperOR = Styled.div`
margin-top:2.4rem;
margin-bottom:3.9rem;
.hr-theme-slash-2 {
  display: flex;
  
  .hr-line {
    width: 100%;
    position: relative;
    // margin-right: 15px;
    border-bottom: 1px solid #4C4D4F;
  }
  .hr-icon {
    position: relative;
    top: 10px;
    color: #4C4D4F;
    font-size:1.4rem;
    padding:0 1.6rem;
  }
}
`;

const SignUpComponent = () => {
  return (
    <SignupWrapper>
      <h1 className="account">Create an account</h1>
      <p className="already-exist">
        Already have an account?
        <span className="span-login">
          <NavLink to="/login">Log in</NavLink>
        </span>
      </p>
      <AuthButton className="social-hide-tablet">
        <img src={Fb} />
        <p className="paragraph">Continue with Facebook</p>
      </AuthButton>
      <AuthButton>
        <img src={Google} />
        <p className="paragraph">Continue with Google</p>
      </AuthButton>
      <AuthButton>
        <img src={Twitter} />
        <p className="paragraph">Continue with Twitter</p>
      </AuthButton>

      <WrapperOR>
        <div class="hr-theme-slash-2">
          <div class="hr-line"></div>
          <div class="hr-icon">Or</div>
          <div class="hr-line"></div>
        </div>
        {/* <p>or</p> */}
        {/* <hr /> */}
      </WrapperOR>
      <SignupForm>
        <Input name="First Name" placeholder="Jane" className="firstname" />
        <Input name="Last Name" placeholder="Doe" className="lastname" />
        <Input name="Email" placeholder="Jane@gmail.com" className="email" />
        <Input
          name="Password"
          placeholder="Password"
          password
          className="password"
        />
      </SignupForm>
      <div>
        <div className="checkk">
          <input type="checkbox" id="vehicle3" name="vehicle3" value="Boat" />
          <label htmlFor="vehicle3"> Subscribe to our monthly newsletter</label>
        </div>
        <p className="policy-text">
          By clicking below you agree to our <span>Terms of Service</span> and
          <span> Privacy Policy</span>
        </p>
        <ButtonComponent style={{backgroundColor:"#000",color:"#fff"}} className="btn">Sign up</ButtonComponent>
        <p className="already">
          Already have an account?{" "}
          <span>
            <NavLink to="/login">Log in</NavLink>
          </span>
        </p>
      </div>
    </SignupWrapper>
  );
};

export default SignUpComponent;
