// import React from "react";

// const InvoiceListForUserDashboard = () => {
//   return <div>InvoiceListForUserDashboard</div>;
// };

// export default InvoiceListForUserDashboard;

import React, { useState } from "react";
import styled from "styled-components";
import ReuseAbleHeader from "../ReuseAbleHeader/ReuseAbleHeader";
import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TableComponent from "../TableComponent/TableComponent";
// import SpanComponent from "../StatusSpan/StatusSpan";
// import OptionMenu from "../DotMenu/DotMenu";
import SearchArea from "../SearchInput/SearchInput";
import print from "../../assets/Print.png";

import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineExport } from "react-icons/ai";
import Menu from "../Menu/Menu";

const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
  height: 100%;
`;
const Table2Wrapperr = styled.div`
  height: 94%;

  // display: flex;
  // flex: 1;
  // background: #fff9f1;
  padding: 22px 28px;
  background: #fff;
  border-radius: 0 0 14px 14px;

  .add-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
  }
  .add-border {
    border: 1px solid #d6d6d6;
    border-radius: 14px;
    height: 90%;
    overflow: hidden;
    background-color: #f8f8f8;
  }
`;

const TableWrapperr = styled.div`
  border-radius: 14px 14px 0 0;
  margin-top: 19px;
  .innerclass {
    display: flex;
    flex: 1;
    background: #fff9f1;
    border-bottom: 1px solid #dbdbdc;
    padding: 2.1rem 2.7rem;
    background-color: #fff;
    // border-radius: 14px 14px 0 0;
    justify-content: space-between;
    // gap: 298px;
    @media only screen and (max-width: 900px) {
      gap: 100px;
      padding: 1.1rem 0.7rem;
    }
    @media only screen and (max-width: 500px) {
      gap: 18px;
    }
    #mySelect {
      background: #f8f8f8;
      font-size: 13px;
      border: transparent;
      border-radius: 8px;
      padding: 8px;
    }
    h1 {
    }
  }
`;

const TableWrapperr3 = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;

  padding: 2.1rem 2.7rem;
  background-color: #fff;
  border-radius: 14px 14px 0 0;
  gap: 200px;
  h1 {
  }
  img {
    width: 18px;
    height: 18px;
  }
`;

const spanStyle = {
  backgroundColor: "#36C490",
  width: "75px",
  height: "21px",
  top: "391px",
  left: "1178px",
  borderRadius: "11px",
  display: "inline-block",
  fontFamily: "Cabin", // Font family
  fontSize: "10px", // Font size
  fontWeight: 400, // Font weight
  lineHeight: "21px", // Line height
  letterSpacing: "0em", // Letter spacing
  textAlign: "left", // Text alignment
  color: "white",
  letterSpacing: "0em",
  textAlign: "center",
};

const CustomButton = styled.button`
  width: 184px;
  height: 48px;
  align: center;
  border-radius: 24px;
  background-color: black;
  color: white; /* Adding white text color for better visibility on a black background */
  font-family: Cabin;
  font-size: 16px; /* Adjust the font size as needed */
  font-weight: 400;
  line-height: 20px; /* Adjust line height as needed */
  letter-spacing: 0em;
  text-align: center;
  border: none;
  cursor: pointer;
`;

const h3Style = {
  fontFamily: "Cabin",
  fontSize: "20px",
  fontWeight: "400",
  lineHeight: "22px",
  letterSpacing: "0em",
  textAlign: "left",
};

const fontstyleName = {
  fontWeight: 800,
  fontSize: "12px",
  lineHeight: "21px",
};

const Container22 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  width: 84%;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin-bottom: 10px;
  line-height: 2;
`;

const Label = styled.span`
  font-weight: bold;
`;

const Value = styled.span`
  margin-left: auto;
`;

const StyleLine = styled.div`
  width: 100%;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 10px;
    right: 10px;
    border-top: 0.5px solid #ccc; /* Adjust color and thickness as needed */
    transform: translateY(-50%);
  }
`;

const TableContainer = styled.div`
  width: 97%;
  height: 159px;
  top: 454px;
  left: 1075px;
  border-radius: 5px 5px 0px 0px;
  overflow: hidden;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0; /* This removes the spacing between cells */
  border: 1px solid #ccc; /* Add the border to the entire table */
`;

const TableHeader = styled.th`
  background-color: black;
  color: white;
  padding: 10px;
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: center;
`;

const TextContainer = styled.div`
  width: 305px;
  background-color: #f8f8f8;
  height: 55px;
  margin: 0 auto;
  padding: 11px;
`;
const TextItem = styled.div`
  margin: 0 auto;
  font-size: 14px;
  font-weight: 500;
`;
const Textarea = styled.div`
  color: #00000080;
  font-size: 10px;
  font-weight: 500;
`;

const TabWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 2.1rem 2.7rem 0;
  border-bottom: 1px solid #dbdbdc;

  .tabs {
    display: flex;
    gap: 22px;
    p {
      margin: 0;
    }
  }
  .tabs p:nth-child(1) {
    padding-bottom: 0.7rem;
    border-bottom: 2px solid #000;
  }

  .export {
    p {
      margin: 0;
    }
  }
`;

const InvoiceListForUserDashboard = ({ columns, data }) => {
  console.log(columns, data);
  return (
    <>
      <Container>
        <Row>
          <Col xl={12} xs={12}>
            <TableWrapperr>
              <TabWrapper>
                <div className="tabs">
                  <p style={{ color: "#000", fontWeight: "700" }}>Users</p>
                  <p>Appointments</p>
                  <p>Transactions</p>
                  <p>Inventory</p>
                  <p>Patient Records</p>
                  <p>Doctor’s Performance</p>
                </div>
                <div className="export">
                  <p>
                    <AiOutlineExport /> Export CSV
                  </p>
                </div>
              </TabWrapper>
              <div className="innerclass">
                <SearchArea></SearchArea>
                <div>
                  <select id="mySelect">
                    <option value="">Filter By : | Upcoming</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </select>
                  <select id="mySelect" style={{ marginLeft: "8px" }}>
                    <option value="">Select Date: | 20 Aug</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </select>
                </div>
              </div>
            </TableWrapperr>
            <Table2Wrapperr>
              <div className="add-border">
                <TableComponent columns={columns} data={data} />
              </div>
            </Table2Wrapperr>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InvoiceListForUserDashboard;
