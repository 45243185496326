import React, { useState } from "react";
import styled from "styled-components";
import ReuseAbleHeader from "../ReuseAbleHeader/ReuseAbleHeader";
import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TableComponent from "../TableComponent/TableComponent";
// import SpanComponent from "../StatusSpan/StatusSpan";
// import OptionMenu from "../DotMenu/DotMenu";
import SearchArea from "../SearchInput/SearchInput"
import print from "../../assets/Print.png";

const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
  height: 100%;
`;
const Table2Wrapperr = styled.div`
  min-width: 400px;
  // display: flex;
  // flex: 1;
  // background: #fff9f1;
  padding: 22px 28px;
  background: #fff;
  border-radius: 0 0 14px 14px;

  .add-button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
  }
  .add-border {
    border: 1px solid #d6d6d6;
    border-radius: 14px;
height:90%;    

}
`;

const TableWrapperr = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
  border-bottom: 1px solid #dbdbdc;
  padding: 2.1rem 2.7rem;
  background-color: #fff;
  border-radius: 14px 14px 0 0;
  justify-content: space-between;
  @media only screen and (max-width: 900px) {
    gap: 100px;
    padding: 1.1rem 0.7rem;
  }
  @media only screen and (max-width: 500px) {
    gap: 18px;
  }
  #mySelect{
    background: #F8F8F8;
    font-size: 13px;
    border: transparent;
    border-radius: 8px;
    padding:8px;
  }
  h1 {
  }
`;



const TableWrapperr3 = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
  min-width: 400px;
  padding: 2.1rem 2.7rem;
  background-color: #fff;
  border-radius: 14px 14px 0 0;
  gap:200px;
  h1 {
  }
  img{
    width:18px;
    height:18px;
  }
`;



const columns = [
  { Header: "Patient", accessor: "column1" },
  { Header: "Time", accessor: "column2" },
  { Header: "Date", accessor: "column3" },
  { Header: "Total", accessor: "column4" },
  { Header: "Status", accessor: "column5" },

];
const spanStyle = {
  backgroundColor: '#36C490',
  width: '75px',
  height: '21px',
  top: '391px',
  left: '1178px',
  borderRadius: '11px',
  display: 'inline-block',
  fontFamily: 'Cabin', // Font family
  fontSize: '10px', // Font size
  fontWeight: 400, // Font weight
  lineHeight: '21px', // Line height
  letterSpacing: '0em', // Letter spacing
  textAlign: 'left', // Text alignment
  color : 'white',
  letterSpacing: '0em',
  textAlign: 'center',
};

const CustomButton = styled.button`
  width: 184px;
  height: 48px;
  align:center;
  border-radius: 24px;
  background-color: black;
  color: white; /* Adding white text color for better visibility on a black background */
  font-family: Cabin;
  font-size: 16px; /* Adjust the font size as needed */
  font-weight: 400;
  line-height: 20px; /* Adjust line height as needed */
  letter-spacing: 0em;
  text-align: center;
  border: none;
  cursor: pointer;

`;

const h3Style={

    fontFamily: 'Cabin',
    fontSize: '20px',
    fontWeight: '400',
    lineHeight: '22px',
    letterSpacing: '0em',
    textAlign: 'left',

}

const fontstyleName = {
    fontWeight: 800,
    fontSize: '12px',
    lineHeight: '21px',
  };

  const Container22 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  width: 84%;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin-bottom: 10px;
  line-height: 2;
`;

const Label = styled.span`
  font-weight: bold;
`;

const Value = styled.span`
  margin-left: auto;
`;

const StyleLine = styled.div`
  width: 100%;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 10px;
    right:10px;
    border-top: 0.5px solid #ccc; /* Adjust color and thickness as needed */
    transform: translateY(-50%);
  }
`;

const TableContainer = styled.div`
width: 97%;
  height: 159px;
  top: 454px;
  left: 1075px;
  border-radius: 5px 5px 0px 0px;
  overflow: hidden;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0; /* This removes the spacing between cells */
  border: 1px solid #ccc; /* Add the border to the entire table */
`;

const TableHeader = styled.th`
  background-color: black;
  color: white;
  padding: 10px;
`;

const TableCell = styled.td`

  padding: 10px;
  text-align: center;
`;

const TextContainer = styled.div`
 width:305px;
 background-color:#F8F8F8;
 height: 55px;
 margin: 0 auto;
 padding: 11px;
`;
const TextItem = styled.div`
 margin: 0 auto;
 font-size:14px;
 font-weight: 500;
`;
const Textarea = styled.div`
color: #00000080;
 font-size:10px;
 font-weight: 500;
`;
// const data = isdata.map((tableItem) => ({
const data = [
 
  {
    column1: "John Doe",
    column2: "09:00 - 10:30",
    column3: "22/08/2023",
    column4: "$180",
    column5: <span style={spanStyle}>Complete</span>,
  
  },
  {
    column1: "John Doe",
    column2: "09:00 - 10:30",
    column3: "22/08/2023",
    column4: "$180",
    column5: <span style={spanStyle}>Complete</span>,
  
  },
  
  {
    column1: "John Doe",
    column2: "09:00 - 10:30",
    column3: "22/08/2023",
    column4: "$180",
    column5: <span style={spanStyle}>Complete</span>,
  
  },
  
  {
    column1: "John Doe",
    column2: "09:00 - 10:30",
    column3: "22/08/2023",
    column4: "$180",
    column5: <span style={spanStyle}>Complete</span>,
  
  },
  
  {
    column1: "John Doe",
    column2: "09:00 - 10:30",
    column3: "22/08/2023",
    column4: "$180",
    column5: <span style={spanStyle}>Complete</span>,
  
  },
 
  
  

];

const InvoiceList = () => {
  const [isdata, setIsData] = useState(data);
  return (
    <div>
      <ReuseAbleHeader />
      <MainLayout>
        <ReuseAbleSidebar />
        <Container className="main-content">
          <Row>
          <h1 className="after-care-heading">Invoice </h1>
         <h3 className="after-treatment-heading">Invoice list</h3>
            <Col xl={8} xs={8}>
              <div className="mmp-scroll" style={{minWidth:"600px"}}>
              <TableWrapperr>
              <SearchArea></SearchArea>
              <select id="mySelect">
                <option value="">Select Date: | 20 Aug</option>
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </select>
              </TableWrapperr>
              <Table2Wrapperr>
                <div className="add-border">
                  <TableComponent columns={columns} data={isdata} />
                </div>
              </Table2Wrapperr>
              </div>
            </Col>
            <Col xl={4} xs={4}>
           
              <TableWrapperr3>
              <h4>Preview Invoice</h4>
              <img className="print-logo" src={print}  />
              </TableWrapperr3>
              <Table2Wrapperr>
                <div className="add-border">
                <img src="/static/media/logo-dashboard.26b9717866e12cf5d6ac.png" 
      style={{
        width: '90.67px',
        height: '16px',
        marginTop: '10px',marginLeft: '10px',}}
    />
<div  style={{
        marginTop: '10px',marginLeft: '15px',}}>SUMMERY
        <StyleLine></StyleLine>
        </div>
        
       <div style={{display: 'flex', alignItems: 'center',gap: '50%', paddingLeft: '10px',padding:'18px'}}>
        <div style={fontstyleName}>
Dr.Vihang
        </div>
        <div style={fontstyleName}> John
            
            </div>
       </div>
       <div style={{display: 'flex', alignItems: 'center', paddingLeft: '10px'}}>
       <TableContainer>
      <Table>
        <thead>
          <tr>
            <TableHeader>item</TableHeader>
            <TableHeader>Rate</TableHeader>
            <TableHeader>Qty</TableHeader>
            <TableHeader>Amount</TableHeader>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TableCell>Vitamin A Night Serum</TableCell>
            <TableCell>$90</TableCell>
            <TableCell>2</TableCell>
            <TableCell>$180</TableCell>
          </tr>
          <tr>
            <TableCell>Vitamin A Night Serum</TableCell>
            <TableCell>$90</TableCell>
            <TableCell>2</TableCell>
            <TableCell>$180</TableCell>
          </tr>
          <tr>
            <TableCell>Vitamin A Night Serum</TableCell>
            <TableCell>$90</TableCell>
            <TableCell>2</TableCell>
            <TableCell>$180</TableCell>
          </tr>
        </tbody>
      </Table>
    </TableContainer>
                </div>
                
                <Container22>
      <Item>
        <Label>Subtotal</Label>
        <Value>$540.00</Value>
      </Item>
      <Item>
        <Label>Tax 5%</Label>
        <Value>$00.00</Value>
      </Item>
      <Item>
        <Label>Discount 10%</Label>
        <Value>$00.00</Value>
      </Item>
    </Container22>
    <StyleLine></StyleLine>
    <Container22>
    <Item>
        <Label>total</Label>
        <Value>$540.00</Value>
      </Item>
    </Container22   >
    <TextContainer>
        <TextItem>Notes/Terms</TextItem>
        <Textarea>Lorem ipsum dolor sit amet. Sit eaque assumenda qui.</Textarea>
    </TextContainer>










                </div>
               
                <div className="add-button">
                <CustomButton>Create New Invoice</CustomButton>
                </div>
              
              </Table2Wrapperr>
              
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </div>
  );
};

export default InvoiceList;
