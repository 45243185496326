import React,{ useState } from "react";
import styled from "styled-components";
import LogoDashboard from "../../assets/logo-dashboard.png";
import BellIcon from "../../assets/bellicons.png";



import MobileLogo from "../../assets/tempLogo.png";
import MobileNotofication from "../../assets/mobilenotification.png";
import MobileToggle from "../../assets/mobiletoggle.png";



import GenericDrawer from "../Drawer/Drawer";
import { NavLink as NavLink23 } from "react-router-dom";

const DateAndTime = styled.div`
  font-size: 14px;
  color: #4c4d4f;
  line-height: 1.5;
  font-weight: 400;
  // margin-right: 30px;
`;

const NotificationContainer = styled.div`
  width: 48px;
  height: 48px;
  background: #f8f8f8;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;
`;

const PageContainer = styled.div`
  // font-family: "Cabin", sans-serif;
  height: 100vh;
  background-color: #fff9f1;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  // height: 80px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 1rem 2.2rem;
  padding: 1rem 0;
`;

const TopBarMobile = styled.div`
  // height: 20vh;
  // background: red;
  background: #ffffff;
  padding: 2.5rem 3.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 40px;
  }
  .logo-img {
    width: 200px;
  }
`;

const Logo = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-left: 30px;
  img {
    width: 13.6rem;
  }
`;

const ReuseAbleHeader = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  return (
    <>
      {/* <TopBar className="desktop-header">
        <Logo>
          <img src={LogoDashboard} />
        </Logo>
        <RightSide>
          <DateAndTime style={{ marginRight: "56px" }}>
            Sunday, 20 August
          </DateAndTime>
          <DateAndTime style={{ marginRight: "41px" }}>1:50 PM</DateAndTime>
          <NotificationContainer>
            <img src={BellIcon} style={{ width: "2.4rem" }} />
          </NotificationContainer>
        </RightSide>
      </TopBar> */}
      <TopBar className="desktop-header">
          <Logo>
            <img src={LogoDashboard} />
          </Logo>
          <RightSide>
            <DateAndTime style={{ marginRight: "56px" }}>
              Sunday, 20 August
            </DateAndTime>
            <DateAndTime style={{ marginRight: "41px" }}>1:50 PM</DateAndTime>
            <NotificationContainer>
              <img src={BellIcon} style={{ width: "2.4rem" }} />
            </NotificationContainer>
          </RightSide>
        </TopBar>
        <GenericDrawer open={isDrawerOpen} onClose={toggleDrawer} />
        <TopBarMobile className="mobile-header">
          <img src={MobileToggle} onClick={toggleDrawer} />
          <img src={MobileLogo} className="logo-img" />
          <img src={MobileNotofication} />
        </TopBarMobile>
    </>
  );
};

export default ReuseAbleHeader;
