import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import logo from '../assets/SkinClubLogo_Logotype_White 1.png'
import modalimg from '../assets/Group 11776.png'
import styled from "styled-components";
import  slider1 from '../assets/slider1.png';
import  slider2 from '../assets/slider2.png';
import  slider3 from '../assets/slider3.png';
import  slider4 from '../assets/slider4.png';
import  footlogo from '../assets/carbon_ibm-watson-speech-to-text.png';
import dropdown  from '../assets/Vector 249.png';

const AppointmentButton = styled.button`
border-radius: 5px;
font-size: 12px;
height: 57px;
color: black;
padding: 0 9px;
display: flex;
align-items: center;
justify-content: space-between;
cursor: pointer;
width: 100%;
border: 1px solid;
background-color: white;
button{
  height: 38px;
  background-color: #343434;
  width: 144px;
  color: white;
  border-radius: 11px;
  border: transparent;
}
`;
const AppointmentButton2 = styled.button`
border-radius: 14px;
height: 48px;
color: #4C4D4F;
padding: 0 20px;
display: flex;
align-items: center;
justify-content: space-between;
cursor: pointer;
width: 100%;
border: 1px solid #4C4D4F33;
background-color: #4C4D4F33;
margin-top: 11px;
font-size: 14px;
    font-weight: 400;
    svg{
      transform: rotate(88deg);
    }
    .selected{
      background-color: transparent;
      border: none;
      width: 100%;
    }     
`;
const AppointmentButton4 = styled.button`
height: 48px;
color: #4C4D4F;
padding: 0 22px;
display: flex;
align-items: center;
justify-content: space-between;
cursor: pointer;
width: 100%;
border: 1px solid #4C4D4F33;
background-color: #F8F8F8;
font-size: 14px;
font-weight: 400;
    font-weight: 400;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    tr{
      display: flex;
      gap: 106px;
    }
    @media (max-width: 768px){
      tr {
        gap: 57px;
      }
    }
    @media (max-width: 691px){
      tr {
        gap: 47px;
      }
    }
    @media (max-width: 656px){
      tr {
        gap: 18px;
      }
    }
    
`;
const AppointmentButton3 = styled.button`
height: 48px;
color: #4C4D4F;
padding: 0 22px;
display: flex;
align-items: center;
justify-content: space-between;
cursor: pointer;
width: 100%;
border: 1px solid #4C4D4F33;
background-color: #F8F8F8;
font-size: 14px;
font-weight: 400;
tr{
  display: flex;
  gap: 106px;
}
@media (max-width: 768px){
  tr {
    gap: 57px;
  }
}
@media (max-width: 691px){
  tr {
    gap: 34px;
  }
}
@media (max-width: 656px){
  tr {
    gap: 18px;
  }
}

.resposive{
  margin-left:5px;;
}
.resposive1{
  margin-left:11px;;
}

`;
const AppointmentButton1 = styled.button`
border-top-right-radius: 13px;
border-top-left-radius: 13px;
height: 48px;
color: #4C4D4F;
padding: 0 20px;
display: flex;
align-items: center;
justify-content: space-between;
cursor: pointer;
width: 100%;
border: 1px solid #4C4D4F33;
background-color: #FFF;
font-size: 16px;
font-weight: 500;
@media (max-width: 691px){
  padding: 0 13px
}
tr{
  display: flex;
  gap: 68px;
}
@media (max-width: 768px){
  tr {
    gap: 20px;
  }
}
.resposive3{
  margin-left: 19px;
}
.resposive4{
  margin-left: 35px;
}
.resposive5{
  margin-left:125px;;
}
@media (max-width: 691px){
  .resposive5{
    margin-left:0px;;
  }
}
@media (max-width: 656px){
  tr {
    gap: 27px;
  }
  .resposive3{
    margin-left: 0px;
  }
  .resposive4{
    margin-left: 0px;
  }
}

`;

const SliderImages = styled.image`
display:flex;
margin-top:11px;
gap:8px;
width: 48px;
height: 48px;
`;
const AppointmentText = styled.image`
font-size:18px;
display: block;
line-height: 3;
`;
const AppointmentPara = styled.image`
font-size:18px;
display: block;
line-height: 2;
    font-weight: 400;
`;
const TagSection = styled.div`
@media (max-width: 656px){
  .tag-section{
    overflow: scroll;
  }
`;
function StaticExample() {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button>
      <Modal show={show} onHide={handleClose}>
        <div style={{width:"200%",backgroundColor:"#fff",marginLeft: "-35%"}}>
          <Modal.Header>
            <img style={{margin:"0 auto",marginTop: "20px",marginBottom: "20px"}} className='logo' src={logo}/>
          </Modal.Header>
          <Modal.Title className='modal-heading'>Upload image for treatment profile </Modal.Title>
          <Modal.Body className='modal-container'>
          <div className="col-xl-7">
        <img style={{width: "14px",
        position: "absolute",
        zIndex: "999999",left: "102px",marginTop: "50px"}} src={dropdown}/>
        <select style={{    borderRadius: "5px",
              position: "absolute",
              marginTop: "37px",
              marginLeft: "15px",
              paddingLeft: "10px",
              paddingTop: "5px",
              paddingBottom: "5px",
              paddingRight: "35px",}} id="mySelect">
          <option value="">Before</option>
          <option value="option1">After</option>
        </select>
        <img className='modal-img' src={modalimg}/>
        </div>
          <div className="col-xl-5 right-text">
          <AppointmentButton>
          Image of treatment <button>upload Image</button>
          </AppointmentButton>
          <div className='slider-button'>
          <SliderImages>
          <img className='slider1' src={slider1}/>
          <img className='slider1' src={slider2}/>
          <img className='slider1' src={slider3}/>
          <img className='slider1' src={slider4}/>
          </SliderImages>
          </div>
          <AppointmentText>Tools</AppointmentText>
          <AppointmentPara>Click on image and ad descriptions about tag</AppointmentPara>
          <AppointmentPara>Tag no : 1</AppointmentPara>
          <AppointmentButton2>
          <select className="selected" name="cars" id="cars">
            <option value="volvo">Select the part of body</option>
            <option value="saab">Saab</option>
          </select>
        </AppointmentButton2>
        
        <AppointmentButton2>
          <select className="selected" name="carss" id="carss">
            <option value="volvos">Titile</option>
            <option value="saabs">Saab</option>
          </select>
        </AppointmentButton2>
        <AppointmentButton2>
          <input type='text' className='selected' placeholder='Dosage name/ mls'/>
        </AppointmentButton2>
        <textarea style={{paddingTop:"15px"}} className='text-area' type="textarea" 
          name="textValue"
          placeholder="Description"
        />
          <button className='Addtag'>Add tag</button>
          </div>
          </Modal.Body>
          <TagSection>
            <div className='tag-section'>
            <AppointmentButton1> 
              <tr>
              <td>Tag no</td>
              <td className='resposive2'>Body region</td>
              <td className='resposive3'>Dosage</td>
              <td className='resposive4'>Description</td>
              <td className='resposive5'>Action</td>
              </tr>
            </AppointmentButton1>
            <AppointmentButton3> 
              <tr>
              <td>1</td>
              <td>Upper Lips</td>
              <td>100 mls</td>
              <td>Lorem ipsum dolor sit amet.....</td>
              <td>View</td>
              </tr>
            </AppointmentButton3>
            <AppointmentButton3> 
              <tr>
              <td>2</td>
              <td>Forehead</td>
              <td className='resposive'>100 mls</td>
              <td>Lorem ipsum dolor sit amet.....</td>
              <td>View</td>
              </tr>
            </AppointmentButton3>
            <AppointmentButton3> 
              <tr>
              <td>3</td>
              <td>Teazone</td>
              <td className='resposive1'>100 mls</td>
              <td>Lorem ipsum dolor sit amet.....</td>
              <td>View</td>
              </tr>
            </AppointmentButton3>
            <AppointmentButton4 className=''> 
              <tr>
              <td>4</td>
              <td>Cheek line</td>
              <td>100 mls</td>
              <td>Lorem ipsum dolor sit amet.....</td>
              <td>View</td>
              </tr>
            </AppointmentButton4>
            </div>
          </TagSection>
          <textarea style={{position: "relative",
            left: "0",right: "0",marginLeft: "auto",
            marginRight: "auto",display: "block",paddingTop: "15px"}} className='text-area' type="textarea" 
            name="textValue"
            placeholder="Add note3"
          />
          <div className='footer'>
          <img className='foot-logo' src={footlogo}/>
          <AppointmentPara>Use Text to speech for notes</AppointmentPara>
          </div>
          
          
          <Modal.Footer>
            <Button className='footer-btn' variant="primary" onClick={handleClose}>
            Submit
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

export default StaticExample;