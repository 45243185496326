// import React, { useState } from "react";
// import styled from "styled-components";
// import ReuseAbleHeader from "../ReuseAbleHeader/ReuseAbleHeader";
// import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";
// import Container from "react-bootstrap/Container";
// import TableComponent from "../TableComponent/TableComponent2"
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import BarChart from '../Chart/chart';
// import img1 from '../../assets/heroicons-solid_gift.png';
// import img2 from '../../assets/Frame.png';
// import CustomClanader from '../CustomCalender/CustomCalender';
// const MainLayout = styled.div`
//   display: flex;
//   flex: 1;
//   background: #fff9f1;
//   // align-items: center;
//   .table-div{
//     padding: 31px;
//     background-color: #fff;
//     border-radius: 10px;
//     margin-top: 15px;
//   }
//   .view-more-btn{
//     font-size: 15px;
//     text-align: center;
//     cursor: pointer;
//     padding: 12px;
//     position: relative;
//     text-decoration: underline;
//   }
//   .treat-m{
//     font-weight: 600;
//     font-size: 15px;
//     color: #4C4D4F;
//     padding-left: 5px;
//     margin-bottom: 15px;
//   }
//   .main-table{
//     border-radius: 10px;
//     border: solid 2px #DBDBDB;
//   }
//   .Treatmentcost{
//     background-color: #fff;
//     border-radius: 10px;
//     margin-top: 25px;
//     padding-top: 25px;
//     padding-bottom: 30px;
//   }
//   .Treatment-Cost-Estimate{
//     background-color: #fff;
//     border-radius: 10px;
//     margin-top: 20px;
//     padding-top: 25px;
//     padding-bottom: 30px;
//   }
//   .second-box{
//     background-color: #fff;
//     padding: 18px;
//     border-radius: 10px;
//     width: 300px;
//   }
//   .second-box div{
//     display: flex;
//     align-items: center;
//     gap: 5px;
//     font-size: 18px;
//   }
//   .second-box p{
//     position: relative;
//     top: 7px;
//     font-weight: 800;
//   }
// `;

// const HeaderWrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-start;

//   .main-heading {
//     h1 {
//       font-size: 3.4rem;
//       font-weight: 400;
//       line-height: 1.5;
//       margin-bottom: 1.4rem;
//       color: #4c4d4f;
//     }
//     p {
//       font-size: 1.4rem;
//       font-weight: 400;
//       line-height: 1.5;
//       margin-bottom: 1.4rem;
//       color: #4c4d4f;
//     }
//   }

// `;
// const columns = [
//   { Header: "ID", accessor: "column1" },
//   { Header: "Coupon Code", accessor: "column2" },
//   { Header: "Order Id", accessor: "column3" },
//   { Header: "Practitioner", accessor: "column4" },
//   { Header: "Product type", accessor: "column5" },
//   { Header: "Status", accessor: "column7" },
// ];

// const data = [
//   {
//     column1: "#A746",
//     column2: "A746",
//     column3: "#7896",
//     column4: "Dr. Vihang",
//     column7: "Section",
//     column5: "Claimed",
//   },
//   {
//     column1: "#A746",
//     column2: "A746",
//     column3: "#7896",
//     column4: "Dr. Vihang",
//     column7: "Section",
//     column5: "Claimed",
//   },

// ];
// const Rewardpoint = () => {
//   const [isdata, setIsData] = useState(data);
//   return (
//     <>
//     <div>
//       <ReuseAbleHeader />
//       <MainLayout>
//         <ReuseAbleSidebar />
//         <Container className="main-content">
//           {/* <Row></Row> */}
//           <HeaderWrapper>
//             <div className="main-heading">
//               <h1>Track Rewards Points</h1>
//               <div style={{marginLeft:"10px"}}>
//                 <h2 style={{fontSize: "21px",fontWeight: "bold"}}>Welcome to our Rewards Program!</h2>
//                 <p style={{fontSize: "16px",paddingTop: "5px",width: "90%"}}>With every purchase, you'll earn valuable points that translate into discounts on future shopping. For each dollar spent, you'll accrue [X] points, and when you've collected enough, simply apply them at checkout to enjoy fantastic savings. Keep track of your points balance here and start redeeming with a minimum of [X] points. Remember, points can't cover taxes or shipping, and terms apply. Your loyalty deserves recognition – happy shopping and saving!</p>
//               </div>
//             </div>
//           </HeaderWrapper>
//           <div className="second-box">
//             <h1 style={{marginBottom: "15px"}}>Current Reward Points</h1>
//             <div>
//                 <img src={img1} />
//                 <p>430 Points</p>
//             </div>
//           </div>
//           <Row>
//             <Col xs={12} md={12}>
//               <div className="table-div">
//                 <p className="treat-m">Reward point history</p>
//                 <div className="main-table">
//                   <TableComponent columns={columns} data={isdata} />
//                   <p className="view-more-btn">View more</p>
//                 </div>
//               </div>
//             </Col>
//           </Row>

//         </Container>
//       </MainLayout>
//     </div>
//     </>
//   );
// };

// export default Rewardpoint;

import React, { useState } from "react";
import styled from "styled-components";
import ReuseAbleHeader from "../ReuseAbleHeader/ReuseAbleHeader";
import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";
import Container from "react-bootstrap/Container";
import TableComponent from "../TableComponent/TableComponent2";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BarChart from "../Chart/chart";
import img1 from "../../assets/heroicons-solid_gift.png";
import img2 from "../../assets/Frame.png";
import CustomClanader from "../CustomCalender/CustomCalender";
const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
  // align-items: center;
  .table-div {
    padding: 31px;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 15px;
  }
  .view-more-btn {
    font-size: 15px;
    text-align: center;
    cursor: pointer;
    padding: 12px;
    position: relative;
    text-decoration: underline;
  }
  .treat-m {
    font-weight: 600;
    font-size: 15px;
    color: #4c4d4f;
    padding-left: 5px;
    margin-bottom: 15px;
  }
  .main-table {
    border-radius: 10px;
    border: solid 2px #dbdbdb;
  }
  @media only screen and (max-width: 992px) {
    .main-table{
      overflow: scroll;
    }
  }
  .Treatmentcost {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 25px;
    padding-top: 25px;
    padding-bottom: 30px;
  }
  .Treatment-Cost-Estimate {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 20px;
    padding-top: 25px;
    padding-bottom: 30px;
  }
  .second-box {
    background-color: #fff;
    padding: 18px;
    border-radius: 10px;
    width: 300px;
  }
  .second-box div {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 18px;
  }
  .second-box p {
    position: relative;
    top: 7px;
    font-weight: 800;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .main-heading {
    h1 {
      font-size: 3.4rem;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 1.4rem;
      color: #4c4d4f;
    }
    p {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 1.4rem;
      color: #4c4d4f;
    }
  }
`;
const columns = [
  { Header: "ID", accessor: "column1" },
  { Header: "Coupon Code", accessor: "column2" },
  { Header: "Order Id", accessor: "column3" },
  { Header: "Practitioner", accessor: "column4" },
  { Header: "Product type", accessor: "column5" },
  { Header: "Status", accessor: "column7" },
];

const data = [
  {
    column1: "#A746",
    column2: "A746",
    column3: "#7896",
    column4: "Dr. Vihang",
    column7: "Section",
    column5: "Claimed",
  },
  {
    column1: "#A746",
    column2: "A746",
    column3: "#7896",
    column4: "Dr. Vihang",
    column7: "Section",
    column5: "Claimed",
  },
];
const Rewardpoint = () => {
  const [isdata, setIsData] = useState(data);
  return (
    <>
      <div>
        <ReuseAbleHeader />
        <MainLayout>
          <ReuseAbleSidebar />
          <Container className="main-content">
            <HeaderWrapper>
              <div className="main-heading">
                <h1>Track Rewards Points</h1>
                <div style={{ marginLeft: "10px" }}>
                  <h2 style={{ fontSize: "21px", fontWeight: "bold" }}>
                    Welcome to our Rewards Program!
                  </h2>
                  <p
                    style={{
                      fontSize: "16px",
                      paddingTop: "5px",
                      width: "90%",
                    }}
                  >
                    With every purchase, you'll earn valuable points that
                    translate into discounts on future shopping. For each dollar
                    spent, you'll accrue [X] points, and when you've collected
                    enough, simply apply them at checkout to enjoy fantastic
                    savings. Keep track of your points balance here and start
                    redeeming with a minimum of [X] points. Remember, points
                    can't cover taxes or shipping, and terms apply. Your loyalty
                    deserves recognition – happy shopping and saving!
                  </p>
                </div>
              </div>
            </HeaderWrapper>
            <div className="second-box">
              <h1 style={{ marginBottom: "15px" }}>Current Reward Points</h1>
              <div>
                <img style={{ width: "50px" }} src={img1} />
                <p>430 Points</p>
              </div>
            </div>
            <Row>
              <Col xs={12} md={12}>
                <div className="table-div">
                  <p className="treat-m">Reward point history</p>
                  <div className="main-table">
                    <TableComponent columns={columns} data={isdata} />
                    <p className="view-more-btn">View more</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </MainLayout>
      </div>
    </>
  );
};

export default Rewardpoint;
