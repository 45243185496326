import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import { yellow } from "@mui/material/colors";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const MuiCheckbox = () => {
  return (
    // <div>
    //   <Checkbox
    //     {...label}
    //     defaultChecked
    //     sx={{
    //       color: "#a5a6a7",
    //       "&.Mui-checked": {
    //         color: "#f1b34a",
    //       },
    //     }}
    //   />
    //   <Checkbox
    //     {...label}
    //     defaultChecked
    //     sx={{
    //       color: "#a5a6a7",
    //       "&.Mui-checked": {
    //         color: "#f1b34a",
    //       },
    //     }}
    //   />
    // </div>
    <FormGroup style={{ flexDirection: "row" }}>
      <FormControlLabel
        control={
          <Checkbox
            defaultChecked
            sx={{
              color: "#a5a6a7",
              "&.Mui-checked": {
                color: "#f1b34a",
              },
            }}
          />
        }
        label="Shine Serum "
      />
      <FormControlLabel
        required
        control={
          <Checkbox
            sx={{
              color: "#a5a6a7",
              "&.Mui-checked": {
                color: "#f1b34a",
              },
            }}
          />
        }
        label="PRP Injection"
      />
      <FormControlLabel
        required
        control={
          <Checkbox
            sx={{
              color: "#a5a6a7",
              "&.Mui-checked": {
                color: "#f1b34a",
              },
            }}
          />
        }
        label="White cell cream"
      />
    </FormGroup>
  );
};

export default MuiCheckbox;
