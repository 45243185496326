import { createTheme } from "@mui/material/styles";
import { ButtonDark } from "./Button/Button";

const DarkTheme = createTheme({
  palette: {
    primary: {
      main: "#000000",
      type: "dark",
    },
  },
  components: {
    MuiButton: ButtonDark,
  },
});

export default DarkTheme;
