import React, { useState } from "react";
import styled from "styled-components";
import ReuseAbleHeader from "../ReuseAbleHeader/ReuseAbleHeader";
import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";
import Container from "react-bootstrap/Container";
import TableComponent from "../TableComponent/TableComponent2";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BarChart from "../Chart/chart";
import img1 from "../../assets/Group 11447.png";
import img2 from "../../assets/Frame.png";
import CustomClanader from "../CustomCalender/CustomCalender";
import { BsChevronDown } from "react-icons/bs";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import { FiSettings } from "react-icons/fi";

import graphdown from "../../assets/graphdown.png";
import graphdown2 from "../../assets/graphdown2.png";

import RecentActivities from "../RecentActivities/RecentActivities";

import CustomerSupportActivities from "../CustomerSupportActivities/CustomerSupportActivities";

import InvoiceListForUserDashboard from "../InvoiceListForUserDashboard/InvoiceListForUserDashboard";

import Menu from "../Menu/Menu";
import SearchArea from "../SearchInput/SearchInput";
import { AiOutlineExport } from "react-icons/ai";

const TabWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 2.1rem 2.7rem 0;
  border-bottom: 1px solid #dbdbdc;

  .tabs {
    display: flex;
    gap: 22px;
    p {
      margin: 0;
    }
  }
  .tabs p:nth-child(2) {
    padding-bottom: 0.7rem;
    border-bottom: 2px solid #000;
  }

  .export {
    p {
      margin: 0;
    }
  }
`;

const spanStyle = {
  backgroundColor: "#36C490",
  width: "75px",
  height: "21px",
  top: "391px",
  left: "1178px",
  borderRadius: "11px",
  display: "inline-block",
  fontFamily: "Cabin", // Font family
  fontSize: "10px", // Font size
  fontWeight: 400, // Font weight
  lineHeight: "21px", // Line height
  letterSpacing: "0em", // Letter spacing
  textAlign: "left", // Text alignment
  color: "white",
  letterSpacing: "0em",
  textAlign: "center",
};
const spanStyleerr = {
  backgroundColor: "#E23943",
  width: "75px",
  height: "21px",
  top: "391px",
  left: "1178px",
  borderRadius: "11px",
  display: "inline-block",
  fontFamily: "Cabin", // Font family
  fontSize: "10px", // Font size
  fontWeight: 400, // Font weight
  lineHeight: "21px", // Line height
  letterSpacing: "0em", // Letter spacing
  textAlign: "left", // Text alignment
  color: "white",
  letterSpacing: "0em",
  textAlign: "center",
};
const spanStyleprog = {
  backgroundColor: "#FFD76F",
  width: "75px",
  height: "21px",
  top: "391px",
  left: "1178px",
  borderRadius: "11px",
  display: "inline-block",
  fontFamily: "Cabin", // Font family
  fontSize: "10px", // Font size
  fontWeight: 400, // Font weight
  lineHeight: "21px", // Line height
  letterSpacing: "0em", // Letter spacing
  textAlign: "left", // Text alignment
  color: "white",
  letterSpacing: "0em",
  textAlign: "center",
};

const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
  // align-items: center;
  .table-div {
    // padding: 31px;
    // background-color: #fff;
    border-radius: 10px;
    // margin-top: 15px;
    padding-bottom: 31px;
  }
  .view-more-btn {
    font-size: 15px;
    text-align: center;
    cursor: pointer;
    padding: 0 12px 0;
    position: relative;
    text-decoration: underline;
  }
  .treat-m {
    font-weight: 600;
    font-size: 15px;
    color: #4c4d4f;
    padding-left: 5px;
    margin-bottom: 15px;
  }
  .main-table {
    // border-radius: 10px;
    // border: solid 2px #dbdbdb;
    @media screen and (max-width: 1060px) {
      .nested-main-table {
        min-width: 1060px;
        overflow-x: auto;
        margin-top: 2.8rem;
        .container {
          margin: 0;
          min-width: 1060px;
          overflow-x: auto;
        }
      }
    }
  }
  .Treatmentcost {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 25px;
    padding-top: 25px;
    padding-bottom: 30px;
  }
  .Treatment-Cost-Estimate {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 20px;
    padding-top: 25px;
    padding-bottom: 30px;
  }
  .input-shape {
    border: solid 1px #d6d6d6;
    border-radius: 10px;
    padding: 15px;
    font-size: 13px;
    width: 340px;
  }
  .txt-color-style {
    font-size: 14px;
    color: #a5a6a7;
    width: 70%;
    padding-left: 30px;
  }
  .img-style {
    width: 78%;
    display: block;
    margin: 0 auto;
  }
  .card-sub-box {
    background-color: #e1d0c1;
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 15px;
    gap: 35px;
    justify-content: center;
  }
  .checkbox-typ {
    display: flex;
    gap: 10px;
    margin-left: 25px;
    font-size: 12px;
    margin-top: 10px;
  }
  .bookknowbtn {
    padding: 10px;
    width: 80%;
    display: block;
    margin: 0 auto;
    margin-top: 19px;
    border-radius: 10px;
    background-color: #000;
    color: #fff;
    font-size: 12px;
  }
  .searchinput {
    padding: 16px;
    border-radius: 12px;
    border: none;
    margin-right: 10px;
    width: 225px;
    font-size: 11px;
  }
  .spmtxt {
    color: #4c4d4f !important;
  }
  .joyetxt {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    margin-top: 18px;
  }
  .circle {
    height: 10px;
    width: 10px;
    background-color: #4c4d4f;
    border-radius: 30px;
  }
  .circletxt {
    position: relative;
    top: 4px;
    font-size: 14px;
  }
  .selecttt {
    margin: 0;
    padding: 7px 14px;
    background-color: #f8f8f8;
    border-radius: 8px;
    @media screen and (max-width: 600px) {
      font-size: 0.8rem;
    }
  }
  .line-design {
    border-left: solid 1px;
    margin-left: 5px;
    margin-top: -11px;
    padding-top: 8px;
    padding-bottom: 20px;
    margin-bottom: -12px;
  }
`;

const SelectWeekWrapper = styled.div`
  // display: flex;
  // align-items: center;
  // gap: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;

  .cus-btn {
    border-radius: 5px;
  }
  @media screen and (max-width: 600px) {
    display: flex;
    .cus-btn {
      font-size: 1.6rem;
      width: fit-content;
      padding: 5px 11px;
    }
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .main-heading {
    width: 100%;
    h1 {
      font-size: 3.4rem;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 1.4rem;
      color: #4c4d4f;
      @media screen and (max-width: 600px) {
        font-size: 1.6rem;
      }
    }
    p {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 1.4rem;
      color: #4c4d4f;
      @media screen and (max-width: 600px) {
        font-size: 1.1rem;
      }
    }
  }
`;

const Table2Wrapperr = styled.div`
  height: 94%;

  // display: flex;
  // flex: 1;
  // background: #fff9f1;
  padding: 22px 28px;
  background: #fff;
  border-radius: 0 0 14px 14px;

  .add-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
  }
  .add-border {
    border: 1px solid #d6d6d6;
    border-radius: 14px;
    height: 90%;
    overflow: hidden;
    background-color: #f8f8f8;
  }
`;

const TableWrapperr = styled.div`
  border-radius: 14px 14px 0 0;
  margin-top: 19px;
  .innerclass {
    display: flex;
    flex: 1;
    background: #fff9f1;
    border-bottom: 1px solid #dbdbdc;
    padding: 2.1rem 2.7rem;
    background-color: #fff;
    // border-radius: 14px 14px 0 0;
    justify-content: space-between;
    // gap: 298px;
    @media only screen and (max-width: 900px) {
      gap: 100px;
      padding: 1.1rem 0.7rem;
    }
    @media only screen and (max-width: 500px) {
      gap: 18px;
    }
    #mySelect {
      background: #f8f8f8;
      font-size: 13px;
      border: transparent;
      border-radius: 8px;
      padding: 8px;
    }
    h1 {
    }
  }
`;

const columns = [
  { Header: "Treatment", accessor: "column1" },
  { Header: "Time", accessor: "column2" },
  { Header: "Date", accessor: "column3" },
  { Header: "Patient", accessor: "column4" },
  { Header: "Practitioner", accessor: "column5" },
  { Header: "Studio", accessor: "column6" },
  { Header: "Status", accessor: "column7" },
  { Header: "Action", accessor: "column8" },
];

const data = [
  {
    column1: "PRP Injections",
    column2: "09:00 - 10:30",
    column3: "21 Aug,2023",
    column4: "John Doe",
    column5: "Dr. Vihang",
    column6: "Toorak",
    column7: <span style={spanStyleerr}>Cancled</span>,
    column8: <Menu />,
  },
  {
    column1: "PRP Injections",
    column2: "09:00 - 10:30",
    column3: "21 Aug,2023",
    column4: "John Doe",
    column5: "Dr. Vihang",
    column6: "Toorak",
    column7: <span style={spanStyle}>Complete</span>,
    column8: <Menu />,
  },
  {
    column1: "PRP Injections",
    column2: "09:00 - 10:30",
    column3: "21 Aug,2023",
    column4: "John Doe",
    column5: "Dr. Vihang",
    column6: "Toorak",
    column7: <span style={spanStyle}>Complete</span>,
    column8: <Menu />,
  },
  {
    column1: "PRP Injections",
    column2: "09:00 - 10:30",
    column3: "21 Aug,2023",
    column4: "John Doe",
    column5: "Dr. Vihang",
    column6: "Toorak",
    column7: <span style={spanStyle}>Complete</span>,
    column8: <Menu />,
  },
  {
    column1: "PRP Injections",
    column2: "09:00 - 10:30",
    column3: "21 Aug,2023",
    column4: "John Doe",
    column5: "Dr. Vihang",
    column6: "Toorak",
    column7: <span style={spanStyle}>Complete</span>,
    column8: <Menu />,
  },
  {
    column1: "PRP Injections",
    column2: "09:00 - 10:30",
    column3: "21 Aug,2023",
    column4: "John Doe",
    column5: "Dr. Vihang",
    column6: "Toorak",
    column7: <span style={spanStyleprog}>in progress</span>,
    column8: <Menu />,
  },
];

const UserManagementDashboard = () => {
  const [isdata, setIsData] = useState(data);
  return (
    <>
      <div>
        <ReuseAbleHeader />
        <MainLayout>
          <ReuseAbleSidebar />
          <Container className="main-content">
            {/* <Row></Row> */}
            <HeaderWrapper>
              <div className="main-heading">
                <h1>Admin Dashboard</h1>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "40px",
                    width: "100%",
                    marginBottom: "33px",
                  }}
                >
                  <p style={{ marginBottom: 0 }}>Appointment management</p>
                  <SelectWeekWrapper>
                    <p
                      className="selecttt"
                      style={{ marginBottom: 0, marginRight: "10px" }}
                    >
                      Select Week: | This Week <BsChevronDown />
                    </p>
                    <ButtonComponent
                      className="cus-btn"
                      style={{ borderRadius: "8px", width: "auto" }}
                    >
                      <FiSettings style={{ marginRight: "8px" }} />
                      Settings
                    </ButtonComponent>
                  </SelectWeekWrapper>
                </div>
              </div>
            </HeaderWrapper>

            <Row>
              <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                <Row>
                  <Col xs={4} md={4}>
                    <div
                      style={{
                        backgroundColor: "#fff",
                        paddingTop: "29px",
                        paddingBottom: "29px",
                        paddingLeft: "28px",
                        paddingRight: "28px",
                        borderRadius: "10px",
                      }}
                    >
                      <h1 style={{ fontSize: "14px", fontWeight: 400 }}>
                        Active Users
                      </h1>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: "44px" }}>430</h1>
                          <p
                            style={{
                              fontSize: "16px",
                              marginLeft: "5px",
                              marginTop: "10px",
                              color: "#A5A6A7",
                            }}
                          >
                            Total: <span style={{ color: "#000" }}>945</span>
                          </p>
                        </div>
                        <img style={{ width: "37%" }} src={img1} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          position: "relative",
                          top: "20px",
                        }}
                      >
                        <img style={{ width: "30px" }} src={img2} />
                        <p
                          style={{
                            position: "relative",
                            top: "6px",
                            fontSize: "12px",
                          }}
                        >
                          <span>+12%</span> than last month
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={4} md={4}>
                    <div
                      style={{
                        backgroundColor: "#fff",
                        paddingTop: "29px",
                        paddingBottom: "29px",
                        paddingLeft: "28px",
                        paddingRight: "28px",
                        borderRadius: "10px",
                      }}
                    >
                      <h1 style={{ fontSize: "14px", fontWeight: 400 }}>
                        Appointments
                      </h1>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: "44px" }}>430</h1>
                          <p
                            style={{
                              fontSize: "16px",
                              marginLeft: "5px",
                              marginTop: "10px",
                              color: "#A5A6A7",
                            }}
                          >
                            Total: <span style={{ color: "#000" }}>945</span>
                          </p>
                        </div>
                        <img style={{ width: "37%" }} src={graphdown2} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          position: "relative",
                          top: "20px",
                        }}
                      >
                        <img style={{ width: "30px" }} src={graphdown} />
                        <p
                          style={{
                            position: "relative",
                            top: "6px",
                            fontSize: "12px",
                          }}
                        >
                          <span style={{ color: "#E23943" }}>-12%</span> than
                          last month
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={4} md={4}>
                    <div
                      style={{
                        backgroundColor: "#fff",
                        paddingTop: "29px",
                        paddingBottom: "29px",
                        paddingLeft: "28px",
                        paddingRight: "28px",
                        borderRadius: "10px",
                      }}
                    >
                      <h1 style={{ fontSize: "14px", fontWeight: 400 }}>
                        Active Users
                      </h1>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: "44px" }}>430</h1>
                          <p
                            style={{
                              fontSize: "16px",
                              marginLeft: "5px",
                              marginTop: "10px",
                              color: "#A5A6A7",
                            }}
                          >
                            Total: <span style={{ color: "#000" }}>945</span>
                          </p>
                        </div>
                        <img style={{ width: "37%" }} src={img1} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          position: "relative",
                          top: "20px",
                        }}
                      >
                        <img style={{ width: "30px" }} src={img2} />
                        <p
                          style={{
                            position: "relative",
                            top: "6px",
                            fontSize: "12px",
                          }}
                        >
                          <span>+12%</span> than last month
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} md={6}>
                    <div
                      style={{
                        backgroundColor: "#fff",
                        paddingTop: "50px",
                        paddingBottom: "50px",
                        paddingLeft: "18px",
                        paddingRight: "18px",
                        borderRadius: "10px",
                        marginTop: "18px",
                      }}
                    >
                      <BarChart />
                    </div>
                  </Col>
                  <Col xs={6} md={6}>
                    <div
                      style={{
                        // backgroundColor: "#fff",
                        // paddingTop: "50px",
                        // paddingBottom: "50px",
                        // paddingLeft: "18px",
                        // paddingRight: "18px",
                        // borderRadius: "10px",
                        marginTop: "18px",
                      }}
                    >
                      {/* <BarChart /> */}
                      <CustomerSupportActivities
                        columns={columns}
                        data={isdata}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                <RecentActivities />
                {/* <div
                  style={{
                    backgroundColor: "#fff",
                    padding: "25px",
                    borderRadius: "10px",
                  }}
                >
                  <p style={{ fontSize: "16px", marginBottom: "30px" }}>
                    Your Rewards Schedule
                  </p>
                  <CustomClanader />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div className="circle"></div>{" "}
                    <p className="circletxt">10:00 AM</p>
                  </div>
                  <div className="line-design">
                    <div
                      style={{
                        backgroundColor: "#F8F8F8",
                        padding: "20px",
                        borderRadius: "10px",
                        marginLeft: "50px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <div>
                            <h1 style={{ fontSize: "15px" }}>Consultation</h1>
                          </div>
                          <p style={{ color: "#A5A6A7" }}>
                            09:00 AM - 10:30 AM
                          </p>
                        </div>
                        <p style={{ color: "#A5A6A7" }}>Point 25</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>Annie Smith</p>
                        <p style={{ color: "#A5A6A7" }}>Earned</p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div className="circle"></div>{" "}
                    <p className="circletxt">10:00 AM</p>
                  </div>
                  <div className="line-design">
                    <div
                      style={{
                        backgroundColor: "#F8F8F8",
                        padding: "20px",
                        borderRadius: "10px",
                        marginLeft: "50px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <div>
                            <h1 style={{ fontSize: "15px" }}>Consultation</h1>
                          </div>
                          <p style={{ color: "#A5A6A7" }}>
                            09:00 AM - 10:30 AM
                          </p>
                        </div>
                        <p style={{ color: "#A5A6A7" }}>Point 25</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>Annie Smith</p>
                        <p style={{ color: "#A5A6A7" }}>Earned</p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div className="circle"></div>{" "}
                    <p className="circletxt">10:00 AM</p>
                  </div>
                  <div className="line-design">
                    <div
                      style={{
                        backgroundColor: "#F8F8F8",
                        padding: "20px",
                        borderRadius: "10px",
                        marginLeft: "50px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <div>
                            <h1 style={{ fontSize: "15px" }}>Consultation</h1>
                          </div>
                          <p style={{ color: "#A5A6A7" }}>
                            09:00 AM - 10:30 AM
                          </p>
                        </div>
                        <p style={{ color: "#A5A6A7" }}>Point 25</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>Annie Smith</p>
                        <p style={{ color: "#A5A6A7" }}>Earned</p>
                      </div>
                    </div>
                  </div>
                </div> */}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <div className="table-div">
                  {/* <div> */}
                  {/* <p className="treat-m">Treatment History</p> */}
                  <div className="main-table">
                    {/* <TableComponent columns={columns} data={isdata} /> */}
                    {/* <InvoiceListForUserDashboard
                      columns={columns}
                      data={isdata}
                    /> */}
                    <div className="nested-main-table">
                      <Container>
                        <Row>
                          <Col xl={12} xs={12}>
                            <TableWrapperr>
                              <TabWrapper>
                                <div className="tabs">
                                  <p>Users</p>
                                  <p
                                    style={{ color: "#000", fontWeight: "700" }}
                                  >
                                    Appointments
                                  </p>
                                  <p>Transactions</p>
                                  <p>Inventory</p>
                                  <p>Patient Records</p>
                                  <p>Doctor’s Performance</p>
                                </div>
                                <div className="export">
                                  <p>
                                    <AiOutlineExport /> Export CSV
                                  </p>
                                </div>
                              </TabWrapper>
                              <div className="innerclass">
                                <SearchArea></SearchArea>
                                <div>
                                  <select id="mySelect">
                                    <option value="">
                                      Filter By : | Upcoming
                                    </option>
                                    <option value="option1">Option 1</option>
                                    <option value="option2">Option 2</option>
                                    <option value="option3">Option 3</option>
                                  </select>
                                  <select
                                    id="mySelect"
                                    style={{ marginLeft: "8px" }}
                                  >
                                    <option value="">
                                      Select Date: | 20 Aug
                                    </option>
                                    <option value="option1">Option 1</option>
                                    <option value="option2">Option 2</option>
                                    <option value="option3">Option 3</option>
                                  </select>
                                </div>
                              </div>
                            </TableWrapperr>
                            <Table2Wrapperr>
                              <div className="add-border">
                                <TableComponent
                                  columns={columns}
                                  data={data}
                                  btntobe
                                />
                              </div>
                            </Table2Wrapperr>
                          </Col>
                        </Row>
                      </Container>
                      <p className="view-more-btn">View more</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {/* <Row>
            <Col xs={12} md={12}>
              <div className="table-div">
                <p className="treat-m">Treatment History</p>
                <div className="main-table">
                  <TableComponent columns={columns} data={isdata} />
                  <p className="view-more-btn">View more</p>
                </div>
              </div>
            </Col>
          </Row> */}
          </Container>
        </MainLayout>
      </div>
    </>
  );
};

export default UserManagementDashboard;
