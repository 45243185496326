import React, { useState } from "react";
import styled from "styled-components";
import { Accordion } from "react-bootstrap";
import { NavLink as AliasLink } from "react-router-dom";

const Sidebar = styled.div`
  background: #ffffff;
  min-width: 240px;
  min-height: 100vh;
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 20px;
`;

const Navigation = styled.ul`
  padding: 0;
`;

const ListItem = styled.li`
  list-style: none;
  padding: 14px 0 14px 20px;
  border-radius: 14px;
  font-size: 1.8rem;

  line-height: 1.2;
`;

const NavLink = styled.a`
  text-decoration: none;
  color: black;

  &:hover {
    color: black;
  }
`;

const ActiveLink = styled(NavLink)`
  font-weight: bold;
`;

const AccordionButton = styled.button`
  padding: 0;
  font-size: 1.8rem;
  line-height: 1.2;

  &.collapsed {
    box-shadow: none;
  }
`;

const Sublink = styled.div`
  margin-top: 15px;
`;

const SublinkText = styled.a`
  font-weight: 400;
  font-size: 14px;
  color: #4c4d4f;
  padding-left: 12px;
`;

const ReuseAbleSidebar = () => {
  const [activeItem, setActiveItem] = useState(0);
  return (
    <>
      {" "}
      <Sidebar className="sidear-for-desktop">
        <Navigation>
          <ListItem
            style={{
              backgroundColor: activeItem === 0 ? "#FFF9F1" : "transparent",
              fontWeight: activeItem === 0 ? "bold" : "",
            }}
            onClick={() => setActiveItem(0)}
          >
            <AliasLink to="/patient-dashboard">
              <NavLink href="/patient-dashboard">Overview</NavLink>
            </AliasLink>
          </ListItem>
          <ListItem
            style={{
              backgroundColor: activeItem === 1 ? "#FFF9F1" : "transparent",
              fontWeight: activeItem === 1 ? "bold" : "",
            }}
            onClick={() => setActiveItem(1)}
          >
            {/* <AliasLink to="/patient-dashboard"> */}
            <NavLink href="#">Profile</NavLink>
            {/* </AliasLink> */}
          </ListItem>
          <ListItem
            style={{
              backgroundColor: activeItem === 2 ? "#FFF9F1" : "transparent",
              fontWeight: activeItem === 2 ? "bold" : "",
            }}
            onClick={() => setActiveItem(2)}
          >
            <AliasLink to="/appointment">
              <NavLink href="#">Appointments</NavLink>
            </AliasLink>
          </ListItem>
          <ListItem style={{ padding: 0 }}>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header
                  style={{
                    backgroundColor: activeItem === 3 ? "#FFF9F1" : "",
                    fontWeight: activeItem === 3 ? "bold" : "",
                    padding: "14px 0 14px 20px",
                    borderRadius: "14px",
                    color: "#000",
                  }}
                  onClick={() => setActiveItem(3)}
                >
                  History
                </Accordion.Header>
                <Accordion.Body>
                  <Sublink>
                    <AliasLink to="/videolibrarytiktok">
                      <SublinkText href="#">Videos</SublinkText>
                    </AliasLink>
                  </Sublink>
                  <Sublink>
                    <AliasLink to="/videolibraryyoutube">
                      <SublinkText href="#">Videos Library</SublinkText>
                    </AliasLink>
                  </Sublink>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </ListItem>
          <ListItem
            style={{
              backgroundColor: activeItem === 4 ? "#FFF9F1" : "transparent",
              fontWeight: activeItem === 4 ? "bold" : "",
            }}
            onClick={() => setActiveItem(4)}
          >
            <AliasLink to="/supportchat">
              <NavLink href="#">Messages</NavLink>
            </AliasLink>
          </ListItem>
          <ListItem style={{ padding: 0 }}>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header
                  style={{
                    backgroundColor: activeItem === 10 ? "#FFF9F1" : "",
                    fontWeight: activeItem === 10 ? "bold" : "",
                    padding: "14px 0 14px 20px",
                    borderRadius: "14px",
                    color: "#000",
                  }}
                  onClick={() => setActiveItem(10)}
                >
                  Support
                </Accordion.Header>
                <Accordion.Body>
                  <Sublink>
                    <AliasLink to="/faqs">
                      <SublinkText href="#">FAQ’s</SublinkText>
                    </AliasLink>
                  </Sublink>
                  <Sublink>
                    <AliasLink to="/customersurvey1">
                      <SublinkText href="#">Procedure Information</SublinkText>
                    </AliasLink>
                  </Sublink>
                  {/* </div>  */}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </ListItem>
          <ListItem style={{ padding: 0 }}>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header
                  style={{
                    backgroundColor: activeItem === 13 ? "#FFF9F1" : "",
                    fontWeight: activeItem === 13 ? "bold" : "",
                    padding: "14px 0 14px 20px",
                    borderRadius: "14px",
                    color: "#000",
                  }}
                  onClick={() => setActiveItem(13)}
                >
                  Aftercare
                </Accordion.Header>

                <Accordion.Body>
                  <Sublink>
                    <AliasLink to="/aftercarepage">
                      <SublinkText>Quizzes</SublinkText>
                    </AliasLink>
                  </Sublink>
                  <Sublink>
                    <AliasLink to="/aftercarepage">
                      <SublinkText>Health & Skin Assessment</SublinkText>
                    </AliasLink>
                  </Sublink>
                  {/* </div>  */}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </ListItem>
          <ListItem
            style={{
              backgroundColor: activeItem === 5 ? "#FFF9F1" : "transparent",
              fontWeight: activeItem === 5 ? "bold" : "",
            }}
            onClick={() => setActiveItem(5)}
          >
            {/* <AliasLink to="/patient-dashboard"> */}
            <NavLink href="#">Finances</NavLink>
            {/* </AliasLink> */}
          </ListItem>
          <ListItem
            style={{
              backgroundColor: activeItem === 6 ? "#FFF9F1" : "transparent",
              fontWeight: activeItem === 6 ? "bold" : "",
            }}
            onClick={() => setActiveItem(6)}
          >
            <AliasLink to="/virtualconsultation">
              <NavLink href="#">Virtual Consultation</NavLink>
            </AliasLink>
          </ListItem>
        </Navigation>
      </Sidebar>
    </>
  );
};

export default ReuseAbleSidebar;
