import React, { useState } from "react";
import styled from "styled-components";
import ReuseAbleHeader from "../ReuseAbleHeader/ReuseAbleHeader";
import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TableComponent from "../TableComponent/TableComponent";
import SpanComponent from "../StatusSpan/StatusSpan";
import OptionMenu from "../DotMenu/DotMenu";
import SearchArea from "../SearchInput/SearchInput"

const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
`;
const Table2Wrapperr = styled.div`
  height: 100%;

  // display: flex;
  // flex: 1;
  // background: #fff9f1;
  padding: 22px 28px;
  background: #fff;
  border-radius: 0 0 14px 14px;

  .add-border {
    border: 1px solid #d6d6d6;
    border-radius: 14px;
    overflow: auto;
  }
`;

const TableWrapperr = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
  border-bottom: 1px solid #dbdbdc;
  padding: 2.1rem 2.7rem;
  background-color: #fff;
  border-radius: 14px 14px 0 0;

  h1 {
  }
`;



const columns = [
  { Header: "ProductID", accessor: "column1" },
  { Header: "Name", accessor: "column2" },
  { Header: "Category", accessor: "column3" },
  { Header: "Quantity", accessor: "column4" },
  { Header: "Price ", accessor: "column5" },
  { Header: "Discounted Price", accessor: "column6" },
  { Header: "Status ", accessor: "column7" },
  { Header: "Action ", accessor: "column8" },
];
const spanStyle = {
  backgroundColor: '#36C490',
  width: '75px',
  height: '21px',
  top: '391px',
  left: '1178px',
  borderRadius: '11px',
  display: 'inline-block',
  fontFamily: 'Cabin', // Font family
  fontSize: '10px', // Font size
  fontWeight: 400, // Font weight
  lineHeight: '21px', // Line height
  letterSpacing: '0em', // Letter spacing
  textAlign: 'left', // Text alignment
  color : 'white',
  letterSpacing: '0em',
  textAlign: 'center',
};

const h3Style={

    fontFamily: 'Cabin',
    fontSize: '20px',
    fontWeight: '400',
    lineHeight: '22px',
    letterSpacing: '0em',
    textAlign: 'left',

}
// const data = isdata.map((tableItem) => ({
const data = [
  {
    column1: "#4589",
    column2: "AHA Deep Clean Exfoliator",
    column3: "Brightening Vitamin A Serum",
    column4: "30ml",
    column5: "$90.00",
    column6: "$69.00",
    column7: <span style={spanStyle}>In Stock</span>,
    column8: <OptionMenu></OptionMenu>,
  },
  {
    column1: "#4589",
    column2: "AHA Deep Clean Exfoliator",
    column3: "Brightening Vitamin A Serum",
    column4: "30ml",
    column5: "$90.00",
    column6: "$69.00",
    column7: <SpanComponent>Out of Stock</SpanComponent>,
    column8: <OptionMenu></OptionMenu>,
  },
  
  {
    column1: "#4589",
    column2: "AHA Deep Clean Exfoliator",
    column3: "Brightening Vitamin A Serum",
    column4: "30ml",
    column5: "$90.00",
    column6: "$69.00",
    column7: <SpanComponent>Out of Stock</SpanComponent>,
    column8: <OptionMenu></OptionMenu>,
  },
  
  {
    column1: "#4589",
    column2: "AHA Deep Clean Exfoliator",
    column3: "Brightening Vitamin A Serum",
    column4: "30ml",
    column5: "$90.00",
    column6: "$69.00",
    column7: <SpanComponent>Out of Stock</SpanComponent>,
    column8: <OptionMenu></OptionMenu>,
  },
  
  {
    column1: "#4589",
    column2: "AHA Deep Clean Exfoliator",
    column3: "Brightening Vitamin A Serum",
    column4: "30ml",
    column5: "$90.00",
    column6: "$69.00",
    column7: <SpanComponent>Out of Stock</SpanComponent>,
    column8: <OptionMenu></OptionMenu>,
  },
];

const InventoryManagement = () => {
  const [isdata, setIsData] = useState(data);
  return (
    <div>
      <ReuseAbleHeader />
      <MainLayout>
        <ReuseAbleSidebar />
        <Container className="main-content">
          <Row>
            <Col xl={12} xs={12}>
              <h1 className="after-care-heading">Inventory Management </h1>
              <h3 className="after-treatment-heading">All products inventory management</h3>
              <TableWrapperr>
              <SearchArea></SearchArea>
              </TableWrapperr>
              <Table2Wrapperr>
                <div className="add-border">
                  <TableComponent columns={columns} data={isdata} />
                </div>
              </Table2Wrapperr>
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </div>
  );
};

export default InventoryManagement;
