import React, { useState } from "react";
import styled from "styled-components";
import ReuseAbleHeader from "../ReuseAbleHeader/ReuseAbleHeader";
import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TableComponent from "../TableComponent/TableComponent";
// import SpanComponent from "../StatusSpan/StatusSpan";
// import OptionMenu from "../DotMenu/DotMenu";
import SearchArea from "../SearchInput/SearchInput"
import Template from "../../assets/Templates.png"
import Layout from "../../assets/Layout.png"
import Assests from "../../assets/Assests.png"
import Color from "../../assets/Color.png"
import Background from "../../assets/Background.png"
import Shapes from "../../assets/Shapes.png"
import Images from "../../assets/Images.png"
import Icons from "../../assets/Icons.png"

const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
  height: 100%;
`;






const TableWrapperr3 = styled.div`
display: flex;
background: #fff9f1;
padding: 2.1rem 2.7rem;
background-color: #fff;
border-radius: 14px 14px 0 0;
gap: 14px;
flex-direction: column;
  h1 {
    font-size: 20px;
    color: #4C4D4F;
  }
  img{
    width:18px;
    height:18px;
  }
  @media only screen and (max-width: 992px){
    margin-top: 62px;
  }
`;



const columns = [
  { Header: "Patient", accessor: "column1" },
  { Header: "Time", accessor: "column2" },
  { Header: "Date", accessor: "column3" },
  { Header: "Total", accessor: "column4" },
  { Header: "Status", accessor: "column5" },

];
const spanStyle = {
  backgroundColor: '#36C490',
  width: '75px',
  height: '21px',
  top: '391px',
  left: '1178px',
  borderRadius: '11px',
  display: 'inline-block',
  fontFamily: 'Cabin', // Font family
  fontSize: '10px', // Font size
  fontWeight: 400, // Font weight
  lineHeight: '21px', // Line height
  letterSpacing: '0em', // Letter spacing
  textAlign: 'left', // Text alignment
  color : 'white',
  letterSpacing: '0em',
  textAlign: 'center',
};

const CustomButton = styled.button`
  width: 184px;
  height: 48px;
  align:center;
  border-radius: 24px;
  background-color: black;
  color: white; /* Adding white text color for better visibility on a black background */
  font-family: Cabin;
  font-size: 16px; /* Adjust the font size as needed */
  font-weight: 400;
  line-height: 20px; /* Adjust line height as needed */
  letter-spacing: 0em;
  text-align: center;
  border: none;
  cursor: pointer;

`;


const h3Style={

    fontFamily: 'Cabin',
    fontSize: '20px',
    fontWeight: '400',
    lineHeight: '22px',
    letterSpacing: '0em',
    textAlign: 'left',

}

const fontstyleName = {
    fontWeight: 800,
    fontSize: '12px',
    lineHeight: '21px',
  };

  const Container22 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  width: 84%;
`;
  const Container23 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  width: 95%;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin-bottom: 10px;
  line-height: 2;
`;
const TextPara = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;;
  margin-bottom: 10px;
  line-height: 2;
`;
const CustomButton2 = styled.button`
margin: 0 auto;
  width: 184px;
  height: 48px;
  align:center;
  border-radius: 24px;
  background-color: black;
  color: white; /* Adding white text color for better visibility on a black background */
  font-family: Cabin;
  font-size: 16px; /* Adjust the font size as needed */
  font-weight: 400;
  line-height: 20px; /* Adjust line height as needed */
  letter-spacing: 0em;
  text-align: center;
  border: none;
  cursor: pointer;

`;
const Label = styled.span`
  font-weight: bold;
`;

const Value = styled.span`
  margin-left: auto;
`;

const StyleLine = styled.div`
width: 83%;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 10px;
    right:10px;
    border-top: 0.5px solid #ccc; /* Adjust color and thickness as needed */
    transform: translateY(-50%);
  }
`;

const TableContainer1 = styled.div`
width: 97%;
height: 147px;
  border-radius: 5px 5px 0px 0px;
  overflow: hidden;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0; /* This removes the spacing between cells */
  border: 1px solid #ccc; /* Add the border to the entire table */
`;

const TableHeader = styled.th`
  background-color: black;
  color: white;
  padding: 10px;
`;

const TableCell1 = styled.td`
padding: 3px;
text-align: center;
`;

const TextContainer1 = styled.div`
width: 94%;
background-color: #F8F8F8;
margin: 0 auto;
padding: 4px;
margin-bottom: 5px
`;
const TextItem = styled.div`
 margin: 0 auto;
 font-size:14px;
 font-weight: 500;
`;
const Textarea = styled.div`
color: #00000080;
 font-size:8px;
 font-weight: 500;
`;
const SelectBtn1 = styled.div`
color: #000000;
 font-size:20px;
 font-weight: 500;
`;
const SelectBtn = styled.div`
color: #4C4D4F;
 font-size:20px;
 font-weight: 400;
 margin-top:11px;
`;
const TableWrapperr = styled.div`
background: #fff9f1;
border-bottom: 1px solid #dbdbdc;
padding: 27px 0px 0px 47px;
background-color: #fff;
border-radius: 14px 14px 0 0;
gap: 20px;
  #mySelect{
    background: #F8F8F8;
    font-size: 13px;
    border: transparent;
    border-radius: 8px;
    padding:8px;
  }
  h1 {
  }
`;
const Table2Wrapperr = styled.div`
display: flex;
background: #fff;
border-radius: 0 0 14px 14px;
gap: 11px;
padding: 11px 25px;
flex-wrap: wrap;
 .edit-list{
    width: 45%;
    border-radius: 8px;
    height: 100px;
    border: 1px solid #4C4D4F33;
 }
 .edit-list img{
    width: 70px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
 }
 h4{
    font-size: 15px;
    color: #4C4D4F;
 }
 .edit-list h4{
    font-size: 15px;
    color: #4C4D4F;
    text-align: center;
 }
 .SignatureBox{
    width: 321px;
    border-radius: 8px;
    height: 100px;
    border: 1px solid #4C4D4F33;
 }
`;
const Table3Wrapperr = styled.div`
max-width: 300px;
    background: #fff;
    border-radius: 0 0 14px 14px;
    width: 100%;
    margin: auto;
        @media only screen and (max-width: 900px) {
        padding: 0;
    }
  p{
    font-size: 18.35px;
    color: #4C4D4F;
  }
  select{
    color: #4C4D4F;
    border: 2px solid #4c4d4f4a;
    height: 44.09px;
    font-size: 14.35px;
    border-radius: 6px;
    width: 100%;
  }
  .input-boxes{ width:100%; 
    margin-top: 18px;
}
  .input-boxes input{
    width:100%;
    color: #4C4D4F;
    border: 2px solid #4c4d4f4a;
    height: 44.09px;
    font-size: 14.35px;
    border-radius: 6px;
  }
  .add-button{
    display:flex;
    margin-top: 500px;
    gap: 12px;
  }
  .add-border{
    border: 1px solid #4C4D4F33;
    border-radius: 14px;
  }

`;



const EditInvoiceComponent = () => {
  
  return (
    <div>
      <ReuseAbleHeader />
      <MainLayout>
        <ReuseAbleSidebar />
        <Container className="main-content">
          <Row>
          <h1 className="after-care-heading">Invoice </h1>
         <h3 className="after-treatment-heading">Invoice list</h3>
            <Col xl={8} xs={8}>
              
              <TableWrapperr>
                <SelectBtn1>Edit Invoice</SelectBtn1>
                <SelectBtn>custom Invoice layout editing </SelectBtn>
              </TableWrapperr>
              <div className="TemplateBox1">
              <Table3Wrapperr>
                <div className="add-border">
                <img src="/static/media/logo-dashboard.26b9717866e12cf5d6ac.png" 
      style={{
        width: '90.67px',
        height: '16px',
        marginTop: '10px',marginLeft: '10px',}}
    />
<div  style={{
        marginTop: '10px',marginLeft: '15px', display:"flex"}}>SUMMERY
        <StyleLine></StyleLine>
        </div>
        
       <div style={{display: 'flex', alignItems: 'center',gap: '16%',padding:'5px 14px'}}>
        <div style={fontstyleName}>
Dr.Vihang
        </div>
        <div style={fontstyleName}> John
            
            </div>
       </div>
       <div style={{display: 'flex', alignItems: 'center', paddingLeft: '10px'}}>
       <TableContainer1>
      <Table>
        <thead>
          <tr>
            <TableHeader>item</TableHeader>
            <TableHeader>Rate</TableHeader>
            <TableHeader>Qty</TableHeader>
            <TableHeader>Amount</TableHeader>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TableCell1>Vitamin A Night</TableCell1>
            <TableCell1>$90</TableCell1>
            <TableCell1>2</TableCell1>
            <TableCell1>$180</TableCell1>
          </tr>
          <tr>
            <TableCell1>Vitamin A Night</TableCell1>
            <TableCell1>$90</TableCell1>
            <TableCell1>2</TableCell1>
            <TableCell1>$180</TableCell1>
          </tr>
          <tr>
            <TableCell1>Vitamin A Night</TableCell1>
            <TableCell1>$90</TableCell1>
            <TableCell1>2</TableCell1>
            <TableCell1>$180</TableCell1>
          </tr>
        </tbody>
      </Table>
    </TableContainer1>
                </div>
                
                <Container23>
                    <TextPara>
                <Label>Subtotal</Label>
                    <Value>$540.00</Value>
                </TextPara>
                    <TextPara>
             <Label>Tax 5%</Label>
                <Value>$00.00</Value>
            </TextPara>
            <TextPara>
                <Label>Discount 10%</Label>
                <Value>$00.00</Value>
             </TextPara>
                </Container23>
            <StyleLine></StyleLine>
            <Container23>
            <TextPara>
            <Label>total</Label>
                <Value>$540.00</Value>
            </TextPara>
                 </Container23>
                <TextContainer1>
            <TextItem>Notes/Terms</TextItem>
            <Textarea>Lorem ipsum dolor sit amet. Sit eaque assumenda qui.</Textarea>
            </TextContainer1>
            </div>
              </Table3Wrapperr>
              </div>
              
            </Col>
            <Col xl={4} xs={4}>
           
              <TableWrapperr3>
             <h4>Template Elements</h4>
             <SearchArea></SearchArea>
              </TableWrapperr3>
              <Table2Wrapperr>
                    <div className="edit-list">
                    <img src={Template} />
                    <h4>Templates</h4>
                    </div>
                    <div className="edit-list">
                    <img src={Layout} />
                    <h4>Layout</h4>
                    </div>
                    <div className="edit-list">
                    <img src={Assests} />
                    <h4>Assests</h4>
                    </div>
                    <div className="edit-list">
                    <img src={Color} />
                    <h4>Color</h4>
                    </div>
                    <div className="edit-list">
                    <img src={Background} />
                    <h4>Background</h4>
                    </div>
                    <div className="edit-list">
                    <img src={Shapes} />
                    <h4>Shapes</h4>
                    </div>
                    <div className="edit-list">
                    <img src={Images} />
                    <h4>Images</h4>
                    </div>
                    <div className="edit-list">
                    <img src={Icons} />
                    <h4>Icons</h4>
                    </div>
                    <h4>Template Elements</h4>
                    <div className="SignatureBox">  
                    </div>
                    <CustomButton2>Save & Send</CustomButton2>
              </Table2Wrapperr>

            </Col>
          </Row>
        </Container>
      </MainLayout>
    </div>
  );
};

export default EditInvoiceComponent;
