import React from 'react';
import styled from 'styled-components';

const SearchContainer = styled.div`
  position: relative;
`;

const SearchInput = styled.input`
  width: 310px;
  height: 35px;
  border-radius: 8px;
  background: #F8F8F8;
  padding: 5px; /* Add padding for better input field appearance */
  border: none; /* Remove the default input border */
`;

const SearchButton = styled.button`
  position: absolute;
  border: 1px solid #4C4D4F;
  width: 20px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
  top: 271px;
  left: 600px; /* Adjust the left position as needed */
  cursor: pointer;
  background: transparent;
  border-radius: 50%;
`;

const SearchArea = () => {
  return (
    <SearchContainer>
      <SearchInput type="text" placeholder="Search..." />
      
    </SearchContainer>
  );
};

export default SearchArea;