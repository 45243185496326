import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid } from "@mui/material";
import Logo from "../../assets/hero-logo.png";

const ImageContainer = styled.div`
  width: 100%;
  // height: 100vh;
  // height: 80vh;

  position: relative;

  .auth-cover-image {
    background-size: contain;
    width: 100%;
    height: 100%;
  }

  .auth-image-logo {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

const AuthLayoutContainer = styled(Grid)`
  overflow: hidden;
  max-width: 96rem;
  // background-color: red;
  margin: auto;
  border-radius: 14px;
  over-flow: hidden;
`;

const AuthLayout = ({ children, src, isImage = false, ...props }) => {
  console.log(props);
  return (
    <AuthLayoutContainer container {...props}>
      <Grid item lg={7} md={7} xs={12} sm={12}>
        {children}
      </Grid>
      <Grid item lg={5} md={5} xs={0} sm={0} className="mobile-view">
        <ImageContainer style={{ height: "100%" }}>
          <img src={src} className="auth-cover-image" alt="Cover" />
          {isImage && <img src={Logo} className="auth-image-logo" />}
        </ImageContainer>
      </Grid>
    </AuthLayoutContainer>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node,
  src: PropTypes.string,
};

AuthLayout.defaultProps = {
  children: <div>Hello</div>,
  src: "",
};

export default AuthLayout;
