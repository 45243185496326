import React from "react";
import styled from "styled-components";

import Group from "../../assets/Group 11774.png";
import Group1 from "../../assets/Group 11775.png";
const CardLayout = styled.div`
  background-color: #fff;
  border-radius: 14px;
  padding: 2.8rem;

  h5 {
    color: #4c4d4f;
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 1.8rem;
  }

  h1 {
    color: #4c4d4f;
    font-size: 2.5rem;
    font-weight: 400;
    margin-bottom: 1.4rem;
  }
  h4 {
    color: rgba(76, 77, 79, 0.5);
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 1.8rem;
    span {
      color: #4c4d4f;
    }
  }
  h4.two {
    margin-bottom: 0.4rem;
  }
  h4.three {
    margin-bottom: 0rem;
  }
  .dobclass{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const RecentComponent1 = () => {
  return (
    <CardLayout>
     <div style={{borderBottom: "solid 1px",marginBottom: "15px"}}>
        <h5>Recent Payment</h5>
        <h1>$350.00</h1>
        <h4>
            Paid via:<span> Master Card (***456) </span>
        </h4>
        <h4 className="two">
            Date: <span>15 August, 2023</span>
        </h4>
        <h4 className="three">
            For PRP Injections scheduled session <span> View Detail</span>
        </h4>
        <div style={{marginTop:"20px",marginBottom:"20px"}}>
            <img style={{width: "40px",marginRight: "15px"}} src={Group}/>
            <img style={{width: "40px"}} src={Group1}/>
        </div>
     </div>
     <div>
        <h5>Patient Detail</h5>
        <div className="dobclass">
            <p>D.O.B</p>
            <p>14 Aug, 1995</p>
        </div>
        <div className="dobclass">
            <p>D.O.B</p>
            <p>14 Aug, 1995</p>
        </div>
        <div className="dobclass">
            <p>D.O.B</p>
            <p>14 Aug, 1995</p>
        </div>
        <div className="dobclass">
            <p>D.O.B</p>
            <p>14 Aug, 1995</p>
        </div>
        <h5>Medical History</h5>
        <p style={{color:"#A5A6A7"}}>
           1. Patient suffers from dust allergy <br/>
           2. Patient had a major accident last year and lost his hearing.
        </p>
        <h5>Ongoing Treatments</h5>
        <p style={{color:"#A5A6A7"}}>
           1. PRP Injections<br/>
           2. Dermal Fillers
        </p>
     </div>
    </CardLayout>
  );
};

export default RecentComponent1;
