import * as React from "react";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

const StyledRating = styled(Rating)({
  "&": {
    fontSize: "33px",
  },
  "& .MuiRating-iconFilled": {
    color: "#F1B34A",
    fontSize: "33px",
  },
  "& .MuiRating-iconHover": {
    color: "#DBDBDC",
    fontSize: "33px",
  },
});

const RatingComponent = () => {
  return (
    // <Stack spacing={1}>
    <StyledRating
      name="half-rating"
      defaultValue={6}
      precision={0.5}
      max={10}
      size="large"
    />
    // </Stack>
  );
};

export default RatingComponent;
