import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

const SurveyPrgress = () => {
  const now = 60;
  return (
    // <ProgressBar
    //   now={now}
    //   label={`${now}%`}
    //   visuallyHidden
    //   color="#f1b34a"
    //   style={{ color: "red" }}
    // />
    <Box>
      <Slider
        defaultValue={50}
        aria-label="Default"
        // color="secondary"
        valueLabelDisplay="auto"
        sx={{ color: "#f1b34a" }}
      />
    </Box>
  );
};

export default SurveyPrgress;
