import React, { useState } from 'react';
import styled from 'styled-components';

const MenuContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const MenuIcon = styled.div`
  cursor: pointer;
`;

const OptionsMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 6px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  min-width: 120px;
  font-family: Cabin;
  font-size: 8px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
`;

const MenuItem = styled.div`
  padding: 5px;
  cursor: pointer;
   border:0.5px solid #D5D5D6;
  &:hover {
    background-color: #f2f2f2;
    font-family: Cabin;
    font-size: 8px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

const OptionMenu = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <MenuContainer>
      <MenuIcon onClick={toggleMenu}>⋮</MenuIcon>
      <OptionsMenu isOpen={isMenuOpen}>
        <MenuItem onClick={() => console.log('Open clicked')}>Restore</MenuItem>
        <MenuItem onClick={() => console.log('Close clicked')}>Order Info</MenuItem>
        <MenuItem onClick={() => console.log('Pause clicked')}>Delete</MenuItem>
      </OptionsMenu>
    </MenuContainer>
  );
};

export default OptionMenu;