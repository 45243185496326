import React from "react";
import styled from "styled-components";
import Pdf from '../assets/notfound/pdf-img.png';
import Pdf1 from '../assets/notfound/banner.png';
import logo from '../assets/notfound/SkinClubLogo_Logotype_White 1.png';
import logo1 from '../assets/notfound/SC-logo.png';
import care from '../assets/notfound/care-form.png';
import carebanner from '../assets/notfound/banner1.png';

const PageContainer = styled.div`
  font-family: "Cabin", sans-serif;
  height: 100vh;
  background-color: #fff9f1;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  height: 80px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-left: 30px;
  img{
    width: 30% !important;
    float: right;
    margin-top: 50px;
  }
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;
`;

const DateAndTime = styled.div`
  font-size: 14px;
  color: #4c4d4f;
  margin-right: 30px;
`;

const NotificationContainer = styled.div`
  width: 48px;
  height: 48px;
  background: #f8f8f8;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;

const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
`;

const Sidebar = styled.div`
  background: #ffffff;
  min-width: 240px;
  min-height: 100vh;
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 20px;
`;

const Navigation = styled.ul`
  padding: 0;
`;

const ListItem = styled.li`
  list-style: none;
  padding: 14px 0 14px 20px;
  border-radius: 14px;
`;

const NavLink = styled.a`
  text-decoration: none;
  color: black;

  &:hover {
    color: black;
  }
`;

const ActiveLink = styled(NavLink)`
  font-weight: bold;
`;

const AccordionButton = styled.button`
  padding: 0;

  &.collapsed {
    box-shadow: none;
  }
`;

const Sublink = styled.div`
  margin-top: 15px;
`;

const SublinkText = styled.a`
  font-weight: 400;
  font-size: 14px;
  color: #4c4d4f;
  padding-left: 12px;
`;




const MainSection = styled.div`
  img{
    width: 100%;
  }
`;
const AfterCareText = styled.div`
  img{
    width: 50%;
    display: flex;
    margin: 27px auto;
  }
`;


const AppointmentHeading = styled.div`
font-size: 30px;
color: #4c4d4f;
margin-bottom: 10px;
`;
const PdfText = styled.div`
font-size: 20px;
color: #343434;
margin-top: 13px;

@media only screen and (max-width: 768px) {
  font-size: 13px;
}
@media only screen and (max-width: 500px) {
    font-size: 20px;
}
`;


  
  const AppointmentText = styled.div`
  font-size: 20px;
  padding: 3px 40px;
  font-weight: 600;
  color: #4c4d4f;
  `;
  const CareForm = styled.div`
  img{
    width: 100%;
    height: 1266px;
  }
  .banner-img img{
    width: 70%;
    position: absolute;
    height: 200px;
    right: 112px;
    top: 2050px;
  }
  
  `;
  const AppointmentPara = styled.div`
  padding: 3px 40px;
  font-size: 18px;
  color: #4c4d4f;
  font-weight: 500;
  @media only screen and (max-width: 500px) {
    font-size: 10px;
  }
  ol{
  margin-top: 13px;
  line-height: 2;
  }
  ol ::marker {
  font-weight: 600;
  font-size: 20px;
  }
  `;
  






const AfterCareFormComponent = () => {
  return (
    <PageContainer>
      <TopBar>
        <Logo>Logo</Logo>
        <RightSide>
          <DateAndTime>Sunday, 20 August</DateAndTime>
          <DateAndTime>1:50 PM</DateAndTime>
          <NotificationContainer>
            <svg width="20" height="22" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.73 20C11.5542 20.3031 11.3019 20.5547 10.9982 20.7295C10.6946 20.9044 10.3504 20.9965 10 20.9965C9.64964 20.9965 9.30541 20.9044 9.00179 20.7295C8.69818 20.5547 8.44583 20.3031 8.27002 20M16 7C16 5.4087 15.3679 3.88258 14.2426 2.75736C13.1174 1.63214 11.5913 1 10 1C8.4087 1 6.88258 1.63214 5.75736 2.75736C4.63214 3.88258 4 5.4087 4 7C4 14 1 16 1 16H19C19 16 16 14 16 7Z"
                stroke="#4C4D4F"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </NotificationContainer>
        </RightSide>
      </TopBar>
      <MainLayout>
        <Sidebar>
          <Navigation>
            <ListItem>
              <ActiveLink href="#">Overview</ActiveLink>
            </ListItem>
            <ListItem>
              <NavLink href="#">Profile</NavLink>
            </ListItem>
            <ListItem>
              <AccordionButton
                className="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#historyCollapse"
                aria-expanded="false"
                aria-controls="historyCollapse"
              >
                History
              </AccordionButton>
              <div id="historyCollapse" className="collapse">
                <Sublink>
                  <SublinkText href="#">Videos</SublinkText>
                </Sublink>
                <Sublink>
                  <SublinkText href="#">Videos Library</SublinkText>
                </Sublink>
              </div>
            </ListItem>
            <ListItem>
              <NavLink href="#">Messages</NavLink>
            </ListItem>
            <ListItem>
              <AccordionButton
                className="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#supportCollapse"
                aria-expanded="false"
                aria-controls="supportCollapse"
              >
                Support
              </AccordionButton>
              <div id="supportCollapse" className="collapse">
                <Sublink>
                  <SublinkText href="#">FAQ's</SublinkText>
                </Sublink>
                <Sublink>
                  <SublinkText href="#">Procedure Information</SublinkText>
                </Sublink>
              </div>
            </ListItem>
            <ListItem>
              <AccordionButton
                className="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#aftercareCollapse"
                aria-expanded="false"
                aria-controls="aftercareCollapse"
              >
                Aftercare
              </AccordionButton>
              <div id="aftercareCollapse" className="collapse">
                <Sublink>
                  <SublinkText href="#">Quizzes</SublinkText>
                </Sublink>
                <Sublink>
                  <SublinkText href="#">Health & Skin Assessment</SublinkText>
                </Sublink>
              </div>
            </ListItem>
            <ListItem>
              <NavLink href="#">Finances</NavLink>
            </ListItem>
            <ListItem>
              <NavLink href="#">Virtual Consultation</NavLink>
            </ListItem>
          </Navigation>
        </Sidebar>
        <div className="col-xl-10">
          <MainSection>
          <img src={Pdf}/>
            <div className="care-text">
              <AfterCareText>
                <img src={logo}/>
               <AppointmentText>Anti-Wrinkle Session Aftercare Precaution</AppointmentText>
               <AppointmentPara>congratulation on completing your anti-wrinkle session! To ensure the best possible results and a smooth recovery. It's important
                      to follow these aftercare precaution.
                      <ol>
                        <li>Avoid Touching:</li>
                         Refrain from touching, rubbing or massaging the treated area for at least 24 hours after the session. The helps prevent the
                         product from moving to unintended area and ensure it settles in the targerted wrinkle.
                        <li>Stay Upright:</li>
                         maintain an upright position for the first few hours after the treatment. avoid lying down or bending over excessivel,as this
                        can also prevent the product from migrating.
                      <li>Facial Expressions:</li>
                      While it's important to continue normal facial expressions, try to avoid excessive frowning, squinting, or other repetitive movements that could stress the treated muscles.
                      <li>Avoid Heat and Sun: </li>
                      Steer clear of hot baths, saunas, and direct sun exposure for the next 24 hours. Heat can cause the product to spread beyond the intended area.
                      <li>Gentle Cleansing:</li>
                      You can gently cleanse your face after the session, but avoid using aggressive motions or exfoliants for the first 24 hours.
                      <li>Avoid Makeup:</li>
                      Refrain from applying makeup to the treated area for at least 4 hours post-session. This allows the product to settle without interference.
                      <li>Hydration: </li>
                      Stay well-hydrated by drinking plenty of water, as proper hydration supports the skin's recovery process.
                      </ol>
                </AppointmentPara>
              </AfterCareText>
              <Logo>
              <img src={logo1}/>
              
              </Logo>
            </div>
            <img src={Pdf1}/>
            <CareForm>
            <img src={care}/>
             <div className="banner-img">
             <img src={carebanner}/>
             </div>
            </CareForm>
          </MainSection>
        </div>
      </MainLayout>
    </PageContainer>
  );
};

export default AfterCareFormComponent;
