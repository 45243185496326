import React from "react";
import CreateInvoiceTemplete from "../component/createInvoiceTemplete/createInvoiceTemplete"; 

const CreateInvoiceTemplete1 = () => {
  return (
    <div>
      <CreateInvoiceTemplete/>
    </div>
  );
};

export default CreateInvoiceTemplete1;
