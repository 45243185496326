import React, { useState } from "react";
import styled from "styled-components";

const spanStyle = {
    backgroundColor: 'red',
    width: '75px',
    height: '21px',
    top: '391px',
    left: '1178px',
    borderRadius: '11px',
    display: 'inline-block',
    fontFamily: 'Cabin', // Font family
    fontSize: '10px', // Font size
    fontWeight: 400, // Font weight
    lineHeight: '21px', // Line height
    letterSpacing: '0em', // Letter spacing
    textAlign: 'left', // Text alignment
    color : 'white',
    letterSpacing: '0em',
    textAlign: 'center',
  };



const SpanComponent = () => {
  return (
    <div>
      {/* Pass the desired background color as a prop */}
      <span style={spanStyle}>Out of Stock</span>
    </div>
  );
};

export default SpanComponent;