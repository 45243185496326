import React, { useState } from "react";
import styled from "styled-components";
import RxCaretDown from "react-icons/rx";
import LogoDashboard from "../../assets/logo-dashboard.png";
import BellIcon from "../../assets/bellicons.png";
import AllCollapseExample from "../Accordion/Accordion";
import { Accordion } from "react-bootstrap";

import MobileLogo from "../../assets/tempLogo.png";
import MobileNotofication from "../../assets/mobilenotification.png";
import MobileToggle from "../../assets/mobiletoggle.png";
import Taaarakk from "../../assets/toorak.png";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GenericDrawer from "../Drawer/Drawer";
import { NavLink as MyAlias } from "react-router-dom";

const PageContainer = styled.div`
  font-family: "Cabin", sans-serif;
  height: 100vh;
  background-color: #fff9f1;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  // height: 80px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 1rem 2.2rem;
  padding: 1rem 0;
`;

const TopBarMobile = styled.div`
  // height: 20vh;
  // background: red;
  background: #ffffff;
  padding: 2.5rem 3.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 40px;
  }
  .logo-img {
    width: 200px;
  }
`;

const Logo = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-left: 30px;
  img {
    width: 13.6rem;
  }
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;
`;

const DateAndTime = styled.div`
  font-size: 14px;
  color: #4c4d4f;
  line-height: 1.5;
  font-weight: 400;
  // margin-right: 30px;
`;

const NotificationContainer = styled.div`
  width: 48px;
  height: 48px;
  background: #f8f8f8;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;

const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
`;

const Sidebar = styled.div`
  background: #ffffff;
  min-width: 240px;
  min-height: 100vh;
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 20px;
`;

const Navigation = styled.ul`
  padding: 0;
`;

const ListItem = styled.li`
  list-style: none;
  padding: 14px 0 14px 20px;
  border-radius: 14px;
  font-size: 1.8rem;

  line-height: 1.2;
`;

const NavLink = styled.a`
  text-decoration: none;
  color: black;

  &:hover {
    color: black;
  }
`;

const ActiveLink = styled(NavLink)`
  font-weight: bold;
`;

const AccordionButton = styled.button`
  padding: 0;
  font-size: 1.8rem;
  line-height: 1.2;

  &.collapsed {
    box-shadow: none;
  }
`;

const Sublink = styled.div`
  margin-top: 15px;
`;

const SublinkText = styled.a`
  font-weight: 400;
  font-size: 14px;
  color: #4c4d4f;
  padding-left: 12px;
`;

const MainSection = styled.div`
  // margin-left: 30px;
  // margin-top: 20px;
  // padding-right: 20px;
  margin-bottom: 5.6rem;
`;

const AppointmentHeading = styled.div`
  font-size: 3rem;
  color: #4c4d4f;
  margin-bottom: 12px;

  span {
    font-size: 2.3rem;
    color: #828180;
  }
`;

const AppointmentText = styled.div`
  font-size: 20px;
  line-height: 22px;
  color: rgba(76, 77, 79, 0.85);
  // color: #676767;
`;

const AppointmentButton = styled.div`
  // margin-top: 15px;
  // background: rgba(242, 236, 217, 0.6);
  // border-radius: 5px;
  // height: 42px;
  // font-size: 18px;
  // color: rgba(0, 0, 0, 0.5);
  // padding: 0 20px;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  // cursor: pointer;
  // width: 100%;

  .detail {
    margin-top: 0.7rem;
    h3 {
      fonr-size: 1.2rem;
      font-weight: 400;
      line-height: 1.5;
    }
    input {
      font-size: 1.1rem;
      font-weight: 400;
      line-height: 1.5;
      margin-left: 0.4rem;
    }
  }
  input {
    border-left: none;
    border-right: none;
    border-top: none;
    background: transparent;
    height: 30px;
    width: 100%;
  }
  input:focus {
    outline: none;
  }
  button {
    width: 90%;
    background-color: #252222;
    color: #fff;
    border-radius: 15px;
    height: 38px;
    border: transparent;
    margin: auto;
    margin-top: 38px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 10%;
  }
`;

const AppointmentPara = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: #4c4d4f80;
  margin-top: 11px;
`;

const MapSection = styled.div`
  img {
    width: 92%;
    height: 700px;
    padding-left: 56px;
    padding-top: 25px;
  }
  @media (max-width: 900px) {
    img {
      padding-left: 0px;
    }
  }
  @media (max-width: 768px) {
    img {
      padding-left: 0;
      padding-top: 0;
      width: 100%;
      height: 563px;
    }
  }
`;

const BrightonAppointment = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(0);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  return (
    <>
      <PageContainer>
        <TopBar className="desktop-header">
          <Logo>
            <img src={LogoDashboard} />
          </Logo>
          <RightSide>
            <DateAndTime style={{ marginRight: "56px" }}>
              Sunday, 20 August
            </DateAndTime>
            <DateAndTime style={{ marginRight: "41px" }}>1:50 PM</DateAndTime>
            <NotificationContainer>
              <img src={BellIcon} style={{ width: "2.4rem" }} />
            </NotificationContainer>
          </RightSide>
        </TopBar>
        <GenericDrawer open={isDrawerOpen} onClose={toggleDrawer} />
        <TopBarMobile className="mobile-header">
          <img src={MobileToggle} onClick={toggleDrawer} />
          <img src={MobileLogo} className="logo-img" />
          <img src={MobileNotofication} />
        </TopBarMobile>

        <MainLayout>
          <Sidebar className="sidear-for-desktop">
            <Navigation>
              <ListItem
                style={{
                  backgroundColor: activeItem === 0 ? "#FFF9F1" : "transparent",
                  fontWeight: activeItem === 0 ? "bold" : "",
                }}
                onClick={() => setActiveItem(0)}
              >
                <MyAlias to="/patient-dashboard">
                  <NavLink href="/patient-dashboard">Overview</NavLink>
                </MyAlias>
              </ListItem>
              <ListItem
                style={{
                  backgroundColor: activeItem === 1 ? "#FFF9F1" : "transparent",
                  fontWeight: activeItem === 1 ? "bold" : "",
                }}
                onClick={() => setActiveItem(1)}
              >
                {/* <MyAlias to="/patient-dashboard"> */}
                <NavLink href="#">Profile</NavLink>
                {/* </MyAlias> */}
              </ListItem>
              <ListItem
                style={{
                  backgroundColor: activeItem === 2 ? "#FFF9F1" : "transparent",
                  fontWeight: activeItem === 2 ? "bold" : "",
                }}
                onClick={() => setActiveItem(2)}
              >
                <MyAlias to="/appointment">
                  <NavLink href="#">Appointments</NavLink>
                </MyAlias>
              </ListItem>
              <ListItem style={{ padding: 0 }}>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header
                      style={{
                        backgroundColor: activeItem === 3 ? "#FFF9F1" : "",
                        fontWeight: activeItem === 3 ? "bold" : "",
                        padding: "14px 0 14px 20px",
                        borderRadius: "14px",
                        color: "#000",
                      }}
                      onClick={() => setActiveItem(3)}
                    >
                      History
                    </Accordion.Header>
                    <Accordion.Body>
                      <Sublink>
                        <MyAlias to="/videolibrarytiktok">
                          <SublinkText href="#">Videos</SublinkText>
                        </MyAlias>
                      </Sublink>
                      <Sublink>
                        <MyAlias to="/videolibraryyoutube">
                          <SublinkText href="#">Videos Library</SublinkText>
                        </MyAlias>
                      </Sublink>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </ListItem>
              <ListItem
                style={{
                  backgroundColor: activeItem === 4 ? "#FFF9F1" : "transparent",
                  fontWeight: activeItem === 4 ? "bold" : "",
                }}
                onClick={() => setActiveItem(4)}
              >
                <MyAlias to="/supportchat">
                  <NavLink href="#">Messages</NavLink>
                </MyAlias>
              </ListItem>
              <ListItem style={{ padding: 0 }}>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header
                      style={{
                        backgroundColor: activeItem === 10 ? "#FFF9F1" : "",
                        fontWeight: activeItem === 10 ? "bold" : "",
                        padding: "14px 0 14px 20px",
                        borderRadius: "14px",
                        color: "#000",
                      }}
                      onClick={() => setActiveItem(10)}
                    >
                      Support
                    </Accordion.Header>
                    <Accordion.Body>
                      <Sublink>
                        <MyAlias to="/faqs">
                          <SublinkText href="#">FAQ’s</SublinkText>
                        </MyAlias>
                      </Sublink>
                      <Sublink>
                        <MyAlias to="/customersurvey1">
                          <SublinkText href="#">
                            Procedure Information
                          </SublinkText>
                        </MyAlias>
                      </Sublink>
                      {/* </div>  */}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </ListItem>
              <ListItem style={{ padding: 0 }}>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header
                      style={{
                        backgroundColor: activeItem === 13 ? "#FFF9F1" : "",
                        fontWeight: activeItem === 13 ? "bold" : "",
                        padding: "14px 0 14px 20px",
                        borderRadius: "14px",
                        color: "#000",
                      }}
                      onClick={() => setActiveItem(13)}
                    >
                      Aftercare
                    </Accordion.Header>

                    <Accordion.Body>
                      <Sublink>
                        <MyAlias to="/aftercarepage">
                          <SublinkText>Quizzes</SublinkText>
                        </MyAlias>
                      </Sublink>
                      <Sublink>
                        <MyAlias to="/aftercarepage">
                          <SublinkText>Health & Skin Assessment</SublinkText>
                        </MyAlias>
                      </Sublink>
                      {/* </div>  */}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </ListItem>
              <ListItem
                style={{
                  backgroundColor: activeItem === 5 ? "#FFF9F1" : "transparent",
                  fontWeight: activeItem === 5 ? "bold" : "",
                }}
                onClick={() => setActiveItem(5)}
              >
                {/* <MyAlias to="/patient-dashboard"> */}
                <NavLink href="#">Finances</NavLink>
                {/* </MyAlias> */}
              </ListItem>
              <ListItem
                style={{
                  backgroundColor: activeItem === 6 ? "#FFF9F1" : "transparent",
                  fontWeight: activeItem === 6 ? "bold" : "",
                }}
                onClick={() => setActiveItem(6)}
              >
                <MyAlias to="/virtualconsultation">
                  <NavLink href="#">Virtual Consultation</NavLink>
                </MyAlias>
              </ListItem>
            </Navigation>
          </Sidebar>
          <Container className="main-content">
            <Row>
              <Col xl={6} xs={12}>
                <div className="">
                  <MainSection>
                    <AppointmentHeading>
                      Appointments <span>/ Brighton</span>{" "}
                    </AppointmentHeading>
                    <AppointmentText>
                      Enter your email and phone number
                    </AppointmentText>
                    <AppointmentPara>
                      Help us find or set up your account by verifying your
                      email and phone. Referral credit is only valid for
                      verified new patients
                    </AppointmentPara>
                    <AppointmentButton>
                      <div className="detail">
                        <h3 htmlFor="email">Email</h3>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="jane@gmail.com"
                        />
                      </div>
                    </AppointmentButton>
                    <AppointmentButton>
                      <div className="detail">
                        <h3 htmlFor="phone">Phone</h3>
                        <input
                          type="Phone"
                          id="Phone"
                          name="Phone"
                          placeholder="+6112432413"
                        />
                      </div>
                    </AppointmentButton>
                    <AppointmentButton style={{ textAlign: "center" }}>
                      <div>
                        <MyAlias to="/brighton-verification">
                          <button>CONTINUE</button>
                        </MyAlias>
                      </div>
                    </AppointmentButton>
                  </MainSection>
                </div>
              </Col>
              <Col xl={6} xs={12}>
                <MapSection>
                  <img src={Taaarakk} />
                </MapSection>
                {/* <div className="">
                  <MapSection>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319"
                      width="100%"
                      height="600"
                      frameBorder="0"
                      style={{ border: 0 }}
                      title="Google Maps"
                    ></iframe>
                  </MapSection>
                </div> */}
              </Col>
            </Row>
          </Container>
        </MainLayout>
      </PageContainer>
    </>
  );
};

export default BrightonAppointment;
