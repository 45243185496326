import React, { useState } from "react";
import styled from "styled-components";
// import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";

import ReuseAbleHeader from "../component/ReuseAbleHeader/ReuseAbleHeader";
import ReuseAbleSidebar from "../component/ReuseAbleSidebar/ReuseAbleSidebar";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Logo from "../assets/SkinClubLogo_Logotype_White 2.png";
import { ProgressBar as ReactProgressBar } from "react-bootstrap";

import RatingComponent from "../component/RatingComponent/RatingComponent";

import ButtonComponent from "../component/ButtonComponent/ButtonComponent";

import MuiRadioButton from "../component/MuiRadioButton/MuiRadioButton";
import MuiCheckbox from "../component/MuiCheckbox/MuiCheckbox";

import SurveyPrgress from "../component/SurveyPrgress/SurveyPrgress";
import { Input } from "@mui/base";

import RadioGroupss from "../component/RadioGroupss/RadioGroupss";

const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
`;
const Table2Wrapperr = styled.div`
  height: 178px;

  // display: flex;
  // flex: 1;
  // background: #fff9f1;
  padding: 22px 28px;
  background: #fff;
  border-radius: 0 0 14px 14px;

  overflow-y: auto;

  .add-border {
    border: 1px solid #d6d6d6;
    border-radius: 14px;
    overflow: auto;
  }
`;

const TableWrapperr = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
  border-bottom: 1px solid #dbdbdc;
  padding: 2.1rem 2.7rem;
  background-color: #fff;
  border-radius: 14px 14px 0 0;

  h1 {
  }
`;

const PrpgressBar = styled.div`
  width: 31.5rem;
  margin: auto;
  margin-top: 2.8rem;
  div {
    // background: red;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.8rem;

    p {
      color: #4c4d4f;
      font-size: 1.4rem;
      font-weight: 400;
      margin-bottom: 2.7rem;

      margin: 0;
    }
  }
`;

const ChildrenWrapper = styled.div`
  width: 100%;
  // background-color: red;
  margin: 24px;
  text-align: center;


  .logos img {
    display: inline-block;
    margin-top: 2.8rem;
    width: 240px;
    height: 60px;
    margin: 2.8rem auto 0;
  }
`;

const SurveyWrapper = styled.div`
  margin-top: 2.8rem;
  padding-top: 3rem;
  background-color: #fff;
  border-radius: 14px;

  .survey-text {
    font-size: 3rem;
    font-weight: 400;
    color: #000;
    margin-bottom: 7rem;
    margin-top: 3rem;
  }
  .colleague-text {
    font-size: 2rem;
    font-weight: 400;
    color: #000;
    margin-bottom: 1.6rem;
  }
  .rating-wrapper {
    width: 55.2rem;
    margin: auto;

    @media only screen and (max-width: 600px) {
      width: 100% !important;
    }
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 32.3rem;
      margin: auto;
    }
    div p {
      font-size: 1.4rem;
      font-weight: 400;
      color: #808080;
      margin: 0;
    }
  }
  .radio-btn {
    font-size: 1.4rem;
    font-weight: 700;
    color: #000;
  }

  .wrapper-choise-section {
    text-align: start;
    margin-left: 5.2rem;
  }
  .textareaa {
    width: 95%;
    padding: 12px 22px;
    border: 1px solid #4c4d4f;
    border-radius: 7px;
    margin-bottom: 37px;
  }

  .progress-wrapper {
    display: flex;
    align-items: center;
    gap: 2.8rem;
    width: 50%;
    div div .progress-bar {
      height: 20px;
    }
    p {
      margin: 0;
    }
  }

  .testing-radio {
    width: 200px;
    div {
      display: flex;
      align-items: center;
      gap: 36px;
      margin-top: 8px;
    }
  }
`;

const CustomerSurvey2 = () => {
  return (
    <div>
      <ReuseAbleHeader />
      <MainLayout>
        <ReuseAbleSidebar />
        <ChildrenWrapper>
          <div className="logos">
            <img src={Logo} />
          </div>

          <PrpgressBar>
            <div>
              <p>Progress</p>
              <p>0%</p>
            </div>
            <ReactProgressBar
              variant="success"
              now={40}
              style={{ backgroundColor: "#978f88" }}
            />
          </PrpgressBar>
          <SurveyWrapper>
            <p className="survey-text">
              Answer those questions below for Survey
            </p>
            <p className="colleague-text">
              How Likely are you to recommend our company to a friend or
              colleague?
            </p>
            <div className="rating-wrapper">
              <RatingComponent />
              <div>
                <p>0 - Not Likely</p>
                <p>10 - Very Likely</p>
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <ButtonComponent
                style={{
                  backgroundColor: "#000",
                  color: "#fff",
                  padding: "1.3rem 4.8rem",
                  width: "auto",
                  marginTop: "6.9rem",
                  marginBottom: "5.4rem",
                }}
              >
                Continue
              </ButtonComponent>
            </div>
            <div className="wrapper-choise-section">
              <p className="radio-btn">Choice the best section into them</p>
              <MuiRadioButton />
              <p className="radio-btn">Select the Product you like most</p>
              <MuiCheckbox />
              <p className="radio-btn">Rate our services</p>
              <div className="progress-wrapper">
                <div style={{ width: "200px" }}>
                  <SurveyPrgress />
                </div>
                <p>4.8/10</p>
              </div>
              <p className="radio-btn">How can we improve our experience ?</p>
              <textarea
                id="w3review"
                className="textareaa"
                name="w3review"
                rows="3"
                placeholder="Start Typing here"
                cols="50"
              ></textarea>
              <p className="radio-btn">
                How much happy you are with our services
              </p>
              <div className="testing-radio">
                <RadioGroupss />
                <div>
                  <p>Text input</p>
                  <p>Text input</p>
                  <p>Text input</p>
                </div>
              </div>

              <div style={{ width: "100%", textAlign: "center" }}>
                <ButtonComponent
                  style={{
                    backgroundColor: "#000",
                    color: "#fff",
                    padding: "1.3rem 4.8rem",
                    width: "auto",

                    marginTop: "1.2rem",
                    marginBottom: "8.1rem",
                  }}
                >
                  Submit
                </ButtonComponent>
              </div>
            </div>
          </SurveyWrapper>
        </ChildrenWrapper>
      </MainLayout>
    </div>
  );
};

export default CustomerSurvey2;
