import React from "react";
import Toorak3 from "../component/Toorak3/Toorak3";

const ToorakPage2 = () => {
  return (
    <div>
      <Toorak3 />
    </div>
  );
};

export default ToorakPage2;
