import React from "react";
import styled from "styled-components";

import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import VolumeDown from "@mui/icons-material/VolumeDown";
import VolumeUp from "@mui/icons-material/VolumeUp";
import Mic from ".././../assets/micc.png";
import ButtonComponent from "../ButtonComponent/ButtonComponent";

import Flag from ".././../assets/flag.png";

import {
  AiOutlineUpload,
  AiOutlineLike,
  AiOutlineDislike,
} from "react-icons/ai";
import { TbEdit } from "react-icons/tb";
import { IoMdCopy } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BsChevronRight } from "react-icons/bs";

import MusicButton from ".././../assets/musicbtn.png";
import MusicWave from ".././../assets/musicwave.png";

const label = { inputProps: { "aria-label": "Switch demo" } };

const SpeechToTextWrapper = styled.div`
  padding: 18px 20px;

  .text-switch {
    background-color: #f8f8f8;
    border: 1px solid #c9c9c9;
    border-radius:14px;
    padding: 22px 18px;
    display: flex;
    align-items:center;
    justify-content:space-between;
    div{
      display: flex;
    align-items:center;
    p{
      margin-bottom:0;
      font-size:1.2rem;
      font-weight:400;
      color:#000;
    }
    }
    }
  }
  .textswithc{
    margin-bottom:0;
    font-size:1.2rem;
    font-weight:400;
    color:#000;
  }

  .mic-wrpper{
    margin-top:8px;
    margin-bottom:8px;
    background-color: #f8f8f8;
    border: 1px solid #c9c9c9;
    border-radius:14px;
    padding: 22px 18px;
    text-align:start;
    img{
      width:50px;
      height:50px;
      
    }
    .hold{
      font-size:1.2rem;
      font-weight:400;
      color:#000;
    margin:12px 0 8px;
    }
    .or{
      font-size:1.2rem;
      font-weight:400;
      color:#000;

      margin:0 0 6px;
    }
  }
  .text-wrapper{
    margin-top:8px;

    background-color: #f8f8f8;
    border: 1px solid #c9c9c9;
    border-radius:14px;
    padding: 22px 18px;
  }
  .text-content{ co
    font-size:1.2rem;
    font-weight:400;
    color:#000;
    height:120px;
    overflow-y:auto;
  }
  .icons-wrapper{
    margin-top:25px;
display:flex;
justify-content:space-between;
  }

  .enter-text{
    font-size:1.4rem;
    font-weight:400;
    color:#7c7c7c;
    margin:0;
    margin-bottom:6px;
  }
  .text-welcome{
    font-size:1.4rem;
    font-weight:400;
    color:#7c7c7c;
    border:1px solid #dbdbdc;
    padding: 6px 8px;
    border-radius:5px;

    width:100%;

  }
  .listening-wrapper{
    display:flex;
    justify-content:space-between;
    align-items: center;

    border:1px solid #dbdbdc;
    padding: 6px 8px;
    border-radius:5px;
  }
  .listening-voices{
    display:flex;
    justify-content:space-between;
    p{
      margin:0;
      font-size:1.4rem;
      font-weight:400;
      color:#7c7c7c;
    }

  }
  .iimmgg{
    display:flex;
    align-items:center;
    gap:8px;
    div p{
      margin:0;
    }
    .simon{
      font-size:1rem;
      font-weight:400;
      color:#4C4D4F;
    }
    .us{
      font-size:1.4rem;
      font-weight:400;
      color:#a2a3a4;
    }
  }
`;

const TextToSpeech = () => {
  return (
    <SpeechToTextWrapper>
      <div className="text-switch">
        <p className="textswithc">Speech-to-text</p>
        <div>
          <p>switch to TTS</p>
          <Switch {...label} style={{ color: "#4c4d4f", fontSize: "10px" }} />
        </div>
      </div>

      <div className="mic-wrpper">
        <p className="enter-text">Enter Text</p>
        <textarea
          id="w3review"
          value="Welcome"
          name="w3review"
          rows="4"
          // cols="50"
          className="text-welcome"
        ></textarea>
      </div>
      <div className="text-wrapper">
        <div className="listening-voices">
          <p>Listening speed</p>
          <p>1.9x - 390WPM</p>
        </div>

        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
          <VolumeDown />
          <Slider style={{ color: "#000" }} />
          <VolumeUp />
        </Stack>

        <p>Listening voices</p>

        <div className="listening-wrapper">
          <div className="iimmgg">
            <img src={Flag} style={{ width: "30px", height: "30px" }} />
            <div>
              <p className="simon">Simon</p>
              <p className="us">Male - US</p>
            </div>
          </div>
          <div>
            <BsChevronRight
              style={{ width: "1.2rem", height: "1.2rem", color: "#000" }}
            />
          </div>
        </div>
      </div>
      <div style={{ display: "flex", marginTop: "19px", gap: "9px" }}>
        <img src={MusicWave} style={{ flex: "1", height: "35px" }} />
        <img src={MusicButton} style={{ width: "35px", height: "35px" }} />
      </div>
    </SpeechToTextWrapper>
  );
};

export default TextToSpeech;
