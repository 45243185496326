import React from "react";
// import AppointmentsPage from "../component/AppointmentsPage/AppointmentsPage";
import Toorak from "../component/Toorak.js/Toorak";

const ToorakPage = () => {
  return (
    <div>
      <Toorak />
    </div>
  );
};

export default ToorakPage;
