import React from "react";
import SupportChat from "../component/SupportChat/SupportChatComponent";

const SupportChat1 = () => {
  return (
    <div>
      <SupportChat/>
    </div>
  );
};

export default SupportChat1;
