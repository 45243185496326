import React from "react";
import AppointmentsPage from "../component/AppointmentsPage/AppointmentsPage";

const Skin = () => {
  return (
    <div>
      <AppointmentsPage />
    </div>
  );
};

export default Skin;
