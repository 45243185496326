import React, { useState } from "react";
import styled from "styled-components";
import ReuseAbleHeader from "../ReuseAbleHeader/ReuseAbleHeader";
import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../assets/tempLogo.png";
import Send from "../../assets/send.png";
import Emoji from "../../assets/emoji.png";
import File from "../../assets/file.png";


const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
`;
const Table2Wrapperr = styled.div`

  // display: flex;
  // flex: 1;
  // background: #fff9f1;
  padding: 22px 28px;
  background: #fff;
  border-radius: 0 0 14px 14px;
  .chat-box{
    background: #E7F5FA;
    width: 350px;
    border-radius: 13px;s
  }
  .chat-box-red{
    margin-top: 20px;
    background: #FFF9F1;
    width: 350px;
    margin-left: auto;
    display: flex;
    border-radius: 13px;
  }
  .text-box{
    padding: 23px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  p{
    color: #4C4D4F80;
    font-size: 10px;
    padding-left: 13px;
  }
  .write-msg{
    display: flex;
    gap: 25px;
    margin-top: 33px;
  }
  .write-msg input{
    width: 80%;
    border-radius: 11px;
    height: 47px;
    padding: 11px;
    color: #4C4D4F80;
    border: 2px solid #4C4D4F33;
  }
  .write-msg img{
    width: 33px;
    height: 33px;
  }
  @media only screen and (max-width: 900px) {
    .write-msg{
        gap: 11px;
      }
      .write-msg img{
        height: 30px;
      }
      .chat-box{
        width: 300px;
      }
      .chat-box-red{
        width: 300px;
      }
  }
`;

const TableWrapperr = styled.div`
  display: flex;
  flex: 1;
  background: #F8F8F8;
  border-bottom: 1px solid #dbdbdc;
  padding: 2.1rem 2.7rem;
  border-radius: 14px 14px 0 0;
.logo{
    width: auto;
    height: 29px;
    margin: 0 auto;
}
`;

const StyleLine = styled.div`
width: 46%;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 10px;
    right:10px;
    border-top: 0.5px solid #ccc; /* Adjust color and thickness as needed */
    transform: translateY(-50%);
  }
  @media only screen and (max-width: 900px) {
    width: 41%;
  }
`;

const SupportChatComponent = () => {
  return (
    <div>
      <ReuseAbleHeader />
      <MainLayout>
        <ReuseAbleSidebar />
        <Container className="main-content">
          <Row>
            <Col xl={12} xs={12}>
              <TableWrapperr>
              <img className="logo" src={logo} />
              </TableWrapperr>
              <Table2Wrapperr>
              <div  style={{
                 marginTop: '10px',marginLeft: '15px', display:"flex"}}> <StyleLine></StyleLine>10:17 AM
                <StyleLine></StyleLine>
                </div>
                <p>Support Team</p>
                <div className="chat-box">
                    <div className="text-box">
                        <h3>Hey</h3>
                        <h3>We’re here to help!</h3>
                    </div>
                </div>
                <div className="chat-box-red">
                    <div className="text-box">
                        <h3>Hi</h3>
                        <h3>I was hoping if you could help me with appointment booking, i’m having some trouble filling in some info.</h3>
                        <h3>thank you.</h3>
                    </div>
                </div>
                <p>Support Team</p>
                <div className="chat-box">
                    <div className="text-box">
                        <h3>Sure! help is on the way :)</h3>
                    </div>
                </div>
                <div className="write-msg">
                    <input placeholder="Write message..." />
                    <img  src={Send} />
                    <img  src={File} />
                    <img  src={Emoji} />
                </div>
              </Table2Wrapperr>
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </div>
  );
};

export default SupportChatComponent;
