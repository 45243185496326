import React from "react";
import AfterCareFormComponent from "../component/AfterCareFormComponent"
const AfterCare = () => {
  return (
    <div>
      <AfterCareFormComponent />
    </div>
  );
};

export default AfterCare;

