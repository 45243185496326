import React from "react";
import styled from "styled-components";
import avatar from "../../assets/avatarrr.png";
import recent1 from "../../assets/recent1.png";
import recent2 from "../../assets/recent2.png";
import recent3 from "../../assets/recent3.png";
import recent4 from "../../assets/recent4.png";

const RecentWrapper = styled.div`
  padding: 28px 20px;
  background-color: #fff;
  border-radius: 14px;
  @media screen and (max-width: 992px) {
    margin-top: 3.2rem;
  }
  .recent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.8rem;
    p {
      font-size: 1.4rem;
      color: #000;
      font-weight: 400;
      margin: 0;
    }
  }
  .content {
    display: flex;
    gap: 7px;
    // justify-content: space-between;
    align-items: center;
    margin-bottom: 26px;
    .wrapper {
      display: flex;
      justify-content: space-between;
      // align-items: center;

      flex-direction: column;

      .one {
        font-size: 1.2rem;
        color: #4c4d4f;
        margin: 0;
      }

      .two {
        font-size: 1.2rem;
        margin: 0;
        color: #a5a6a7;
      }
    }
  }
`;
const RecentActivities = ({ rewardManagement }) => {
  return (
    <RecentWrapper>
      <div className="recent">
        <p>Recent Activity</p>
        <p>view all</p>
      </div>
      <div className="content">
        <div
          style={{
            padding: "10px",
            backgroundColor: "#F8F8F8",
            borderRadius: "14px",
          }}
        >
          <img src={avatar} style={{ width: "24px", height: "24px" }} />
        </div>
        <div className="wrapper">
          <p className="one">New User registered.</p>
          <p className="two">2 sec ago</p>
        </div>
      </div>
      <div className="content">
        <div
          style={{
            padding: "10px",
            backgroundColor: "#F8F8F8",
            borderRadius: "14px",
          }}
        >
          <img src={recent1} style={{ width: "24px", height: "24px" }} />
        </div>
        <div className="wrapper">
          <p className="one">A product stock has been updated.</p>
          <p className="two">2 sec ago</p>
        </div>
      </div>
      <div className="content">
        <div
          style={{
            padding: "10px",
            backgroundColor: "#F8F8F8",
            borderRadius: "14px",
          }}
        >
          <img src={recent2} style={{ width: "24px", height: "24px" }} />
        </div>
        <div className="wrapper">
          <p className="one">A Payment has been done.</p>
          <p className="two">2 sec ago</p>
        </div>
      </div>
      <div className="content">
        <div
          style={{
            padding: "10px",
            backgroundColor: "#F8F8F8",
            borderRadius: "14px",
          }}
        >
          <img src={recent3} style={{ width: "24px", height: "24px" }} />
        </div>
        <div className="wrapper">
          <p className="one">A product is out of stock.</p>
          <p className="two">2 sec ago</p>
        </div>
      </div>
      <div className="content">
        <div
          style={{
            padding: "10px",
            backgroundColor: "#F8F8F8",
            borderRadius: "14px",
          }}
        >
          <img src={recent4} style={{ width: "24px", height: "24px" }} />
        </div>
        <div className="wrapper">
          <p className="one">An appointment confirmed.</p>
          <p className="two">2 sec ago</p>
        </div>
      </div>
      <div className="content">
        <div
          style={{
            padding: "10px",
            backgroundColor: "#F8F8F8",
            borderRadius: "14px",
          }}
        >
          <img src={recent2} style={{ width: "24px", height: "24px" }} />
        </div>
        <div className="wrapper">
          <p className="one">New User registered</p>
          <p className="two">2 sec ago</p>
        </div>
      </div>
      {rewardManagement && (
        <>
          <div className="content">
            <div
              style={{
                padding: "10px",
                backgroundColor: "#F8F8F8",
                borderRadius: "14px",
              }}
            >
              <img src={recent2} style={{ width: "24px", height: "24px" }} />
            </div>
            <div className="wrapper">
              <p className="one">New User registered</p>
              <p className="two">2 sec ago</p>
            </div>
          </div>
          <div className="content">
            <div
              style={{
                padding: "10px",
                backgroundColor: "#F8F8F8",
                borderRadius: "14px",
              }}
            >
              <img src={recent2} style={{ width: "24px", height: "24px" }} />
            </div>
            <div className="wrapper">
              <p className="one">New User registered</p>
              <p className="two">2 sec ago</p>
            </div>
          </div>
        </>
      )}
    </RecentWrapper>
  );
};

export default RecentActivities;
