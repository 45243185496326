import React, { useState } from "react";
import styled from "styled-components";
import ReuseAbleHeader from "../ReuseAbleHeader/ReuseAbleHeader";
import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TableComponent from "../TableComponent/TableComponent";

import deleteicon from "../../assets/fluent_delete-12-regular.png";

const TopBar = styled.div`
  // height: 80px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 1rem 2.2rem;
  padding: 1rem 0;
`;

const TopBarMobile = styled.div`
  // height: 20vh;
  // background: red;
  background: #ffffff;
  padding: 2.5rem 3.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 40px;
  }
  .logo-img {
    width: 200px;
  }
`;

const Logo = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-left: 30px;
  img {
    width: 13.6rem;
  }
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;
`;

const DateAndTime = styled.div`
  font-size: 14px;
  color: #4c4d4f;
  line-height: 1.5;
  font-weight: 400;
  // margin-right: 30px;
`;

const NotificationContainer = styled.div`
  width: 48px;
  height: 48px;
  background: #f8f8f8;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;

const NavLink = styled.a`
  text-decoration: none;
  color: black;

  &:hover {
    color: black;
  }
`;

const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
`;

const PatientMain = styled.div`
  background-color: #fff9f1;
`;

const BoxSection = styled.div`
  background-color: #ffff;
  padding: 4%;
  // margin: 0 auto;
  // width: 85%;
  @media screen and (max-width: 992px) {
    min-width: 1200px;
    overflow-x: auto;
  }
`;

const FirstRow = styled.div`
  display: grid;
  // grid-template-columns: 66% 39%;
  grid-template-columns: 1fr auto;
`;

const SecondRow = styled.div`
  display: grid;
  grid-template-columns: 24% 25% 19% 25% 0%;
  align-items: center;
`;

const FirstSection = styled.div`
  margin-left: 62px;
`;

const Button = styled.button`
  width: 120px;
  height: 48px;
  border: 1px solid #4c4d4f;
  border-radius: 24px;
`;

const Input = styled.input`
  width: 310px;
  height: 48px;
  border-radius: 8px;
  border: none;
  background-color: #f8f8f8;
`;

const Paragraph = styled.p`
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 0;
`;

const Heading1 = styled.h1`
  font-size: 34px;
  font-weight: 400;
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
`;

const SecondRows = styled.div`
  display: grid;
  grid-template-columns: 21% 23% 26% 18% 13%;
  margin-top: 25px;
  margin-bottom: 12px;
  background-color: #f8f8f8;
  padding: 8px 16px;
`;

const HoverEffect = styled.div`
  margin-bottom: 12px;
  &:hover {
    background-color: #f8f8f8;
    border-radius: 14px;
  }
`;

const Mail = styled.p`
  color: #4c4d4f80;
`;

const AfterCare = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  return (
    <div>
      <ReuseAbleHeader />

      <MainLayout>
        <ReuseAbleSidebar />
        <Container className="main-content">
          <Row>
            <Col xl={12} xs={12}>
              {" "}
              <PatientMain>
                <BoxSection>
                  <FirstRow>
                    <Paragraph>Patient Management</Paragraph>
                    <div>
                      <Input
                        placeholder="Search"
                        type="text"
                        style={{ marginRight: "16px" }}
                      />
                      <Button>Add patient</Button>
                    </div>
                  </FirstRow>
                  <div className="scroll">
                    <SecondRows>
                      <Paragraph>Name</Paragraph>
                      <Paragraph>Medicare Number</Paragraph>
                      <Paragraph>Private health number</Paragraph>
                      <Paragraph>DOB</Paragraph>
                      <Paragraph>Remove User</Paragraph>
                    </SecondRows>
                    {/* Repeat the following block for each patient */}
                    <HoverEffect>
                      <SecondRow>
                        <div>
                          <Paragraph>Jane Doe</Paragraph>
                          <Mail>jane@gmail.com</Mail>
                        </div>
                        <Paragraph>SK1586</Paragraph>
                        <Paragraph>SK1586</Paragraph>
                        <Paragraph>Aug 15, 2023</Paragraph>
                        <Image src={deleteicon} alt="" />
                      </SecondRow>
                    </HoverEffect>
                    <HoverEffect>
                      <SecondRow>
                        <div>
                          <Paragraph>Jane Doe</Paragraph>
                          <Mail>jane@gmail.com</Mail>
                        </div>
                        <Paragraph>SK1586</Paragraph>
                        <Paragraph>SK1586</Paragraph>
                        <Paragraph>Aug 15, 2023</Paragraph>
                        <Image src={deleteicon} alt="" />
                      </SecondRow>
                    </HoverEffect>
                    <HoverEffect>
                      <SecondRow>
                        <div>
                          <Paragraph>Jane Doe</Paragraph>
                          <Mail>jane@gmail.com</Mail>
                        </div>
                        <Paragraph>SK1586</Paragraph>
                        <Paragraph>SK1586</Paragraph>
                        <Paragraph>Aug 15, 2023</Paragraph>
                        <Image src={deleteicon} alt="" />
                      </SecondRow>
                    </HoverEffect>
                    <HoverEffect>
                      <SecondRow>
                        <div>
                          <Paragraph>Jane Doe</Paragraph>
                          <Mail>jane@gmail.com</Mail>
                        </div>
                        <Paragraph>SK1586</Paragraph>
                        <Paragraph>SK1586</Paragraph>
                        <Paragraph>Aug 15, 2023</Paragraph>
                        <Image src={deleteicon} alt="" />
                      </SecondRow>
                    </HoverEffect>
                    <HoverEffect>
                      <SecondRow>
                        <div>
                          <Paragraph>Jane Doe</Paragraph>
                          <Mail>jane@gmail.com</Mail>
                        </div>
                        <Paragraph>SK1586</Paragraph>
                        <Paragraph>SK1586</Paragraph>
                        <Paragraph>Aug 15, 2023</Paragraph>
                        <Image src={deleteicon} alt="" />
                      </SecondRow>
                    </HoverEffect>
                    <HoverEffect>
                      <SecondRow>
                        <div>
                          <Paragraph>Jane Doe</Paragraph>
                          <Mail>jane@gmail.com</Mail>
                        </div>
                        <Paragraph>SK1586</Paragraph>
                        <Paragraph>SK1586</Paragraph>
                        <Paragraph>Aug 15, 2023</Paragraph>
                        <Image src={deleteicon} alt="" />
                      </SecondRow>
                    </HoverEffect>
                    <HoverEffect>
                      <SecondRow>
                        <div>
                          <Paragraph>Jane Doe</Paragraph>
                          <Mail>jane@gmail.com</Mail>
                        </div>
                        <Paragraph>SK1586</Paragraph>
                        <Paragraph>SK1586</Paragraph>
                        <Paragraph>Aug 15, 2023</Paragraph>
                        <Image src={deleteicon} alt="" />
                      </SecondRow>
                    </HoverEffect>
                    {/* End of patient block */}
                  </div>
                </BoxSection>
              </PatientMain>
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </div>
  );
};

export default AfterCare;
