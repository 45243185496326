// import { useState } from "react";
// import Carousel from "react-bootstrap/Carousel";
// // import ExampleCarouselImage from "components/ExampleCarouselImage";

// import PatientCarousel from "../../assets/patiendSlider.png";

// function ImageCarousel() {
//   const [index, setIndex] = useState(0);

//   const handleSelect = (selectedIndex) => {
//     setIndex(selectedIndex);
//   };

//   return (
//     <Carousel activeIndex={index} onSelect={handleSelect}>
//       <Carousel.Item>
//         <img src={PatientCarousel} />
//         {/* <ExampleCarouselImage text="First slide" /> */}
//         {/* <Carousel.Caption>
//           <h3>First slide label</h3>
//           <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
//         </Carousel.Caption> */}
//       </Carousel.Item>
//       <Carousel.Item>
//         <img src={PatientCarousel} />
//         {/* <ExampleCarouselImage text="Second slide" />
//         <Carousel.Caption>
//           <h3>Second slide label</h3>
//           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
//         </Carousel.Caption> */}
//       </Carousel.Item>
//       <Carousel.Item>
//         <img src={PatientCarousel} />
//         {/* <ExampleCarouselImage text="Third slide" />
//         <Carousel.Caption>
//           <h3>Third slide label</h3>
//           <p>
//             Praesent commodo cursus magna, vel scelerisque nisl consectetur.
//           </p>
//         </Carousel.Caption> */}
//       </Carousel.Item>
//     </Carousel>
//   );
// }

// export default ImageCarousel;

import Carousel from "react-bootstrap/Carousel";
import PatientCarousel from "../../assets/patiendSlider.png";
import ButtonComponent from "../ButtonComponent/ButtonComponent";

function ImageCarousel() {
  return (
    <Carousel data-bs-theme="dark">
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={PatientCarousel}
          alt="First slide"
        />
        <Carousel.Caption>
          <h5>Recommendations & Accessories</h5>
          <p>
            Discover Personalized Care Treatment <br /> & Products Just For You
          </p>
          <ButtonComponent style={{ width: "auto", padding: "14px 28px" }}>
            Discover More
          </ButtonComponent>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={PatientCarousel}
          alt="First slide"
        />
        <Carousel.Caption>
          <h5>Recommendations & Accessories</h5>
          <p>
            Discover Personalized Care Treatment <br /> & Products Just For You
          </p>
          <ButtonComponent style={{ width: "auto", padding: "14px 28px" }}>
            Discover More
          </ButtonComponent>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={PatientCarousel}
          alt="First slide"
        />
        <Carousel.Caption>
          <h5>Recommendations & Accessories</h5>
          <p>
            Discover Personalized Care Treatment <br /> & Products Just For You
          </p>
          <ButtonComponent
            style={{
              width: "auto",
              padding: "14px 28px",
              backgroundColor: "transparent",
              border: "1px solid #4C4D4F",
              color: "#000",
            }}
          >
            Discover More
          </ButtonComponent>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default ImageCarousel;
