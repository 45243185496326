import { InputLabel, NativeSelect } from "@mui/material";
import AdminLayout from "../../component/AdminLayout/AdminLayout";
import InputField from "../../component/InputField/InputField";
import "./style.css";

const AdminProfileManagementPage = () => {
    return (
        <AdminLayout>
            <div className="adminLayout d-flex justify-content-center">
                <div className="content-dashboard admin-panel-content py-0 py-md-3 ps-0 pe-0 pe-md-4 py-md-5 w-100">



                    <div className="content-body px-2 pe-md-5 mt-3" >

                        <div className="main-containerfaq py-3 px-md-3 py-md-5 d-non">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 col-lg-4">
                                        <h2 className="mb-5">Profile Management</h2>
                                        <p>Manage your profile setting and personal data</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-4">
                                        <div
                                            className="nav nav-tabs mb-3 d-flex flex-md-column flex-row gap-3"
                                            id="nav-tab"
                                            role="tablist"
                                        >
                                            <button
                                                className="nav-link active navbar-tab-heading-text"
                                                id="nav-Personal-Information-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-Personal-Information"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-Personal-Information"
                                                aria-selected="true"
                                            >
                                                1. Personal Information
                                            </button>
                                            <button
                                                className="nav-link navbar-tab-heading-text"
                                                id="nav-Account-Settings-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-Account-Settings"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-Account-Settings"
                                                aria-selected="false"
                                            >
                                                2. Account Settings
                                            </button>
                                            <button
                                                className="nav-link navbar-tab-heading-text"
                                                id="nav-Preference-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-Preference"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-Preference"
                                                aria-selected="false"
                                            >
                                                3. Preference
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-8">
                                        <div
                                            className="tab-content tabs-Faqs-data-container"
                                            id="nav-tabContent"
                                        >
                                            <div
                                                className="tab-pane fade active show"
                                                id="nav-Personal-Information"
                                                role="tabpanel"
                                                aria-labelledby="nav-Personal-Information-tab"
                                            >
                                                <h2>Personal Information</h2>
                                                <p>Update your personal information</p>
                                                <form className="py-4">
                                                    <div className="row">
                                                        <div className="col-12 col-lg-6 py-3">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1">First Name</label>
                                                                <InputField placeholder="jane" variant="standard" id="outlined-basic" size="small" fullWidth styledInputProps={{ height: "2px", type: "text" }} />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-lg-6 py-3">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1">Last Name</label>
                                                                <InputField placeholder="dane" variant="standard" id="outlined-basic" size="small" fullWidth styledInputProps={{ height: "2px", type: "text" }} />

                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-lg-6 py-3">
                                                            <label for="exampleInputEmail1">Country</label>
                                                            <select
                                                                
                                                                style={{ borderTop: "none", borderLeft: "none", borderRight: "none" }}
                                                                className="form-select personal-information-form-input"
                                                                aria-label="Default select example"
                                                            >
                                                                <option style={{color: "#4C4D4F", opacity: "50%"}} disabled>United States</option>
                                                                <option value="1">Lahore</option>
                                                                <option value="2">Changhai</option>
                                                                <option value="3">Amsterdam</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-12 col-lg-6 py-3">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1">City</label>
                                                                <select
                                                                    style={{ borderTop: "none", borderLeft: "none", borderRight: "none" }}

                                                                    className="form-select personal-information-form-input"
                                                                    aria-label="Default select example"
                                                                >
                                                                    <option disabled>New York</option>
                                                                    <option value="1">Lahore</option>
                                                                    <option value="2">Changhai</option>
                                                                    <option value="3">Amsterdam</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 py-3">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1">Address</label>
                                                                <InputField placeholder="175 Street Town" variant="standard" id="outlined-basic" size="small" fullWidth styledInputProps={{ height: "2px", type: "text" }} />

                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-lg-6 py-3">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1">Postal Code</label>
                                                                <InputField placeholder="983700" variant="standard" id="outlined-basic" size="small" fullWidth styledInputProps={{ height: "2px", type: "text" }} />

                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-lg-6 py-3">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1">Phone Number</label>
                                                                <InputField placeholder="232-850-4511" variant="standard" id="outlined-basic" size="small" fullWidth styledInputProps={{ height: "2px", type: "text" }} />

                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-lg-6 py-3">
                                                            <h3>Payment Information</h3>
                                                        </div>
                                                        <div className="col-12 py-3">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1">Card Holder*</label>
                                                                <InputField placeholder="Jane Doe"variant="standard" id="outlined-basic" size="small" fullWidth styledInputProps={{ height: "2px", type: "text" }} />

                                                            </div>
                                                        </div>
                                                        <div className="col-12 py-3">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1"
                                                                >Credit/Debit Card Number</label
                                                                >
                                                                <InputField placeholde="4224 4224 4224 4224" variant="standard" id="outlined-basic" size="small" fullWidth styledInputProps={{ height: "2px", type: "text" }} />

                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-lg-6 py-3">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1"
                                                                >Expiration Month & Year*</label
                                                                >
                                                                <InputField placeholder="05/2027" variant="standard" id="outlined-basic" size="small" fullWidth styledInputProps={{ height: "2px", type: "text" }} />

                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-lg-6 py-3">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1">CVC*</label>
                                                                <InputField placeholder="373" variant="standard" id="outlined-basic" size="small" fullWidth styledInputProps={{ height: "2px", type: "text" }} />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between py-4">
                                                        <button
                                                            type="submit"
                                                            className="btn-admin btn-admin-primary personal-information-form-button bg-transparent text-dark"
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className="btn-admin btn-admin-primary personal-information-form-button"
                                                        >
                                                            Save Changes
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="nav-Account-Settings"
                                                role="tabpanel"
                                                aria-labelledby="nav-Account-Settings-tab"
                                            >
                                                <h2>Account Settings</h2>
                                                <p>Update your Account settings</p>
                                                <div className="d-flex justify-content-between">
                                                    <form className="py-4">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <h3>Change Email Address</h3>
                                                            </div>
                                                            <div className="col-12 py-4 border-bottom mx-2">
                                                                <div className="row">
                                                                    <div className="col-6 px-1">
                                                                        <div className="form-group">
                                                                            <label for="exampleInputEmail1"
                                                                            >Email Address</label
                                                                            >
                                                                            <InputField placeholder="janedoe@gmail.com" variant="standard" id="outlined-basic" size="small" fullWidth styledInputProps={{ height: "2px", type: "text" }} />

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 d-flex align-items-end">
                                                                        <button
                                                                            type="submit"
                                                                            className="btn btn-primary bg-transparent border-0 text-dark change-email-text"
                                                                        >
                                                                            Change Email
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 pt-4">
                                                                <h3>Change Password</h3>
                                                            </div>
                                                            <div className="row border-bottom pb-4 px-0 mx-2">
                                                                <div className="col-12 col-lg-4 py-3 px-1">
                                                                    <div className="form-group">
                                                                        <label for="exampleInputEmail1"
                                                                        >Current Password</label
                                                                        >
                                                                        <InputField placeholder="********" variant="standard" id="outlined-basic" size="small" fullWidth styledInputProps={{ height: "2px", type: "text" }} />

                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-lg-4 py-3">
                                                                    <div className="form-group">
                                                                        <label for="exampleInputEmail1"
                                                                        >New Password</label
                                                                        >
                                                                        <InputField  placeholder="********" variant="standard" id="outlined-basic" size="small" fullWidth styledInputProps={{ height: "2px", type: "text" }} />

                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-lg-4 py-3 px-1">
                                                                    <div className="form-group">
                                                                        <label for="exampleInputEmail1"
                                                                        >Update Password</label
                                                                        >
                                                                        <InputField  placeholder="********" variant="standard" id="outlined-basic" size="small" fullWidth styledInputProps={{ height: "2px", type: "text" }} />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 pt-3">
                                                                <h3>Newsletter</h3>
                                                                <p>
                                                                    Get lates news and updates directly into your
                                                                    inbox
                                                                </p>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="form-check border-bottom pb-4">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        value=""
                                                                        id="flexCheckDefault"
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        for="flexCheckDefault"
                                                                    >
                                                                        Subscribe to our monthly newsletter
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 py-4">
                                                                <h3>Delete Account</h3>
                                                                <p>
                                                                    We do best to give you a great experience, we'll
                                                                    be sad to see you leave us.
                                                                    <button
                                                                        className="change-email-text bg-transparent border-0"
                                                                    >
                                                                        Delete your Account
                                                                    </button>
                                                                </p>
                                                            </div>
                                                            <div
                                                                className="col-12 d-flex justify-content-between py-4"
                                                            >
                                                                <button
                                                                    type="submit"
                                                                    className="btn-admin btn-admin-primary personal-information-form-button bg-transparent text-dark"
                                                                >
                                                                    Cancel
                                                                </button>
                                                                <button
                                                                    type="submit"
                                                                    className="btn-admin btn-admin-primary personal-information-form-button"
                                                                >
                                                                    Save Changes
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="nav-Preference"
                                                role="tabpanel"
                                                aria-labelledby="nav-Preference-tab"
                                            >
                                                <h2>Preferences</h2>
                                                <p>Update your App preferences</p>
                                                <div className="">
                                                    <form className="py-4">
                                                        <div className="row">
                                                            <div
                                                                className="col-12 d-flex justify-content-between border-bottom align-items-center py-3 px-0 mx-2"
                                                            >
                                                                <div className="ms-1">
                                                                    <span>Email Notifications</span>
                                                                    <p>
                                                                        Send Me notifications and updates through email
                                                                    </p>
                                                                </div>
                                                                <div className="me-3">
                                                                    <div className="form-check form-switch">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="flexSwitchCheckDefault"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="col-12 d-flex justify-content-between border-bottom align-items-center py-3 px-0 mx-2"
                                                            >
                                                                <div className="ms-1">
                                                                    <span>Message Notifications</span>
                                                                    <p>Send Me Message notification through email</p>
                                                                </div>
                                                                <div  className="me-3">
                                                                    <div className="form-check form-switch">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="flexSwitchCheckDefault2"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="col-12 d-flex justify-content-between border-bottom align-items-center py-3 px-0 mx-2"
                                                            >
                                                                <div className="ms-1">
                                                                    <span>Upcoming Appointments Notifications</span>
                                                                    <p>
                                                                        Send Me Upcoming appointment notifications
                                                                        through email
                                                                    </p>
                                                                </div>
                                                                <div className="me-3" >
                                                                    <div className="form-check form-switch">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="flexSwitchCheckDefault3"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="col-12 d-flex justify-content-between align-items-center py-3 px-0 mx-2"
                                                            >
                                                                <div className="ms-1">
                                                                    <span>Web App Notifications</span>
                                                                    <p>
                                                                        Send me message notifications through a web or
                                                                        your device
                                                                    </p>
                                                                </div>
                                                                <div  className="me-3">
                                                                    <div className="form-check form-switch">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="flexSwitchCheckDefault4"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="col-12 d-flex flex-column flex-md-row justify-content-between py-4"
                                                            >
                                                                <button
                                                                    type="submit"
                                                                    className="btn-admin btn-admin-primary personal-information-form-button bg-transparent text-dark"
                                                                >
                                                                    Cancel
                                                                </button>
                                                                <button
                                                                    type="submit"
                                                                    className="btn-admin btn-admin-primary personal-information-form-button"
                                                                >
                                                                    Save Changes
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </AdminLayout>
    )

}
export default AdminProfileManagementPage;
