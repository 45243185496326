import React from "react";
import { useTable } from "react-table";
import styled from "styled-components";
import Pagination from "react-js-pagination";

// const Table = styled.table`
//   border-radius: 0 0 14px 14px;
//   overflow: hidden;
//   width: 100%;
//   border-collapse: collapse;
//   min-width: 500px;
//   color: #fff;
//   th,
//   td {
//     padding: 8px;
//     text-align: left;

//     font-size: 1rem;
//     font-weight: 400;
//     color: #4c4d4f;
//     background-color: #f8f8f8;

//     padding: 1.4rem 2.1rem;
//   }

//   th {
//     background-color: #fff;
//     font-size: 1rem;
//     padding: 1.4rem 2.1rem;
//     font-weight: 400;
//     color: #4c4d4f;
//   }
//   tbody tr:nth-last-child {
//     border-radius: 0px 0px 14px 14px;
//   }
//   tbody tr:nth-child(even) {
//     padding-bottom: 20px;
//   }
//   tbody tr {
//     font-size: 1.8rem;
//     border-bottom: 1px solid #d6d6d6;
//     margin: 0 20px;
//   }
// `;

const Table = styled.table`
  border-radius: 0 0 14px 14px;
  overflow: hidden;
  width: 100%;
  border-collapse: collapse;
  min-width: 500px;
  color: #4c4d4f; /* Set text color here */

  th,
  td {
    padding: 1.4rem 2.1rem; /* Adjust padding as needed */
    text-align: left;
    font-size: 1rem;
    font-weight: 400;
    background-color: #f8f8f8;
    border-bottom: 1px solid #d6d6d6;
  }

  th {
    background-color: #fff;
    /* Additional styles specific to th if needed */
  }

  tbody tr:nth-last-child {
    border-radius: 0px 0px 14px 14px;
  }

  tbody tr:nth-child(even) {
    /* Remove padding-bottom to maintain consistent row height */
  }

  tbody tr {
    font-size: 1.8rem;
    margin: 0 20px;
  }
`;

const TableComponent = ({
  columns,
  data,
  activePage,
  totalItemsCount,
  itemsCountPerPage,
  onChange,
  isDisplayPagnation = true,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: data });

  return (
    <>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups?.map((headerGroup) => (
            <tr {...headerGroup?.getHeaderGroupProps()}>
              {headerGroup.headers?.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows?.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row?.cells?.map((cell) => (
                  <td {...cell?.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default TableComponent;
