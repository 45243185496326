import React, { useState } from "react";
import styled from "styled-components";
import RxCaretDown from "react-icons/rx";
import LogoDashboard from "../../assets/logo-dashboard.png";
import BellIcon from "../../assets/bellicons.png";
import AllCollapseExample from "../Accordion/Accordion";
import { Accordion } from "react-bootstrap";

import MobileLogo from "../../assets/tempLogo.png";
import MobileNotofication from "../../assets/mobilenotification.png";
import MobileToggle from "../../assets/mobiletoggle.png";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GenericDrawer from "../Drawer/Drawer";
import { NavLink as NavLink23 } from "react-router-dom";
import Button from "../ButtonComponent/ButtonComponent";

import Morning from "../../assets/morning.png";
import Evening from "../../assets/evening.png";
import AfterNoon from "../../assets/afternoon.png";

import CustomCalender from "../CustomCalender/CustomCalender";

const PageContainer = styled.div`
  // font-family: "Cabin", sans-serif;
  height: 100vh;
  background-color: #fff9f1;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  // height: 80px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 1rem 2.2rem;
  padding: 1rem 0;
`;

const TopBarMobile = styled.div`
  // height: 20vh;
  // background: red;
  background: #ffffff;
  padding: 2.5rem 3.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 40px;
  }
  .logo-img {
    width: 200px;
  }
`;

const Logo = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-left: 30px;
  img {
    width: 13.6rem;
  }
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;
`;

const DateAndTime = styled.div`
  font-size: 14px;
  color: #4c4d4f;
  line-height: 1.5;
  font-weight: 400;
  // margin-right: 30px;
`;

const NotificationContainer = styled.div`
  width: 48px;
  height: 48px;
  background: #f8f8f8;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;

const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
`;

const Sidebar = styled.div`
  background: #ffffff;
  min-width: 240px;
  min-height: 100vh;
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 20px;
`;

const Navigation = styled.ul`
  padding: 0;
`;

const ListItem = styled.li`
  list-style: none;
  padding: 14px 0 14px 20px;
  border-radius: 14px;
  font-size: 1.8rem;

  line-height: 1.2;
`;

const NavLink = styled.a`
  text-decoration: none;
  color: black;

  &:hover {
    color: black;
  }
`;

const ActiveLink = styled(NavLink)`
  font-weight: bold;
`;

const AccordionButton = styled.button`
  padding: 0;
  font-size: 1.8rem;
  line-height: 1.2;

  &.collapsed {
    box-shadow: none;
  }
`;

const Sublink = styled.div`
  margin-top: 15px;
`;

const SublinkText = styled.a`
  font-weight: 400;
  font-size: 14px;
  color: #4c4d4f;
  padding-left: 12px;
`;

const MainSection = styled.div`
  // margin-left: 30px;
  // margin-top: 20px;
  // padding-right: 20px;
  margin-bottom: 5.6rem;
`;

const AppointmentHeading = styled.div`
  font-size: 30px;
  color: #4c4d4f;
  margin-bottom: 10px;
`;

const AppointmentText = styled.div`
  font-size: 20px;
  line-height: 22px;
  color: rgba(76, 77, 79, 0.85);
`;

const ItemWrapper = styled.div`
  // font-size: 20px;
  // line-height: 22px;
  // color: rgba(76, 77, 79, 0.85);
  margin-bottom: 3.5rem;
`;

const AppointmentButton = styled.div`
  margin-top: 15px;
  background: rgba(242, 236, 217, 0.6);
  border-radius: 5px;
  height: 42px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
`;

const MapSection = styled.div`
  // margin-top: 20px;
`;

// Styled components
const Container2 = styled.div`
  // text-align: center;
  // font-family: Arial, sans-serif;
  // margin-top: 20px;
  margin-bottom: 3.5rem;
  margin-left: 2.4rem;

  @media screen and (max-width: 1200px) {
    margin-left: 0;
  }
`;

const Heading = styled.h1`
  font-size: 2.4rem;
  color: #333230;
  font-weight: 400;
  margin-bottom: 2.4rem;

  span {
    font-weight: 500;
  }
`;

const SubContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  margin-bottom: 1.2rem;
`;

const Image = styled.img`
  // max-width: 100px;
  width: 2.4rem;
  height: auto;
  // margin-right: 20px;
`;

const TimeLabel = styled.h5`
  font-size: 1.4rem;
  color: #000000;
`;

const Message = styled.p`
  font-size: 1.4rem;
  color: #807d79;
  margin-left: 1rem;
`;

const Container3 = styled.div`
  // text-align: center;
  // font-family: Arial, sans-serif;
  margin-top: 20px;
`;

const Title = styled.h1`
  font-size: 2.3rem;
  color: #333;
  margin-bottom: 1.2rem;
  // span {
  //   font-weight: bold;
  //   margin: 0 5px;
  // }
  .span-1 {
    font-size: 2rem;
    color: #515254;
    font-weight: 400;
  }
  .span-2 {
    font-size: 1.8rem;
    color: #828180;
    font-weight: 400;
  }
`;

const Subtitle = styled.h2`
  font-size: 20px;
  color: #676767;
  margin-bottom: 0.7rem;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  color: #666;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 1.1rem;
  margin-bottom: 0.8rem;
`;
const Paragraph2 = styled.p`
  font-size: 1.2rem;
  color: #000;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 5%;
  margin-bottom: 0.8rem;
`;
const Paragraph3 = styled.p`
  font-size: 1.1rem;
  color: #807d79;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 5%;
  margin-bottom: 0.8rem;
`;
const ButtonWrapper = styled.div`
  display: flex;
  gap: 1.1rem;
  margin-bottom: 2.5rem;
`;

const PrahanConsultaionEnd = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(0);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  return (
    <>
      <PageContainer>
        <TopBar className="desktop-header">
          <Logo>
            <img src={LogoDashboard} />
          </Logo>
          <RightSide>
            <DateAndTime style={{ marginRight: "56px" }}>
              Sunday, 20 August
            </DateAndTime>
            <DateAndTime style={{ marginRight: "41px" }}>1:50 PM</DateAndTime>
            <NotificationContainer>
              <img src={BellIcon} style={{ width: "2.4rem" }} />
            </NotificationContainer>
          </RightSide>
        </TopBar>
        <GenericDrawer open={isDrawerOpen} onClose={toggleDrawer} />
        <TopBarMobile className="mobile-header">
          <img src={MobileToggle} onClick={toggleDrawer} />
          <img src={MobileLogo} className="logo-img" />
          <img src={MobileNotofication} />
        </TopBarMobile>

        <MainLayout>
          <Sidebar className="sidear-for-desktop">
            <Navigation>
              <ListItem
                style={{
                  backgroundColor: activeItem === 0 ? "#FFF9F1" : "transparent",
                  fontWeight: activeItem === 0 ? "bold" : "",
                }}
                onClick={() => setActiveItem(0)}
              >
                <NavLink23 to="/patient-dashboard">
                  <NavLink href="/patient-dashboard">Overview</NavLink>
                </NavLink23>
              </ListItem>
              <ListItem
                style={{
                  backgroundColor: activeItem === 1 ? "#FFF9F1" : "transparent",
                  fontWeight: activeItem === 1 ? "bold" : "",
                }}
                onClick={() => setActiveItem(1)}
              >
                {/* <NavLink23 to="/patient-dashboard"> */}
                <NavLink href="#">Profile</NavLink>
                {/* </NavLink23> */}
              </ListItem>
              <ListItem
                style={{
                  backgroundColor: activeItem === 2 ? "#FFF9F1" : "transparent",
                  fontWeight: activeItem === 2 ? "bold" : "",
                }}
                onClick={() => setActiveItem(2)}
              >
                <NavLink23 to="/appointment">
                  <NavLink href="#">Appointments</NavLink>
                </NavLink23>
              </ListItem>
              <ListItem style={{ padding: 0 }}>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header
                      style={{
                        backgroundColor: activeItem === 3 ? "#FFF9F1" : "",
                        fontWeight: activeItem === 3 ? "bold" : "",
                        padding: "14px 0 14px 20px",
                        borderRadius: "14px",
                        color: "#000",
                      }}
                      onClick={() => setActiveItem(3)}
                    >
                      History
                    </Accordion.Header>
                    <Accordion.Body>
                      <Sublink>
                        <NavLink23 to="/videolibrarytiktok">
                          <SublinkText href="#">Videos</SublinkText>
                        </NavLink23>
                      </Sublink>
                      <Sublink>
                        <NavLink23 to="/videolibraryyoutube">
                          <SublinkText href="#">Videos Library</SublinkText>
                        </NavLink23>
                      </Sublink>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </ListItem>
              <ListItem
                style={{
                  backgroundColor: activeItem === 4 ? "#FFF9F1" : "transparent",
                  fontWeight: activeItem === 4 ? "bold" : "",
                }}
                onClick={() => setActiveItem(4)}
              >
                <NavLink23 to="/supportchat">
                  <NavLink href="#">Messages</NavLink>
                </NavLink23>
              </ListItem>
              <ListItem style={{ padding: 0 }}>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header
                      style={{
                        backgroundColor: activeItem === 10 ? "#FFF9F1" : "",
                        fontWeight: activeItem === 10 ? "bold" : "",
                        padding: "14px 0 14px 20px",
                        borderRadius: "14px",
                        color: "#000",
                      }}
                      onClick={() => setActiveItem(10)}
                    >
                      Support
                    </Accordion.Header>
                    <Accordion.Body>
                      <Sublink>
                        <NavLink23 to="/faqs">
                          <SublinkText href="#">FAQ’s</SublinkText>
                        </NavLink23>
                      </Sublink>
                      <Sublink>
                        <NavLink23 to="/customersurvey1">
                          <SublinkText href="#">
                            Procedure Information
                          </SublinkText>
                        </NavLink23>
                      </Sublink>
                      {/* </div>  */}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </ListItem>
              <ListItem style={{ padding: 0 }}>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header
                      style={{
                        backgroundColor: activeItem === 13 ? "#FFF9F1" : "",
                        fontWeight: activeItem === 13 ? "bold" : "",
                        padding: "14px 0 14px 20px",
                        borderRadius: "14px",
                        color: "#000",
                      }}
                      onClick={() => setActiveItem(13)}
                    >
                      Aftercare
                    </Accordion.Header>

                    <Accordion.Body>
                      <Sublink>
                        <NavLink23 to="/aftercarepage">
                          <SublinkText>Quizzes</SublinkText>
                        </NavLink23>
                      </Sublink>
                      <Sublink>
                        <NavLink23 to="/aftercarepage">
                          <SublinkText>Health & Skin Assessment</SublinkText>
                        </NavLink23>
                      </Sublink>
                      {/* </div>  */}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </ListItem>
              <ListItem
                style={{
                  backgroundColor: activeItem === 5 ? "#FFF9F1" : "transparent",
                  fontWeight: activeItem === 5 ? "bold" : "",
                }}
                onClick={() => setActiveItem(5)}
              >
                {/* <NavLink23 to="/patient-dashboard"> */}
                <NavLink href="#">Finances</NavLink>
                {/* </NavLink23> */}
              </ListItem>
              <ListItem
                style={{
                  backgroundColor: activeItem === 6 ? "#FFF9F1" : "transparent",
                  fontWeight: activeItem === 6 ? "bold" : "",
                }}
                onClick={() => setActiveItem(6)}
              >
                <NavLink23 to="/virtualconsultation">
                  <NavLink href="#">Virtual Consultation</NavLink>
                </NavLink23>
              </ListItem>
            </Navigation>
          </Sidebar>
          <Container className="main-content">
            <Row>
              <Col xl={12} xs={12}>
                <div className="">
                  <Container3>
                    <Title>
                      Appointments / <span className="span-1">Prahan</span> /{" "}
                      <span className="span-2">consultation</span>
                    </Title>
                    <Subtitle>30 minutes Consultation</Subtitle>
                    <Paragraph>
                      Schedule a complimentary Wrinkle Consultation (with option
                      to receive same-day Wrinkle Treatment)
                    </Paragraph>
                    <Paragraph2>SET FILTERS</Paragraph2>
                    <Paragraph3>Choose Providers</Paragraph3>
                    <ButtonWrapper>
                      <Button
                        className="test-dr"
                        style={{
                          backgroundColor: "#ffe9c5",
                          padding: "0.7rem 0.8rem",
                          color: "#000",
                          borderRadius: "5px",
                          width: "auto",
                          fontSize: "1.1rem",
                        }}
                      >
                        Dr Vihang Sharma
                      </Button>
                      <Button
                        className="bring"
                        style={{
                          backgroundColor: "#f8c269",
                          padding: "0.7rem 0.8rem",
                          color: "#fff",
                          borderRadius: "5px",
                          width: "auto",
                          fontSize: "1.1rem",
                        }}
                      >
                        Brington
                      </Button>
                    </ButtonWrapper>
                  </Container3>
                </div>
              </Col>
              <Col xl={6} xs={12}>
                <div className="">
                  <CustomCalender />
                </div>
              </Col>
              <Col xl={6} xs={12}>
                <div className="">
                  <Container2>
                    <Heading>
                      Select a time for <span> Tuesday, Aug. 22</span>
                    </Heading>
                    <ItemWrapper>
                      <SubContainer>
                        <Image src={Morning} alt="Morning" />
                        <TimeLabel>MORNING</TimeLabel>
                      </SubContainer>
                      <Message>
                        No morning appointments are available on this date.
                      </Message>
                    </ItemWrapper>
                    <ItemWrapper>
                      <SubContainer>
                        <Image src={AfterNoon} alt="Morning" />
                        <TimeLabel>AFTERNOON</TimeLabel>
                      </SubContainer>
                      <Message>
                        No morning appointments are available on this date.
                      </Message>
                    </ItemWrapper>
                    <ItemWrapper>
                      <SubContainer>
                        <Image src={Evening} alt="Morning" />
                        <TimeLabel>EVENING</TimeLabel>
                      </SubContainer>
                      <Message>
                        No morning appointments are available on this date.
                      </Message>
                    </ItemWrapper>
                    <p
                      style={{
                        fontSize: "1.4rem",
                        fontWeight: 400,
                        color: "#000",
                        marginLeft: "1rem",
                      }}
                    >
                      Not seeing the time you want? Call us at 212-884-9663 to
                      schedule.
                    </p>
                  </Container2>

                  {/* <Container2>
                    <Heading>
                      Select a time for <span> Tuesday, Aug. 22</span>
                    </Heading>
                    <SubContainer>
                      <Image src="" alt="Morning" />
                      <TimeLabel>MORNING</TimeLabel>
                    </SubContainer>
                    <Message>
                      No morning appointments are available on this date.
                    </Message>
                  </Container2> */}
                  {/* <MapSection>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319"
                      width="100%"
                      height="600"
                      frameBorder="0"
                      style={{ border: 0 }}
                      title="Google Maps"
                    ></iframe>
                  </MapSection> */}
                </div>
              </Col>
            </Row>
          </Container>
        </MainLayout>
      </PageContainer>
    </>
  );
};

export default PrahanConsultaionEnd;
