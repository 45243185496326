// import React, { useState } from "react";
// import styled from "styled-components";
// import ReuseAbleHeader from "../ReuseAbleHeader/ReuseAbleHeader";
// import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";
// import Container from "react-bootstrap/Container";
// import TableComponent from "../TableComponent/TableComponent2"
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import img1 from '../../assets/Rectangle 2647.png';
// const MainLayout = styled.div`
//   display: flex;
//   flex: 1;
//   background: #fff9f1;
//   // align-items: center;
//   .table-div{
//     padding: 31px;
//     background-color: #fff;
//     border-radius: 10px;
//     margin-top: 15px;
//   }
//  .catbtn1{
//     background-color: #000;
//     color: #fff;
//     font-size: 14px;
//     padding-left: 30px;
//     padding-right: 30px;
//     padding-top: 10px;
//     padding-bottom: 10px;
//     border-radius: 25px;
//  }
//  .btns-group{
//     display: flex;
//     gap: 25px;
//     padding-left: 70px;
//     padding-top: 30px;
//     padding-bottom: 25px;
//     border-bottom: solid 1px;
//  }
//  .main-box{
//     display: flex;
//     border: solid 1px;
//     padding: 20px;
//     border-radius: 12px;
//     width: 450px;
//     gap: 15px;
//     height: 300px;
//     min-width: 395px;
//  }
//  .buy{
//     position: relative;
//     top: 15px;
//     margin-left: auto;
//     display: block;
//  }
// `;

// const HeaderWrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-start;

//   .main-heading {
//     h1 {
//       font-size: 3.4rem;
//       font-weight: 400;
//       line-height: 1.5;
//       margin-bottom: 1.4rem;
//       color: #4c4d4f;
//     }
//     p {
//       font-size: 1.4rem;
//       font-weight: 400;
//       line-height: 1.5;
//       margin-bottom: 1.4rem;
//       color: #4c4d4f;
//     }
//   }

// `;
// const columns = [
//   { Header: "Treatment", accessor: "column1" },
//   { Header: "Procedure Notes", accessor: "column2" },
//   { Header: "Date", accessor: "column3" },
//   { Header: "Time", accessor: "column4" },
//   { Header: "Payment", accessor: "column7" },
// ];

// const data = [
//   {
//     column1: "PRP Injections",
//     column2: "View Notes",
//     column3: "12/08/23",
//     column4: "09:00am",
//     column7: "Pending",
//   },
//   {
//     column1: "PRP Injections",
//     column2: "View Notes",
//     column3: "12/08/23",
//     column4: "09:00am",
//     column7: "Pending",
//   },

// ];
// const ShoppingInterface = () => {
//   const [isdata, setIsData] = useState(data);
//   return (
//     <>
//     <div>
//       <ReuseAbleHeader />
//       <MainLayout>
//         <ReuseAbleSidebar />
//         <Container className="main-content">
//           {/* <Row></Row> */}
//           <HeaderWrapper>
//             <div className="main-heading">
//               <h1>Shopping Interface</h1>
//               <div style={{display: "flex",alignItems: "center",gap: "40px"}}>
//                 <p>My Schedules</p>
//               </div>
//             </div>
//           </HeaderWrapper>

//           <Row>
//             <Col xs={12} md={12}>
//                <div style={{backgroundColor:"#fff"}}>
//                     <div className="btns-group">
//                         <button className="catbtn1">category1</button>
//                         <button style={{backgroundColor:"#fff",color:"#000"}} className="catbtn1">category2</button>
//                         <button style={{backgroundColor:"#fff",color:"#000"}} className="catbtn1">category3</button>
//                         <button style={{backgroundColor:"#fff",color:"#000"}} className="catbtn1">category4</button>
//                     </div>
//                     <div style={{padding: "25px",display:"flex",gap:"20px"}}>
//                         <div className="main-box">
//                             <img style={{width: "145px",height: "260px"}} src={img1}/>
//                             <div>
//                                 <h1 style={{marginTop: "25px",paddingBottom: "5px",fontWeight: "800"}}>Vitamin A Night Serum</h1>
//                                 <p style={{fontSize: "12px",color:"#4C4D4F",fontWeight: "800"}}>30 ML / 1 FL OZ</p>
//                                 <p style={{fontSize: "12px",color:"#BABBBC",fontWeight: "800"}}>Brightening Vitamin A Serum</p>
//                                 <p style={{fontSize: "12px",fontWeight: "800"}}>30 reviews</p>
//                                 <p style={{fontSize: "12px",fontWeight: "800"}}>$69.00  $90.00</p>
//                                 <button className="catbtn1 buy">Buy Now</button>
//                             </div>
//                         </div>
//                         <div className="main-box">
//                             <img style={{width: "145px",height: "260px"}} src={img1}/>
//                             <div>
//                                 <h1 style={{marginTop: "25px",paddingBottom: "5px",fontWeight: "800"}}>Vitamin A Night Serum</h1>
//                                 <p style={{fontSize: "12px",color:"#4C4D4F",fontWeight: "800"}}>30 ML / 1 FL OZ</p>
//                                 <p style={{fontSize: "12px",color:"#BABBBC",fontWeight: "800"}}>Brightening Vitamin A Serum</p>
//                                 <p style={{fontSize: "12px",fontWeight: "800"}}>30 reviews</p>
//                                 <p style={{fontSize: "12px",fontWeight: "800"}}>$69.00  $90.00</p>
//                                 <button className="catbtn1 buy">Buy Now</button>
//                             </div>
//                         </div>
//                         <div className="main-box">
//                             <img style={{width: "145px",height: "260px"}} src={img1}/>
//                             <div>
//                                 <h1 style={{marginTop: "25px",paddingBottom: "5px",fontWeight: "800"}}>Vitamin A Night Serum</h1>
//                                 <p style={{fontSize: "12px",color:"#4C4D4F",fontWeight: "800"}}>30 ML / 1 FL OZ</p>
//                                 <p style={{fontSize: "12px",color:"#BABBBC",fontWeight: "800"}}>Brightening Vitamin A Serum</p>
//                                 <p style={{fontSize: "12px",fontWeight: "800"}}>30 reviews</p>
//                                 <p style={{fontSize: "12px",fontWeight: "800"}}>$69.00  $90.00</p>
//                                 <button className="catbtn1 buy">Buy Now</button>
//                             </div>
//                         </div>
//                     </div>
//                     <div style={{padding: "25px",display:"flex",gap:"20px"}}>
//                         <div className="main-box">
//                             <img style={{width: "145px",height: "260px"}} src={img1}/>
//                             <div>
//                                 <h1 style={{marginTop: "25px",paddingBottom: "5px",fontWeight: "800"}}>Vitamin A Night Serum</h1>
//                                 <p style={{fontSize: "12px",color:"#4C4D4F",fontWeight: "800"}}>30 ML / 1 FL OZ</p>
//                                 <p style={{fontSize: "12px",color:"#BABBBC",fontWeight: "800"}}>Brightening Vitamin A Serum</p>
//                                 <p style={{fontSize: "12px",fontWeight: "800"}}>30 reviews</p>
//                                 <p style={{fontSize: "12px",fontWeight: "800"}}>$69.00  $90.00</p>
//                                 <button className="catbtn1 buy">Buy Now</button>
//                             </div>
//                         </div>
//                         <div className="main-box">
//                             <img style={{width: "145px",height: "260px"}} src={img1}/>
//                             <div>
//                                 <h1 style={{marginTop: "25px",paddingBottom: "5px",fontWeight: "800"}}>Vitamin A Night Serum</h1>
//                                 <p style={{fontSize: "12px",color:"#4C4D4F",fontWeight: "800"}}>30 ML / 1 FL OZ</p>
//                                 <p style={{fontSize: "12px",color:"#BABBBC",fontWeight: "800"}}>Brightening Vitamin A Serum</p>
//                                 <p style={{fontSize: "12px",fontWeight: "800"}}>30 reviews</p>
//                                 <p style={{fontSize: "12px",fontWeight: "800"}}>$69.00  $90.00</p>
//                                 <button className="catbtn1 buy">Buy Now</button>
//                             </div>
//                         </div>
//                         <div className="main-box">
//                             <img style={{width: "145px",height: "260px"}} src={img1}/>
//                             <div>
//                                 <h1 style={{marginTop: "25px",paddingBottom: "5px",fontWeight: "800"}}>Vitamin A Night Serum</h1>
//                                 <p style={{fontSize: "12px",color:"#4C4D4F",fontWeight: "800"}}>30 ML / 1 FL OZ</p>
//                                 <p style={{fontSize: "12px",color:"#BABBBC",fontWeight: "800"}}>Brightening Vitamin A Serum</p>
//                                 <p style={{fontSize: "12px",fontWeight: "800"}}>30 reviews</p>
//                                 <p style={{fontSize: "12px",fontWeight: "800"}}>$69.00  $90.00</p>
//                                 <button className="catbtn1 buy">Buy Now</button>
//                             </div>
//                         </div>
//                     </div>
//                     <div style={{padding: "25px",display:"flex",gap:"20px"}}>
//                         <div className="main-box">
//                             <img style={{width: "145px",height: "260px"}} src={img1}/>
//                             <div>
//                                 <h1 style={{marginTop: "25px",paddingBottom: "5px",fontWeight: "800"}}>Vitamin A Night Serum</h1>
//                                 <p style={{fontSize: "12px",color:"#4C4D4F",fontWeight: "800"}}>30 ML / 1 FL OZ</p>
//                                 <p style={{fontSize: "12px",color:"#BABBBC",fontWeight: "800"}}>Brightening Vitamin A Serum</p>
//                                 <p style={{fontSize: "12px",fontWeight: "800"}}>30 reviews</p>
//                                 <p style={{fontSize: "12px",fontWeight: "800"}}>$69.00  $90.00</p>
//                                 <button className="catbtn1 buy">Buy Now</button>
//                             </div>
//                         </div>
//                         <div className="main-box">
//                             <img style={{width: "145px",height: "260px"}} src={img1}/>
//                             <div>
//                                 <h1 style={{marginTop: "25px",paddingBottom: "5px",fontWeight: "800"}}>Vitamin A Night Serum</h1>
//                                 <p style={{fontSize: "12px",color:"#4C4D4F",fontWeight: "800"}}>30 ML / 1 FL OZ</p>
//                                 <p style={{fontSize: "12px",color:"#BABBBC",fontWeight: "800"}}>Brightening Vitamin A Serum</p>
//                                 <p style={{fontSize: "12px",fontWeight: "800"}}>30 reviews</p>
//                                 <p style={{fontSize: "12px",fontWeight: "800"}}>$69.00  $90.00</p>
//                                 <button className="catbtn1 buy">Buy Now</button>
//                             </div>
//                         </div>
//                         <div className="main-box">
//                             <img style={{width: "145px",height: "260px"}} src={img1}/>
//                             <div>
//                                 <h1 style={{marginTop: "25px",paddingBottom: "5px",fontWeight: "800"}}>Vitamin A Night Serum</h1>
//                                 <p style={{fontSize: "12px",color:"#4C4D4F",fontWeight: "800"}}>30 ML / 1 FL OZ</p>
//                                 <p style={{fontSize: "12px",color:"#BABBBC",fontWeight: "800"}}>Brightening Vitamin A Serum</p>
//                                 <p style={{fontSize: "12px",fontWeight: "800"}}>30 reviews</p>
//                                 <p style={{fontSize: "12px",fontWeight: "800"}}>$69.00  $90.00</p>
//                                 <button className="catbtn1 buy">Buy Now</button>
//                             </div>
//                         </div>
//                     </div>
//                </div>
//             </Col>
//           </Row>
//         </Container>
//       </MainLayout>
//     </div>
//     </>
//   );
// };

// export default ShoppingInterface;

import React, { useState } from "react";
import styled from "styled-components";
import ReuseAbleHeader from "../ReuseAbleHeader/ReuseAbleHeader";
import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";
import Container from "react-bootstrap/Container";
import TableComponent from "../TableComponent/TableComponent2";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import img1 from "../../assets/Rectangle 2647.png";
import img2 from "../../assets/Rectangle 2647 (1).png";
import img3 from "../../assets/Rectangle.png";
const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
  // align-items: center;
  .table-div {
    padding: 31px;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 15px;
  }
  .catbtn1 {
    background-color: #000;
    color: #fff;
    font-size: 14px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 25px;
  }
  .btns-group {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    padding-left: 70px;
    padding-top: 30px;
    padding-bottom: 25px;
    border-bottom: solid 1px;
  }
  .main-box {
   
    display: flex;
    border: solid 1px;
    padding: 20px;
    border-radius: 12px;
    width: 362px;
    gap: 15px;
    height: 263px;
    min-width: 289px;

  }
  .images{
    width: 145px;
    height: 222px;
  }
  @media only screen and (max-width: 992px) {
    .main-box{
      width: 100%;
    }
    .images{
      width: 30%;
    }
  }
  .buy {
    position: relative;
    width: 100%;
    display: block;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .main-heading {
    h1 {
      font-size: 3.4rem;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 1.4rem;
      color: #4c4d4f;
    }
    p {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 1.4rem;
      color: #4c4d4f;
    }
  }
`;
const columns = [
  { Header: "Treatment", accessor: "column1" },
  { Header: "Procedure Notes", accessor: "column2" },
  { Header: "Date", accessor: "column3" },
  { Header: "Time", accessor: "column4" },
  { Header: "Payment", accessor: "column7" },
];

const data = [
  {
    column1: "PRP Injections",
    column2: "View Notes",
    column3: "12/08/23",
    column4: "09:00am",
    column7: "Pending",
  },
  {
    column1: "PRP Injections",
    column2: "View Notes",
    column3: "12/08/23",
    column4: "09:00am",
    column7: "Pending",
  },
];
const ShoppingInterface = () => {
  const [isdata, setIsData] = useState(data);
  return (
    <>
      <div>
        <ReuseAbleHeader />
        <MainLayout>
          <ReuseAbleSidebar />
          <Container className="main-content">
            {/* {/ <Row></Row> /} */}
            <HeaderWrapper>
              <div className="main-heading">
                <h1>Shopping Interface</h1>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "40px" }}
                >
                  <p>My Schedules</p>
                </div>
              </div>
            </HeaderWrapper>

            <Row>
              <Col xs={12} md={12}>
                <div style={{ backgroundColor: "#fff" }}>
                  <div className="btns-group">
                    <button className="catbtn1" style={{border:"solid 1px"}}>category1</button>
                    <button
                      style={{ backgroundColor: "#fff", color: "#000",border:"solid 1px" }}
                      className="catbtn1"
                    >
                      category2
                    </button>
                    <button
                      style={{ backgroundColor: "#fff", color: "#000",border:"solid 1px" }}
                      className="catbtn1"
                    >
                      category3
                    </button>
                    <button
                      style={{ backgroundColor: "#fff", color: "#000",border:"solid 1px" }}
                      className="catbtn1"
                    >
                      category4
                    </button>
                  </div>
                  <div className="shopping-box">
                    <div className="main-box">
                      <img className="images"
                        src={img1}
                      />
                      <div>
                        <h1
                          style={{
                            fontSize:"18px",
                            fontWeight: "600",
                            lineHeight:"1.8888"
                          }}
                        >
                          Vitamin A Night Serum
                        </h1>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#4C4D4F",
                            fontWeight: "800",
                          }}
                        >
                          30 ML / 1 FL OZ
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#BABBBC",
                            fontWeight: "800",
                          }}
                        >
                          Brightening Vitamin A Serum
                        </p>
                        <p style={{ fontSize: "12px", fontWeight: "800" }}>
                          30 reviews
                        </p>
                        <p style={{ fontSize: "12px", fontWeight: "800" }}>
                          $69.00 $90.00
                        </p>
                        <button className="catbtn1 buy">Buy Now</button>
                      </div>
                    </div>
                    <div className="main-box">
                      <img className="images"
                        src={img3}
                      />
                      <div>
                        <h1
                          style={{
                            fontSize:"18px",
                            fontWeight: "600",
                            lineHeight:"1.8888"
                          }}
                        >
                          Vitamin A Night Serum
                        </h1>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#4C4D4F",
                            fontWeight: "800",
                          }}
                        >
                          30 ML / 1 FL OZ
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#BABBBC",
                            fontWeight: "800",
                          }}
                        >
                          Brightening Vitamin A Serum
                        </p>
                        <p style={{ fontSize: "12px", fontWeight: "800" }}>
                          30 reviews
                        </p>
                        <p style={{ fontSize: "12px", fontWeight: "800" }}>
                          $69.00 $90.00
                        </p>
                        <button className="catbtn1 buy">Buy Now</button>
                      </div>
                    </div>
                    <div className="main-box">
                      <img className="images"
                        src={img2}
                      />
                      <div>
                        <h1
                          style={{
                            fontSize:"18px",
                            fontWeight: "600",
                            lineHeight:"1.8888"
                          }}
                        >
                          Vitamin A Night Serum
                        </h1>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#4C4D4F",
                            fontWeight: "800",
                          }}
                        >
                          30 ML / 1 FL OZ
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#BABBBC",
                            fontWeight: "800",
                          }}
                        >
                          Brightening Vitamin A Serum
                        </p>
                        <p style={{ fontSize: "12px", fontWeight: "800" }}>
                          30 reviews
                        </p>
                        <p style={{ fontSize: "12px", fontWeight: "800" }}>
                          $69.00 $90.00
                        </p>
                        <button className="catbtn1 buy">Buy Now</button>
                      </div>
                    </div>
                    <div className="main-box">
                      <img className="images"
                        src={img3}
                      />
                      <div>
                        <h1
                          style={{
                            fontSize:"18px",
                            fontWeight: "600",
                            lineHeight:"1.8888"
                          }}
                        >
                          Vitamin A Night Serum
                        </h1>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#4C4D4F",
                            fontWeight: "800",
                          }}
                        >
                          30 ML / 1 FL OZ
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#BABBBC",
                            fontWeight: "800",
                          }}
                        >
                          Brightening Vitamin A Serum
                        </p>
                        <p style={{ fontSize: "12px", fontWeight: "800" }}>
                          30 reviews
                        </p>
                        <p style={{ fontSize: "12px", fontWeight: "800" }}>
                          $69.00 $90.00
                        </p>
                        <button className="catbtn1 buy">Buy Now</button>
                      </div>
                    </div>
                    <div className="main-box">
                      <img className="images"
                        src={img1}
                      />
                      <div>
                        <h1
                          style={{
                            fontSize:"18px",
                            fontWeight: "600",
                            lineHeight:"1.8888"
                          }}
                        >
                          Vitamin A Night Serum
                        </h1>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#4C4D4F",
                            fontWeight: "800",
                          }}
                        >
                          30 ML / 1 FL OZ
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#BABBBC",
                            fontWeight: "800",
                          }}
                        >
                          Brightening Vitamin A Serum
                        </p>
                        <p style={{ fontSize: "12px", fontWeight: "800" }}>
                          30 reviews
                        </p>
                        <p style={{ fontSize: "12px", fontWeight: "800" }}>
                          $69.00 $90.00
                        </p>
                        <button className="catbtn1 buy">Buy Now</button>
                      </div>
                    </div>
                    <div className="main-box">
                      <img className="images"
                        src={img3}
                      />
                      <div>
                        <h1
                          style={{
                            fontSize:"18px",
                            fontWeight: "600",
                            lineHeight:"1.8888"
                          }}
                        >
                          Vitamin A Night Serum
                        </h1>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#4C4D4F",
                            fontWeight: "800",
                          }}
                        >
                          30 ML / 1 FL OZ
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#BABBBC",
                            fontWeight: "800",
                          }}
                        >
                          Brightening Vitamin A Serum
                        </p>
                        <p style={{ fontSize: "12px", fontWeight: "800" }}>
                          30 reviews
                        </p>
                        <p style={{ fontSize: "12px", fontWeight: "800" }}>
                          $69.00 $90.00
                        </p>
                        <button className="catbtn1 buy">Buy Now</button>
                      </div>
                    </div>
                    <div className="main-box">
                      <img className="images"
                        src={img2}
                      />
                      <div>
                        <h1
                          style={{
                            fontSize:"18px",
                            fontWeight: "600",
                            lineHeight:"1.8888"
                          }}
                        >
                          Vitamin A Night Serum
                        </h1>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#4C4D4F",
                            fontWeight: "800",
                          }}
                        >
                          30 ML / 1 FL OZ
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#BABBBC",
                            fontWeight: "800",
                          }}
                        >
                          Brightening Vitamin A Serum
                        </p>
                        <p style={{ fontSize: "12px", fontWeight: "800" }}>
                          30 reviews
                        </p>
                        <p style={{ fontSize: "12px", fontWeight: "800" }}>
                          $69.00 $90.00
                        </p>
                        <button className="catbtn1 buy">Buy Now</button>
                      </div>
                    </div>
                    <div className="main-box">
                      <img className="images"
                        src={img1}
                      />
                      <div>
                        <h1
                          style={{
                            fontSize:"18px",
                            fontWeight: "600",
                            lineHeight:"1.8888"
                          }}
                        >
                          Vitamin A Night Serum
                        </h1>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#4C4D4F",
                            fontWeight: "800",
                          }}
                        >
                          30 ML / 1 FL OZ
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#BABBBC",
                            fontWeight: "800",
                          }}
                        >
                          Brightening Vitamin A Serum
                        </p>
                        <p style={{ fontSize: "12px", fontWeight: "800" }}>
                          30 reviews
                        </p>
                        <p style={{ fontSize: "12px", fontWeight: "800" }}>
                          $69.00 $90.00
                        </p>
                        <button className="catbtn1 buy">Buy Now</button>
                      </div>
                    </div>
                    <div className="main-box">
                      <img className="images"
                        src={img2}
                      />
                      <div>
                        <h1
                          style={{
                            fontSize:"18px",
                            fontWeight: "600",
                            lineHeight:"1.8888"
                          }}
                        >
                          Vitamin A Night Serum
                        </h1>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#4C4D4F",
                            fontWeight: "800",
                          }}
                        >
                          30 ML / 1 FL OZ
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            color: "#BABBBC",
                            fontWeight: "800",
                          }}
                        >
                          Brightening Vitamin A Serum
                        </p>
                        <p style={{ fontSize: "12px", fontWeight: "800" }}>
                          30 reviews
                        </p>
                        <p style={{ fontSize: "12px", fontWeight: "800" }}>
                          $69.00 $90.00
                        </p>
                        <button className="catbtn1 buy">Buy Now</button>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </Col>
            </Row>
          </Container>
        </MainLayout>
      </div>
    </>
  );
};

export default ShoppingInterface;
