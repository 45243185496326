// import React from "react";

// const PaymentProcessComponent = () => {
//   return <div>PaymentProcessComponent</div>;
// };

// export default PaymentProcessComponent;

import React, { useState } from "react";
import styled from "styled-components";
import ReuseAbleHeader from "../ReuseAbleHeader/ReuseAbleHeader";
import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TableComponent from "../TableComponent/TableComponent";
import print from "../../assets/Print.png";

import { AiOutlineCheck } from "react-icons/ai";

const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
  .para {
    margin-bottom: 0;
  }
  @media screen and (max-width: 600px) {
    .last-buttons {
      display: flex;
      flex-direction: column;
      gap: 13px;
      align-items: center;
      .confirm-btn {
        padding: 0;
        margin: 0;
      }
    }
  }
`;
const Table2Wrapperr = styled.div`
  height: 178px;

  // display: flex;
  // flex: 1;
  // background: #fff9f1;
  padding: 22px 28px;
  background: #fff;
  border-radius: 0 0 14px 14px;

  overflow-y: auto;

  .add-border {
    border: 1px solid #d6d6d6;
    border-radius: 14px;
    overflow: auto;
  }
`;

const TableWrapperr = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
  border-bottom: 1px solid #dbdbdc;
  padding: 2.1rem 2.7rem;
  background-color: #fff;
  border-radius: 14px 14px 0 0;

  h1 {
  }
`;

const columns = [
  { Header: "Surgery Name", accessor: "column1" },
  { Header: "Date", accessor: "column2" },
  { Header: "Treatment", accessor: "column3" },
  { Header: "Doctor name", accessor: "column4" },
  { Header: "Atter case ", accessor: "column5" },
];

// const data = isdata.map((tableItem) => ({
const data = [
  {
    column1: "Anti wrinkle Section",
    column2: "14/08/2023",
    column3: "PRP Injections",
    column4: "Dr. Hasham Ahmad",
    column5: "View",
  },
  {
    column1: "Anti wrinkle Section",
    column2: "14/08/2023",
    column3: "PRP Injections",
    column4: "Dr. Hasham Ahmad",
    column5: "View",
  },
];

// Define styled components for your elements
const MainBack = styled.div`
  background-color: #fff9f1;

  .first-inputs {
    width: 100%;
    flex: 1;
  }
  .second-inputs {
    width: 100%;
    flex: 1;
  }
`;

const Container2 = styled.div`
  // max-width: 575px;
  max-width: 100%;
  margin: 0 auto;
  padding: 40px;
  background-color: #fff;
`;

const FirstHead = styled.div`
  padding: 40px;
  padding-left: 0;
`;

const H1 = styled.h1`
  margin-top: 0;
`;

const P = styled.p`
  color: grey;
`;

const FirstCard = styled.div`
  margin-top: 20px;
`;

const Row2 = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Frame = styled.div`
  display: flex;
  align-items: center;
  // display: none;
`;

const FrameImage = styled.div`
  width: 48px;
  height: 48px;
  background-color: #36c490;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HorizontalLine = styled.div`
  width: 100px;
  height: 1px;
  background-color: black;
  margin-left: 19px;
  margin-right: 21px;
`;

const SecondFrame = styled.div`
  text-align: center;
  background-color: grey;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ThirdFrame = styled.div`
  text-align: center;
  background-color: #ffff;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainFrame = styled.div`
  display: flex;
`;

const MainInputs = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 2.1rem;
  @media screen and (max-width: 1060px) {
    flex-direction: column;
  }
`;

const MainCheckboxes = styled.div`
  display: flex;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 0.8rem;
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const InputCheck = styled.input`
  margin-top: 6px;
  margin-right: 6px;
`;

const Inputs = styled.input`
  // width: 340px;
  width: 100%;
  height: 43px;
  border: 1px solid #4c4d4f;
  border-radius: 14px;
`;

const SaveButton = styled.button`
  width: 184px;
  height: 48px;
  border: 1px solid black;
  border-radius: 24px;
  background-color: white;
  margin-top: 20px;
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }
`;

const ConfirmButton = styled.button`
  width: 184px;
  height: 48px;
  border: 1px solid black;
  border-radius: 24px;
  background-color: black;
  color: white;
  margin-left: 15px;
  cursor: pointer;

  &:hover {
    background-color: #333;
  }
`;

const TableWrapperr3 = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;

  padding: 2.1rem 2.7rem;
  background-color: #fff;
  border-radius: 14px 14px 0 0;
  gap: 200px;
  h1 {
  }
  img {
    width: 18px;
    height: 18px;
  }
`;

const fontstyleName = {
  fontWeight: 800,
  fontSize: "12px",
  lineHeight: "21px",
};

const TableContainer = styled.div`
  width: 97%;
  height: 159px;
  top: 454px;
  left: 1075px;
  border-radius: 5px 5px 0px 0px;
  overflow: hidden;
`;

const Textarea = styled.div`
  color: #00000080;
  font-size: 10px;
  font-weight: 500;
`;
const TextItem = styled.div`
  margin: 0 auto;
  font-size: 14px;
  font-weight: 500;
`;

const Container22 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  width: 84%;
`;
const Value = styled.span`
  margin-left: auto;
`;
const Label = styled.span`
  font-weight: bold;
`;

const CustomButton = styled.button`
  width: 184px;
  height: 48px;
  align: center;
  border-radius: 24px;
  background-color: black;
  color: white; /* Adding white text color for better visibility on a black background */
  font-family: Cabin;
  font-size: 16px; /* Adjust the font size as needed */
  font-weight: 400;
  line-height: 20px; /* Adjust line height as needed */
  letter-spacing: 0em;
  text-align: center;
  border: none;
  cursor: pointer;
`;

const TextContainer = styled.div`
  width: 305px;
  background-color: #f8f8f8;
  height: 55px;
  margin: 0 auto;
  padding: 11px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0; /* This removes the spacing between cells */
  border: 1px solid #ccc; /* Add the border to the entire table */
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin-bottom: 10px;
  line-height: 2;
`;

const TableHeader = styled.th`
  background-color: black;
  color: white;
  padding: 10px;
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: center;
`;

const StyleLine = styled.div`
  width: 100%;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 10px;
    right: 10px;
    border-top: 0.5px solid #ccc; /* Adjust color and thickness as needed */
    transform: translateY(-50%);
  }
`;

const Para2 = styled.p`
  color: black;
  font-size: 12px;
  margin-top: 20px;
`;

const PaymentProcessComponent = () => {
  const [isdata, setIsData] = useState(data);
  return (
    <div>
      <ReuseAbleHeader />
      <MainLayout>
        <ReuseAbleSidebar />

        <Container className="main-content">
          <FirstHead>
            <H1>Payment</H1>
            <P>Create an invoice for your patients</P>
          </FirstHead>
          <Row>
            <Col xl={8} xs={12}>
              <MainBack>
                <Container2>
                  <FirstCard>
                    <Row2>
                      <div className="col-12">
                        <MainFrame>
                          <Frame>
                            <div>
                              <FrameImage>
                                <AiOutlineCheck />
                              </FrameImage>
                            </div>
                            <p>Shipping</p>
                            <HorizontalLine />
                          </Frame>
                          <Frame>
                            <SecondFrame>
                              <p>2</p>
                            </SecondFrame>
                            <p>Shipping</p>
                            <HorizontalLine />
                          </Frame>
                          <Frame>
                            <ThirdFrame>
                              <p>3</p>
                            </ThirdFrame>
                            <p>Shipping</p>
                          </Frame>
                        </MainFrame>
                        <div>
                          <h4>Payment Method</h4>
                        </div>
                        <MainCheckboxes>
                          <CheckboxLabel>
                            <InputCheck
                              className="inputs-check"
                              type="checkbox"
                              name=""
                              id=""
                            />
                            <p className="para">Credit/Debit card</p>
                          </CheckboxLabel>
                          <CheckboxLabel>
                            <InputCheck
                              className="inputs-check"
                              type="checkbox"
                              name=""
                              id=""
                            />
                            <p className="para">Net Banking</p>
                          </CheckboxLabel>
                          <CheckboxLabel>
                            <InputCheck
                              className="inputs-check"
                              type="checkbox"
                              name=""
                              id=""
                            />
                            <p className="para">Paypal</p>
                          </CheckboxLabel>
                          <CheckboxLabel>
                            <InputCheck
                              className="inputs-check"
                              type="checkbox"
                              name=""
                              id=""
                            />
                            <p className="para">Bitcoin</p>
                          </CheckboxLabel>
                        </MainCheckboxes>
                        <h4>Add Payment Details</h4>
                        <MainInputs>
                          <div className="first-inputs">
                            <p className="para">Enter Name on Card</p>
                            <Inputs
                              className="inputs"
                              type="text"
                              name=""
                              id=""
                            />
                          </div>
                          <div className="second-inputs">
                            <p className="para">Card Number</p>
                            <Inputs
                              className="inputs"
                              type="text"
                              name=""
                              id=""
                            />
                          </div>
                        </MainInputs>
                        <MainInputs>
                          <div className="first-inputs">
                            <p className="para">Expiry (MM/YY)</p>
                            <Inputs
                              className="inputs"
                              type="text"
                              name=""
                              id=""
                            />
                          </div>
                          <div className="second-inputs">
                            <p className="para">CVV Code</p>
                            <Inputs
                              className="inputs"
                              type="text"
                              name=""
                              id=""
                            />
                          </div>
                        </MainInputs>
                        <Para2>
                          By Clicking on ‘Confirm Payment’ I agree with the
                          Terms and conditions of the Company.
                        </Para2>
                        <div className="last-buttons">
                          <SaveButton>Save</SaveButton>
                          <ConfirmButton className="confirm-btn">
                            Confirm Payment
                          </ConfirmButton>
                        </div>
                      </div>
                    </Row2>
                  </FirstCard>
                </Container2>
              </MainBack>
            </Col>
            <Col xl={4} xs={12} className="mt-4">
              <TableWrapperr3>
                <h4>Preview Invoice</h4>
                <img className="print-logo" src={print} />
              </TableWrapperr3>
              <Table2Wrapperr>
                <div className="add-border">
                  <img
                    src="/static/media/logo-dashboard.26b9717866e12cf5d6ac.png"
                    style={{
                      width: "90.67px",
                      height: "16px",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  />
                  <div
                    style={{
                      marginTop: "10px",
                      marginLeft: "15px",
                    }}
                  >
                    SUMMERY
                    <StyleLine></StyleLine>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "50%",
                      paddingLeft: "10px",
                      padding: "18px",
                    }}
                  >
                    <div style={fontstyleName}>Dr.Vihang</div>
                    <div style={fontstyleName}> John</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "10px",
                    }}
                  >
                    <TableContainer>
                      <Table>
                        <thead>
                          <tr>
                            <TableHeader>item</TableHeader>
                            <TableHeader>Rate</TableHeader>
                            <TableHeader>Qty</TableHeader>
                            <TableHeader>Amount</TableHeader>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <TableCell>Vitamin A Night Serum</TableCell>
                            <TableCell>$90</TableCell>
                            <TableCell>2</TableCell>
                            <TableCell>$180</TableCell>
                          </tr>
                          <tr>
                            <TableCell>Vitamin A Night Serum</TableCell>
                            <TableCell>$90</TableCell>
                            <TableCell>2</TableCell>
                            <TableCell>$180</TableCell>
                          </tr>
                          <tr>
                            <TableCell>Vitamin A Night Serum</TableCell>
                            <TableCell>$90</TableCell>
                            <TableCell>2</TableCell>
                            <TableCell>$180</TableCell>
                          </tr>
                        </tbody>
                      </Table>
                    </TableContainer>
                  </div>

                  <Container22>
                    <Item>
                      <Label>Subtotal</Label>
                      <Value>$540.00</Value>
                    </Item>
                    <Item>
                      <Label>Tax 5%</Label>
                      <Value>$00.00</Value>
                    </Item>
                    <Item>
                      <Label>Discount 10%</Label>
                      <Value>$00.00</Value>
                    </Item>
                  </Container22>
                  <StyleLine></StyleLine>
                  <Container22>
                    <Item>
                      <Label>total</Label>
                      <Value>$540.00</Value>
                    </Item>
                  </Container22>
                  <TextContainer>
                    <TextItem>Notes/Terms</TextItem>
                    <Textarea>
                      Lorem ipsum dolor sit amet. Sit eaque assumenda qui.
                    </Textarea>
                  </TextContainer>
                </div>

                <div className="add-button">
                  <CustomButton>Create New Invoice</CustomButton>
                </div>
              </Table2Wrapperr>
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </div>
  );
};

export default PaymentProcessComponent;
