import React from "react";
import styled from "styled-components";

const CardLayout = styled.div`
  background-color: #fff;
  border-radius: 14px;
  padding: 2.8rem;

  h5 {
    color: #4c4d4f;
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 1.8rem;
  }

  h1 {
    color: #4c4d4f;
    font-size: 2.5rem;
    font-weight: 400;
    margin-bottom: 1.4rem;
  }
  h4 {
    color: rgba(76, 77, 79, 0.5);
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 1.8rem;
    span {
      color: #4c4d4f;
    }
  }
  h4.two {
    margin-bottom: 0.4rem;
  }
  h4.three {
    margin-bottom: 0rem;
  }
`;

const RecentComponent = () => {
  return (
    <CardLayout>
      <h5>Recent Payment</h5>
      <h1>$350.00</h1>
      <h4>
        Paid via:<span> Master Card (***456) </span>
      </h4>
      <h4 className="two">
        Date: <span>15 August, 2023</span>
      </h4>
      <h4 className="three">
        For PRP Injections scheduled session <span> View Detail</span>
      </h4>
    </CardLayout>
  );
};

export default RecentComponent;
