import * as admin_assets from "../../assets/Admin";
import "../../pages/Admin/style.css";

const TreatmentCard = () => {
  return (
    <>
      <div className="col-md-6 col-lg-4 col-xl-4">
        <div className="treatment-box mb-4 d-flex justify-content-between">
          <div className="content-box">
            <h6 className="title">Anti Wrinkle Section</h6>
            <p className="sub-title mb-1">8 Sections</p>
            <p className="med-name mb-1">Medicine name/QTY</p>
            <p className="med-price mb-0">
              <strong>$69.00 </strong>
            </p>
          </div>
          <div className="actions">
            <div className="action-box me-3">
              <img src={admin_assets.redBasket} className="w-100" alt="" />
            </div>
            <div className="action-box">
              <img src={admin_assets.edit} className="w-100" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TreatmentCard;
