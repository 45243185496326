import React, { useState } from "react";
import styled from "styled-components";
import ReuseAbleHeader from "../ReuseAbleHeader/ReuseAbleHeader";
import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TableComponent from "../TableComponent/TableComponent";
// import SpanComponent from "../StatusSpan/StatusSpan";
// import OptionMenu from "../DotMenu/DotMenu";
import SearchArea from "../SearchInput/SearchInput"
import print from "../../assets/Print.png";
import { Select } from "@mui/material";

const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
  height: 100%;
`;






const TableWrapperr3 = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
  min-width:400px;
  padding: 2.1rem 2.7rem;
  background-color: #fff;
  border-radius: 14px 14px 0 0;
  gap:200px;
  h1 {
  }
  img{
    width:18px;
    height:18px;
  }
`;



const columns = [
  { Header: "Patient", accessor: "column1" },
  { Header: "Time", accessor: "column2" },
  { Header: "Date", accessor: "column3" },
  { Header: "Total", accessor: "column4" },
  { Header: "Status", accessor: "column5" },

];
const spanStyle = {
  backgroundColor: '#36C490',
  width: '75px',
  height: '21px',
  top: '391px',
  left: '1178px',
  borderRadius: '11px',
  display: 'inline-block',
  fontFamily: 'Cabin', // Font family
  fontSize: '10px', // Font size
  fontWeight: 400, // Font weight
  lineHeight: '21px', // Line height
  letterSpacing: '0em', // Letter spacing
  textAlign: 'left', // Text alignment
  color : 'white',
  letterSpacing: '0em',
  textAlign: 'center',
};

const CustomButton = styled.button`
  width: 184px;
  height: 48px;
  align:center;
  border-radius: 24px;
  background-color: black;
  color: white; /* Adding white text color for better visibility on a black background */
  font-family: Cabin;
  font-size: 16px; /* Adjust the font size as needed */
  font-weight: 400;
  line-height: 20px; /* Adjust line height as needed */
  letter-spacing: 0em;
  text-align: center;
  border: none;
  cursor: pointer;

`;


const h3Style={

    fontFamily: 'Cabin',
    fontSize: '20px',
    fontWeight: '400',
    lineHeight: '22px',
    letterSpacing: '0em',
    textAlign: 'left',

}

const fontstyleName = {
    fontWeight: 800,
    fontSize: '12px',
    lineHeight: '21px',
  };

  const Container22 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  width: 84%;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin-bottom: 10px;
  line-height: 2;
`;

const Label = styled.span`
  font-weight: bold;
`;

const Value = styled.span`
  margin-left: auto;
`;

const StyleLine = styled.div`
  width: 100%;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 10px;
    right:10px;
    border-top: 0.5px solid #ccc; /* Adjust color and thickness as needed */
    transform: translateY(-50%);
  }
`;

const TableContainer = styled.div`
width: 97%;
  height: 159px;
  top: 454px;
  left: 1075px;
  border-radius: 5px 5px 0px 0px;
  overflow: hidden;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0; /* This removes the spacing between cells */
  border: 1px solid #ccc; /* Add the border to the entire table */
`;

const TableHeader = styled.th`
  background-color: black;
  color: white;
  padding: 10px;
`;

const TableCell = styled.td`

  padding: 10px;
  text-align: center;
`;

const TextContainer = styled.div`
 width:305px;
 background-color:#F8F8F8;
 height: 55px;
 margin: 0 auto;
 padding: 11px;
`;
const TextItem = styled.div`
 margin: 0 auto;
 font-size:14px;
 font-weight: 500;
`;
const Textarea = styled.div`
color: #00000080;
 font-size:10px;
 font-weight: 500;
`;
const SelectBtn1 = styled.div`
color: #000000;
 font-size:20px;
 font-weight: 500;
 border-bottom: 1px solid #000000;
`;
const SelectBtn = styled.div`
color: #4C4D4F;
 font-size:20px;
 font-weight: 400;
`;
const TableWrapperr = styled.div`
display: flex;
flex: 1;
background: #fff9f1;
border-bottom: 1px solid #dbdbdc;
padding: 27px 0px 0px 47px;
background-color: #fff;
border-radius: 14px 14px 0 0;
gap: 20px;
  #mySelect{
    background: #F8F8F8;
    font-size: 13px;
    border: transparent;
    border-radius: 8px;
    padding:8px;
  }
  h1 {
  }
`;
const Table2Wrapperr = styled.div`
  padding: 22px 28px;
  background: #fff;
  border-radius: 0 0 14px 14px;
  min-width:400px;
  p{
    font-size: 18.35px;
    color: #4C4D4F;
  }
  select{
    color: #4C4D4F;
    border: 2px solid #4c4d4f4a;
    height: 44.09px;
    font-size: 14.35px;
    border-radius: 6px;
    width: 100%;
  }
  .input-boxes{ width:100%; 
    margin-top: 18px;
}
  .input-boxes input{
    width:100%;
    color: #4C4D4F;
    border: 2px solid #4c4d4f4a;
    height: 44.09px;
    font-size: 14.35px;
    border-radius: 6px;
  }
  .add-button{
    display:flex;
    margin-top: 593px;
    gap: 12px;
  }
  .add-border{
    border: 1px solid #4C4D4F33;
  }
`;

const Table3Wrapperr = styled.div`
background: #F8F8F8;
border: 2px solid #4c4d4f4a;
height: 142px;
margin-top: 34px;
border-radius: 14px;

@media only screen and (max-width: 900px) {
    overflow: scroll;
  }
`;
const Table5Wrapperr = styled.div`
background: #F8F8F8;
border: 2px solid #4c4d4f4a;
height: 191px;
margin-top: 34px;
border-radius: 14px;

.value{
    display: flex;
    padding: 27px 0px 0px 14px;
    gap: 97px;
    margin: 0 auto;
    width: 100%;
}
.value-box{
    width: 12%;
    background: rgb(255, 255, 255);
    height: 35px;
    text-align: center;
}
@media only screen and (max-width: 900px) {
    .value-box{
        display: flex;
        gap: 71px;
        margin: 0 auto;
        width: 100%;
    }
  }
.btn-add{
    margin-top: 12px;
    display: flex;
    gap: 7px;
    float: right;
    margin-right: 11px;
}
`;
const Table6Wrapperr = styled.div`
background: #F8F8F8;
border: 2px solid #4c4d4f4a;
height: 254px;
margin-top: 34px;
border-radius: 14px;

.value{
    display: flex;
    padding: 27px 0px 0px 14px;
    gap: 97px;
    margin: 0 auto;
    width: 100%;
}
.value-box{
    width: 12%;
    background: rgb(255, 255, 255);
    height: 35px;
    text-align: center;
}
.btn-add{
    margin-top: 12px;
    display: flex;
    gap: 7px;
    float: right;
    margin-right: 11px;
}
`;
const Table7Wrapperr = styled.div`
display: flex;
width: 100%;
.footer-invice{
    width: 50%;
}
.foot-list{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    float: right;
}
`;
const SelectiomList = styled.div`
display: flex;
    border-bottom: 1px solid #BCBCBC40;
    padding: 27px 0px 0px 14px;
    gap: 125px;
    margin: 0 auto;
    width: 93%;
    @media only screen and (max-width: 900px) {
        padding: 0px;
        gap: 120px;
        margin: 0px 14px;
      }
`;
const SelectionList1 = styled.div`
display: flex;
border-bottom: 1px solid #BCBCBC40;
padding: 27px 0px 0px 14px;
gap: 97px;
margin: 0 auto;
width: 100%;
@media only screen and (max-width: 900px) {
    display: flex;
    border-bottom: 1px solid #BCBCBC40;
    padding: 10px 8px 0px 6px;
    gap: 10px;
    margin: 0 auto;
    width: 100%;
  }
`;
const SelectionList2 = styled.div`
display: flex;
border-bottom: 1px solid #BCBCBC40;
padding: 27px 0px 0px 14px;
gap: 138px;
margin: 0 auto;
width: 100%;
`;
const Item1 = styled.div`
display: flex;
width: 100%;
float: left;
margin-left: 33px;
margin-top: 31px;
gap: 86px;
flex-wrap: wrap;
@media only screen and (max-width: 900px) {
    display: flex;
    width: 94%;
    float: left;
    margin-left: 18px;
    margin-top: 6px;
    gap: 40px;
    flex-wrap: wrap;
  }
.btn-add{
    margin-top: -12px;
    display: flex;
    gap: 7px;   
}

`;
const Item2 = styled.div`
display: flex;
margin-top: 31px;
gap: 86px;
.btn-text{ 
    color:#4C4D4F;
}

`;
const CustomButton2 = styled.button`
  width: 184px;
  height: 48px;
  align:center;
  border-radius: 24px;
  background-color: black;
  color: white; /* Adding white text color for better visibility on a black background */
  font-family: Cabin;
  font-size: 16px; /* Adjust the font size as needed */
  font-weight: 400;
  line-height: 20px; /* Adjust line height as needed */
  letter-spacing: 0em;
  text-align: center;
  border: none;
  cursor: pointer;

`;
const CustomButton1 = styled.button`
width: 178px;
height: 47px;
align: center;
border-radius: 24px;
border: 1px solid #E23943;
color: #E23943;
font-size: 16px;

`;
const CustomButton4 = styled.button`
width: 178px;
height: 47px;
align: center;
border-radius: 24px;
border: 1px solid #4C4D4F;
color: #4C4D4F;
font-size: 16px;

`;



const CreateInvoiceComponent = () => {
  
  return (
    <div>
      <ReuseAbleHeader />
      <MainLayout>
        <ReuseAbleSidebar />
        <Container className="main-content">
          <Row>
          <h1 className="after-care-heading">Invoice </h1>
         <h3 className="after-treatment-heading">Create a invoice for your patients</h3>
            <Col xl={8} xs={8}>
              <div style={{overflow:"scroll"}}>
                <div style={{minWidth: "850px"}}>  
                <TableWrapperr>
                  <SelectBtn>Templates</SelectBtn>
                  <SelectBtn1>Create Invoice</SelectBtn1>
                </TableWrapperr>
                <Table2Wrapperr>
                  <div style={{display:"flex", gap:"11px",width:"100%"}}>
                      <div className="input-boxes">
                      <p>Select Person</p>
                      <select>
                      <option value="option1">Select the person to whom you will send this</option>
                      <option value="option2">Select the person to whom you will send this</option>
                      <option value="option3">Select the person to whom you will send this</option>
                      </select>
                      </div>
                      <div className="input-boxes">
                      <p>Invoice Number</p>
                      <input placeholder="" />
                      </div>
                  </div>
                  <div style={{display:"flex", gap:"11px",width:"100%",}}>
                      <div className="input-boxes">
                      <p>Add a Treatment</p>
                      <select>
                      <option value="option1">Add a Treatment</option>
                      <option value="option2">Add a Treatment</option>
                      <option value="option3">Add a Treatment</option>
                      </select>
                      </div>
                      <div className="input-boxes">
                      <p>Select Date</p>
                      <input placeholder="dd/mm/yyyy" />
                      </div>
                  </div>
                  <Table3Wrapperr>
                      <SelectiomList>
                      <SelectBtn>Treatments</SelectBtn>
                      <SelectBtn>Amount</SelectBtn>
                      </SelectiomList>
                      <Item1>
                          <h3 >Anti wrinkle treatment</h3>
                          <h3>$1480</h3>
                          <div className="btn-add">
                            <CustomButton2>Add</CustomButton2>
                            <CustomButton1>Remove</CustomButton1>
                          </div>
                      </Item1>
                </Table3Wrapperr>
                  <Table5Wrapperr>
                      <SelectionList1>
                      <SelectBtn>Treatments</SelectBtn>
                      <SelectBtn>Rate/per section</SelectBtn>
                      <SelectBtn>no. of section</SelectBtn>
                      <SelectBtn>Total</SelectBtn>
                      </SelectionList1>
                      <div className="value">
                          <div style={{background: "rgb(255, 255, 255)",height: "35px",width: "17%"}}>
                              <h3>Hair Care Session</h3>
                          </div>
                          <div className="value-box">
                              <h3>$145</h3>
                          </div>
                          <div className="value-box">
                              <h3>8</h3>
                          </div>
                          <div className="value-box">
                              <h3>$1160</h3>
                          </div>
                      </div>
                          <div className="btn-add">
                          <CustomButton2>Add</CustomButton2>
                          <CustomButton1>Remove</CustomButton1>
                          </div>
                </Table5Wrapperr>
                  <Table6Wrapperr>
                      <SelectionList2>
                      <SelectBtn>Skincare Items</SelectBtn>
                      <SelectBtn>Rate</SelectBtn>
                      <SelectBtn>Qty</SelectBtn>
                      <SelectBtn>Amount</SelectBtn>
                      </SelectionList2>
                      <div className="value">
                          <div style={{background: "rgb(255, 255, 255)",height: "35px",width: "17%"}}>
                              <h3>Hair Care Session</h3>
                          </div>
                          <div className="value-box">
                              <h3>$90</h3>
                          </div>
                          <div className="value-box">
                              <h3>2</h3>
                          </div>
                          <div className="value-box">
                              <h3>$180</h3>
                          </div>
                      </div>
                      <div className="value">
                          <div style={{background: "rgb(255, 255, 255)",height: "35px",width: "17%"}}>
                              <h3>Hair Care Session</h3>
                          </div>
                          <div className="value-box">
                              <h3>$90</h3>
                          </div>
                          <div className="value-box">
                              <h3>2</h3>
                          </div>
                          <div className="value-box">
                              <h3>$180</h3>
                          </div>
                      </div>
                          <div className="btn-add">
                          <CustomButton2>Add</CustomButton2>
                          <CustomButton1>Remove</CustomButton1>
                          </div>
                </Table6Wrapperr>
                <Table7Wrapperr>
                  <div className="footer-invice">
                      <div className="input-boxes">
                      <p>Add Notes/ Terms</p>
                      <input placeholder="" />
                      </div>
                  </div>
                  <div style={{width:"100%"}}>
                  <div className="foot-list">
                      <Item2>
                      <h4>Subtotal</h4>
                    <h5>$540.00</h5>
                      </Item2>
                      <Item2>
                      <h4 className="btn-text">Transaction Fee 5%</h4>
                      <h5 className="btn-text">$540.00</h5>
                      </Item2>
                      <Item2>
                    <h4 className="btn-text">Discount 10%</h4>
                    <h5 className="btn-text">$540.00</h5>
                      </Item2>
                      <Item2>
                    <h4>Total</h4>
                    <h5>$540.00</h5>
                      </Item2>
                      </div>
                  </div>
                </Table7Wrapperr>
                
                </Table2Wrapperr>
                </div>
              </div>
            </Col>
            <Col xl={4} xs={4}>
           
              <TableWrapperr3>
             <h4>Preview Invoice</h4>
             <img className="print-logo" src={print}  />
              </TableWrapperr3>
              <Table2Wrapperr>
                <div className="add-border">
                <img src="/static/media/logo-dashboard.26b9717866e12cf5d6ac.png" 
      style={{
        width: '90.67px',
        height: '16px',
        marginTop: '10px',marginLeft: '10px',}}
    />
<div  style={{
        marginTop: '10px',marginLeft: '15px',}}>SUMMERY
        <StyleLine></StyleLine>
        </div>
        
       <div style={{display: 'flex', alignItems: 'center',gap: '50%', paddingLeft: '10px',padding:'18px'}}>
        <div style={fontstyleName}>
Dr.Vihang
        </div>
        <div style={fontstyleName}> John
            
            </div>
       </div>
       <div style={{display: 'flex', alignItems: 'center', paddingLeft: '10px'}}>
       <TableContainer>
      <Table>
        <thead>
          <tr>
            <TableHeader>item</TableHeader>
            <TableHeader>Rate</TableHeader>
            <TableHeader>Qty</TableHeader>
            <TableHeader>Amount</TableHeader>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TableCell>Vitamin A Night Serum</TableCell>
            <TableCell>$90</TableCell>
            <TableCell>2</TableCell>
            <TableCell>$180</TableCell>
          </tr>
          <tr>
            <TableCell>Vitamin A Night Serum</TableCell>
            <TableCell>$90</TableCell>
            <TableCell>2</TableCell>
            <TableCell>$180</TableCell>
          </tr>
          <tr>
            <TableCell>Vitamin A Night Serum</TableCell>
            <TableCell>$90</TableCell>
            <TableCell>2</TableCell>
            <TableCell>$180</TableCell>
          </tr>
        </tbody>
      </Table>
    </TableContainer>
                </div>
                
                <Container22>
      <Item>
        <Label>Subtotal</Label>
        <Value>$540.00</Value>
      </Item>
      <Item>
        <Label>Tax 5%</Label>
        <Value>$00.00</Value>
      </Item>
      <Item>
        <Label>Discount 10%</Label>
        <Value>$00.00</Value>
      </Item>
    </Container22>
    <StyleLine></StyleLine>
    <Container22>
    <Item>
        <Label>total</Label>
        <Value>$540.00</Value>
      </Item>
    </Container22   >
    <TextContainer>
        <TextItem>Notes/Terms</TextItem>
        <Textarea>Lorem ipsum dolor sit amet. Sit eaque assumenda qui.</Textarea>
    </TextContainer>










                </div>
               
                <div className="add-button">
                <CustomButton4>Save</CustomButton4>
                <CustomButton2>Save & Send</CustomButton2>
                </div>
              
              </Table2Wrapperr>
              
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </div>
  );
};

export default CreateInvoiceComponent;
