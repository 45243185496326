import React, { useState } from "react";
import * as admin_assets from "../../assets/Admin";

const AdminSidebar = () => {
  const [isFormsToggle, setIsFormsToggle] = useState(false);
  const [isProductsToggle, setIsProductsToggle] = useState(false);

  const handleFormsToggle = () => {
    setIsFormsToggle(!isFormsToggle);
    setIsProductsToggle(false); // Close the Products section
  };

  const handleProductsToggle = () => {
    setIsProductsToggle(!isProductsToggle);
    setIsFormsToggle(false); // Close the Forms section
  };

  return (
    <nav
      id="admin-sidebar"
      className="admin-sidebar d-none d-lg-block bg-light sidebar"
    >
      <div className="position-sticky h-100">
        <div className="ms-4 ps-3 ">
          <p className="mt-4 pt-3 fs-5">Overview</p>

          <ul className="nav flex-column">
            <li className="nav-item">
              <div
                className={`d-flex justify-content-between pe-2`}
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#formsSubmenu"
                onClick={handleFormsToggle}
              >
                <p className="fs-5 mt-4">Forms</p>
                {isFormsToggle ? (
                  <img className="mt-2" src={admin_assets.dropDownUpNavBtn} />
                ) : (
                  <img className="mt-2" src={admin_assets.dropDownNavBtn} />
                )}
              </div>

              <div
                className={`collapse ${isFormsToggle ? "show" : ""}`}
                id="formsSubmenu"
              >
                <ul className="nav flex-column">
                  <li className="">
                    <p className="ps-3 mt-2" href="#">
                      SkinCare
                    </p>
                  </li>
                  <li className=" mt-1">
                    <p className="ps-3" href="#">
                      SkinCare
                    </p>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <ul className="nav flex-column">
            <li className="nav-item">
              <div
                className={`d-flex justify-content-between pe-2`}
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#productsSubmenu"
                onClick={handleProductsToggle}
              >
                <p className="fs-5 mt-3">Products</p>
                {isProductsToggle ? (
                  <img className="mt-2" src={admin_assets.dropDownUpNavBtn} />
                ) : (
                  <img className="mt-2" src={admin_assets.dropDownNavBtn} />
                )}
              </div>
              <div
                className={`collapse ${isProductsToggle ? "show" : ""}`}
                id="productsSubmenu"
              >
                <ul className="nav flex-column">
                  <li className="">
                    <p className="ps-3" href="#">
                      Skincare
                    </p>
                  </li>
                  <li className="">
                    <p className="ps-3" href="#">
                      Skincare
                    </p>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <p className="pt-3 fs-5">Session</p>
        </div>
      </div>
    </nav>
  );
};

export default AdminSidebar;
