import React from "react";
import styled from "styled-components";
import Treatment from "../../assets/Group 117761.png";

const TreatmentWrapper = styled.div`
  background: #fff;
  padding: 2rem;
  border-radius: 1.4rem;
  margin-top: 1.8rem;
  .wrapper {
    margin-bottom: 1.8rem;
  }
`;

const Card = styled.div`
  background: #f8f8f8;
  display: block;
  padding: 2.2rem;
  border-radius: 1.4rem;
  position: relative;
  img {
    width: 100%;
  }
  h1 {
    font-size: 1.2rem;
    color: #4c4d4f;
    line-height: 1.5;
    margin-top: 2rem;
    margin-bottom: 1.2rem;
  }
  h3 {
    font-size: 1.8rem;
    color: #4c4d4f;
    line-height: 1.5;
    font-weight: 700;
  }
  .btn{
    border-radius: 10px;
    padding: 10px;
    background-color: transparent;
    border: solid 1px;
    position: absolute;
    right: 18px;
    font-size: 12px;
    bottom: 10px;
  }
`;

const TreatmentDetail = () => {
  return (
    <TreatmentWrapper>
      <h1 className="wrapper">Treatment Details</h1>
      <Card>
        <img src={Treatment} />
        <h1>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed commodo
          eget velit non luctus. Duis at enim eu elit facilisis fermentum.Lorem
          ipsum dolor sit amet, consectetur adipiscing elit. Sed commodo eget
          velit non luctus. Duis at enim eu elit facilisis fermentum.Lorem ipsum
          dolor sit amet, consectetur adipiscing elit. Sed commodo eget velit
          non luctus. Duis at enim eu elit facilisis fermentum.Lorem ipsum dolor
          sit amet, consectetur adipiscing elit. Sed commodo eget velit non
          luctus. Duis at enim eu elit facilisis fermentum.
        </h1>
        <h3>Dr. Irfan Ahmad</h3>  
        <button className="btn">View Details</button>
      </Card>
    </TreatmentWrapper>
  );
};

export default TreatmentDetail;
