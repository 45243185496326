import React from "react";
// import AppointmentsPage from "../component/AppointmentsPage/AppointmentsPage";
// import Toorak2 from "../component/Toorak.js/Toorak2";
import Toorak2 from "../component/Toorak2/Toorak2";

const ToorakPage2 = () => {
  return (
    <div>
      <Toorak2 />
    </div>
  );
};

export default ToorakPage2;
