import React from "react";
import styled from "styled-components";
import { ProgressBar as ReactProgressBar } from "react-bootstrap";

const CardLayout = styled.div`
  background-color: #e1d0c1;
  border-radius: 14px;
  padding: 2.8rem 2.8rem 1.8rem 2.8rem;

  h5 {
    color: #4c4d4f;
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 1.8rem;
  }

  h1 {
    color: #4c4d4f;
    font-size: 2.5rem;
    font-weight: 400;
    margin-bottom: 1.4rem;
  }
  h4 {
    color: rgba(76, 77, 79, 0.5);
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 2.7rem;
    span {
      color: #4c4d4f;
    }
  }
`;

const PrpgressBar = styled.div`
  div {
    // background: red;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.8rem;

    p {
      color: #4c4d4f;
      font-size: 1.4rem;
      font-weight: 400;
      margin-bottom: 2.7rem;

      margin: 0;
    }
  }
`;

const OngoingTreatmentCard = () => {
  return (
    <CardLayout>
      <h5>Ongoing Treatment</h5>
      <h1>PRP Injections</h1>
      <h4>
        Start Date:<span> 31 July, 2023</span>
      </h4>

      <PrpgressBar>
        <div>
          <p>Progress</p>
          <p>0%</p>
        </div>
        <ReactProgressBar
          variant="success"
          now={40}
          style={{ backgroundColor: "#978f88" }}
        />
      </PrpgressBar>
    </CardLayout>
  );
};

export default OngoingTreatmentCard;
