import React, { useState } from "react";
import styled from "styled-components";
import ReuseAbleHeader from "../ReuseAbleHeader/ReuseAbleHeader";
import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";
import Container from "react-bootstrap/Container";
import TableComponent from "../TableComponent/TableComponent2"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import img1 from '../../assets/Group 11771.png';

import img2 from '../../assets/Group 11773.png';
import img3 from '../../assets/Group 107.png';

const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
  // align-items: center;
  .table-div{
    padding: 31px;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 15px;
  }
 .catbtn1{
    background-color: #000;
    color: #fff;
    font-size: 14px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 25px;
 }
 .btns-group{
    display: flex;
    gap: 25px;
    padding-left: 70px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px;
 }
 .main-box{
    display: flex;
    border: solid 1px;
    padding: 20px;
    border-radius: 12px;
    width: 450px;
    gap: 15px;
    height: 300px;
    min-width: 395px;
 }
 .buy{
    position: relative;
    top: 15px;
    margin-left: auto;
    display: block;
 }
 .tit1{
    margin-top: 10px;
    font-size: 19px;
    margin-bottom: 5px;
 }
 .main-div{
    width: 300px;
    border: solid 1px #DBDBDC;
 }
 .selectedbtn{
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    font-size: 17px;
    border: none;
    margin-top: 15px;
    border-bottom: solid 2px #000;
    padding-bottom: 15px;
 }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .main-heading {
    h1 {
      font-size: 3.4rem;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 1.4rem;
      color: #4c4d4f;
    }
    p {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 1.4rem;
      color: #4c4d4f;
    }
  }

`;
const columns = [
  { Header: "Treatment", accessor: "column1" },
  { Header: "Procedure Notes", accessor: "column2" },
  { Header: "Date", accessor: "column3" },
  { Header: "Time", accessor: "column4" },
  { Header: "Payment", accessor: "column7" },
];

const data = [
  {
    column1: "PRP Injections",
    column2: "View Notes",
    column3: "12/08/23",
    column4: "09:00am",
    column7: "Pending",
  },
  {
    column1: "PRP Injections",
    column2: "View Notes",
    column3: "12/08/23",
    column4: "09:00am",
    column7: "Pending",
  },

];
const VideoLibrary = () => {
  const [isdata, setIsData] = useState(data);
  return (
    <>
    <div>
      <ReuseAbleHeader />
      <MainLayout>
        <ReuseAbleSidebar />
        <Container className="main-content">
          {/* <Row></Row> */}
          <HeaderWrapper>
            <div className="main-heading">
              <h1>History / Videos Library</h1>
              <div style={{display: "flex",alignItems: "center",gap: "40px"}}>
                <p>Choose a Library</p>
              </div>
            </div>
          </HeaderWrapper>
         
          <Row>
            <Col xs={12} md={12}>
               <div style={{backgroundColor:"#fff"}}>
                    <div style={{paddingBottom:"0px"}} className="btns-group">
                        <button style={{borderBottom:"unset"}} className="selectedbtn">TikTok Clips</button>
                        <button className="selectedbtn">You Tube Videos</button>
                    </div>
                    <div style={{justifyContent: "space-between"}} className="btns-group btns-video">
                        <div style={{display: "flex",gap: "25px"}}>
                            <button style={{backgroundColor:"#fff",color:"#000",border:"solid 1px"}} className="catbtn1">category1</button>
                            <button style={{backgroundColor:"#fff",color:"#000",border:"solid 1px"}} className="catbtn1">category2</button>
                            <button style={{backgroundColor:"#fff",color:"#000",border:"solid 1px"}} className="catbtn1">category3</button>
                            <button style={{backgroundColor:"#fff",color:"#000",border:"solid 1px"}} className="catbtn1">category4</button>
                        </div>
                        <div style={{display: "flex",alignItems: "center",gap: "15px",justifyContent: "end",paddingRight: "25px"}}>
                            <img style={{width: "20%"}} src={img2}/>
                            <img style={{width: "20%"}} src={img3}/>
                        </div>
                    </div>
                    <div style={{padding: "25px",display:"flex",gap:"20px",flexWrap: "wrap"}}>
                        <div className="main-div">
                            <img style={{width: "100%"}} src={img1} />
                            <h2 style={{paddingLeft:"10px"}} className="tit1">Tittle Of the video</h2>
                            <p style={{fontSize: "12px",paddingLeft:"10px"}}>Aug 25 2023, 01:12 AM - 1Highlight</p>
                        </div>  
                        <div className="main-div">
                            <img style={{width: "100%"}} src={img1} />
                            <h2 style={{paddingLeft:"10px"}} className="tit1">Tittle Of the video</h2>
                            <p style={{fontSize: "12px",paddingLeft:"10px"}}>Aug 25 2023, 01:12 AM - 1Highlight</p>
                        </div>  
                        <div className="main-div">
                            <img style={{width: "100%"}} src={img1} />
                            <h2 style={{paddingLeft:"10px"}} className="tit1">Tittle Of the video</h2>
                            <p style={{fontSize: "12px",paddingLeft:"10px"}}>Aug 25 2023, 01:12 AM - 1Highlight</p>
                        </div>  
                        <div className="main-div">
                            <img style={{width: "100%"}} src={img1} />
                            <h2 style={{paddingLeft:"10px"}} className="tit1">Tittle Of the video</h2>
                            <p style={{fontSize: "12px",paddingLeft:"10px"}}>Aug 25 2023, 01:12 AM - 1Highlight</p>
                        </div>
                    </div>
                  
               </div> 
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </div>
    </>
  );
};

export default VideoLibrary;
