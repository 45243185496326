import React from "react";
import styled from "styled-components";
import { BiPlus } from "react-icons/bi";

import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
const TreatmentWrapper = styled.div`
  background: #fff;
  padding: 2rem;
  border-radius: 1.4rem;
  margin-top: 1.8rem;
  .heading {
    font-size: 15px;
    color: #4c4d4f;
    border-bottom: 1px solid #4c4d4f;
    padding-bottom: 20px;
    font-weight: bold;
  }
  .main-div{
    border: solid 2px #D6D6D6;
    margin-top: 30px;
    background-color:#F8F8F8;
    border-radius: 15px;
    width: 80%;
    margin-left: 10%;
  }
  .sub-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 12px;
    border-radius: 15px;
    padding-left: 3%;
    padding-right: 4%;
  }
  .sub-div-1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #D6D6D6;
    width: 94%;
    margin-left: 3%;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .btn-txt{
    font-size: 14px;
    position: relative;
    top: 4px;
    left: 10px;
  }
  .icon-bg{
    background-color: #F8F8F8;
    width: 20px;
    height: 20px;
    border-radius: 12px;
  }
  .icon-bg-1{
    height: 28px;
    width: 28px;
    background-color: #fff;
    padding: 6px;
    border-radius: 10px;
  }
`;

const MedicalRecord = () => {
  const [chips, setChips] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const handleAddChip = () => {
    if (inputValue.trim() !== '') {
      setChips([...chips, inputValue]);
      setInputValue('');
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleChipDelete = (chipToDelete) => () => {
    setChips((chips) => chips.filter((chip) => chip !== chipToDelete));
  };
  return (
     
    <TreatmentWrapper>
      <h1 className="heading">Medical Records</h1>
      <div className="main-div">
        <div className="sub-div">
          <p className="btn-txt">Allergies</p>
          <AddIcon className="icon-bg" />
        </div> 
        <div className="sub-div-1">
          <p className="btn-txt">Eggs</p>
          <CloseIcon className="icon-bg-1" />
        </div> 
        <div className="sub-div-1">
          <p className="btn-txt">Peanuts</p>
          <CloseIcon className="icon-bg-1" />
        </div> 
        <div style={{border:"none"}} className="sub-div-1">
          <p className="btn-txt">Pollen</p>
          <CloseIcon className="icon-bg-1" />
        </div> 
      </div>
      <div className="main-div">
        <div className="sub-div">
          <p className="btn-txt">Previous Surgeries</p>
          <AddIcon className="icon-bg" />
        </div> 
        <div className="sub-div-1">
          <p className="btn-txt">C - Section</p>
          <CloseIcon className="icon-bg-1" />
        </div> 
        <div className="sub-div-1">
          <p className="btn-txt">Appendix Removal</p>
          <CloseIcon className="icon-bg-1" />
        </div> 
        <div style={{border:"none"}} className="sub-div-1">
          <p className="btn-txt">Pollen</p>
          <CloseIcon className="icon-bg-1" />
        </div> 
      </div>
    </TreatmentWrapper>
  );
};

export default MedicalRecord;
