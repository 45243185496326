import React from "react";
import styled from "styled-components";

const CardLayout = styled.div`
  background-color: #c2d5dc;
  border-radius: 14px;
  padding: 2.8rem;

  h5 {
    color: #4c4d4f;
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 1.8rem;
  }

  h1 {
    color: #4c4d4f;
    font-size: 2.5rem;
    font-weight: 400;
    margin-bottom: 1.4rem;
  }
  h4 {
    color: rgba(76, 77, 79, 0.5);
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 1.8rem;
    span {
      color: #4c4d4f;
    }
  }
  h4.two {
    margin-bottom: 0.4rem;
  }
  h4.three {
    margin-bottom: 0rem;
  }
`;

const UpcomingAppointmentCard = () => {
  return (
    <CardLayout>
      <h5>Upcoming Appointment</h5>
      <h1>25 Aug, 2023</h1>
      <h4>
        At <span> 12:00 PM</span>
      </h4>
      <h4 className="two">
        Provider: <span>Dr. Vihang Sharma</span>
      </h4>
      <h4 className="three">
        Studio: <span> Toorak</span>
      </h4>
    </CardLayout>
  );
};

export default UpcomingAppointmentCard;
