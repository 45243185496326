import React from "react";
import styled from "styled-components";
import Switch from "@mui/material/Switch";
import Mic from ".././../assets/micc.png";
import ButtonComponent from "../ButtonComponent/ButtonComponent";

import {
  AiOutlineUpload,
  AiOutlineLike,
  AiOutlineDislike,
} from "react-icons/ai";
import { TbEdit } from "react-icons/tb";
import { IoMdCopy } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";

const label = { inputProps: { "aria-label": "Switch demo" } };

const SpeechToTextWrapper = styled.div`
  padding: 18px 20px;

  .text-switch {
    background-color: #f8f8f8;
    border: 1px solid #c9c9c9;
    border-radius:14px;
    padding: 22px 18px;
    display: flex;
    align-items:center;
    justify-content:space-between;
    div{
      display: flex;
    align-items:center;
    p{
      margin-bottom:0;
      font-size:1.2rem;
      font-weight:400;
      color:#000;
    }
    }
    }
  }
  .textswithc{
    margin-bottom:0;
    font-size:1.2rem;
    font-weight:400;
    color:#000;
  }

  .mic-wrpper{
    margin-top:8px;
    margin-bottom:8px;
    background-color: #f8f8f8;
    border: 1px solid #c9c9c9;
    border-radius:14px;
    padding: 22px 18px;
    text-align:center;
    img{
      width:50px;
      height:50px;
      
    }
    .hold{
      font-size:1.2rem;
      font-weight:400;
      color:#000;
    margin:12px 0 8px;
    }
    .or{
      font-size:1.2rem;
      font-weight:400;
      color:#000;

      margin:0 0 6px;
    }
  }
  .text-wrapper{
    margin-top:8px;

    background-color: #f8f8f8;
    border: 1px solid #c9c9c9;
    border-radius:14px;
    padding: 22px 18px;
  }
  .text-content{ co
    font-size:1.2rem;
    font-weight:400;
    color:#000;
    height:120px;
    overflow-y:auto;
  }
  .icons-wrapper{
    margin-top:25px;
display:flex;
justify-content:space-between;
  }
`;

const SpeechToText = () => {
  return (
    <SpeechToTextWrapper>
      <div className="text-switch">
        <p className="textswithc">Speech-to-text</p>
        <div>
          <p>switch to TTS</p>
          <Switch {...label} style={{ color: "#4c4d4f", fontSize: "10px" }} />
        </div>
      </div>

      <div className="mic-wrpper">
        <img src={Mic} />
        <p className="hold">Hold this mic and speak</p>
        <p className="or">or</p>
        <ButtonComponent
          style={{
            width: "auto",
            padding: "9px 16px",
            fontSize: "1.2rem",
            borderRadius: "10px",
          }}
        >
          <AiOutlineUpload
            style={{ width: "24px", height: "24px", marginRight: "8px" }}
          />{" "}
          Upload your voice
        </ButtonComponent>
      </div>
      <div className="text-wrapper">
        <div className="text-content">
          Lorem ipsum dolor sit amet. Ex officia libero est cupiditate
          exercitationem non Quis iusto. At ratione praesentium et enim
          explicabo ut harum dolores. At magnam illo aut enim harum vel placeat
          dolorum qui dolores vero et obcaecati delectus sit galisum nulla eum
          ipsa veritatis.Lorem ipsum dolor sit amet. Ex officia libero est
          cupiditate exercitationem non Quis iusto. At ratione praesentium et
          enim explicabo ut harum dolores. At magnam illo aut enim harum vel
          placeat dolorum qui dolores vero et obcaecati delectus sit galisum
          nulla eum ipsa veritatis.
        </div>
        <div className="icons-wrapper">
          <div>
            <AiOutlineLike
              style={{ width: "1.2rem", height: "1.2rem", marginRight: "6px" }}
            />
            <AiOutlineDislike style={{ width: "1.2rem", height: "1.2rem" }} />
          </div>
          <div>
            <TbEdit
              style={{ width: "1.2rem", height: "1.2rem", marginRight: "6px" }}
            />
            <IoMdCopy
              style={{ width: "1.2rem", height: "1.2rem", marginRight: "6px" }}
            />
            <RiDeleteBin6Line style={{ width: "1.2rem", height: "1.2rem" }} />
          </div>
        </div>
      </div>
    </SpeechToTextWrapper>
  );
};

export default SpeechToText;
