import React, { useState } from "react";
import styled from "styled-components";
import ReuseAbleHeader from "../ReuseAbleHeader/ReuseAbleHeader";
import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OngoingTreatmentCard from "../OngoingTreatmentCard/OngoingTreatmentCard";
import UpcomingAppointmentCard from "../UpcomingAppointmentCard/UpcomingAppointmentCard";
import RecentComponent from "../RecentComponent/RecentComponent1";
import TableComponent from "../TableComponent/TableComponent2";
import filtre from "../../assets/octicon_filter-16.png";
const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
  // align-items: center;
  .table-div {
    padding: 31px;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 15px;
  }
  .view-more-btn {
    font-size: 15px;
    text-align: center;
    cursor: pointer;
    padding: 12px;
    position: relative;
    text-decoration: underline;
  }
  .treat-m {
    font-weight: 600;
    font-size: 15px;
    color: #4c4d4f;
    padding-left: 5px;
    margin-bottom: 15px;
  }
  .main-table {
    border-radius: 10px;
    border: solid 2px #dbdbdb;
    @media only screen and (max-width: 1415px) {
      overflow-x: auto;
    }
  }
  .Treatmentcost {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 25px;
    padding-top: 25px;
    padding-bottom: 30px;
  }
  .Treatment-Cost-Estimate {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 20px;
    padding-top: 25px;
    padding-bottom: 30px;
  }
  .input-shape {
    border: solid 1px #d6d6d6;
    border-radius: 10px;
    padding: 15px;
    font-size: 13px;
    width: 340px;
  }
  @media only screen and (max-width: 900px) {
    .input-shape {
      width: 85%;
    }
  }
  .txt-color-style {
    font-size: 14px;
    color: #a5a6a7;
    width: 70%;
    padding-left: 30px;
  }
  .img-style {
    width: 78%;
    display: block;
    margin: 0 auto;
  }
  .card-sub-box {
    background-color: #e1d0c1;
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 15px;
    gap: 35px;
    justify-content: center;
  }
  .checkbox-typ {
    display: flex;
    gap: 10px;
    margin-left: 25px;
    font-size: 12px;
    margin-top: 10px;
  }
  .bookknowbtn {
    padding: 10px;
    width: 80%;
    display: block;
    margin: 0 auto;
    margin-top: 19px;
    border-radius: 10px;
    background-color: #000;
    color: #fff;
    font-size: 12px;
  }
  .searchp {
    background-color: #fff;
    border: none;
    border-radius: 30px;
    width: 255px;
    padding-left: 24px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  .main-heading {
    h1 {
      font-size: 3.4rem;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 1.4rem;
      color: #4c4d4f;
    }
    p {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 1.4rem;
      color: #4c4d4f;
    }
  }

  .faqs-button {
    display: flex;
    align-items: center;
    button {
      width: auto;
      padding: 14px 28px;
      font-size: 1.4rem;
      font-weight: 400;
    }
    .support-btn {
      background: #fff;
      color: #4c4d4f;
      margin-right: 14px;
    }
  }
`;
const columns = [
  { Header: "Treatment", accessor: "column1" },
  { Header: "Time", accessor: "column2" },
  { Header: "Patient", accessor: "column3" },
  { Header: "Practitioner", accessor: "column4" },
  { Header: "Studio", accessor: "column5" },
  { Header: "Status", accessor: "column7" },
];

// const data = isdata.map((tableItem) => ({
const data = [
  {
    column1: "PRP Injections",
    column2: "09:00 - 10:30",
    column3: "Jane Doe",
    column4: "Dr. Vihang",
    column5: "Toorak",
    column7: "Complete",
  },
  {
    column1: "PRP Injections",
    column2: "09:00 - 10:30",
    column3: "Jane Doe",
    column4: "Dr. Vihang",
    column5: "Toorak",
    column7: "Complete",
  },
  {
    column1: "PRP Injections",
    column2: "09:00 - 10:30",
    column3: "Jane Doe",
    column4: "Dr. Vihang",
    column5: "Toorak",
    column7: "Complete",
  },
  {
    column1: "PRP Injections",
    column2: "09:00 - 10:30",
    column3: "Jane Doe",
    column4: "Dr. Vihang",
    column5: "Toorak",
    column7: "Complete",
  },
];
const Overview = () => {
  const [isdata, setIsData] = useState(data);
  const [showmodal, setShowmodal] = useState(false);
  return (
    <>
      <div>
        <ReuseAbleHeader />
        <MainLayout>
          <ReuseAbleSidebar />
          <Container className="main-content">
            {/* <Row></Row> */}
            <HeaderWrapper>
              <div className="main-heading">
                <h1>Welcome, Dr. Vihang</h1>
                <p>Check the latest updates on your account</p>
              </div>
              <div className="faqs-button">
                <img
                  style={{ width: "45px", marginRight: "12px" }}
                  src={filtre}
                />
                <input className="searchp" type="text" placeholder="Search" />
              </div>
            </HeaderWrapper>
            <Row>
              <Col xs={12} md={8}>
                <Row className="mb-3">
                  <Col xs={12} md={6} className="mb-3">
                    {" "}
                    <OngoingTreatmentCard />
                  </Col>
                  <Col xs={12} md={6}>
                    <UpcomingAppointmentCard />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} className="mb-3">
                    <div className="table-div">
                      <p className="treat-m">Treatment History</p>
                      <div className="main-table">
                        <TableComponent
                          onClick={() => {
                            setShowmodal(true);
                          }}
                          columns={columns}
                          data={isdata}
                          imageColumn1="column4"
                        />
                        <p className="view-more-btn">View more</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={4}>
                <RecentComponent />
              </Col>
            </Row>
          </Container>
        </MainLayout>
      </div>
    </>
  );
};

export default Overview;
