import React from "react";
import styled from "styled-components";
import ReuseAbleHeader from "../ReuseAbleHeader/ReuseAbleHeader";
import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Pdf from "../../assets/pdf.png";
import Pdf2 from "../../assets/pdf-img1.png";

// import Pdf from

const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
`;

const PdfBoxes = styled.div`
  display: flex !important;
  gap: 20px;
  // overflow: scroll;
  margin-top: 20px;
  flex-wrap: wrap;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
  .pdf-card {
    // width: 31%;
    width: 100%;
    border-radius: 12px;
    background-color: #fff9f1;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 15px 0px;
  }
  @media only screen and (max-width: 1200px) {
    .pdf-card {
      width: 98%;
    }
  }
  .pdf-card img {
    width: 100%;
    border-radius: 16px 16px 0px 0px;
  }
  .pdf-img {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    gap: 9px;
    border-radius: 12px;
  }
  .pdf-img img {
    width: 62px;
    height: 55px;
    margin-top: 2px;
    padding-left: 9px;
  }
  ol {
    font-weight: bold;
    padding-left: 5px;
    margin-bottom: 0;
  }
`;

const AppointmentText = styled.div`
  font-size: 14px;
  padding: 0 9px;
  color: #4c4d4f;
`;
// const PdfText = styled.div`
//   font-size: 14px;
//   padding: 0 9px;
//   color: #4c4d4f;
// `;

const PdfText = styled.div`
  font-size: 1.4rem;
  color: #343434;
  // margin-top: 13px;

  @media only screen and (max-width: 768px) {
    font-size: 13px;
  }
  @media only screen and (max-width: 500px) {
    font-size: 20px;
  }
`;

const AppointmentPara = styled.div`
  padding: 1px 10px;
  font-size: 8px;
  color: #4c4d4f;
  font-weight: bold;
  @media only screen and (max-width: 500px) {
    font-size: 10px;
  }
`;

const AfterCarePdf = () => {
  return (
    <div>
      <ReuseAbleHeader />
      <MainLayout>
        <ReuseAbleSidebar />
        <Container className="main-content">
          <Row>
            <Col xl={12} xs={12}>
              <h1 className="after-care-heading">After care </h1>
            </Col>
            <Col xl={4} lg={6} md={6} sm={12} xs={12}>
              <PdfBoxes>
                <div className="pdf-card">
                  <img src={Pdf2} />
                  <div>
                    <AppointmentText>
                      Anti-Wrinkle Session Aftercare Precaution
                    </AppointmentText>
                    <AppointmentPara>
                      congratulation on completing your anti-wrinkle session! To
                      ensure the best possible results and a smooth recovery.
                      It's important to follow these aftercare precaution.
                      <ol>
                        <li>Avoid Touching:</li>
                        Refrain from touching, rubbing or massaging the treated
                        area for at least 24 hours after the session. The helps
                        prevent the product from moving to unintended area and
                        ensure it settles in the targerted wrinkle.
                        <li>Stay Upright:</li>
                        maintain an upright position for the first few hours
                        after the treatment. avoid lying down or bending over
                        excessivel,as this can also prevent the product from
                        migrating.
                        <li>Facial Expressions:</li>
                        maintain an upright position for the first few hours
                        after the treatment. avoid lying down or bending over
                        excessivel,as this can also prevent the product from
                        migrating.
                      </ol>
                    </AppointmentPara>
                  </div>
                  <div className="pdf-img">
                    <img src={Pdf} />
                    <PdfText>Hair Treatment After care</PdfText>
                  </div>
                </div>
              </PdfBoxes>
            </Col>
            <Col xl={4} lg={6} md={6} sm={12} xs={12}>
              <PdfBoxes>
                <div className="pdf-card">
                  <img src={Pdf2} />
                  <div>
                    <AppointmentText>
                      Anti-Wrinkle Session Aftercare Precaution
                    </AppointmentText>
                    <AppointmentPara>
                      congratulation on completing your anti-wrinkle session! To
                      ensure the best possible results and a smooth recovery.
                      It's important to follow these aftercare precaution.
                      <ol>
                        <li>Avoid Touching:</li>
                        Refrain from touching, rubbing or massaging the treated
                        area for at least 24 hours after the session. The helps
                        prevent the product from moving to unintended area and
                        ensure it settles in the targerted wrinkle.
                        <li>Stay Upright:</li>
                        maintain an upright position for the first few hours
                        after the treatment. avoid lying down or bending over
                        excessivel,as this can also prevent the product from
                        migrating.
                        <li>Facial Expressions:</li>
                        maintain an upright position for the first few hours
                        after the treatment. avoid lying down or bending over
                        excessivel,as this can also prevent the product from
                        migrating.
                      </ol>
                    </AppointmentPara>
                  </div>
                  <div className="pdf-img">
                    <img src={Pdf} />
                    <PdfText>Hair Treatment After care</PdfText>
                  </div>
                </div>
              </PdfBoxes>
            </Col>
            <Col xl={4} lg={6} md={6} sm={12} xs={12}>
              <PdfBoxes>
                <div className="pdf-card">
                  <img src={Pdf2} />
                  <div>
                    <AppointmentText>
                      Anti-Wrinkle Session Aftercare Precaution
                    </AppointmentText>
                    <AppointmentPara>
                      congratulation on completing your anti-wrinkle session! To
                      ensure the best possible results and a smooth recovery.
                      It's important to follow these aftercare precaution.
                      <ol>
                        <li>Avoid Touching:</li>
                        Refrain from touching, rubbing or massaging the treated
                        area for at least 24 hours after the session. The helps
                        prevent the product from moving to unintended area and
                        ensure it settles in the targerted wrinkle.
                        <li>Stay Upright:</li>
                        maintain an upright position for the first few hours
                        after the treatment. avoid lying down or bending over
                        excessivel,as this can also prevent the product from
                        migrating.
                        <li>Facial Expressions:</li>
                        maintain an upright position for the first few hours
                        after the treatment. avoid lying down or bending over
                        excessivel,as this can also prevent the product from
                        migrating.
                      </ol>
                    </AppointmentPara>
                  </div>
                  <div className="pdf-img">
                    <img src={Pdf} />
                    <PdfText>Hair Treatment After care</PdfText>
                  </div>
                </div>
              </PdfBoxes>
            </Col>
            <Col xl={4} lg={6} md={6} sm={12} xs={12}>
              <PdfBoxes>
                <div className="pdf-card">
                  <img src={Pdf2} />
                  <div>
                    <AppointmentText>
                      Anti-Wrinkle Session Aftercare Precaution
                    </AppointmentText>
                    <AppointmentPara>
                      congratulation on completing your anti-wrinkle session! To
                      ensure the best possible results and a smooth recovery.
                      It's important to follow these aftercare precaution.
                      <ol>
                        <li>Avoid Touching:</li>
                        Refrain from touching, rubbing or massaging the treated
                        area for at least 24 hours after the session. The helps
                        prevent the product from moving to unintended area and
                        ensure it settles in the targerted wrinkle.
                        <li>Stay Upright:</li>
                        maintain an upright position for the first few hours
                        after the treatment. avoid lying down or bending over
                        excessivel,as this can also prevent the product from
                        migrating.
                        <li>Facial Expressions:</li>
                        maintain an upright position for the first few hours
                        after the treatment. avoid lying down or bending over
                        excessivel,as this can also prevent the product from
                        migrating.
                      </ol>
                    </AppointmentPara>
                  </div>
                  <div className="pdf-img">
                    <img src={Pdf} />
                    <PdfText>Hair Treatment After care</PdfText>
                  </div>
                </div>
              </PdfBoxes>
            </Col>
            <Col xl={4} lg={6} md={6} sm={12} xs={12}>
              <PdfBoxes>
                <div className="pdf-card">
                  <img src={Pdf2} />
                  <div>
                    <AppointmentText>
                      Anti-Wrinkle Session Aftercare Precaution
                    </AppointmentText>
                    <AppointmentPara>
                      congratulation on completing your anti-wrinkle session! To
                      ensure the best possible results and a smooth recovery.
                      It's important to follow these aftercare precaution.
                      <ol>
                        <li>Avoid Touching:</li>
                        Refrain from touching, rubbing or massaging the treated
                        area for at least 24 hours after the session. The helps
                        prevent the product from moving to unintended area and
                        ensure it settles in the targerted wrinkle.
                        <li>Stay Upright:</li>
                        maintain an upright position for the first few hours
                        after the treatment. avoid lying down or bending over
                        excessivel,as this can also prevent the product from
                        migrating.
                        <li>Facial Expressions:</li>
                        maintain an upright position for the first few hours
                        after the treatment. avoid lying down or bending over
                        excessivel,as this can also prevent the product from
                        migrating.
                      </ol>
                    </AppointmentPara>
                  </div>
                  <div className="pdf-img">
                    <img src={Pdf} />
                    <PdfText>Hair Treatment After care</PdfText>
                  </div>
                </div>
              </PdfBoxes>
            </Col>
            <Col xl={4} lg={6} md={6} sm={12} xs={12}>
              <PdfBoxes>
                <div className="pdf-card">
                  <img src={Pdf2} />
                  <div>
                    <AppointmentText>
                      Anti-Wrinkle Session Aftercare Precaution
                    </AppointmentText>
                    <AppointmentPara>
                      congratulation on completing your anti-wrinkle session! To
                      ensure the best possible results and a smooth recovery.
                      It's important to follow these aftercare precaution.
                      <ol>
                        <li>Avoid Touching:</li>
                        Refrain from touching, rubbing or massaging the treated
                        area for at least 24 hours after the session. The helps
                        prevent the product from moving to unintended area and
                        ensure it settles in the targerted wrinkle.
                        <li>Stay Upright:</li>
                        maintain an upright position for the first few hours
                        after the treatment. avoid lying down or bending over
                        excessivel,as this can also prevent the product from
                        migrating.
                        <li>Facial Expressions:</li>
                        maintain an upright position for the first few hours
                        after the treatment. avoid lying down or bending over
                        excessivel,as this can also prevent the product from
                        migrating.
                      </ol>
                    </AppointmentPara>
                  </div>
                  <div className="pdf-img">
                    <img src={Pdf} />
                    <PdfText>Hair Treatment After care</PdfText>
                  </div>
                </div>
              </PdfBoxes>
            </Col>
            <Col xl={4} lg={6} md={6} sm={12} xs={12}>
              <PdfBoxes>
                <div className="pdf-card">
                  <img src={Pdf2} />
                  <div>
                    <AppointmentText>
                      Anti-Wrinkle Session Aftercare Precaution
                    </AppointmentText>
                    <AppointmentPara>
                      congratulation on completing your anti-wrinkle session! To
                      ensure the best possible results and a smooth recovery.
                      It's important to follow these aftercare precaution.
                      <ol>
                        <li>Avoid Touching:</li>
                        Refrain from touching, rubbing or massaging the treated
                        area for at least 24 hours after the session. The helps
                        prevent the product from moving to unintended area and
                        ensure it settles in the targerted wrinkle.
                        <li>Stay Upright:</li>
                        maintain an upright position for the first few hours
                        after the treatment. avoid lying down or bending over
                        excessivel,as this can also prevent the product from
                        migrating.
                        <li>Facial Expressions:</li>
                        maintain an upright position for the first few hours
                        after the treatment. avoid lying down or bending over
                        excessivel,as this can also prevent the product from
                        migrating.
                      </ol>
                    </AppointmentPara>
                  </div>
                  <div className="pdf-img">
                    <img src={Pdf} />
                    <PdfText>Hair Treatment After care</PdfText>
                  </div>
                </div>
              </PdfBoxes>
            </Col>
            <Col xl={4} lg={6} md={6} sm={12} xs={12}>
              <PdfBoxes>
                <div className="pdf-card">
                  <img src={Pdf2} />
                  <div>
                    <AppointmentText>
                      Anti-Wrinkle Session Aftercare Precaution
                    </AppointmentText>
                    <AppointmentPara>
                      congratulation on completing your anti-wrinkle session! To
                      ensure the best possible results and a smooth recovery.
                      It's important to follow these aftercare precaution.
                      <ol>
                        <li>Avoid Touching:</li>
                        Refrain from touching, rubbing or massaging the treated
                        area for at least 24 hours after the session. The helps
                        prevent the product from moving to unintended area and
                        ensure it settles in the targerted wrinkle.
                        <li>Stay Upright:</li>
                        maintain an upright position for the first few hours
                        after the treatment. avoid lying down or bending over
                        excessivel,as this can also prevent the product from
                        migrating.
                        <li>Facial Expressions:</li>
                        maintain an upright position for the first few hours
                        after the treatment. avoid lying down or bending over
                        excessivel,as this can also prevent the product from
                        migrating.
                      </ol>
                    </AppointmentPara>
                  </div>
                  <div className="pdf-img">
                    <img src={Pdf} />
                    <PdfText>Hair Treatment After care</PdfText>
                  </div>
                </div>
              </PdfBoxes>
            </Col>
            <Col xl={4} lg={6} md={6} sm={12} xs={12}>
              <PdfBoxes>
                <div className="pdf-card">
                  <img src={Pdf2} />
                  <div>
                    <AppointmentText>
                      Anti-Wrinkle Session Aftercare Precaution
                    </AppointmentText>
                    <AppointmentPara>
                      congratulation on completing your anti-wrinkle session! To
                      ensure the best possible results and a smooth recovery.
                      It's important to follow these aftercare precaution.
                      <ol>
                        <li>Avoid Touching:</li>
                        Refrain from touching, rubbing or massaging the treated
                        area for at least 24 hours after the session. The helps
                        prevent the product from moving to unintended area and
                        ensure it settles in the targerted wrinkle.
                        <li>Stay Upright:</li>
                        maintain an upright position for the first few hours
                        after the treatment. avoid lying down or bending over
                        excessivel,as this can also prevent the product from
                        migrating.
                        <li>Facial Expressions:</li>
                        maintain an upright position for the first few hours
                        after the treatment. avoid lying down or bending over
                        excessivel,as this can also prevent the product from
                        migrating.
                      </ol>
                    </AppointmentPara>
                  </div>
                  <div className="pdf-img">
                    <img src={Pdf} />
                    <PdfText>Hair Treatment After care</PdfText>
                  </div>
                </div>
              </PdfBoxes>
            </Col>
            <Col xl={4} lg={6} md={6} sm={12} xs={12}>
              <PdfBoxes>
                <div className="pdf-card">
                  <img src={Pdf2} />
                  <div>
                    <AppointmentText>
                      Anti-Wrinkle Session Aftercare Precaution
                    </AppointmentText>
                    <AppointmentPara>
                      congratulation on completing your anti-wrinkle session! To
                      ensure the best possible results and a smooth recovery.
                      It's important to follow these aftercare precaution.
                      <ol>
                        <li>Avoid Touching:</li>
                        Refrain from touching, rubbing or massaging the treated
                        area for at least 24 hours after the session. The helps
                        prevent the product from moving to unintended area and
                        ensure it settles in the targerted wrinkle.
                        <li>Stay Upright:</li>
                        maintain an upright position for the first few hours
                        after the treatment. avoid lying down or bending over
                        excessivel,as this can also prevent the product from
                        migrating.
                        <li>Facial Expressions:</li>
                        maintain an upright position for the first few hours
                        after the treatment. avoid lying down or bending over
                        excessivel,as this can also prevent the product from
                        migrating.
                      </ol>
                    </AppointmentPara>
                  </div>
                  <div className="pdf-img">
                    <img src={Pdf} />
                    <PdfText>Hair Treatment After care</PdfText>
                  </div>
                </div>
              </PdfBoxes>
            </Col>
            <Col xl={4} lg={6} md={6} sm={12} xs={12}>
              <PdfBoxes>
                <div className="pdf-card">
                  <img src={Pdf2} />
                  <div>
                    <AppointmentText>
                      Anti-Wrinkle Session Aftercare Precaution
                    </AppointmentText>
                    <AppointmentPara>
                      congratulation on completing your anti-wrinkle session! To
                      ensure the best possible results and a smooth recovery.
                      It's important to follow these aftercare precaution.
                      <ol>
                        <li>Avoid Touching:</li>
                        Refrain from touching, rubbing or massaging the treated
                        area for at least 24 hours after the session. The helps
                        prevent the product from moving to unintended area and
                        ensure it settles in the targerted wrinkle.
                        <li>Stay Upright:</li>
                        maintain an upright position for the first few hours
                        after the treatment. avoid lying down or bending over
                        excessivel,as this can also prevent the product from
                        migrating.
                        <li>Facial Expressions:</li>
                        maintain an upright position for the first few hours
                        after the treatment. avoid lying down or bending over
                        excessivel,as this can also prevent the product from
                        migrating.
                      </ol>
                    </AppointmentPara>
                  </div>
                  <div className="pdf-img">
                    <img src={Pdf} />
                    <PdfText>Hair Treatment After care</PdfText>
                  </div>
                </div>
              </PdfBoxes>
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </div>
  );
};

export default AfterCarePdf;
