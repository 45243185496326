import React, { useState } from "react";
import styled from "styled-components";
import ReuseAbleHeader from "../ReuseAbleHeader/ReuseAbleHeader";
import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import OngoingTreatmentCard from "../OngoingTreatmentCard/OngoingTreatmentCard";
import UpcomingAppointmentCard from "../UpcomingAppointmentCard/UpcomingAppointmentCard";
import FormControl from "@mui/material/FormControl"; // Import FormControl from Material-UI
import InputLabel from "@mui/material/InputLabel"; // Import InputLabel from Material-UI
import Select from "@mui/material/Select"; // Import Select from Material-UI
import MenuItem from "@mui/material/MenuItem"; // Import MenuItem from Material-UI
import ModalComponent from "../ModalComponent";
import RecentComponent from "../RecentComponent/RecentComponent";
import ImageCarousel from "../ImageCarousel/ImageCarousel";
import TreatmentDetail from "../TreatmentDetail/TreatmentDetail";
import TableComponent from "../TableComponent/TableComponent2";
import MedicalRecord from "../MedicalRecord/MedicalRecord";
import CloseIcon from '@mui/icons-material/Close';
import pic from '../../assets/image 4.png';
import pic1 from '../../assets/image 10.png';
import { NavLink as AliasLink } from "react-router-dom";
const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
  // align-items: center;
  .table-div {
    padding: 31px;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 15px;
  }
  .view-more-btn {
    font-size: 15px;
    text-align: center;
    cursor: pointer;
    padding: 12px;
    position: relative;
    text-decoration: underline;
  }
  .treat-m {
    font-weight: 600;
    font-size: 15px;
    color: #4c4d4f;
    padding-left: 5px;
    margin-bottom: 15px;
  }
  .main-table {
    border-radius: 10px;
    border: solid 2px #dbdbdb;
  }
  .Treatmentcost {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 25px;
    padding-top: 25px;
    padding-bottom: 30px;
  }
  .Treatment-Cost-Estimate {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 20px;
    padding-top: 25px;
    padding-bottom: 30px;
  }
  .input-shape {
    border: solid 1px #d6d6d6;
    border-radius: 10px;
    padding: 15px;
    font-size: 13px;
    width: 340px;
  }
  @media only screen and (max-width: 900px) {
    .input-shape {
      width: 85%;
    }
  }
  .txt-color-style {
    font-size: 14px;
    color: #a5a6a7;
    width: 70%;
    padding-left: 30px;
  }
  .img-style {
    width: 78%;
    display: block;
    margin: 0 auto;
  }
  .card-sub-box {
    background-color:#F8F8F8;
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 15px;
    margin-bottom:30px;
    justify-content: center;
    flex-direction: column;
  }
  .checkbox-typ {
    display: flex;
    gap: 10px;
    margin-left: 25px;
    font-size: 12px;
    margin-top: 10px;
  }
  .bookknowbtn {
    padding: 10px;
    width: 90%;
    display: block;
    margin: 0 auto;
    margin-top: 19px;
    border-radius: 10px;
    background-color: #000;
    color: #fff;
    font-size: 12px;
  }
  .selected-masters{
    width: 90%;
    margin-left: 5%;
    padding: 10px;
    border-radius: 10px;
    margin-top: 15px;
    padding-left: 40px;
  }
  .pic1{
    width: 22px;
    position: absolute;
    top: 30px;
    left: 25px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  .main-heading {
    h1 {
      font-size: 3.4rem;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 1.4rem;
      color: #4c4d4f;
    }
    p {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 1.4rem;
      color: #4c4d4f;
    }
  }

  .faqs-button {
    display: flex;
    align-items: center;
    button {
      width: auto;
      padding: 14px 28px;
      font-size: 1.4rem;
      font-weight: 400;
    }
    .support-btn {
      background: #fff;
      color: #4c4d4f;
      margin-right: 14px;
    }
  }
`;
const columns = [
  { Header: "Treatment", accessor: "column1" },
  { Header: "Thumbnail", accessor: "column2" },
  { Header: "Date", accessor: "column3" },
  { Header: "Time", accessor: "column4" },
  { Header: "Doctor", accessor: "column5" },
  { Header: "Studio", accessor: "column6" },
  { Header: "Payment", accessor: "column7" },
  { Header: "Detail", accessor: "column8" },
];

// const data = isdata.map((tableItem) => ({
const data = [
  {
    column1: "PRP Injections",
    column2: "../../assets/image 4.png",
    column3: "12/08/23",
    column4: "09:00am",
    column5: "Vihang Sharma",
    column6: "Toorak",
    column7: "Pending",
    column8: "icon",
  },
  {
    column1: "PRP Injections",
    column2: "../../assets/image 4.png",
    column3: "12/08/23",
    column4: "09:00am",
    column5: "Vihang Sharma",
    column6: "Toorak",
    column7: "Pending",
    column8: "icon",
  },
  {
    column1: "PRP Injections",
    column2: "../../assets/image 4.png",
    column3: "12/08/23",
    column4: "09:00am",
    column5: "Vihang Sharma",
    column6: "Toorak",
    column7: "Pending",
    column8: "icon",
  },
  {
    column1: "PRP Injections",
    column2: "../../assets/image 4.png",
    column3: "12/08/23",
    column4: "09:00am",
    column5: "Vihang Sharma",
    column6: "Toorak",
    column7: "Pending",
    column8: "icon",
  },
  {
    column1: "PRP Injections",
    column2: "../../assets/image 4.png",
    column3: "12/08/23",
    column4: "09:00am",
    column5: "Vihang Sharma",
    column6: "Toorak",
    column7: "Pending",
    column8: "icon",
  },
];
const PatientDashboard = () => {
  const [isdata, setIsData] = useState(data);
  const [showmodal, setShowmodal] = useState(false);

  
  return (
    <>
    <div>
      <ReuseAbleHeader />
      <MainLayout>
        <ReuseAbleSidebar />
        <Container className="main-content">
          <Row></Row>
          <HeaderWrapper>
            <div className="main-heading">
              <h1>Welcome, Jane</h1>
              <p>Check the latest updates on your account</p>
            </div>
            <div className="faqs-button">
              <ButtonComponent className="support-btn">
                Support & FAQ’s
              </ButtonComponent>
              <AliasLink to="/Prp-Injections">
              <ButtonComponent style={{backgroundColor:"#000",color:"#fff"}}>Book an appointment</ButtonComponent>
              </AliasLink>
            </div>
          </HeaderWrapper>
         
         
       
            <Row>
              <Col className="col1-resp1" xs={12} md={4}>
                {" "}
                <OngoingTreatmentCard />
              </Col>
              <Col className="col1-resp1" xs={12} md={4}>
                <UpcomingAppointmentCard />
              </Col>
              <Col className="col1-resp1" xs={12} md={4}>
                <RecentComponent />
              </Col>
              <Col xs={12} md={12}>
                <ImageCarousel />
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={6}>
                {/* <TreatmentDetail /> */}
                <MedicalRecord />
              </Col>
              <Col xs={6} md={6}>
                <TreatmentDetail />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <div className="table-div">
                  <p className="treat-m">Treatment History</p>
                  <div className="main-table">
                    <TableComponent
                      onClick={() => {
                        setShowmodal(true);
                      }}
                      columns={columns}
                      data={isdata}
                      imageColumn="column2"
                    />
                    <p className="view-more-btn">View more</p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <div className="Treatment-Cost-Estimate">
                  <p className="treat-m" style={{ paddingLeft: "30px" }}>
                    Treatment Cost Estimate
                  </p>
                  <hr />
                  <div className="responsive-pd">
                    <div id="left-side">
                      <p
                        className="treat-m"
                        style={{ paddingLeft: "30px", fontSize: "15px" }}
                      >
                        Select a Treatment
                      </p>
                      <div
                        style={{
                          marginLeft: "20px",
                          display: "flex",
                          gap: "20px",
                          flexWrap: "wrap",
                        }}
                      >
                        <input
                          className="input-shape"
                          type="text"
                          placeholder="Select a treatment"
                        />
                        <input
                          className="input-shape"
                          type="text"
                          placeholder="Select a treatment"
                        />
                      </div>
                      <p
                        className="treat-m"
                        style={{
                          paddingLeft: "30px",
                          fontSize: "15px",
                          marginTop: "20px",
                        }}
                      >
                        PRP Injections
                      </p>
                      <p className="txt-color-style">
                        It is a long established fact that a reader will be
                        distracted by the readable content. It is a long
                        established fact that a reader will be distracted by the
                        readable content. It is a long established fact.
                      </p>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                          <p
                            className="treat-m"
                            style={{ paddingLeft: "30px", fontSize: "15px" }}
                          >
                            Treatment Areas
                          </p>
                          <p className="txt-color-style">
                            1. It is a long established fact. <br />
                            <br />
                            2. Reader will be distracted by the readable
                            content.
                            <br />
                            <br />
                            3. It is a long established fact that a reader will
                            be distracted.
                            <br />
                            <br />
                          </p>
                        </div>
                        <div>
                          <p
                            className="treat-m"
                            style={{ paddingLeft: "30px", fontSize: "15px" }}
                          >
                            Duration
                          </p>
                          <p className="txt-color-style">
                            1. It is a long established fact. <br />
                            <br />
                            2. Reader will be distracted by the readable
                            content.
                            <br />
                            <br />
                            3. It is a long established fact that a reader will
                            be distracted.
                            <br />
                            <br />
                          </p>
                        </div>
                      </div>
                      <p
                        className="treat-m"
                        style={{
                          paddingLeft: "30px",
                          fontSize: "15px",
                          marginTop: "20px",
                        }}
                      >
                        Treatment Process
                      </p>
                      <p className="txt-color-style">
                        It is a long established fact that a reader will be
                        distracted by the readable content. It is a long
                        established fact that a reader will be distracted by the
                        readable content. It is a long established fact.
                        <br />
                        <br />
                        1. It is a long established fact.
                        <br />
                        <br />
                        2. Reader will be distracted by the readable content.
                        <br />
                        <br />
                        3. It is a long established fact that a reader will be
                        distracted.
                        <br />
                        <br />
                      </p>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                          <p
                            className="treat-m"
                            style={{ paddingLeft: "30px", fontSize: "15px" }}
                          >
                            Treatment Areas
                          </p>
                          <p className="txt-color-style">
                            1. It is a long established fact. <br />
                            <br />
                            2. Reader will be distracted by the readable
                            content.
                            <br />
                            <br />
                            3. It is a long established fact that a reader will
                            be distracted.
                            <br />
                            <br />
                          </p>
                        </div>
                        <div>
                          <p
                            className="treat-m"
                            style={{ paddingLeft: "30px", fontSize: "15px" }}
                          >
                            Duration
                          </p>
                          <p className="txt-color-style">
                            1. It is a long established fact. <br />
                            <br />
                            2. Reader will be distracted by the readable
                            content.
                            <br />
                            <br />
                            3. It is a long established fact that a reader will
                            be distracted.
                            <br />
                            <br />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div id="right-side">
                      <div
                        style={{
                          backgroundColor: "#F8F8F8",
                          border: "solid 2px #D6D6D6",
                          padding: "20px",
                          borderRadius: "10px",
                          marginRight: "14px",
                          marginBottom: "15px",
                        }}
                      >
                        <div className="card-sub-box">
                          <div style={{    display: "flex",
                              alignItems: "center",
                              gap: "20px",backgroundColor: "#e1d0c1",
                              padding: "10px",borderRadius: "10px"}}>
                            <div
                              style={{
                                borderRight: "solid 1px",
                                paddingRight: "22px",
                              }}
                            >
                              <p style={{ fontSize: "13px", fontWeight: "bold" }}>
                                PRP Injections
                              </p>
                              <p style={{ fontSize: "12px" }}>
                                It is a long established fact.
                              </p>
                            </div>
                            <div>
                              <p style={{ fontSize: "13px", fontWeight: "bold" }}>
                                $350.00
                              </p>
                              <p style={{ fontSize: "12px" }}>Per Session / 12</p>
                            </div>
                          </div>
                          <div className="checkbox-typ">
                            <input  type="checkbox"/>
                            <p style={{position: "relative",top: "5px",color: "#A5A6A7"}}>Reader will be distracted by the readable content.</p>
                          </div>
                          <div className="checkbox-typ">
                            <input  type="checkbox"/>
                            <p style={{position: "relative",top: "5px",color: "#A5A6A7"}}>It is a long established fact that a reader will be distracted.</p>
                          </div>
                          <div className="checkbox-typ">
                            <input  type="checkbox"/>
                            <p style={{position: "relative",top: "5px",color: "#A5A6A7"}}>Reader will be distracted by the readable content.</p>
                          </div>
                          <div style={{position:"relative",width: "100%"}}>
                            <img className="pic1" src={pic1}/>
                            <select className="selected-masters" name="cars" id="cars">
                              <option value="volvo">Master Card (***6578)</option>
                              <option value="saab">Master Card (***6578)</option>
                              <option value="mercedes">Master Card (***6578)</option>
                              <option value="audi">Master Card (***6578)</option>
                            </select>
                          </div>
                          <button className="bookknowbtn">Book Now</button>
                      </div>
                      <div>
                        <p
                          className="treat-m"
                          style={{ paddingLeft: "30px", fontSize: "15px" }}
                        >
                          Treatment Areas
                        </p>
                        <p className="txt-color-style">
                          1. It is a long established fact. <br />
                          <br />
                          2. Reader will be distracted by the readable content.
                          <br />
                          <br />
                          3. It is a long established fact that a reader will be
                          distracted.
                          <br />
                          <br />
                        </p>
                        <img className="img-style" src={pic} />
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </Col>
            </Row>
          </Container>
        </MainLayout>
      </div>
      {showmodal && (
        <>
          <CloseIcon
            className="closebtn"
            style={{
              position: "fixed",
              top: "5%",
              fontSize: "30px",
              color: "#fff",
              zIndex: "999999",
              right: "5%",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowmodal(false);
            }}
            fontSize="large"
          />
          <div
            style={{
              position: "fixed",
              top: "0",
              height: "100%",
              width: "100%",
              backgroundColor: "#0009",
            }}
          >
            <ModalComponent />
          </div>
        </>
      )}
    </>
  );
};

export default PatientDashboard;
