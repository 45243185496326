import AdminLayout from "../../component/AdminLayout/AdminLayout"
import * as admin_assets from "../../assets/Admin";
import "./style.css";
import TreatmentCard from "../../component/AdminTreatmentCard";

const AdminTreatments = () => {
    return (
        <>
            <AdminLayout>
                <div className="adminLayout d-flex justify-content-center">
                    <div className="content-dashboard admin-panel-content py-0 py-md-3 ps-0 pe-0 pe-md-4 py-md-5 w-100">

                        <div
                            className="content-header align-items-center d-flex justify-content-between"
                        >
                            <div>
                                <h2 className="ms-4 fs-4 mt-4 " >Admin Panel</h2>
                                <p className="ms-4 sub-heading mt-3">Treatments</p>
                            </div>

                            <div>
                                <button
                                    type="button"
                                    className=" btn-admin btn-admin-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add-section"
                                >
                                    Create a new section
                                </button>
                            </div>
                        </div>

                        <div className="content-body px-2 pe-md-5 mt-3" >
                            <div className="row gutter">
                                <TreatmentCard />
                                <TreatmentCard />
                                <TreatmentCard />
                                <TreatmentCard />
                                <TreatmentCard />
                                <TreatmentCard />
                                <TreatmentCard />
                                <TreatmentCard />
                                <TreatmentCard />
                                <TreatmentCard />
                                <TreatmentCard />
                                <TreatmentCard />
                                <TreatmentCard />


                            </div>
                        </div>

                    </div>
                </div>

            </AdminLayout>
            <div
                class="modal fade add-section modal"
                id="add-section"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="add-section"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style={{ borderRadius: "13px" }}>
                        <div class="modal-header text-center m-auto border-0">
                            <img className="mt-4" src={admin_assets.adminWhiteLogo} alt="" />
                            <button
                                style={{ marginLeft: "40px" }}
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div class="modal-body p-4">
                            <h4 style={{ color: '#343434', fontSize: "18px", lineHeight: "21px", fontWeight: 700 }}>Createa a section</h4>
                            <p
                                style={{ color: '#343434', fontSize: "14px", lineHeight: "21px", fontWeight: 400 }}
                                className="sub-heading">Add Section Details</p>
                            <form class="mt-4">
                                <div class="mb-3">
                                    <label className="" name="productName" style={{ color: "#4C4D4F", fontFamily: "cabin", fontWeight: 400, fontSize: "14px", opacity: "50%" }}> Section name</label>

                                    <input type="text" class="form-control" id="sectionName" />
                                </div>

                                <div class="mb-3">
                                    <label className="" name="productName" style={{ color: "#4C4D4F", fontFamily: "cabin", fontWeight: 400, fontSize: "14px", opacity: "50%" }}> Total Sections</label>
                                    <input type="text" class="form-control" id="totalSections" />
                                </div>

                                <div class="mb-3">
                                    <label className="" name="productName" style={{ color: "#4C4D4F", fontFamily: "cabin", fontWeight: 400, fontSize: "14px", opacity: "50%" }}> Medical details</label>

                                    <input type="text" class="form-control" id="medicalDetails" />
                                </div>

                                <div class="mb-3">
                                    <label className="" name="productName" style={{ color: "#4C4D4F", fontFamily: "cabin", fontWeight: 400, fontSize: "14px", opacity: "50%" }}> Each Section Price</label>

                                    <input type="text" class="form-control" id="eachSectionPrice" />
                                </div>

                                <div class="">
                                    <label className="" name="productName" style={{ color: "#4C4D4F", fontFamily: "cabin", fontWeight: 400, fontSize: "14px", opacity: "50%" }}> Total Price</label>

                                    <input
                                        type="text"
                                        class="form-control"
                                        id="totalSectionPrice"
                                    />
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer border-0 me-4" style={{marginBottom:"75px"}} >
                            <button
                                type="button"
                                data-bs-dismiss="modal"
                                class="btn-admin btn-admin-primary"
                            >
                                Create Section
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default AdminTreatments