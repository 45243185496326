import React from "react";
import EditInvoice from "../component/EditInvoice/EditInvoiceComponent"; 

const EditInvoice1 = () => {
  return (
    <div>
      <EditInvoice/>
    </div>
  );
};

export default EditInvoice1;

