import PropTypes from "prop-types";
import { Button as MuiButton } from "@mui/material";

function Button({ variant, children, ...props }) {
  return (
    <MuiButton variant={variant} {...props}>
      {children}
    </MuiButton>
  );
}

Button.propTypes = {
  variant: PropTypes.string,
  disableFocusRipple: PropTypes.bool,
  children: PropTypes.node,
};

Button.defaultProps = {
  variant: "contained",
  children: "Text Goes Here",
  disableFocusRipple: true,
};

export default Button;
