// import React, { useState } from "react";
// import styled from "styled-components";
// import ReuseAbleHeader from "../ReuseAbleHeader/ReuseAbleHeader";
// import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";
// import Container from "react-bootstrap/Container";
// import TableComponent from "../TableComponent/TableComponent2"
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import BarChart from '../Chart/chart';
// import img1 from '../../assets/Group 11447.png';
// import img2 from '../../assets/Frame.png';
// import CustomClanader from '../CustomCalender/CustomCalender';
// const MainLayout = styled.div`
//   display: flex;
//   flex: 1;
//   background: #fff9f1;
//   // align-items: center;
//   .table-div{
//     padding: 31px;
//     background-color: #fff;
//     border-radius: 10px;
//     margin-top: 15px;
//   }
//   .view-more-btn{
//     font-size: 15px;
//     text-align: center;
//     cursor: pointer;
//     padding: 12px;
//     position: relative;
//     text-decoration: underline;
//   }
//   .treat-m{
//     font-weight: 600;
//     font-size: 15px;
//     color: #4C4D4F;
//     padding-left: 5px;
//     margin-bottom: 15px;
//   }
//   .main-table{
//     border-radius: 10px;
//     border: solid 2px #DBDBDB;
//   }
//   .Treatmentcost{
//     background-color: #fff;
//     border-radius: 10px;
//     margin-top: 25px;
//     padding-top: 25px;
//     padding-bottom: 30px;
//   }
//   .Treatment-Cost-Estimate{
//     background-color: #fff;
//     border-radius: 10px;
//     margin-top: 20px;
//     padding-top: 25px;
//     padding-bottom: 30px;
//   }
//   .input-shape{
//     border: solid 1px #D6D6D6;
//     border-radius: 10px;
//     padding: 15px;
//     font-size: 13px;
//     width: 340px;
//   }
//   .txt-color-style{
//     font-size: 14px;
//     color: #A5A6A7;
//     width: 70%;
//     padding-left: 30px;
//   }
//   .img-style{
//     width: 78%;
//     display: block;
//     margin: 0 auto;
//   }
//   .card-sub-box{
//     background-color: #E1D0C1;
//     display: flex;
//     align-items: center;
//     padding: 15px;
//     border-radius: 15px;
//     gap: 35px;
//     justify-content: center;
//   }
//   .checkbox-typ{
//     display: flex;
//     gap: 10px;
//     margin-left: 25px;
//     font-size: 12px;
//     margin-top:10px;
//   }
//   .bookknowbtn{
//     padding: 10px;
//     width: 80%;
//     display: block;
//     margin: 0 auto;
//     margin-top: 19px;
//     border-radius: 10px;
//     background-color: #000;
//     color: #fff;
//     font-size: 12px;
//   }
//   .searchinput{
//     padding: 16px;
//     border-radius: 12px;
//     border: none;
//     margin-right: 10px;
//     width: 225px;
//     font-size: 11px;
//   }
//   .spmtxt{
//     color:#4C4D4F !important;
//   }
//   .joyetxt{
//     background-color: #fff;
//     border-radius: 10px;
//     padding: 30px;
//     margin-top: 18px;
//   }
//   .circle{
//     height: 10px;
//     width: 10px;
//     background-color: #4C4D4F;
//     border-radius: 30px;
//   }
//   .circletxt{
//     position: relative;
//     top: 4px;
//     font-size: 14px;
//   }
//   .line-design{
//     border-left: solid 1px;
//     margin-left: 5px;
//     margin-top: -11px;
//     padding-top: 8px;
//     padding-bottom: 20px;
//     margin-bottom: -12px;
//   }
// `;

// const HeaderWrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-start;

//   .main-heading {
//     h1 {
//       font-size: 3.4rem;
//       font-weight: 400;
//       line-height: 1.5;
//       margin-bottom: 1.4rem;
//       color: #4c4d4f;
//     }
//     p {
//       font-size: 1.4rem;
//       font-weight: 400;
//       line-height: 1.5;
//       margin-bottom: 1.4rem;
//       color: #4c4d4f;
//     }
//   }

// `;
// const columns = [
//   { Header: "Treatment", accessor: "column1" },
//   { Header: "Procedure Notes", accessor: "column2" },
//   { Header: "Date", accessor: "column3" },
//   { Header: "Time", accessor: "column4" },
//   { Header: "Payment", accessor: "column7" },
// ];

// const data = [
//   {
//     column1: "PRP Injections",
//     column2: "View Notes",
//     column3: "12/08/23",
//     column4: "09:00am",
//     column7: "Pending",
//   },
//   {
//     column1: "PRP Injections",
//     column2: "View Notes",
//     column3: "12/08/23",
//     column4: "09:00am",
//     column7: "Pending",
//   },

// ];
// const TreatmentTracking = () => {
//   const [isdata, setIsData] = useState(data);
//   return (
//     <>
//     <div>
//       <ReuseAbleHeader />
//       <MainLayout>
//         <ReuseAbleSidebar />
//         <Container className="main-content">
//           {/* <Row></Row> */}
//           <HeaderWrapper>
//             <div className="main-heading">
//               <h1>Track Rewards</h1>
//               <div style={{display: "flex",alignItems: "center",gap: "40px"}}>
//                 <p>Track Your patient & rewards for appointments</p>
//               </div>
//             </div>
//           </HeaderWrapper>

//           <Row>
//             <Col xs={8} md={8}>
//                 <Row>
//                     <Col xs={6} md={6}>
//                         <div style={{backgroundColor:"#fff",paddingTop: "50px",paddingBottom: "50px",paddingLeft: "18px",paddingRight: "18px",borderRadius: "10px"}}>
//                             <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
//                                 <div>
//                                     <h1 style={{fontSize: "44px"}}>430</h1>
//                                     <p style={{fontSize: "16px",marginLeft: "5px",marginTop: "10px",color:"#A5A6A7"}}>Total: <spam style={{color:"#000"}}>945</spam></p>
//                                 </div>
//                                 <img style={{width: "37%"}} src={img1} />
//                             </div>
//                             <div style={{display: "flex",alignItems: "center",gap: "8px",position: "relative",top: "20px"}}>
//                                 <img style={{width: "30px"}} src={img2}/>
//                                 <p style={{position: "relative",top: "6px",fontSize: "12px"}}><spam>+12%</spam> than last month</p>
//                             </div>
//                         </div>
//                     </Col>
//                     <Col xs={6} md={6}>
//                         <div style={{backgroundColor:"#fff",paddingTop: "50px",paddingBottom: "50px",paddingLeft: "18px",paddingRight: "18px",borderRadius: "10px"}}>
//                                 <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
//                                     <div>
//                                         <h1 style={{fontSize: "44px"}}>430</h1>
//                                         <p style={{fontSize: "16px",marginLeft: "5px",marginTop: "10px",color:"#A5A6A7"}}>Total: <spam style={{color:"#000"}}>945</spam></p>
//                                     </div>
//                                 <img style={{width: "37%"}} src={img1} />
//                             </div>
//                             <div style={{display: "flex",alignItems: "center",gap: "8px",position: "relative",top: "20px"}}>
//                                 <img style={{width: "30px"}} src={img2}/>
//                                 <p style={{position: "relative",top: "6px",fontSize: "12px"}}><spam>+12%</spam> than last month</p>
//                             </div>
//                         </div>
//                     </Col>
//                     <Col xs={12} md={12}>
//                         <div style={{backgroundColor:"#fff",paddingTop: "50px",paddingBottom: "50px",paddingLeft: "18px",paddingRight: "18px",borderRadius: "10px",marginTop: "18px"}}>
//                             <BarChart />
//                         </div>
//                     </Col>
//                     <Col xs={12} md={12}>
//                         <div className="table-div">
//                             <p className="treat-m">Treatment History</p>
//                             <div className="main-table">
//                             <TableComponent columns={columns} data={isdata} />
//                             <p className="view-more-btn">View more</p>
//                             </div>
//                         </div>
//                     </Col>
//                 </Row>
//             </Col>
//             <Col xs={4} md={4}>
//                 <div style={{backgroundColor: "#fff",padding: "25px",borderRadius: "10px"}}>
//                     <p style={{fontSize: "16px",marginBottom: "30px"}}>Your Rewards Schedule</p>
//                     <CustomClanader />
//                     <div style={{display:"flex",alignItems:"center",gap: "10px"}}>
//                         <div className="circle"></div> <p className="circletxt">10:00 AM</p>
//                     </div>
//                     <div className="line-design">
//                         <div style={{backgroundColor:"#F8F8F8",padding: "20px",borderRadius: "10px",marginLeft: "50px"}}>
//                             <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
//                                 <div>
//                                     <div><h1 style={{fontSize: "15px"}}>Consultation</h1></div>
//                                     <p style={{color:"#A5A6A7"}}>09:00 AM - 10:30 AM</p>
//                                 </div>
//                                 <p style={{color:"#A5A6A7"}}>Point 25</p>
//                             </div>
//                             <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
//                                 <p>Annie Smith</p>
//                                 <p style={{color:"#A5A6A7"}}>Earned</p>
//                             </div>
//                         </div>
//                     </div>
//                     <div style={{display:"flex",alignItems:"center",gap: "10px"}}>
//                         <div className="circle"></div> <p className="circletxt">10:00 AM</p>
//                     </div>
//                     <div className="line-design">
//                         <div style={{backgroundColor:"#F8F8F8",padding: "20px",borderRadius: "10px",marginLeft: "50px"}}>
//                             <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
//                                 <div>
//                                     <div><h1 style={{fontSize: "15px"}}>Consultation</h1></div>
//                                     <p style={{color:"#A5A6A7"}}>09:00 AM - 10:30 AM</p>
//                                 </div>
//                                 <p style={{color:"#A5A6A7"}}>Point 25</p>
//                             </div>
//                             <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
//                                 <p>Annie Smith</p>
//                                 <p style={{color:"#A5A6A7"}}>Earned</p>
//                             </div>
//                         </div>
//                     </div>
//                     <div style={{display:"flex",alignItems:"center",gap: "10px"}}>
//                         <div className="circle"></div> <p className="circletxt">10:00 AM</p>
//                     </div>
//                     <div className="line-design">
//                         <div style={{backgroundColor:"#F8F8F8",padding: "20px",borderRadius: "10px",marginLeft: "50px"}}>
//                             <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
//                                 <div>
//                                     <div><h1 style={{fontSize: "15px"}}>Consultation</h1></div>
//                                     <p style={{color:"#A5A6A7"}}>09:00 AM - 10:30 AM</p>
//                                 </div>
//                                 <p style={{color:"#A5A6A7"}}>Point 25</p>
//                             </div>
//                             <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
//                                 <p>Annie Smith</p>
//                                 <p style={{color:"#A5A6A7"}}>Earned</p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </Col>
//           </Row>
//           {/* <Row>
//             <Col xs={12} md={12}>
//               <div className="table-div">
//                 <p className="treat-m">Treatment History</p>
//                 <div className="main-table">
//                   <TableComponent columns={columns} data={isdata} />
//                   <p className="view-more-btn">View more</p>
//                 </div>
//               </div>
//             </Col>
//           </Row> */}

//         </Container>
//       </MainLayout>
//     </div>
//     </>
//   );
// };

// export default TreatmentTracking;

import React, { useState } from "react";
import styled from "styled-components";
import ReuseAbleHeader from "../ReuseAbleHeader/ReuseAbleHeader";
import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";
import Container from "react-bootstrap/Container";
import TableComponent from "../TableComponent/TableComponent2";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BarChart from "../Chart/chart";
import img1 from "../../assets/Group 11447.png";
import img2 from "../../assets/Frame.png";
import CustomClanader from "../CustomCalender/CustomCalender";
const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
  // align-items: center;
  .table-div {
    padding: 31px;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 15px;
  }
  .view-more-btn {
    font-size: 15px;
    text-align: center;
    cursor: pointer;
    padding: 12px;
    position: relative;
    text-decoration: underline;
  }
  .treat-m {
    font-weight: 600;
    font-size: 15px;
    color: #4c4d4f;
    padding-left: 5px;
    margin-bottom: 15px;
  }
  .main-table {
    border-radius: 10px;
    border: solid 2px #dbdbdb;
  }
  .Treatmentcost {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 25px;
    padding-top: 25px;
    padding-bottom: 30px;
  }
  .Treatment-Cost-Estimate {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 20px;
    padding-top: 25px;
    padding-bottom: 30px;
  }
  .input-shape {
    border: solid 1px #d6d6d6;
    border-radius: 10px;
    padding: 15px;
    font-size: 13px;
    width: 340px;
  }
  .txt-color-style {
    font-size: 14px;
    color: #a5a6a7;
    width: 70%;
    padding-left: 30px;
  }
  .img-style {
    width: 78%;
    display: block;
    margin: 0 auto;
  }
  .card-sub-box {
    background-color: #e1d0c1;
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 15px;
    gap: 35px;
    justify-content: center;
  }
  .checkbox-typ {
    display: flex;
    gap: 10px;
    margin-left: 25px;
    font-size: 12px;
    margin-top: 10px;
  }
  .bookknowbtn {
    padding: 10px;
    width: 80%;
    display: block;
    margin: 0 auto;
    margin-top: 19px;
    border-radius: 10px;
    background-color: #000;
    color: #fff;
    font-size: 12px;
  }
  .searchinput {
    padding: 16px;
    border-radius: 12px;
    border: none;
    margin-right: 10px;
    width: 225px;
    font-size: 11px;
  }
  .spmtxt {
    color: #4c4d4f !important;
  }
  .joyetxt {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    margin-top: 18px;
  }
  .circle {
    height: 10px;
    width: 10px;
    background-color: #4c4d4f;
    border-radius: 30px;
  }
  .circletxt {
    position: relative;
    top: 4px;
    font-size: 14px;
  }
  .line-design {
    border-left: solid 1px;
    margin-left: 5px;
    margin-top: -11px;
    padding-top: 8px;
    padding-bottom: 20px;
    margin-bottom: -12px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .main-heading {
    h1 {
      font-size: 3.4rem;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 1.4rem;
      color: #4c4d4f;
    }
    p {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 1.4rem;
      color: #4c4d4f;
    }
  }
`;
const columns = [
  { Header: "Treatment", accessor: "column1" },
  { Header: "Procedure Notes", accessor: "column2" },
  { Header: "Date", accessor: "column3" },
  { Header: "Time", accessor: "column4" },
  { Header: "Payment", accessor: "column7" },
];

const data = [
  {
    column1: "PRP Injections",
    column2: "View Notes",
    column3: "12/08/23",
    column4: "09:00am",
    column7: "Pending",
  },
  {
    column1: "PRP Injections",
    column2: "View Notes",
    column3: "12/08/23",
    column4: "09:00am",
    column7: "Pending",
  },
];
const TreatmentTracking = () => {
  const [isdata, setIsData] = useState(data);
  return (
    <>
      <div>
        <ReuseAbleHeader />
        <MainLayout>
          <ReuseAbleSidebar />
          <Container className="main-content">
            <HeaderWrapper>
              <div className="main-heading">
                <h1>Track Rewards</h1>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "40px" }}
                >
                  <p>Track Your patient & rewards for appointments</p>
                </div>
              </div>
            </HeaderWrapper>

            <Row>
              <Col xs={8} md={8}>
                <Row>
                  <Col xs={6} md={6}>
                    <div
                      style={{
                        backgroundColor: "#fff",
                        paddingTop: "50px",
                        paddingBottom: "50px",
                        paddingLeft: "18px",
                        paddingRight: "18px",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: "44px" }}>430</h1>
                          <p
                            style={{
                              fontSize: "16px",
                              marginLeft: "5px",
                              marginTop: "10px",
                              color: "#A5A6A7",
                            }}
                          >
                            Total: <spam style={{ color: "#000" }}>945</spam>
                          </p>
                        </div>
                        <img style={{ width: "37%" }} src={img1} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          position: "relative",
                          top: "20px",
                        }}
                      >
                        <img style={{ width: "30px" }} src={img2} />
                        <p
                          style={{
                            position: "relative",
                            top: "6px",
                            fontSize: "12px",
                          }}
                        >
                          <spam>+12%</spam> than last month
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} md={6}>
                    <div
                      style={{
                        backgroundColor: "#fff",
                        paddingTop: "50px",
                        paddingBottom: "50px",
                        paddingLeft: "18px",
                        paddingRight: "18px",
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <h1 style={{ fontSize: "44px" }}>430</h1>
                          <p
                            style={{
                              fontSize: "16px",
                              marginLeft: "5px",
                              marginTop: "10px",
                              color: "#A5A6A7",
                            }}
                          >
                            Total: <spam style={{ color: "#000" }}>945</spam>
                          </p>
                        </div>
                        <img style={{ width: "37%" }} src={img1} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          position: "relative",
                          top: "20px",
                        }}
                      >
                        <img style={{ width: "30px" }} src={img2} />
                        <p
                          style={{
                            position: "relative",
                            top: "6px",
                            fontSize: "12px",
                          }}
                        >
                          <spam>+12%</spam> than last month
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={12}>
                    <div
                      style={{
                        backgroundColor: "#fff",
                        paddingTop: "50px",
                        paddingBottom: "50px",
                        paddingLeft: "18px",
                        paddingRight: "18px",
                        borderRadius: "10px",
                        marginTop: "18px",
                      }}
                    >
                      <BarChart />
                    </div>
                  </Col>
                  <Col xs={12} md={12}>
                    <div className="table-div">
                      <p className="treat-m">Treatment History</p>
                      <div className="main-table">
                        <TableComponent columns={columns} data={isdata} />
                        <p className="view-more-btn">View more</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={4} md={4}>
                <div
                  style={{
                    backgroundColor: "#fff",
                    padding: "25px",
                    borderRadius: "10px",
                  }}
                >
                  <p style={{ fontSize: "16px", marginBottom: "30px" }}>
                    Your Rewards Schedule
                  </p>
                  <CustomClanader />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div className="circle"></div>{" "}
                    <p className="circletxt">10:00 AM</p>
                  </div>
                  <div className="line-design">
                    <div
                      style={{
                        backgroundColor: "#F8F8F8",
                        padding: "20px",
                        borderRadius: "10px",
                        marginLeft: "50px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <div>
                            <h1 style={{ fontSize: "15px" }}>Consultation</h1>
                          </div>
                          <p style={{ color: "#A5A6A7" }}>
                            09:00 AM - 10:30 AM
                          </p>
                        </div>
                        <p style={{ color: "#A5A6A7" }}>Point 25</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>Annie Smith</p>
                        <p style={{ color: "#A5A6A7",display: "flex",gap:" 5px"}}><p style={{    backgroundColor: "#F1B34A",
    height: "18px",
    width: "18px",
    borderRadius: "12px",marginBottom: "0"}}></p>Earned</p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div className="circle"></div>{" "}
                    <p className="circletxt">10:00 AM</p>
                  </div>
                  <div className="line-design">
                    <div
                      style={{
                        backgroundColor: "#F8F8F8",
                        padding: "20px",
                        borderRadius: "10px",
                        marginLeft: "50px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <div>
                            <h1 style={{ fontSize: "15px" }}>Consultation</h1>
                          </div>
                          <p style={{ color: "#A5A6A7" }}>
                            09:00 AM - 10:30 AM
                          </p>
                        </div>
                        <p style={{ color: "#A5A6A7" }}>Point 25</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>Annie Smith</p>
                        <p style={{ color: "#A5A6A7", display: "flex",gap:" 5px"}}><p style={{    backgroundColor: "#F1B34A",
    height: "18px",
    width: "18px",
    borderRadius: "12px",marginBottom: "0"}}></p>Earned</p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div className="circle"></div>{" "}
                    <p className="circletxt">10:00 AM</p>
                  </div>
                  <div className="line-design">
                    <div
                      style={{
                        backgroundColor: "#F8F8F8",
                        padding: "20px",
                        borderRadius: "10px",
                        marginLeft: "50px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <div>
                            <h1 style={{ fontSize: "15px" }}>Consultation</h1>
                          </div>
                          <p style={{ color: "#A5A6A7" }}>
                            09:00 AM - 10:30 AM
                          </p>
                        </div>
                        <p style={{ color: "#A5A6A7" }}>Point 25</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>Annie Smith</p>
                        <p style={{ color: "#A5A6A7",display: "flex",gap:" 5px" }}><p style={{    backgroundColor: "#F1B34A",
    height: "18px",
    width: "18px",
    borderRadius: "12px",marginBottom: "0"}}></p>Earned</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {/* <Row>
            <Col xs={12} md={12}>
              <div className="table-div">
                <p className="treat-m">Treatment History</p>
                <div className="main-table">
                  <TableComponent columns={columns} data={isdata} />
                  <p className="view-more-btn">View more</p>
                </div>
              </div>
            </Col>
          </Row> */}
          </Container>
        </MainLayout>
      </div>
    </>
  );
};

export default TreatmentTracking;
