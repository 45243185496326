// import React, { useEffect } from "react";
// import styled from "styled-components";
// import FullCalendar from "@fullcalendar/core";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";
// import interactionPlugin from "@fullcalendar/interaction";

// const CalendarContainer = styled.div`
//   max-width: 900px;
//   margin: 40px auto;
// `;

// const CustomCalender = () => {
//   useEffect(() => {
//     const calendarEl = document.getElementById("calendar");
//     let awaitingSecondClick = false;

//     const calendar = new FullCalendar.Calendar(calendarEl, {
//       plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
//       dateClick: (info) => {
//         if (!awaitingSecondClick) {
//           awaitingSecondClick = true;
//           setTimeout(() => (awaitingSecondClick = false), 500);
//         } else {
//           alert("doubleclick");
//         }
//         console.log("Click");
//       },
//       header: {
//         left: "dayGridMonth,timeGridWeek,timeGridDay",
//         center: "title",
//         right: "prevYear,prev,next,nextYear",
//       },
//     });

//     calendar.render();
//   }, []);

//   return <CalendarContainer id="calendar" />;
// };

// export default CustomCalender;

import React, { useEffect } from "react";
import styled from "styled-components";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

const CalendarContainer = styled.div`
  max-width: 900px;
  // margin: 40px auto;
  margin-bottom: 5.4rem;
`;

const CalendarComponent = () => {
  useEffect(() => {
    // Add any additional initialization code here if needed
  }, []);

  return (
    <CalendarContainer>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={
          [
            /* Your event data here */
          ]
        }
        // Add event handlers and other configurations here
      />
    </CalendarContainer>
  );
};

export default CalendarComponent;
