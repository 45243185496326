import * as admin_assets from "../../assets/Admin";

import "./AdminHeader.css"

const AdminHeader = () => {
  return (
    <>
    <div className="">    
      <div className="admin-header d-flex justify-content-between align-items-center admin-header-padding">
        <div className="admin-mobile-btn-bg d-md-none">
          <img src={admin_assets.hamburger} />            
        </div>

        <div className="admin-header-logo">
          <img src={admin_assets.adminWhiteLogo} />          
        </div>       
        
        <div className="d-flex justify-content-around align-items-center gap-5">
          <div className="d-none d-md-block">
            <p>Sunday, 20 August</p>
          </div>
          <div className="d-none d-md-block">
            <p>1:50 PM</p>            
          </div>
          
          <div className="admin-mobile-btn-bg ">
            <img src={admin_assets.notificationIcon} />            
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
export default AdminHeader;
