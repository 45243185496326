import { colors } from "../../config/colors";

export const InputFieldDark = {
  variants: [
    {
      props: { variant: "standard" },
      style: {
        color: colors.greyText[900],
        backgroundColor: colors.greyText[40],
        fill: "currentColor",
      },
    },
  ],
};

export const InputFieldLight = {
  variants: [
    {
      props: { variant: "outlined" },
      style: {
        border: "none",
        outline: "none",
        boxShadow: "none",
        // backgroundColor: "green",
        "& .MuiOutlinedInput-input:focused": {
          borderColor: "green",
        },
        ".Mui-focused": {
          border: "none",
          outline: "none",
          boxShadow: "none",
        },
        "&.MuiOutlinedInput-root": {
          // backgroundColor: "green"
        },
        // padding: "28px 0",
        // backgroundColor: "green",
        "&.MuiTextField-root": {
          // backgroundColor: "red",
          border: "none",
          outline: "none",
          boxShadow: "none",
          "&.Mui-focused": {
            border: "none",
            outline: "none",
            boxShadow: "none",
          },
          "&:focused": {
            border: "none",
            outline: "none",
          },
        },
        // "&.MuiTextField-root": { backgroundColor: "red" },
        "& .MuiInputBase-root": {
          // backgroundColor: "red",
          // border: "1px solid rgba(0, 0, 0, 0.5)",
          // borderRadius: "20px",
          "&.Mui-focused": {
            border: "none",
            outline: "none",
          },
          "& .MuiInputBase-input": {
            // backgroundColor: "red",
            // border: "1px solid rgba(0, 0, 0, 0.5)",
            // borderRadius: "20px",
            "&.Mui-focused": {
              border: "none",
              outline: "none",
            },
            // padding: "28px",
          },
          // padding: "0",
        },
      },
    },
    // {
    //   props: { variant: "outlined", size: "small" },
    //   style: {
    //     "& .MuiInputBase-root": {
    //       color: colors.greyText[900],
    //       background: colors.primary[0],
    //       minHeight: "32px",
    //       height: "auto",
    //       padding: "5px 11px",
    //       borderRadius: "4px",
    //       // lineHeight: "20px",
    //       border: `1.5px solid ${colors.greyText[40]}`,
    //       width: "inherit",

    //       "& .MuiInputBase-input": {
    //         padding: 0,
    //         fontSize: "14px",
    //         letterSpacing: "normal",
    //         "&::placeholder": {
    //           display: "flex",
    //           alignSelf: "center",
    //           color: colors.greyText[400],
    //           opacity: "1",
    //         },
    //         "&:-webkit-autofill": {
    //           backgroundColor: "red",
    //           color: "green",
    //         },
    //         "&:auto-fill": {
    //           backgroundColor: "red",
    //           color: "green",
    //         },
    //       },
    //       "& .MuiOutlinedInput-notchedOutline": {
    //         border: "none",
    //       },
    //     },
    //     "& .MuiInputBase-root.Mui-focused": {
    //       outline: `3px solid ${colors.primary[10]}`,
    //       border: `1.5px solid ${colors.primary[100]}`,
    //       background: colors.greyText[0],
    //       // background: "transparent",
    //       // background: "green",
    //     },
    //     "& .MuiInputBase-root.Mui-disabled": {
    //       backgroundColor: colors.greyText[10],
    //     },
    //     "& .MuiInputBase-root.Mui-error": {
    //       outline: `3px solid ${colors.error[0]}`,
    //       border: `1.5px solid ${colors.error[400]}`,
    //       // background: "transparent",
    //       background: colors.greyText[0],
    //     },
    //     "& .MuiInputBase-root.Mui-error.Mui-focused": {
    //       outline: `3px solid ${colors.error[0]}`,
    //       border: `1.5px solid ${colors.error[400]}`,
    //       // background: "transparent",
    //       background: colors.greyText[0],
    //     },

    //     "& .MuiFormHelperText-root": {
    //       textTransform: "initial",
    //       color: colors.error[500],
    //       fontSize: "14px",
    //       lineHeight: "18px",
    //       marginLeft: "6px",
    //       position: "absolute",
    //       bottom: "-21px",
    //     },
    //     "& .MuiFormHelperText-root.Mui-error": {
    //       color: colors.error[500],
    //       fontSize: "14px",
    //       lineHeight: "18px",
    //       marginLeft: "6px",
    //     },
    //     "&.css-1ong9q-MuiFormControl-root-MuiTextField-root .MuiInputBase-root.Mui-error":
    //       {
    //         backgroundColor: colors.background[0],
    //       },
    //     "& .Mui-error .MuiOutlinedInput-notchedOutline": {
    //       backgroundColor: "transparent !important",
    //     },
    //     "& .MuiInputAdornment-root": {
    //       cursor: "pointer",
    //     },
    //     "&.MuiTextField-root": {
    //       width: "100%",
    //     },
    //     "&.search-bar": {
    //       "& .MuiInputBase-root": {
    //         color: colors.greyText[900],
    //         background: colors.greyText[0],
    //         // minHeight: "40px",
    //         minHeight: "32px",
    //         height: "auto",
    //         padding: "0px 13px",
    //         borderRadius: "6px",
    //         lineHeight: "20px",
    //         border: `1.5px solid ${colors.greyText[10]}`,
    //         width: "inherit",
    //         "&:hover": {
    //           border: `1.5px solid ${colors.greyText[80]}`,
    //           outline: "none",
    //           background: colors.greyText[0],
    //         },
    //         "&.Mui-focused": {
    //           border: `1.5px solid ${colors.greyText[80]}`,
    //           outline: "none",
    //           background: colors.greyText[0],
    //         },
    //       },
    //     },
    //   },
    // },
  ],
};
