// import React, { useState } from "react";
// import styled from "styled-components";
// // import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";

// import ReuseAbleHeader from "../component/ReuseAbleHeader/ReuseAbleHeader";
// import ReuseAbleSidebar from "../component/ReuseAbleSidebar/ReuseAbleSidebar";

// import RatingComponent from "../component/RatingComponent/RatingComponent";

// import ButtonComponent from "../component/ButtonComponent/ButtonComponent";

// import Logo from "../assets/logo-dashboard.png";
// import { ProgressBar as ReactProgressBar } from "react-bootstrap";

// const MainLayout = styled.div`
//   display: flex;
//   flex: 1;
//   background: #fff9f1;
// `;
// const Table2Wrapperr = styled.div`
//   height: 178px;

//   // display: flex;
//   // flex: 1;
//   // background: #fff9f1;
//   padding: 22px 28px;
//   background: #fff;
//   border-radius: 0 0 14px 14px;

//   overflow-y: auto;

//   .add-border {
//     border: 1px solid #d6d6d6;
//     border-radius: 14px;
//     overflow: auto;
//   }
// `;

// const TableWrapperr = styled.div`
//   display: flex;
//   flex: 1;
//   background: #fff9f1;
//   border-bottom: 1px solid #dbdbdc;
//   padding: 2.1rem 2.7rem;
//   background-color: #fff;
//   border-radius: 14px 14px 0 0;

//   h1 {
//   }
// `;

// const PrpgressBar = styled.div`
//   width: 31.5rem;
//   margin: auto;
//   margin-top: 2.8rem;
//   div {
//     // background: red;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     margin-bottom: 0.8rem;

//     p {
//       color: #4c4d4f;
//       font-size: 1.4rem;
//       font-weight: 400;
//       margin-bottom: 2.7rem;

//       margin: 0;
//     }
//   }
// `;

// const ChildrenWrapper = styled.div`
//   width: 100%;
//   // background-color: red;
//   margin: 24px;
//   text-align: center;

//   .logos img {
//     display: inline-block;
//     margin-top: 2.8rem;
//     width: 240px;
//     height: 60px;
//     margin: 2.8rem auto 0;
//   }
// `;

// const SurveyWrapper = styled.div`
//   margin-top: 2.8rem;
//   background-color: #fff;
//   border-radius: 14px;

//   .survey-text {
//     font-size: 3rem;
//     font-weight: 400;
//     color: #000;
//     margin-bottom: 7rem;
//     margin-top: 3rem;
//   }
//   .colleague-text {
//     font-size: 2rem;
//     font-weight: 400;
//     color: #000;
//     margin-bottom: 1.6rem;
//   }
//   .rating-wrapper {
//     width: 55.2rem;
//     margin: auto;
//     div {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;

//       width: 32.3rem;
//       margin: auto;
//     }
//     div p {
//       font-size: 1.4rem;
//       font-weight: 400;
//       color: #808080;
//       margin: 0;
//     }
//   }
//   .radio-btn {
//     font-size: 1.4rem;
//     font-weight: 700;
//     color: #000;
//   }

//   .wrapper-choise-section {
//     text-align: start;
//     margin-left: 5.2rem;
//   }
//   .progress-wrapper {
//     display: flex;
//     align-items: center;
//     gap: 2.8rem;
//     width: 50%;
//     div div .progress-bar {
//       height: 20px;
//     }
//     p {
//       margin: 0;
//     }
//   }
// `;

// const CustomerSurvey1 = () => {
//   return (
//     <div>
//       <ReuseAbleHeader />
//       <MainLayout>
//         <ReuseAbleSidebar />
//         <ChildrenWrapper>
//           <div className="logos">
//             <img src={Logo} />
//           </div>

//           <PrpgressBar>
//             <div>
//               <p>Progress</p>
//               <p>0%</p>
//             </div>
//             <ReactProgressBar
//               variant="success"
//               now={40}
//               style={{ backgroundColor: "#978f88" }}
//             />
//           </PrpgressBar>
//           <SurveyWrapper>
//             <p className="survey-text">
//               Answer those questions below for Survey
//             </p>
//             <p className="colleague-text">
//               How Likely are you to recommend our company to a friend or
//               colleague?
//             </p>
//             <div className="rating-wrapper">
//               <RatingComponent />
//               <div>
//                 <p>0 - Not Likely</p>
//                 <p>10 - Very Likely</p>
//               </div>
//             </div>
//             <div style={{ width: "100%" }}>
//               <ButtonComponent
//                 style={{
//                   backgroundColor: "#000",
//                   color: "#fff",
//                   padding: "1.3rem 4.8rem",
//                   width: "auto",
//                   marginTop: "6.9rem",
//                   marginBottom: "5.4rem",
//                 }}
//               >
//                 Continue
//               </ButtonComponent>
//             </div>
//           </SurveyWrapper>
//         </ChildrenWrapper>
//       </MainLayout>
//     </div>
//   );
// };

// export default CustomerSurvey1;

import React, { useState } from "react";
import styled from "styled-components";
// import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";

import ReuseAbleHeader from "../component/ReuseAbleHeader/ReuseAbleHeader";
import ReuseAbleSidebar from "../component/ReuseAbleSidebar/ReuseAbleSidebar";

import RatingComponent from "../component/RatingComponent/RatingComponent";

import ButtonComponent from "../component/ButtonComponent/ButtonComponent";

import Logo from "../assets/logo-dashboard.png";
import { ProgressBar as ReactProgressBar } from "react-bootstrap";

const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
`;
const Table2Wrapperr = styled.div`
  height: 178px;

  // display: flex;
  // flex: 1;
  // background: #fff9f1;
  padding: 22px 28px;
  background: #fff;
  border-radius: 0 0 14px 14px;

  overflow-y: auto;

  .add-border {
    border: 1px solid #d6d6d6;
    border-radius: 14px;
    overflow: auto;
  }
`;

const TableWrapperr = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
  border-bottom: 1px solid #dbdbdc;
  padding: 2.1rem 2.7rem;
  background-color: #fff;
  border-radius: 14px 14px 0 0;

  h1 {
  }
`;

const PrpgressBar = styled.div`
  width: 31.5rem;
  margin: auto;
  margin-top: 2.8rem;
  div {
    // background: red;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.8rem;

    p {
      color: #4c4d4f;
      font-size: 1.4rem;
      font-weight: 400;
      margin-bottom: 2.7rem;

      margin: 0;
    }
  }
`;

const ChildrenWrapper = styled.div`
  width: 100%;
  // background-color: red;
  margin: 24px;
  text-align: center;

  .logos img {
    display: inline-block;
    margin-top: 2.8rem;
    width: 240px;
    height: 60px;
    margin: 2.8rem auto 0;
  }
`;

const SurveyWrapper = styled.div`
  margin-top: 2.8rem;
  background-color: #fff;
  border-radius: 14px;
  width: 100%;

  .survey-text {
    padding-top: 60px;
    font-size: 3rem;
    font-weight: 400;
    color: #000;
    margin-bottom: 7rem;
    margin-top: 3rem;
  }
  .colleague-text {
    font-size: 2rem;
    font-weight: 400;
    color: #000;
    margin-bottom: 1.6rem;
  }
  .rating-wrapper {
    width: 100%;
    margin: auto;
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 32.3rem;
      margin: auto;
    }
    div p {
      font-size: 1.4rem;
      font-weight: 400;
      color: #808080;
      margin: 0;
    }
  }
  .radio-btn {
    font-size: 1.4rem;
    font-weight: 700;
    color: #000;
  }

  .wrapper-choise-section {
    text-align: start;
    margin-left: 5.2rem;
  }
  .progress-wrapper {
    display: flex;
    align-items: center;
    gap: 2.8rem;
    width: 50%;
    div div .progress-bar {
      height: 20px;
    }
    p {
      margin: 0;
    }
  }
`;

const CustomerSurvey1 = () => {
  return (
    <div>
      <ReuseAbleHeader />
      <MainLayout>
        <ReuseAbleSidebar />
        <ChildrenWrapper>
          <div className="logos">
            <img src={Logo} />
          </div>

          <PrpgressBar>
            <div>
              <p>Progress</p>
              <p>0%</p>
            </div>
            <ReactProgressBar
              variant="success"
              now={40}
              style={{ backgroundColor: "#978f88" }}
            />
          </PrpgressBar>
          <SurveyWrapper>
            <p className="survey-text">Label your Grader</p>
            <p className="colleague-text">
              How Likely are you to recommend our company to a friend or
              colleague?
            </p>
            <div className="rating-wrapper">
              <RatingComponent />
              <div>
                <p>0 - Not Likely</p>
                <p>10 - Very Likely</p>
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <ButtonComponent
                style={{
                  backgroundColor: "#000",
                  color: "#fff",
                  padding: "1.3rem 4.8rem",
                  width: "auto",
                  marginTop: "6.9rem",
                  marginBottom: "5.4rem",
                }}
              >
                Continue
              </ButtonComponent>
            </div>
          </SurveyWrapper>
        </ChildrenWrapper>
      </MainLayout>
    </div>
  );
};

export default CustomerSurvey1;
