// import React from "react";

// const MuiRadioButton = () => {
//   return <div>MuiRadioButton</div>;
// };

// export default MuiRadioButton;

import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const MuiRadioButton = () => {
  return (
    <FormControl>
      {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel
          value="Anti ageing Secton"
          control={<Radio />}
          label="Anti ageing Secton"
          color="red"
        />
        <FormControlLabel
          value="Anti Wrinkle Section"
          control={<Radio />}
          label="Anti Wrinkle Section"
          color="red"
        />
      </RadioGroup>
    </FormControl>
  );
};

export default MuiRadioButton;
