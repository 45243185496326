
import React, { useState } from "react";
import styled from "styled-components";
import ReuseAbleHeader from "../ReuseAbleHeader/ReuseAbleHeader";
import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import img1 from '../../assets/Group 11763.png';

import icon1 from '../../assets/Group 11765.png';
import icon2 from '../../assets/Group 11766.png';
import icon3 from '../../assets/Group 11767.png';
import icon4 from '../../assets/Group 11768.png';
import icon5 from '../../assets/Group 11764.png';

import Ellipse from '../../assets/Ellipse 18.png';

import SupportChat from "../SupportChat/supportChatComponent1";
const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
  // align-items: center;
  .table-div{
    padding: 31px;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 15px;
  }
  .view-more-btn{
    font-size: 15px;
    text-align: center;
    cursor: pointer;
    padding: 12px;
    position: relative;
    text-decoration: underline;
  }
  .treat-m{
    font-weight: 600;
    font-size: 15px;
    color: #4C4D4F;
    padding-left: 5px;
    margin-bottom: 15px;
  }
  .main-table{
    border-radius: 10px;
    border: solid 2px #DBDBDB; 
  }
  .Treatmentcost{
    background-color: #fff;
    border-radius: 10px;
    margin-top: 25px;
    padding-top: 25px;
    padding-bottom: 30px;
  }
  .Treatment-Cost-Estimate{
    background-color: #fff;
    border-radius: 10px;
    margin-top: 20px;
    padding-top: 25px;
    padding-bottom: 30px;
  }
  .second-box{
    background-color: #fff;
    padding: 18px;
    border-radius: 10px;
    width: 300px;
  }
  .second-box div{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 18px;
  }
  .second-box p{
    position: relative;
    top: 7px;
    font-weight: 800;
  }
  .icocns{
    width:18%;
    margin-right:15px
  }
  .icons-dev{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
  }
  .main-box{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 35px;
    padding-bottom: 30px;
    border-bottom: solid 1px;
  }
  .mmm-box{
    background-color: #fff;
    border-radius: 5px;
    padding-top: 30px;
    min-width: 414px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .main-heading {
    h1 {
      font-size: 3.4rem;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 1.4rem;
      color: #4c4d4f;
    }
    p {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 1.4rem;
      color: #4c4d4f;
    }
  }

`;
const columns = [
  { Header: "ID", accessor: "column1" },
  { Header: "Coupon Code", accessor: "column2" },
  { Header: "Order Id", accessor: "column3" },
  { Header: "Practitioner", accessor: "column4" },
  { Header: "Product type", accessor: "column5" },
  { Header: "Status", accessor: "column7" },
];

const data = [
  {
    column1: "#A746",
    column2: "A746",
    column3: "#7896",
    column4: "Dr. Vihang",
    column7: "Section",
    column5: "Claimed",
  },
  {
    column1: "#A746",
    column2: "A746",
    column3: "#7896",
    column4: "Dr. Vihang",
    column7: "Section",
    column5: "Claimed",
  },

];
const VirtualConsultation = () => {
  const [isdata, setIsData] = useState(data);
  return (
    <>
    <div>
      <ReuseAbleHeader />
      <MainLayout>
        <ReuseAbleSidebar />
        <Container className="main-content">
          {/* <Row></Row> */}
          <HeaderWrapper>
            <div className="main-heading">
              <h1>Virtual Consultation / Live Consultation</h1>
              <p>Live Consultation</p>
            </div>
          </HeaderWrapper>
          <Row className="mmkkii">
            <Col style={{display:"none"}} className="colom1" xs={12} md={12}>
             <div>
                <img style={{width: "100%",height: "600px"}} src={img1}/>
                <div className="icons-dev">
                  <img className="icocns" src={icon1}/>
                  <img className="icocns" src={icon2}/>
                  <img className="icocns" src={icon3}/>
                  <img className="icocns" src={icon4}/>
                  <img className="icocns" src={icon5}/>
                </div>
              </div>
            </Col>
            <Col style={{display:"none"}} className="colom12" xs={8} md={8}>
              <div>
                <img style={{width: "100%",height: "600px"}} src={img1}/>
                <div className="icons-dev">
                  <img className="icocns" src={icon1}/>
                  <img className="icocns" src={icon2}/>
                  <img className="icocns" src={icon3}/>
                  <img className="icocns" src={icon4}/>
                  <img className="icocns" src={icon5}/>
                </div>
              </div>
            </Col>
            <Col xs={4} md={4}>  
                <div className="mmm-box">
                    <div className="main-box">
                        <img style={{width: "85px"}} src={Ellipse}/>
                        <div>
                            <h1 style={{fontSize: "27px",fontWeight: "bold"}}>Jane Doe</h1>
                            <p>For PRP Injections</p>
                        </div>
                    </div>
                    <SupportChat />
                </div>
            </Col>
          </Row>
        
        </Container>
      </MainLayout>
    </div>
    </>
  );
};

export default VirtualConsultation;
