import React, { useState } from "react";
import styled from "styled-components";
import ReuseAbleHeader from "../ReuseAbleHeader/ReuseAbleHeader";
import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";
import Container from "react-bootstrap/Container";
import TableComponent from "../TableComponent/TableComponent2";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BarChart from "../Chart/chart";
import img1 from "../../assets/Group 11447.png";
import img2 from "../../assets/Frame.png";
import { BiSearch } from "react-icons/bi";
import Accordion from "react-bootstrap/Accordion";

import InputField from "../../component/InputField/InputField";

const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;

  .acccorion-itemss {
    border-top: 1px solid #dbdbdc;
    border-bottom: 1px solid #dbdbdc;
    padding: 22px 0 18px;
    border-radius: 0;
  }

  .tabss-heading {
    padding: 24px !important;
    border: 1px solid #dbd7d1 !important;
  }
`;
const FrequestSearch = styled.div`
  display: flex;
  color: #a5a3a0;
  margin-top: 1.2rem;
  margin-left: 3.2rem;
  margin-bottom: 4.2rem;

  p {
    font-size: 1rem;
    margin-bottom: 0;
  }
  p:nth-child(1) {
    margin-right: 16px;
  }
  p:nth-child(2) {
    margin-right: 12px;
    text-decoration: underline;
    text-decoration-color: #a5a3a0;
  }
  p:nth-child(3) {
    margin-right: 12px;
    text-decoration: underline;
    text-decoration-color: #a5a3a0;
  }
  p:nth-child(4) {
    margin-right: 12px;
    text-decoration: underline;
    text-decoration-color: #a5a3a0;
  }
`;

const SearchWrapper = styled.div`
  background-color: #ffffff;
  padding: 18px;
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  border-radius: 14px;
  input {
    border: none;
  }
  input:focus {
    border: none;
    outline: none;
  }
  .icons {
    width: 1.8rem;
    height: 1.8rem;
    margin: 0 18px;
  }
`;

const Faqs = () => {
  return (
    <>
      <div>
        <ReuseAbleHeader />
        <MainLayout>
          <ReuseAbleSidebar />
          <div className="adminLayout d-flex justify-content-center w-100">
            <div className="content-dashboard admin-panel-content py-0 py-md-3 ps-0 pe-0 pe-md-4 py-md-5 w-100">
              <div className="content-body px-2 pe-md-5 mt-3">
                <div className="main-containerfaq py-3 px-md-3 py-md-5 d-non">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 col-lg-4">
                        <h2 className="mb-5">FAQ’s</h2>
                        <p>Quick answers to questions you may have.</p>
                      </div>
                      <div className="col-12 col-lg-8">
                        <SearchWrapper>
                          <input
                            type="text"
                            placeholder="Search for answers here..."
                          />
                          |
                          <BiSearch className="icons" />
                        </SearchWrapper>
                        <FrequestSearch>
                          <p>Frequent searches: </p>
                          <p>How to book appointments? </p>
                          <p>How to cancel appointments? </p>
                          <p>How to reschedule appointments? </p>
                        </FrequestSearch>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-4">
                        <div
                          className="nav nav-tabs mb-3 d-flex flex-md-column flex-row gap-3"
                          id="nav-tab"
                          role="tablist"
                        >
                          <button
                            className="nav-link active navbar-tab-heading-text tabss-heading"
                            id="nav-Personal-Information-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-Personal-Information"
                            type="button"
                            role="tab"
                            aria-controls="nav-Personal-Information"
                            aria-selected="true"
                          >
                            1. General Inquiries
                          </button>
                          <button
                            className="nav-link navbar-tab-heading-text tabss-heading"
                            id="nav-Account-Settings-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-Account-Settings"
                            type="button"
                            role="tab"
                            aria-controls="nav-Account-Settings"
                            aria-selected="false"
                          >
                            2. What payment methods do you need?
                          </button>
                          <button
                            className="nav-link navbar-tab-heading-text tabss-heading"
                            id="nav-Preference-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-Preference"
                            type="button"
                            role="tab"
                            aria-controls="nav-Preference"
                            aria-selected="false"
                          >
                            3. What if I have more questions?
                          </button>
                          <button
                            className="nav-link navbar-tab-heading-text tabss-heading"
                            id="nav-Preference-tab2"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-Preference2"
                            type="button"
                            role="tab"
                            aria-controls="nav-Preference2"
                            aria-selected="false"
                          >
                            4. About SKINCLUB site
                          </button>
                        </div>
                      </div>
                      <div className="col-12 col-lg-8">
                        <div
                          className="tab-content tabs-Faqs-data-container"
                          id="nav-tabContent"
                        >
                          <div
                            className="tab-pane fade active show"
                            id="nav-Personal-Information"
                            role="tabpanel"
                            aria-labelledby="nav-Personal-Information-tab"
                          >
                            <h2>General Inquiries</h2>
                            <p className="py-4">
                              New around here? start with the basics.
                            </p>
                            <Accordion defaultActiveKey={["0"]} alwaysOpen>
                              <Accordion.Item
                                eventKey="0"
                                className="acccorion-itemss"
                                style={{ borderTop: "none" }}
                              >
                                <Accordion.Header>
                                  What is question 1 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                className="acccorion-itemss"
                                eventKey="1"
                              >
                                <Accordion.Header>
                                  What is question 2 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                className="acccorion-itemss"
                                eventKey="2"
                              >
                                <Accordion.Header>
                                  What is question 3 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                className="acccorion-itemss"
                                eventKey="3"
                              >
                                <Accordion.Header>
                                  What is question 4 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                className="acccorion-itemss"
                                eventKey="4"
                              >
                                <Accordion.Header>
                                  What is question 5 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                className="acccorion-itemss"
                                eventKey="5"
                              >
                                <Accordion.Header>
                                  What is question 6 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                className="acccorion-itemss"
                                eventKey="6"
                                style={{ borderBottom: "none" }}
                              >
                                <Accordion.Header>
                                  What is question 7 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="nav-Account-Settings"
                            role="tabpanel"
                            aria-labelledby="nav-Account-Settings-tab"
                          >
                            <h2>What payment methods do you need?</h2>
                            <p className="py-4">
                              New around here? start with the basics.
                            </p>
                            <Accordion defaultActiveKey={["0"]} alwaysOpen>
                              <Accordion.Item
                                eventKey="0"
                                className="acccorion-itemss"
                                style={{ borderTop: "none" }}
                              >
                                <Accordion.Header>
                                  What is question 1 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                className="acccorion-itemss"
                                eventKey="1"
                              >
                                <Accordion.Header>
                                  What is question 2 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                className="acccorion-itemss"
                                eventKey="2"
                              >
                                <Accordion.Header>
                                  What is question 3 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                className="acccorion-itemss"
                                eventKey="3"
                              >
                                <Accordion.Header>
                                  What is question 4 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                className="acccorion-itemss"
                                eventKey="4"
                              >
                                <Accordion.Header>
                                  What is question 5 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                className="acccorion-itemss"
                                eventKey="5"
                              >
                                <Accordion.Header>
                                  What is question 6 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                className="acccorion-itemss"
                                eventKey="6"
                                style={{ borderBottom: "none" }}
                              >
                                <Accordion.Header>
                                  What is question 7 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="nav-Preference"
                            role="tabpanel"
                            aria-labelledby="nav-Preference-tab"
                          >
                            <h2>What if I have more questions?</h2>
                            <p className="py-4">
                              New around here? start with the basics.
                            </p>
                            <Accordion defaultActiveKey={["0"]} alwaysOpen>
                              <Accordion.Item
                                eventKey="0"
                                className="acccorion-itemss"
                                style={{ borderTop: "none" }}
                              >
                                <Accordion.Header>
                                  What is question 1 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                className="acccorion-itemss"
                                eventKey="1"
                              >
                                <Accordion.Header>
                                  What is question 2 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                className="acccorion-itemss"
                                eventKey="2"
                              >
                                <Accordion.Header>
                                  What is question 3 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                className="acccorion-itemss"
                                eventKey="3"
                              >
                                <Accordion.Header>
                                  What is question 4 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                className="acccorion-itemss"
                                eventKey="4"
                              >
                                <Accordion.Header>
                                  What is question 5 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                className="acccorion-itemss"
                                eventKey="5"
                              >
                                <Accordion.Header>
                                  What is question 6 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                className="acccorion-itemss"
                                eventKey="6"
                                style={{ borderBottom: "none" }}
                              >
                                <Accordion.Header>
                                  What is question 7 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="nav-Preference2"
                            role="tabpanel"
                            aria-labelledby="nav-Preference-tab2"
                          >
                            <h2>About SKINCLUB site</h2>
                            <p className="py-4">
                              New around here? start with the basics.
                            </p>
                            <Accordion defaultActiveKey={["0"]} alwaysOpen>
                              <Accordion.Item
                                eventKey="0"
                                className="acccorion-itemss"
                                style={{ borderTop: "none" }}
                              >
                                <Accordion.Header>
                                  What is question 1 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                className="acccorion-itemss"
                                eventKey="1"
                              >
                                <Accordion.Header>
                                  What is question 2 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                className="acccorion-itemss"
                                eventKey="2"
                              >
                                <Accordion.Header>
                                  What is question 3 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                className="acccorion-itemss"
                                eventKey="3"
                              >
                                <Accordion.Header>
                                  What is question 4 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                className="acccorion-itemss"
                                eventKey="4"
                              >
                                <Accordion.Header>
                                  What is question 5 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                className="acccorion-itemss"
                                eventKey="5"
                              >
                                <Accordion.Header>
                                  What is question 6 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                className="acccorion-itemss"
                                eventKey="6"
                                style={{ borderBottom: "none" }}
                              >
                                <Accordion.Header>
                                  What is question 7 ?
                                </Accordion.Header>
                                <Accordion.Body>
                                  Everyone in your agency knows exactly what’s
                                  going on with every project from start to
                                  close. You’ll stop running in circles with
                                  your team and clients, and finally feel like
                                  you’ve got it together. Everyone in your
                                  agency knows exactly what’s going on with
                                  every project from start to close. You’ll stop
                                  running in circles with your team and clients,
                                  and finally feel like you’ve got it together.
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MainLayout>
      </div>
    </>
  );
};

export default Faqs;
