import React from "react";
import ToorakConsultation from "../component/ToorakConsultation/ToorakConsultation";

const ToorakConsultationPage = () => {
  return (
    <div>
      <ToorakConsultation />
    </div>
  );
};

export default ToorakConsultationPage;
