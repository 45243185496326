import React from "react";
import { useTable } from "react-table";
import styled from "styled-components";
import ShareIcon from "@mui/icons-material/Share";
import pic from "../../assets/image 4.png";
const Table = styled.table`
  border-radius: 0 0 14px 14px;
  // box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5)
  overflow: hidden;
  width: 100%;
  // border: 1px solid red;
  border-collapse: collapse;
  min-width: 700px;
  color: #fff;
  th,
  td {
    padding: 8px;
    text-align: left;
    // background-color: red;
    font-size: 12px;
    font-weight: 400;
    color: #4c4d4f;
    background-color: #f8f8f8;

    padding: 1.4rem 2.1rem;
    // border-radius: 0px 0px 14px 14px;
  }

  th {
    background-color: #fff;
    font-size: 15px;
    padding: 1.4rem 2.1rem;
    font-weight: 400;
    color: #4c4d4f;
    // border-radius: 14px 14px 0px 0px;
  }
  tbody tr:nth-last-child {
  }
  tbody tr:nth-child(even) {
    // background-color: #f9f9f9;
    // background-color: #e5e5e5;
    padding-bottom: 20px;
  }
  tbody tr {
    // background-color: #f9f9f9;
    // background-color: #e5e5e5;
    font-size: 1.8rem;
    border-bottom: 1px solid #d6d6d6;
    margin: 0 20px;
  }
  // tbody tr:nth-last-child {
  //   // background-color: #f9f9f9;
  //   // background-color: #e5e5e5;
  //   font-size: 1.8rem;
  //   border-bottom: 1px solid #d6d6d6;
  //   margin: 0 20px;
  // }
  .removebtnstyle {
    border: none;
    background: #f8f8f8;
  }
  .pending-button {
    background-color: #36c490;
    color: #fff;
    border: none;
    border-radius: 16px;
    font-size: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .image-section {
    width: 11%;
  }
`;

const TableComponent2 = ({
  columns,
  data,
  activePage,
  totalItemsCount,
  itemsCountPerPage,
  onChange,
  isDisplayPagnation = true,
  imageColumn, // Specify the image column
  imageColumn1,
  imageColumn2,
  onClick,
  onClick1,
  btntobe = false,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <>
      <Table style={{ borderRadius: "10px 10px 0px 0px" }} {...getTableProps()}>
        <thead>
          {headerGroups?.map((headerGroup) => (
            <tr {...headerGroup?.getHeaderGroupProps()}>
              {headerGroup.headers?.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows?.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row?.cells?.map((cell, cellIndex) => (
                  <td
                    {...cell?.getCellProps()}
                    className={
                      cell.column.id === imageColumn ? "image-section" : ""
                    }
                  >
                    {/* Check if the current column is the image column and render an image */}
                    {cell.column.id === imageColumn ? (
                      <img
                        style={{ width: "100%" }}
                        src={pic}
                        alt="Thumbnail"
                      />
                    ) : cell.column.id === "column7" ? (
                      <div className="pending-cell">
                        <button
                          className={
                            btntobe ? "removebtnstyle" : "pending-button"
                          }
                        >
                          {cell.value}
                        </button>
                      </div>
                    ) : cell.column.id === "column8" ? (
                      <div className="pending-cell">
                        {btntobe ? (
                          <>{cell?.value}</>
                        ) : (
                          <ShareIcon
                            style={{ cursor: "pointer" }}
                            onClick={onClick}
                          />
                        )}
                      </div>
                    ) : cell.column.id === imageColumn2 ? (
                      <div onClick={onClick} style={{cursor:"pointer"}} className="pending-cell">
                        {cell?.value}
                      </div>
                    ): cell.column.id === imageColumn1 ? (
                      <div className="pending-cell">
                        <div style={{ cursor: "pointer" }} onClick={onClick}>
                          {cell.value}
                        </div>
                      </div>
                    ) : (
                      cell.render("Cell")
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default TableComponent2;
