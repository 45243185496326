import React, { useState } from "react";
import styled from "styled-components";
import ReuseAbleHeader from "../ReuseAbleHeader/ReuseAbleHeader";
import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TableComponent from "../TableComponent/TableComponent";




const TopBar = styled.div`
  // height: 80px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 1rem 2.2rem;
  padding: 1rem 0;
`;

const TopBarMobile = styled.div`
  // height: 20vh;
  // background: red;
  background: #ffffff;
  padding: 2.5rem 3.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 40px;
  }
  .logo-img {
    width: 200px;
  }
`;

const Logo = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-left: 30px;
  img {
    width: 13.6rem;
  }
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;
`;

const DateAndTime = styled.div`
  font-size: 14px;
  color: #4c4d4f;
  line-height: 1.5;
  font-weight: 400;
  // margin-right: 30px;
`;

const NotificationContainer = styled.div`
  width: 48px;
  height: 48px;
  background: #f8f8f8;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;


const NavLink = styled.a`
  text-decoration: none;
  color: black;

  &:hover {
    color: black;
  }
`;


const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
`;
const Table2Wrapperr = styled.div`

  // display: flex;
  // flex: 1;
  // background: #fff9f1;
  padding: 22px 28px;
  background: #fff;
  border-radius: 0 0 14px 14px;

  overflow-y: auto;

  .add-border {
    border: 1px solid #d6d6d6;
    border-radius: 14px;
    overflow: auto;
  }
`;

const TableWrapperr = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
  border-bottom: 1px solid #dbdbdc;
  padding: 2.1rem 2.7rem;
  background-color: #fff;
  border-radius: 14px 14px 0 0;

  h1 {
  }
`;

const columns = [
  { Header: "Surgery Name", accessor: "column1" },
  { Header: "Date", accessor: "column2" },
  { Header: "Treatment", accessor: "column3" },
  { Header: "Doctor name", accessor: "column4" },
  { Header: "Atter case ", accessor: "column5" },
];

// const data = isdata.map((tableItem) => ({
const data = [
  {
    column1: "Anti wrinkle Section",
    column2: "14/08/2023",
    column3: "PRP Injections",
    column4: "Dr. Hasham Ahmad",
    column5: "View",
  },
  {
    column1: "Anti wrinkle Section",
    column2: "14/08/2023",
    column3: "PRP Injections",
    column4: "Dr. Hasham Ahmad",
    column5: "View",
  },
];

const AfterCare = () => {
  const [isdata, setIsData] = useState(data);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  return (
    <div>
      <ReuseAbleHeader />
      
      <MainLayout>
        <ReuseAbleSidebar />
        <Container className="main-content">
          <Row>
            <Col xl={12} xs={12}>
              <h1 className="after-care-heading">After care </h1>
              <TableWrapperr>
                <h1 className="after-treatment-heading">Treatment History</h1>
              </TableWrapperr>
              <Table2Wrapperr>
                <div className="add-border">
                  <TableComponent columns={columns} data={isdata} />
                </div>
              </Table2Wrapperr>
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </div>
  );
};

export default AfterCare;
