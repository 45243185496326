import React from "react";
import styled from "styled-components";
import avatar from "../../assets/avatarrr.png";
import recent1 from "../../assets/recent1.png";
import recent2 from "../../assets/recent2.png";
import recent3 from "../../assets/recent3.png";
import recent4 from "../../assets/recent4.png";

import chatbtn from "../../assets/chatbtn.png";

const RecentWrapper = styled.div`
  padding: 28px 20px 15px;
  background-color: #fff;
  border-radius: 14px;
  .recent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.8rem;
    p {
      font-size: 1.4rem;
      color: #000;
      font-weight: 400;
      margin: 0;
    }
  }
  .content {
    display: flex;
    gap: 7px;
    // justify-content: space-between;
    align-items: center;

    .wrapper {
      display: flex;
      justify-content: space-between;
      // align-items: center;

      flex-direction: column;

      .one {
        font-size: 1.2rem;
        color: #4c4d4f;
        margin: 0;
      }

      .two {
        font-size: 1.2rem;
        margin: 0;
        color: #a5a6a7;
      }
    }
  }
`;
const CustomerSupportActivities = () => {
  return (
    <RecentWrapper>
      <div className="recent">
        <p>Customer Support</p>
        <p>view all</p>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "26px",
        }}
      >
        <div className="content">
          <div
            style={{
              padding: "10px",
              backgroundColor: "#F8F8F8",
              borderRadius: "14px",
            }}
          >
            <img src={avatar} style={{ width: "24px", height: "24px" }} />
          </div>
          <div className="wrapper">
            <p className="one">A person reported a problem.</p>
            <p className="two">2 sec ago</p>
          </div>
        </div>
        <img src={chatbtn} style={{ width: "71px", height: "25px" }} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "26px",
        }}
      >
        <div className="content">
          <div
            style={{
              padding: "10px",
              backgroundColor: "#F8F8F8",
              borderRadius: "14px",
            }}
          >
            <img src={avatar} style={{ width: "24px", height: "24px" }} />
          </div>
          <div className="wrapper">
            <p className="one">A person Needs Help.</p>
            <p className="two">2 sec ago</p>
          </div>
        </div>
        <img src={chatbtn} style={{ width: "71px", height: "25px" }} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="content">
          <div
            style={{
              padding: "10px",
              backgroundColor: "#F8F8F8",
              borderRadius: "14px",
            }}
          >
            <img src={avatar} style={{ width: "24px", height: "24px" }} />
          </div>
          <div className="wrapper">
            <p className="one">A feedback left by a person.</p>
            <p className="two">2 sec ago</p>
          </div>
        </div>
        <img src={chatbtn} style={{ width: "71px", height: "25px" }} />
      </div>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "26px",
        }}
      >
        <div className="content">
          <div
            style={{
              padding: "10px",
              backgroundColor: "#F8F8F8",
              borderRadius: "14px",
            }}
          >
            <img src={avatar} style={{ width: "24px", height: "24px" }} />
          </div>
          <div className="wrapper">
            <p className="one">A person reported a problem.</p>
            <p className="two">2 sec ago</p>
          </div>
        </div>
        <img src={chatbtn} style={{ width: "71px", height: "25px" }} />
      </div> */}
    </RecentWrapper>
  );
};

export default CustomerSupportActivities;
