import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import NotFound from "../component/NotFund/NotFound";
import Signin from "../component/Signin/Signin";
import Signup from "../pages/Signup";
import Skin from "../pages/Skin";
import Login from "../pages/Login";
import ForgetPassword from "../pages/ForgetPassword";
import ResetPassword from "../pages/ResetPassword";

import ToorakPage from "../pages/ToorakPage";
import ToorakPage2 from "../pages/ToorakPage2";
import ToorakPage3 from "../pages/ToorakPage3";
import ToorakPage4 from "../pages/ToorakPage4";
import ToorakConsultationPage from "../pages/ToorakConsultationPage";
import AfterCarePage from "../pages/AfterCarePage";

import PahranAppointment from "../component/PahranAppointment/PahranAppointment";
import BrightonAppointment from "../component/BrightonAppointment/BrightonAppointment";

import PahranVerification from "../component/PahranVerification/PahranVerification";
import BrightonVerification from "../component/BrightonVerification/BrightonVerification";

import PrahanConsultancy from "../component/PrahanConsultancy/PrahanConsultancy";
import BrightonConsultancy from "../component/BrightonConsultancy/BrightonConsultancy";

import PrahanConsultaionEnd from "../component/PrahanConsultaionEnd/PrahanConsultaionEnd";
import BrightoConsultaionEnd from "../component/BrightoConsultaionEnd/BrightoConsultaionEnd";

import AfterCarePdf from "../component/AfterCarePdf/AfterCarePdf";

import PatientDashboard from "../component/PatientDashboard/PatientDashboard";

import PrpPage from "../component/PrPPage/PrpPage";

import InventoryManagementPage from "../pages/InventoryManagement";
import InvoiceListPage from "../pages/InvoiceListPage";

import AfterCareForm from "../pages/AfterCareForm";

// import CreateInvoice from "../component/CreateInvoice/CreateInvoiceComponent";

import CreateInvoice from "../component/CreateInvoice/CreateInvoiceComponent";

import TreatmentTracking from "../component/TreatmentTracking/TreatmentTracking";

import ShoppingInterface from "../component/ShoppingInterface/ShoppingInterface";

import Rewardpoint from "../component/Rewardpoint/Rewardpoint";

import CustomerSurvey1 from "../pages/customersurvay1";

import AdminActiveCareFroms from "../pages/Admin/AdminActiveCareFroms";
import AdminTreatments from "../pages/Admin/AdminTreatments";
import AdminProductsPage from "../pages/Admin/AdminProducts";
import AdminProfileManagementPage from "../pages/Admin/AdminProfileManagmentPage";

import CreateInvoiceTemplete from "../pages/CreateInvoiceTemplete";

import EditInvoice from "../pages/EditInvoice";

import CustomerSurvey2 from "../pages/CustomerSurvey2";

import SpeechToText from "../component/SpeechToText/SpeechToText";

import TextToSpeech from "../component/TextToSpeech/TextToSpeech";
import UserManagementDashboard from "../component/UserManagementDashboard/UserManagementDashboard";

import PaymentProcessComponent from "../component/PaymentProcessComponent/PaymentProcessComponent";

import UserManagementDashboardWithAppointment from "../component/UserManagementDashboardWithAppointment/UserManagementDashboardWithAppointment";
import UserManagementDashboardWithTransaction from "../component/UserManagementDashboardWithTransaction/UserManagementDashboardWithTransaction";

import UserManagementDashboardWithinventry from "../component/UserManagementDashboardWithinventry/UserManagementDashboardWithinventry";

import UserManagementDashboardWithPatient from "../component/UserManagementDashboardWithPatient/UserManagementDashboardWithPatient";

import UserManagementDashboardWithDoctorPerformance from "../component/UserManagementDashboardWithDoctorPerformance/UserManagementDashboardWithDoctorPerformance";

import RewardManagement from "../component/RewardManagement/RewardManagement";

import VirtualConsultation from "../component/VirtualConsultation/VirtualConsultation";

import AdminUserManagement from "../component/AdminUserManagement/AdminUserManagement";

import SupportChat from "../pages/SupportChat";

import VideoLibrary from "../component/VideoLibrary/VideoLibrary";
import VideoLibrary1 from "../component/VideoLibrary1/VideoLibrary1";
import PatientManagement from "../component/PatientManagement/PatientManagement";

import Overview from "../component/Overview/Overview";
import Faqs from "../component/Faqs/Faqs";

const Routers = () => {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Navigate to="/login" />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/appointment" element={<Skin />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/forgetpassword" element={<ForgetPassword />} />
        <Route exact path="/resetpassword" element={<ResetPassword />} />
        <Route exact path="/apppointment-toorak" element={<ToorakPage />} />
        <Route exact path="/toorak-verification" element={<ToorakPage2 />} />
        <Route exact path="/praham" element={<ToorakPage3 />} />
        <Route exact path="/ToorakPage4" element={<ToorakPage4 />} />
        <Route exact path="/aftercarepage" element={<AfterCarePage />} />
        <Route
          exact
          path="/toorak-consultation"
          element={<ToorakConsultationPage />}
        />
        <Route path="*" element={<NotFound />} />
        <Route path="prahan-appointment" element={<PahranAppointment />} />
        <Route path="brighton-appointment" element={<BrightonAppointment />} />

        <Route path="prahan-verification" element={<PahranVerification />} />
        <Route
          path="brighton-verification"
          element={<BrightonVerification />}
        />

        <Route path="prahan-consultancy" element={<PrahanConsultancy />} />
        <Route path="brighton-consultancy" element={<BrightonConsultancy />} />

        <Route path="prahan-consultant" element={<PrahanConsultaionEnd />} />
        <Route path="brighto-consultant" element={<BrightoConsultaionEnd />} />

        <Route path="after-care-pdf" element={<AfterCarePdf />} />

        <Route path="patient-dashboard" element={<PatientDashboard />} />
        <Route path="Prp-Injections" element={<PrpPage />} />

        <Route
          exact
          path="/inventorymanagement"
          element={<InventoryManagementPage />}
        />
        <Route exact path="/invoicelist" element={<InvoiceListPage />} />
        <Route exact path="/aftercareform" element={<AfterCareForm />} />
        <Route exact path="/createinvoice" element={<CreateInvoice />} />
        <Route
          exact
          path="/treatmenttracking"
          element={<TreatmentTracking />}
        />
        <Route
          exact
          path="/shoppinginterface"
          element={<ShoppingInterface />}
        />
        <Route exact path="/rewardpoint" element={<Rewardpoint />} />
        <Route exact path="/customersurvey1" element={<CustomerSurvey1 />} />

        <Route
          path="/admin/ActiveCareFroms"
          element={<AdminActiveCareFroms />}
        />
        <Route path="/admin/AdminTreatments" element={<AdminTreatments />} />
        <Route
          path="/admin/AdminProductsPage"
          element={<AdminProductsPage />}
        />
        <Route
          path="/admin/AdminProfileManagementPage"
          element={<AdminProfileManagementPage />}
        />

        <Route
          path="createinvoicetemplete"
          element={<CreateInvoiceTemplete />}
        />
        <Route path="editinvoice" element={<EditInvoice />} />

        <Route exact path="/customer-survery-2" element={<CustomerSurvey2 />} />
        <Route exact path="/speechtotext" element={<SpeechToText />} />
        <Route exact path="/texttospeech" element={<TextToSpeech />} />
        <Route
          exact
          path="/virtualconsultation"
          element={<VirtualConsultation />}
        />

        <Route
          exact
          path="/adminusermanagement"
          element={<AdminUserManagement />}
        />
        <Route exact path="/supportchat" element={<SupportChat />} />
        <Route exact path="/videolibraryyoutube" element={<VideoLibrary />} />
        <Route exact path="/videolibrarytiktok" element={<VideoLibrary1 />} />
        <Route exact path="/overview" element={<Overview />} />

        <Route
          exact
          path="/usermanagementdashboard"
          element={<UserManagementDashboard />}
        />

        <Route
          exact
          path="/paymentprocesscomponent"
          element={<PaymentProcessComponent />}
        />

        <Route
          exact
          path="/userdashboardwithappointment"
          element={<UserManagementDashboardWithAppointment />}
        />
        <Route
          exact
          path="/userdashboardwithtransaction"
          element={<UserManagementDashboardWithTransaction />}
        />
        <Route
          exact
          path="/userdashboardwithinventry"
          element={<UserManagementDashboardWithinventry />}
        />
        <Route
          exact
          path="/userdashboardwithpatient"
          element={<UserManagementDashboardWithPatient />}
        />
        <Route
          exact
          path="/userdashboardwithdoctorperformance"
          element={<UserManagementDashboardWithDoctorPerformance />}
        />
        <Route exact path="/rewardmanagement" element={<RewardManagement />} />
        <Route
          exact
          path="/patientmanagement"
          element={<PatientManagement />}
        />
        <Route exact path="/faqs" element={<Faqs />} />
      </Routes>
    </>
  );
};

export default Routers;
