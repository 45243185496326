import React from "react";
// import ToorakConsultation from "../component/ToorakConsultation/ToorakConsultation";
import AfterCare from "../component/AfterCare/AfterCare";

const AfterCarePage = () => {
  return (
    <div>
      <AfterCare />
    </div>
  );
};

export default AfterCarePage;
