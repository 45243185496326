import * as React from "react";
import IconButton from "@mui/material/IconButton";
import { Menu as MenuIconss } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { LiaEditSolid } from "react-icons/lia";
import {
  AiOutlineInfoCircle,
  AiOutlineExport,
  AiOutlineEdit,
} from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";

const options = [
  <p style={{ marginBottom: "0" }}>
    <AiOutlineExport style={{ marginRight: "8px" }} />
    Treatment Plan
  </p>,
  <p style={{ marginBottom: "0" }}>
    <AiOutlineEdit style={{ marginRight: "8px" }} />
    Edit Details
  </p>,
  <p style={{ marginBottom: "0" }}>
    <RiDeleteBin5Line style={{ marginRight: "8px" }} />
    Remove
  </p>,
];

const ITEM_HEIGHT = 48;

export default function MenuInventry() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <MenuIconss
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            selected={option === "Pyxis"}
            onClick={handleClose}
          >
            {option}
          </MenuItem>
        ))}
      </MenuIconss>
    </div>
  );
}
