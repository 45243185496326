import React, { useState } from "react";
import styled from "styled-components";
import RxCaretDown from "react-icons/rx";
import LogoDashboard from "../../assets/logo-dashboard.png";
import BellIcon from "../../assets/bellicons.png";
import AllCollapseExample from "../Accordion/Accordion";
import { Accordion } from "react-bootstrap";

import MobileLogo from "../../assets/tempLogo.png";
import MobileNotofication from "../../assets/mobilenotification.png";
import MobileToggle from "../../assets/mobiletoggle.png";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GenericDrawer from "../Drawer/Drawer";

import Toorak22 from "../../assets/toorak2.png";
import { NavLink as AliadNavLink } from "react-router-dom";

const PageContainer = styled.div`
  font-family: "Cabin", sans-serif;
  height: 100vh;
  background-color: #fff9f1;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  // height: 80px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 1rem 2.2rem;
  padding: 1rem 0;
`;

const TopBarMobile = styled.div`
  // height: 20vh;
  // background: red;
  background: #ffffff;
  padding: 2.5rem 3.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 40px;
  }
  .logo-img {
    width: 200px;
  }
`;

const Logo = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-left: 30px;
  img {
    width: 13.6rem;
  }
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;
`;

const DateAndTime = styled.div`
  font-size: 14px;
  color: #4c4d4f;
  line-height: 1.5;
  font-weight: 400;
  // margin-right: 30px;
`;

const NotificationContainer = styled.div`
  width: 48px;
  height: 48px;
  background: #f8f8f8;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;

const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
`;

const Sidebar = styled.div`
  background: #ffffff;
  min-width: 240px;
  min-height: 100vh;
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 20px;
`;

const Navigation = styled.ul`
  padding: 0;
`;

const ListItem = styled.li`
  list-style: none;
  padding: 14px 0 14px 20px;
  border-radius: 14px;
  font-size: 1.8rem;

  line-height: 1.2;
`;

const NavLink = styled.a`
  text-decoration: none;
  color: black;

  &:hover {
    color: black;
  }
`;

const ActiveLink = styled(NavLink)`
  font-weight: bold;
`;

const AccordionButton = styled.button`
  padding: 0;
  font-size: 1.8rem;
  line-height: 1.2;

  &.collapsed {
    box-shadow: none;
  }
`;

const Sublink = styled.div`
  margin-top: 15px;
`;

const SublinkText = styled.a`
  font-weight: 400;
  font-size: 14px;
  color: #4c4d4f;
  padding-left: 12px;
`;

const MainSection = styled.div`
  // margin-left: 30px;
  // margin-top: 20px;
  // padding-right: 20px;
  margin-bottom: 5.6rem;
`;

const AppointmentHeading = styled.div`
  font-size: 30px;
  color: #4c4d4f;
  margin-bottom: 1.2rem;
  font-weight: 400;

  .sub-toorak {
    font-size: 23px;
    color: #828180;
  }
`;

const AppointmentText = styled.div`
  font-size: 20px;
  line-height: 1, 5;
  color: rgba(76, 77, 79, 0.85);
`;

const AppointmentButton = styled.div`
  // margin-top: 15px;
  // background: rgba(242, 236, 217, 0.6);
  // border-radius: 5px;
  // height: 42px;
  // font-size: 18px;
  // color: rgba(0, 0, 0, 0.5);
  // padding: 0 20px;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  // cursor: pointer;
  // width: 100%;

  .detail {
    margin-top: 20px;

    h3 {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.2;
      color: #000;
      margin-left: 0.4rem;
    }
    input {
      margin-left: 0.8rem;

      font-size: 1.1rem;
      font-weight: 400;
      line-height: 1.2;
      // color: #000;
    }
  }
  input {
    border-left: none;
    border-right: none;
    border-top: none;
    background: transparent;
    height: 30px;
    width: 100%;
  }
  input:focus {
    outline: none;
  }
  button {
    width: 90%;
    background-color: #252222;
    color: #fff;
    border-radius: 15px;
    height: 38px;
    border: transparent;
    margin-top: 38px;
  }
`;

const MapSection = styled.div`
  img {
    width: 92%;
    height: 700px;
    padding-left: 56px;
    padding-top: 25px;
  }
  @media (max-width: 900px) {
    img {
      padding-left: 0px;
    }
  }
  @media (max-width: 768px) {
    img {
      padding-left: 0;
      padding-top: 0;
      height: 563px;
      width: 100%;
    }
  }
`;

const AppointmentPara = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: #4c4d4f80;
  margin-top: 11px;
`;

const AppointmentText1 = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  // padding-left: 40%;
  text-align: center;
  margin-top: 21px;
  cursor: pointer;
`;
const AppointmentText2 = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  // padding-left: 28%;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
  text-decoration-line: underline;
`;

const Toorak2 = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(0);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  return (
    <>
      <PageContainer>
        <TopBar className="desktop-header">
          <Logo>
            <img src={LogoDashboard} />
          </Logo>
          <RightSide>
            <DateAndTime style={{ marginRight: "56px" }}>
              Sunday, 20 August
            </DateAndTime>
            <DateAndTime style={{ marginRight: "41px" }}>1:50 PM</DateAndTime>
            <NotificationContainer>
              <img src={BellIcon} style={{ width: "2.4rem" }} />
            </NotificationContainer>
          </RightSide>
        </TopBar>
        <GenericDrawer open={isDrawerOpen} onClose={toggleDrawer} />
        <TopBarMobile className="mobile-header">
          <img src={MobileToggle} onClick={toggleDrawer} />
          <img src={MobileLogo} className="logo-img" />
          <img src={MobileNotofication} />
        </TopBarMobile>

        <MainLayout>
          <Sidebar className="sidear-for-desktop">
            <Navigation>
              <ListItem
                style={{
                  backgroundColor: activeItem === 0 ? "#FFF9F1" : "transparent",
                  fontWeight: activeItem === 0 ? "bold" : "",
                }}
                onClick={() => setActiveItem(0)}
              >
                <AliadNavLink to="/patient-dashboard">
                  <NavLink href="/patient-dashboard">Overview</NavLink>
                </AliadNavLink>
              </ListItem>
              <ListItem
                style={{
                  backgroundColor: activeItem === 1 ? "#FFF9F1" : "transparent",
                  fontWeight: activeItem === 1 ? "bold" : "",
                }}
                onClick={() => setActiveItem(1)}
              >
                {/* <AliadNavLink to="/patient-dashboard"> */}
                <NavLink href="#">Profile</NavLink>
                {/* </AliadNavLink> */}
              </ListItem>
              <ListItem
                style={{
                  backgroundColor: activeItem === 2 ? "#FFF9F1" : "transparent",
                  fontWeight: activeItem === 2 ? "bold" : "",
                }}
                onClick={() => setActiveItem(2)}
              >
                <AliadNavLink to="/appointment">
                  <NavLink href="#">Appointments</NavLink>
                </AliadNavLink>
              </ListItem>
              <ListItem style={{ padding: 0 }}>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header
                      style={{
                        backgroundColor: activeItem === 3 ? "#FFF9F1" : "",
                        fontWeight: activeItem === 3 ? "bold" : "",
                        padding: "14px 0 14px 20px",
                        borderRadius: "14px",
                        color: "#000",
                      }}
                      onClick={() => setActiveItem(3)}
                    >
                      History
                    </Accordion.Header>
                    <Accordion.Body>
                      <Sublink>
                        <AliadNavLink to="/videolibrarytiktok">
                          <SublinkText href="#">Videos</SublinkText>
                        </AliadNavLink>
                      </Sublink>
                      <Sublink>
                        <AliadNavLink to="/videolibraryyoutube">
                          <SublinkText href="#">Videos Library</SublinkText>
                        </AliadNavLink>
                      </Sublink>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </ListItem>
              <ListItem
                style={{
                  backgroundColor: activeItem === 4 ? "#FFF9F1" : "transparent",
                  fontWeight: activeItem === 4 ? "bold" : "",
                }}
                onClick={() => setActiveItem(4)}
              >
                <AliadNavLink to="/supportchat">
                  <NavLink href="#">Messages</NavLink>
                </AliadNavLink>
              </ListItem>
              <ListItem style={{ padding: 0 }}>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header
                      style={{
                        backgroundColor: activeItem === 10 ? "#FFF9F1" : "",
                        fontWeight: activeItem === 10 ? "bold" : "",
                        padding: "14px 0 14px 20px",
                        borderRadius: "14px",
                        color: "#000",
                      }}
                      onClick={() => setActiveItem(10)}
                    >
                      Support
                    </Accordion.Header>
                    <Accordion.Body>
                      <Sublink>
                        <AliadNavLink to="/faqs">
                          <SublinkText href="#">FAQ’s</SublinkText>
                        </AliadNavLink>
                      </Sublink>
                      <Sublink>
                        <AliadNavLink to="/customersurvey1">
                          <SublinkText href="#">
                            Procedure Information
                          </SublinkText>
                        </AliadNavLink>
                      </Sublink>
                      {/* </div>  */}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </ListItem>
              <ListItem style={{ padding: 0 }}>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header
                      style={{
                        backgroundColor: activeItem === 13 ? "#FFF9F1" : "",
                        fontWeight: activeItem === 13 ? "bold" : "",
                        padding: "14px 0 14px 20px",
                        borderRadius: "14px",
                        color: "#000",
                      }}
                      onClick={() => setActiveItem(13)}
                    >
                      Aftercare
                    </Accordion.Header>

                    <Accordion.Body>
                      <Sublink>
                        <AliadNavLink to="/aftercarepage">
                          <SublinkText>Quizzes</SublinkText>
                        </AliadNavLink>
                      </Sublink>
                      <Sublink>
                        <AliadNavLink to="/aftercarepage">
                          <SublinkText>Health & Skin Assessment</SublinkText>
                        </AliadNavLink>
                      </Sublink>
                      {/* </div>  */}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </ListItem>
              <ListItem
                style={{
                  backgroundColor: activeItem === 5 ? "#FFF9F1" : "transparent",
                  fontWeight: activeItem === 5 ? "bold" : "",
                }}
                onClick={() => setActiveItem(5)}
              >
                {/* <AliadNavLink to="/patient-dashboard"> */}
                <NavLink href="#">Finances</NavLink>
                {/* </AliadNavLink> */}
              </ListItem>
              <ListItem
                style={{
                  backgroundColor: activeItem === 6 ? "#FFF9F1" : "transparent",
                  fontWeight: activeItem === 6 ? "bold" : "",
                }}
                onClick={() => setActiveItem(6)}
              >
                <AliadNavLink to="/virtualconsultation">
                  <NavLink href="#">Virtual Consultation</NavLink>
                </AliadNavLink>
              </ListItem>
            </Navigation>
          </Sidebar>
          <Container className="main-content">
            <Row>
              <Col xl={6} xs={12}>
                <div className="">
                  <MainSection>
                    <MainSection>
                      <AppointmentHeading>
                        Appointments{" "}
                        <span className="sub-toorak">/ Toorak</span>{" "}
                      </AppointmentHeading>
                      <AppointmentText>Enter verification code</AppointmentText>
                      <AppointmentPara>
                        Enter the 6 digit code sent to xxx-xxx-xxxx. This may
                        take a moment
                      </AppointmentPara>
                      <AppointmentButton>
                        <div className="detail">
                          <h3 htmlFor="code">Verification Code</h3>
                          <input
                            type="code"
                            id="code"
                            name="code"
                            placeholder="Enter verification code"
                          />
                        </div>
                      </AppointmentButton>
                      <AppointmentButton style={{ textAlign: "center" }}>
                        <div>
                          <AliadNavLink to="/praham">
                            <button>VERIFY</button>
                          </AliadNavLink>
                        </div>
                      </AppointmentButton>
                      <AppointmentText1>OR</AppointmentText1>
                      <AppointmentText2>Continue as a Guest</AppointmentText2>
                    </MainSection>
                  </MainSection>
                </div>
              </Col>
              <Col xl={6} xs={12}>
                <div className="">
                  <MapSection>
                    <img src={Toorak22} />
                  </MapSection>
                </div>
              </Col>
            </Row>
          </Container>
        </MainLayout>
      </PageContainer>
    </>
  );
};

export default Toorak2;
