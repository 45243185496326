import React, { useState } from "react";
import styled from "styled-components";
import ReuseAbleHeader from "../ReuseAbleHeader/ReuseAbleHeader";
import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import OngoingTreatmentCard from "../OngoingTreatmentCard/OngoingTreatmentCard";
import UpcomingAppointmentCard from "../UpcomingAppointmentCard/UpcomingAppointmentCard";
import FormControl from "@mui/material/FormControl"; // Import FormControl from Material-UI
import InputLabel from "@mui/material/InputLabel"; // Import InputLabel from Material-UI
import Select from "@mui/material/Select"; // Import Select from Material-UI
import MenuItem from "@mui/material/MenuItem"; // Import MenuItem from Material-UI
import CloseIcon from "@mui/icons-material/Close";
import RecentComponent from "../RecentComponent/RecentComponent";
import ImageCarousel from "../ImageCarousel/ImageCarousel";
import TreatmentDetail from "../TreatmentDetail/TreatmentDetail";
import TableComponent from "../TableComponent/TableComponent2";
import MedicalRecord from "../MedicalRecord/MedicalRecord";
import AfterCarePdfmodal from "../AfterCarePdf/AfterCarePdfmodal";
import ModalComponent from "../ModalComponent2";
import pic from "../../assets/image 4.png";
import img1 from "../../assets/Group 11742.png";
import img2 from "../../assets/Group 11743.png";

import edit from "../../assets/Group 11745.png";

import img11 from "../../assets/Group 11746.png";
import img12 from "../../assets/Group 11747.png";

import img31 from "../../assets/Group 11748 (1).png";
import img32 from "../../assets/Group 11749.png";

import img41 from "../../assets/Group 11751.png";
import { NavLink as AliasLink } from "react-router-dom";

const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
  // align-items: center;
  .table-div {
    padding: 31px;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 15px;
  }
  .view-more-btn {
    font-size: 15px;
    text-align: center;
    cursor: pointer;
    padding: 12px;
    position: relative;
    text-decoration: underline;
  }
  .treat-m {
    font-weight: 600;
    font-size: 15px;
    color: #4c4d4f;
    padding-left: 5px;
    margin-bottom: 15px;
  }
  .main-table {
    border-radius: 10px;
    border: solid 2px #dbdbdb;
  }
  .Treatmentcost {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 25px;
    padding-top: 25px;
    padding-bottom: 30px;
  }
  .Treatment-Cost-Estimate {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 20px;
    padding-top: 25px;
    padding-bottom: 30px;
  }
  .input-shape {
    border: solid 1px #d6d6d6;
    border-radius: 10px;
    padding: 15px;
    font-size: 13px;
    width: 340px;
  }
  .txt-color-style {
    font-size: 14px;
    color: #a5a6a7;
    width: 70%;
    padding-left: 30px;
  }
  .img-style {
    width: 78%;
    display: block;
    margin: 0 auto;
  }
  .card-sub-box {
    background-color: #e1d0c1;
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 15px;
    gap: 35px;
    justify-content: center;
  }
  .checkbox-typ {
    display: flex;
    gap: 10px;
    margin-left: 25px;
    font-size: 12px;
    margin-top: 10px;
  }
  .bookknowbtn {
    padding: 10px;
    width: 80%;
    display: block;
    margin: 0 auto;
    margin-top: 19px;
    border-radius: 10px;
    background-color: #000;
    color: #fff;
    font-size: 12px;
  }
  .searchinput {
    padding: 16px;
    border-radius: 12px;
    border: none;
    margin-right: 10px;
    width: 225px;
    font-size: 11px;
  }
  @media only screen and (max-width: 992px) {
    .searchinput {
      display: none;
    }
    .side-menu {
      display: none !important;
    }
  }
  .spmtxt {
    color: #4c4d4f !important;
  }
  .joyetxt {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    margin-top: 18px;
  }
  .sub-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .pastdiv {
    background-color: #f8f8f8;
    width: 80%;
    margin-left: 10%;
    padding: 36px;
    border-radius: 10px;
    margin-top: 15px;
  }
  .past-inner-div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 62px;
    margin-top: 33px;
  }
  .past-txt {
    border-right: solid 1px;
    padding-right: 71px;
  }
  .patient-details {
    width: 80%;
    margin-left: 10%;
    margin-top: 18px;
  }
  .align-txt {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // display: flex;
    // width: 100%;
    // justify-content: space-between;
    font-size: 14px;
    margin-top: 10px;
  }
  .resp-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  .screen-images {
    padding: 15px;
    font-size: 14px;
    margin-top: 20px;
    border-radius: 10px;
    padding-left: 22px;
    width: 45%;
    background-color: rgb(248, 248, 248);
  }
  @media only screen and (max-width: 992px) {
    .screen-images {
      width: 100%;
    }
  }
  .img-dublicate {
    display: flex;
    gap: 20px;
    justify-content: center;
  }
  .main-right-side {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: baseline;
  }
  @media only screen and (max-width: 992px) {
    .main-right-side {
      flex-direction: column;
    }
  }
  .treatment-p {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .btno {
    background-color: #fff;
    border: solid 1px #000;
    border-radius: 10px;
    padding: 10px;
    width: 100px;
    margin-right: 10px;
  }
  .btna {
    background-color: #000;
    border: solid 1px #000;
    border-radius: 10px;
    padding: 10px;
    width: 100px;
    color: #fff;
    margin-right: 10px;
  }
  .main-surg-history {
    background-color: #fff;
    padding: 33px;
    margin-top: 20px;
  }
  .mm-kk {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #000;
    padding-bottom: 5px;
  }
  .lipos {
    padding: 18px;
    background-color: #f8f8f8;
    margin-top: 20px;
    border-radius: 10px;
  }
  .width-resp {
    min-width: 376px;
  }
  @media only screen and (max-width: 992px) {
    .responsive {
      overflow: scroll;
    }
  }

  .lipos1 {
    background-color: rgb(255, 255, 255);
    padding: 0;
    border: solid 1px #000;
    border-radius: 10px;
    margin-top: 20px;
    overflow: hidden;
  }
  .dte {
    display: flex;
    gap: 50px;
    font-size: 11px;
    margin-left: 10px;
  }
  .trans {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
    font-size: 12px;
  }
  .display-fex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 531px;
  }
  .paracl {
    display: flex;
    align-items: center;
    gap: 35px;
    font-size: 13px;
  }
  .selected {
    border: solid 1px #babbbb;
    background-color: #f8f8f8;
    padding: 15px;
    font-size: 14px;
    width: 60%;
    border-radius: 10px;
  }
  .selecetbox {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .inputbox {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }
  .btn-btn {
    padding: 14px;
    width: 100px;
    font-size: 13px;
    border-radius: 10px;
    color: #4c4d4f;
    background-color: #fff;
    border: solid 1px #4c4d4f;
  }
  .insur-txt {
    padding: 15px;
    background-color: #fff9f1;
    font-size: 15px;
    border-bottom: solid;
    padding-left: 25px;
    padding-right: 25px;
  }
  .txt-input {
    width: 100%;
    border: none;
    border-bottom: solid 1px #a2a3a4;
    font-size: 15px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-left: 10px;
    padding-left: 3px;
    color: #a2a3a4;
  }
  .btn1 {
    font-size: 12px;
    border-radius: 5px;
    background-color: #fff;
    border: solid 1px #000;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 10px;
  }
  .btn2 {
    font-size: 12px;
    border-radius: 5px;
    background-color: #000;
    border: solid 1px #000;
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 10px;
  }
  .treat-m-1-1{
    position: relative;
    top: 32px;
    color: rgb(255, 255, 255);
    background-color: rgb(54, 196, 144);
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 5px;
    font-size: 13px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  .main-heading {
    h1 {
      font-size: 3.4rem;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 1.4rem;
      color: #4c4d4f;
    }
    p {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 1.4rem;
      color: #4c4d4f;
    }
  }

  .faqs-button {
    display: flex;
    align-items: center;
    button {
      width: auto;
      padding: 14px 28px;
      font-size: 1.4rem;
      font-weight: 400;
    }
    .support-btn {
      background: #fff;
      color: #4c4d4f;
      margin-right: 14px;
    }
  }
`;
const columns = [
  { Header: "Treatment", accessor: "column1" },
  { Header: "Procedure Notes", accessor: "column2" },
  { Header: "Date", accessor: "column3" },
  { Header: "Time", accessor: "column4" },
  { Header: "Doctor", accessor: "column5" },
  { Header: "Studio", accessor: "column6" },
  { Header: "Payment", accessor: "column7" },
  { Header: "Detail", accessor: "column8" },
];

const columnss = [
  { Header: "Form Name", accessor: "column1" },
  { Header: "Date", accessor: "column2" },
  { Header: "Treatment", accessor: "column3" },
  { Header: "Status", accessor: "column7" },
  { Header: "Action", accessor: "column4" },
];
// const data = isdata.map((tableItem) => ({
const data1 = [
  {
    column1: "Consent Form",
    column2: "14/08/2023",
    column3: "PRP Injections",
    column4: "View",
    column7: "Complete",
  },
  {
    column1: "Consent Form",
    column2: "14/08/2023",
    column3: "PRP Injections",
    column4: "View",
    column7: "Complete",
  },
];
const data = [
  {
    column1: "PRP Injections",
    column2: "View Notes",
    column3: "12/08/23",
    column4: "09:00am",
    column5: "Vihang Sharma",
    column6: "Toorak",
    column7: "Pending",
    column8: "icon",
  },
  {
    column1: "PRP Injections",
    column2: "View Notes",
    column3: "12/08/23",
    column4: "09:00am",
    column5: "Vihang Sharma",
    column6: "Toorak",
    column7: "Pending",
    column8: "icon",
  },
  {
    column1: "PRP Injections",
    column2: "View Notes",
    column3: "12/08/23",
    column4: "09:00am",
    column5: "Vihang Sharma",
    column6: "Toorak",
    column7: "Pending",
    column8: "icon",
  },
  {
    column1: "PRP Injections",
    column2: "View Notes",
    column3: "12/08/23",
    column4: "09:00am",
    column5: "Vihang Sharma",
    column6: "Toorak",
    column7: "Pending",
    column8: "icon",
  },
  {
    column1: "PRP Injections",
    column2: "View Notes",
    column3: "12/08/23",
    column4: "09:00am",
    column5: "Vihang Sharma",
    column6: "Toorak",
    column7: "Pending",
    column8: "icon",
  },
];
const PrpPage = () => {
  const [isdata, setIsData] = useState(data);
  const [isdata1, setIsData1] = useState(data1);
  const [modalshow, setModalshow] = useState(false);
  const [modalshow1, setModalshow1] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    });
  }
  return (
    <>
      <div>
        <ReuseAbleHeader />
        <MainLayout>
          <ReuseAbleSidebar />
          <Container className="main-content">
            {/* <Row></Row> */}
            <HeaderWrapper>
              <div className="main-heading">
                <h1>PRP Injections / Dr. Vihang Sharma</h1>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "40px" }}
                >
                  <p>
                    <spam style={{ color: "#A5A3A0" }} className="spmtxt">
                      Date:
                    </spam>{" "}
                    25 Aug, 2023
                  </p>
                  <p>
                    <spam style={{ color: "#A5A3A0" }} className="spmtxt">
                      Time:
                    </spam>{" "}
                    12:00 PM - 02:00 PM
                  </p>
                  <p>
                    <spam style={{ color: "#A5A3A0" }} className="spmtxt">
                      Studio:
                    </spam>{" "}
                    Toorak
                  </p>
                </div>
              </div>
              <div className="faqs-button">
                <input
                  className="searchinput"
                  type="search"
                  placeholder="Search"
                />
                <AliasLink to="/faqs">
                  <ButtonComponent className="support-btn">
                    Support & FAQ’s
                  </ButtonComponent>
                </AliasLink>

                <AliasLink to="/patient-dashboard">
                  <ButtonComponent>Cancel Appointment</ButtonComponent>
                </AliasLink>
              </div>
            </HeaderWrapper>

            <Row>
              <Col xs={6} md={6}>
                <div className="joyetxt">
                  <div className="sub-div">
                    <div>
                      <h1>Jane Doe</h1>
                      <p style={{ marginTop: "5px", fontSize: "13px" }}>
                        <spam className="spmtxt">For</spam> PRP Injections
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        gap: "15px",
                      }}
                    >
                      <img style={{ width: "15%" }} src={img1} />
                      <img style={{ width: "15%" }} src={img2} />
                    </div>
                  </div>
                  <div className="pastdiv">
                    <h1 style={{ fontSize: "20px" }}>Appointments</h1>
                    <div className="past-inner-div">
                      <div className="past-txt">
                        <h1 style={{ fontSize: "35px" }}>4</h1>
                        <p>Past</p>
                      </div>
                      <div>
                        <h1 style={{ fontSize: "35px" }}>10</h1>
                        <p>Upcoming</p>
                      </div>
                    </div>
                  </div>
                  <div className="patient-details">
                    <div className="resp-details">
                      <h1 style={{ fontSize: "20px" }}>Patient Details</h1>
                      <img style={{ width: "30px" }} src={edit} />
                    </div>
                    <div>
                      <div className="align-txt">
                        <p>D.O.B</p>
                        <p style={{ color: "#a5a6a7" }}>14 Aug, 1995</p>
                        <p>Allergies</p>
                      </div>
                      <div className="align-txt">
                        <p>Age</p>
                        <p style={{ color: "#a5a6a7" }}>29 Y</p>
                        <p style={{ color: "#a5a6a7" }}>Peanuts</p>
                      </div>
                      <div className="align-txt">
                        <p>Height</p>
                        <p style={{ color: "#a5a6a7" }}>185 cm</p>
                        <p style={{ color: "#a5a6a7" }}>Pollen</p>
                      </div>
                      <div className="align-txt">
                        <p>Weight</p>
                        <p style={{ color: "#a5a6a7" }}>75 kg</p>
                        <p style={{ color: "#a5a6a7" }}>Household Chemicals</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={6} md={6}>
                <div style={{ paddingBottom: "44px" }} className="joyetxt">
                  <h1>Notes</h1>
                  <div className="main-right-side">
                    <div className="screen-images">
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content. It is a long
                        established fact that a reader.
                      </p>
                      <div className="img-dublicate">
                        <img
                          onClick={() => setModalshow1(true)}
                          style={{ width: "40%", cursor: "pointer" }}
                          src={img11}
                        />
                        <img
                          onClick={() => setModalshow1(true)}
                          style={{ width: "40%", cursor: "pointer" }}
                          src={img12}
                        />
                      </div>
                      <input
                        style={{ marginTop: "25px", width: "100%" }}
                        className="input-shape"
                        type="text"
                        placeholder="Write note..."
                      />
                    </div>
                    <div className="screen-images">
                      <div
                        style={{ backgroundColor: "#F8F8F8", padding: "10px" }}
                      >
                        <div
                          style={{
                            borderBottom: "solid 1px #000",
                            marginBottom: "10px",
                          }}
                          className="treatment-p"
                        >
                          <p>Treatment Performed</p>
                          <p style={{ fontSize: "12px" }}>14 Aug, 2023</p>
                        </div>
                        <div>
                          <p>Dr. Vihang Sharma</p>
                          <div className="treatment-p">
                            <p>Anesthesia:</p>
                            <p>Isoflurane</p>
                          </div>
                          <div className="treatment-p">
                            <p>MLS Units:</p>
                            <p>100</p>
                          </div>
                          <div className="treatment-p">
                            <p>Dosing:</p>
                            <p>2.5 ml</p>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: "10px",
                          backgroundColor: "#F8F8F8",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            borderBottom: "solid 1px #000",
                            marginBottom: "10px",
                          }}
                          className="treatment-p"
                        >
                          <p>Other Notes:</p>
                        </div>
                        <div>
                          <p style={{ fontSize: "10px" }}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Sed commodo eget velit non luctus. Duis at
                            enim eu elit facilisis fermentum.
                          </p>
                        </div>
                      </div>
                      <div style={{ display: "flex", marginTop: "20px" }}>
                        <button className="btno">Overview</button>
                        <button className="btna">Add notes</button>
                       </div>
                    </div>
                </div>
            </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <div className="table-div">
                <p className="treat-m">Treatment History</p>
                <div className="main-table">
                  <TableComponent onClick={scrollToTop} imageColumn2="column2" columns={columns} data={isdata} />
                  <p className="view-more-btn">View more</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={8} md={8}>
              <div className="main-surg-history responsive">
                <div className="mm-kk">
                  <p style={{position: "relative",top: "12px"}} className="treat-m">Surgical History</p>
                  <img style={{width: "50px"}} src={img31}/>
                </div>
                <div className="lipos width-resp">
                  <p style={{fontSize: "14px"}}>1. Liposuction</p>
                  <div className="dte">
                    <p><spam style={{paddingRight: "15px"}}>Date:</spam> 14 Aug, 2023</p>
                    <p><spam style={{paddingRight: "15px"}}>Reason:</spam> Cosmetic Enhancement</p>
                    <p><spam style={{paddingRight: "15px"}}>Location:</spam> Indicate the medical facility</p>
                  </div>
                </div>
                <div className="lipos width-resp">
                  <p style={{fontSize: "14px"}}>1. Liposuction</p>
                  <div className="dte">
                    <p><spam style={{paddingRight: "15px"}}>Date:</spam> 14 Aug, 2023</p>
                    <p><spam style={{paddingRight: "15px"}}>Reason:</spam> Cosmetic Enhancement</p>
                    <p><spam style={{paddingRight: "15px"}}>Location:</spam> Indicate the medical facility</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={4} md={4}>
              <div style={{backgroundColor:"#fff",padding:"20px",marginTop:"25px"}}>
                <p style={{position: "relative",top: "12px"}} className="treat-m">Payments</p>
                <div className="trans">
                  <p>Transaction</p>
                  <p>Amount</p>
                </div>
                <div className="trans">
                  <p style={{position: "relative",top: "12px"}} className="treat-m">PRP Treatment <br/> <spam style={{color:"#A5A6A7"}}>14 Aug, 2023</spam></p>
                  <p className="treat-m-1-1">paid</p>
                </div>
                <div className="trans">
                  <p style={{position: "relative",top: "12px"}} className="treat-m">PRP Treatment <br/> <spam style={{color:"#A5A6A7"}}>14 Aug, 2023</spam></p>
                  <p className="treat-m-1-1">paid</p>
                </div>
                <div className="trans">
                  <p style={{position: "relative",top: "12px"}} className="treat-m">PRP Treatment <br/> <spam style={{color:"#A5A6A7"}}>14 Aug, 2023</spam></p>
                  <p className="treat-m-1-1">paid</p>
                </div>
                <div className="trans">
                  <p style={{position: "relative",top: "25px"}} className="treat-m">PRP Treatment</p>
                  <p style={{position: "relative",top: "25px"}} className="treat-m">$350.00</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={8} md={8}>
              <div className="main-surg-history">
                <div className="mm-kk">
                  <p style={{position: "relative",top: "12px"}} className="treat-m">Medications History</p>
                  <img style={{width: "50px"}} src={img31}/>
                </div>
                <div style={{backgroundColor:"#fff"}} className="lipos responsive">
                  <p style={{fontSize: "14px"}}>1. Liposuction</p>
                  <div className="display-fex">
                    <h1 className="treat-m">Long Term Medications</h1>
                    <h1 className="treat-m">Short Term Medications</h1>
                    <h1 className="treat-m">Botox and Profihilo Injections</h1>
                  </div>
                  <div className="display-fex" style={{marginLeft:"5px"}}>
                    <div>
                      <div className="paracl">
                        <p>1. Paracetamol</p>
                        <p style={{color:"#A5A6A7"}}>250mg</p>
                      </div>
                      <div className="paracl">
                        <p>2. Paracetamol</p>
                        <p style={{color:"#A5A6A7"}}>250mg</p>
                      </div>
                      <div className="paracl">
                        <p>3. Paracetamol</p>
                        <p style={{color:"#A5A6A7"}}>250mg</p>
                      </div>
                      <div className="paracl">
                        <p>4. Paracetamol</p>
                        <p style={{color:"#A5A6A7"}}>250mg</p>
                      </div>
                      <div className="paracl">
                        <p>5. Paracetamol</p>
                        <p style={{color:"#A5A6A7"}}>250mg</p>
                      </div>
                    </div>
                    <div>
                    <div className="paracl">
                        <p>1. Paracetamol</p>
                        <p style={{color:"#A5A6A7"}}>250mg</p>
                      </div>
                      <div className="paracl">
                        <p>2. Paracetamol</p>
                        <p style={{color:"#A5A6A7"}}>250mg</p>
                      </div>
                      <div className="paracl">
                        <p>3. Paracetamol</p>
                        <p style={{color:"#A5A6A7"}}>250mg</p>
                      </div>
                      <div className="paracl">
                        <p>4. Paracetamol</p>
                        <p style={{color:"#A5A6A7"}}>250mg</p>
                      </div>
                      <div className="paracl">
                        <p>5. Paracetamol</p>
                        <p style={{color:"#A5A6A7"}}>250mg</p>
                      </div>
                    </div>
                    <div>
                    <div className="paracl">
                        <p>1. Paracetamol</p>
                        <p style={{color:"#A5A6A7"}}>250mg</p>
                      </div>
                      <div className="paracl">
                        <p>2. Paracetamol</p>
                        <p style={{color:"#A5A6A7"}}>250mg</p>
                      </div>
                      <div className="paracl">
                        <p>3. Paracetamol</p>
                        <p style={{color:"#A5A6A7"}}>250mg</p>
                      </div>
                      <div className="paracl">
                        <p>4. Paracetamol</p>
                        <p style={{color:"#A5A6A7"}}>250mg</p>
                      </div>
                      <div className="paracl">
                        <p>5. Paracetamol</p>
                        <p style={{color:"#A5A6A7"}}>250mg</p>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <div className="table-div">
                  <p className="treat-m">Treatment History</p>
                  <div className="main-table">
                    <TableComponent columns={columns} data={isdata} />
                    <p className="view-more-btn">View more</p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={8} md={8}>
                <div className="main-surg-history responsive">
                  <div className="mm-kk">
                    <p
                      style={{ position: "relative", top: "12px" }}
                      className="treat-m"
                    >
                      Surgical History
                    </p>
                    <img style={{ width: "50px" }} src={img31} />
                  </div>
                  <div className="lipos width-resp">
                    <p style={{ fontSize: "14px" }}>1. Liposuction</p>
                    <div className="dte">
                      <p>
                        <spam style={{ paddingRight: "15px" }}>Date:</spam> 14
                        Aug, 2023
                      </p>
                      <p>
                        <spam style={{ paddingRight: "15px" }}>Reason:</spam>{" "}
                        Cosmetic Enhancement
                      </p>
                      <p>
                        <spam style={{ paddingRight: "15px" }}>Location:</spam>{" "}
                        Indicate the medical facility
                      </p>
                    </div>
                  </div>
                  <div className="lipos width-resp">
                    <p style={{ fontSize: "14px" }}>1. Liposuction</p>
                    <div className="dte">
                      <p>
                        <spam style={{ paddingRight: "15px" }}>Date:</spam> 14
                        Aug, 2023
                      </p>
                      <p>
                        <spam style={{ paddingRight: "15px" }}>Reason:</spam>{" "}
                        Cosmetic Enhancement
                      </p>
                      <p>
                        <spam style={{ paddingRight: "15px" }}>Location:</spam>{" "}
                        Indicate the medical facility
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={4} md={4}>
                <div
                  style={{
                    backgroundColor: "#fff",
                    padding: "20px",
                    marginTop: "25px",
                  }}
                >
                  <p
                    style={{ position: "relative", top: "12px" }}
                    className="treat-m"
                  >
                    Payments
                  </p>
                  <div className="trans">
                    <p>Transaction</p>
                    <p>Amount</p>
                  </div>
                  <div className="trans">
                    <p
                      style={{ position: "relative", top: "12px" }}
                      className="treat-m"
                    >
                      PRP Treatment
                    </p>
                    <p
                      style={{ position: "relative", top: "12px" }}
                      className="treat-m"
                    >
                      $350.00
                    </p>
                  </div>
                  <div className="trans">
                    <p
                      style={{ position: "relative", top: "12px" }}
                      className="treat-m"
                    >
                      PRP Treatment
                    </p>
                    <p
                      style={{ position: "relative", top: "12px" }}
                      className="treat-m"
                    >
                      $350.00
                    </p>
                  </div>
                  <div className="trans">
                    <p
                      style={{ position: "relative", top: "12px" }}
                      className="treat-m"
                    >
                      PRP Treatment
                    </p>
                    <p
                      style={{ position: "relative", top: "12px" }}
                      className="treat-m"
                    >
                      $350.00
                    </p>
                  </div>
                  <div className="trans">
                    <p
                      style={{ position: "relative", top: "12px" }}
                      className="treat-m"
                    >
                      PRP Treatment
                    </p>
                    <p
                      style={{ position: "relative", top: "12px" }}
                      className="treat-m"
                    >
                      $350.00
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={8} md={8}>
                <div className="main-surg-history">
                  <div className="mm-kk">
                    <p
                      style={{ position: "relative", top: "12px" }}
                      className="treat-m"
                    >
                      Medications History
                    </p>
                    <img style={{ width: "50px" }} src={img31} />
                  </div>
                  <div
                    style={{ backgroundColor: "#fff" }}
                    className="lipos responsive"
                  >
                    <p style={{ fontSize: "14px" }}>1. Liposuction</p>
                    <div className="display-fex">
                      <h1 className="treat-m">Long Term Medications</h1>
                      <h1 className="treat-m">Short Term Medications</h1>
                      <h1 className="treat-m">
                        Botox and Profihilo Injections
                      </h1>
                    </div>
                    <div className="display-fex" style={{ marginLeft: "5px" }}>
                      <div>
                        <div className="paracl">
                          <p>1. Paracetamol</p>
                          <p style={{ color: "#A5A6A7" }}>250mg</p>
                        </div>
                        <div className="paracl">
                          <p>2. Paracetamol</p>
                          <p style={{ color: "#A5A6A7" }}>250mg</p>
                        </div>
                        <div className="paracl">
                          <p>3. Paracetamol</p>
                          <p style={{ color: "#A5A6A7" }}>250mg</p>
                        </div>
                        <div className="paracl">
                          <p>4. Paracetamol</p>
                          <p style={{ color: "#A5A6A7" }}>250mg</p>
                        </div>
                        <div className="paracl">
                          <p>5. Paracetamol</p>
                          <p style={{ color: "#A5A6A7" }}>250mg</p>
                        </div>
                      </div>
                      <div>
                        <div className="paracl">
                          <p>1. Paracetamol</p>
                          <p style={{ color: "#A5A6A7" }}>250mg</p>
                        </div>
                        <div className="paracl">
                          <p>2. Paracetamol</p>
                          <p style={{ color: "#A5A6A7" }}>250mg</p>
                        </div>
                        <div className="paracl">
                          <p>3. Paracetamol</p>
                          <p style={{ color: "#A5A6A7" }}>250mg</p>
                        </div>
                        <div className="paracl">
                          <p>4. Paracetamol</p>
                          <p style={{ color: "#A5A6A7" }}>250mg</p>
                        </div>
                        <div className="paracl">
                          <p>5. Paracetamol</p>
                          <p style={{ color: "#A5A6A7" }}>250mg</p>
                        </div>
                      </div>
                      <div>
                        <div className="paracl">
                          <p>1. Paracetamol</p>
                          <p style={{ color: "#A5A6A7" }}>250mg</p>
                        </div>
                        <div className="paracl">
                          <p>2. Paracetamol</p>
                          <p style={{ color: "#A5A6A7" }}>250mg</p>
                        </div>
                        <div className="paracl">
                          <p>3. Paracetamol</p>
                          <p style={{ color: "#A5A6A7" }}>250mg</p>
                        </div>
                        <div className="paracl">
                          <p>4. Paracetamol</p>
                          <p style={{ color: "#A5A6A7" }}>250mg</p>
                        </div>
                        <div className="paracl">
                          <p>5. Paracetamol</p>
                          <p style={{ color: "#A5A6A7" }}>250mg</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                className="side-menu"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
                xs={4}
                md={4}
              >
                <img style={{ width: "18%" }} src={img41} alt="wait" />
                <div
                  style={{
                    backgroundColor: "#fff",
                    padding: "20px",
                    marginTop: "25px",
                  }}
                >
                  <div className="trans">
                    <p
                      style={{ position: "relative", top: "12px" }}
                      className="treat-m"
                    >
                      Surgical History
                    </p>
                  </div>
                  <div className="trans">
                    <p
                      style={{ position: "relative", top: "12px" }}
                      className="treat-m"
                    >
                      Medications History
                    </p>
                  </div>
                  <div className="trans">
                    <p
                      style={{ position: "relative", top: "12px" }}
                      className="treat-m"
                    >
                      Consent Forms
                    </p>
                  </div>
                  <div className="trans">
                    <p
                      style={{ position: "relative", top: "12px" }}
                      className="treat-m"
                    >
                      Blood & Pathology
                    </p>
                  </div>
                  <div className="trans">
                    <p
                      style={{ position: "relative", top: "12px" }}
                      className="treat-m"
                    >
                      Imaging & Radiology
                    </p>
                  </div>
                  <div className="trans">
                    <p
                      style={{ position: "relative", top: "12px" }}
                      className="treat-m"
                    >
                      Insurance & Billing
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={8} md={8}>
                <div
                  style={{ backgroundColor: " #fff", borderRadius: "14px" }}
                  className="main-surg-history"
                >
                  <div></div>
                  <div className="mm-kk">
                    <p
                      style={{ position: "relative", top: "12px" }}
                      className="treat-m"
                    >
                      Consent Forms
                    </p>
                    <img style={{ width: "50px" }} src={img32} />
                  </div>
                  <div className="lipos1">
                    <TableComponent
                      style={{ borderRadius: "10px !important" }}
                      onClick={() => setModalshow(true)}
                      columns={columnss}
                      data={isdata1}
                      imageColumn1="column4"
                    />
                  </div>
                </div>
              </Col>
              <Col xs={4} md={4}></Col>
            </Row>
            <Row>
              <Col xs={8} md={8}>
                <div className="main-surg-history">
                  <div className="mm-kk">
                    <p
                      style={{ position: "relative", top: "12px" }}
                      className="treat-m"
                    >
                      Blood & Pathology
                    </p>
                  </div>
                  <div style={{ backgroundColor: "#fff" }} className="lipos">
                    <p style={{ fontSize: "14px" }}>Select Test</p>
                    <div className="selecetbox">
                      <select className="selected" name="cars" id="cars">
                        <option value="volvo">select test to order</option>
                        <option value="saab">Saab</option>
                      </select>
                      <button className="btn-btn">Order Test</button>
                    </div>
                    <div style={{ marginTop: "80px" }}>
                      <div className="inputbox">
                        <input
                          style={{ width: "80%" }}
                          type="text"
                          className="selected"
                          placeholder="Pathology Test Request"
                        />
                        <button className="btn-btn">Order Test</button>
                      </div>
                      <div className="inputbox">
                        <input
                          style={{ width: "80%" }}
                          type="text"
                          className="selected"
                          placeholder="Pathology Test Request"
                        />
                        <button className="btn-btn">Order Test</button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={4} md={4}></Col>
            </Row>
            <Row>
              <Col xs={8} md={8}>
                <div className="main-surg-history">
                  <div className="mm-kk">
                    <p
                      style={{ position: "relative", top: "12px" }}
                      className="treat-m"
                    >
                      Imaging & Radiology
                    </p>
                  </div>
                  <div style={{ backgroundColor: "#fff" }} className="lipos">
                    <p style={{ fontSize: "14px" }}>Select Test</p>
                    <div className="selecetbox">
                      <select className="selected" name="cars" id="cars">
                        <option value="volvo">select test to order</option>
                        <option value="saab">Saab</option>
                      </select>
                      <button className="btn-btn">Order Test</button>
                    </div>
                    <div style={{ marginTop: "80px" }}>
                      <div className="inputbox">
                        <input
                          style={{ width: "80%" }}
                          type="text"
                          className="selected"
                          placeholder="Pathology Test Request"
                        />
                        <button className="btn-btn">Order Test</button>
                      </div>
                      <div className="inputbox">
                        <input
                          style={{ width: "80%" }}
                          type="text"
                          className="selected"
                          placeholder="Pathology Test Request"
                        />
                        <button className="btn-btn">Order Test</button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={4} md={4}></Col>
            </Row>
            <Row>
              <Col xs={8} md={8}>
                <div className="main-surg-history">
                  <div className="mm-kk">
                    <p
                      style={{ position: "relative", top: "12px" }}
                      className="treat-m"
                    >
                      Insurance & Billing
                    </p>
                  </div>
                  <div style={{ backgroundColor: "#fff" }} className="lipos">
                    <div
                      style={{
                        border: "solid 1px",
                        borderRadius: "10px",
                        overflow: "hidden",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <p className="insur-txt">Insurance</p>
                        <p
                          style={{
                            backgroundColor: "#fff",
                            borderBottom: "unset",
                          }}
                          className="insur-txt"
                        >
                          Medicare Billings
                        </p>
                      </div>
                      <div style={{ backgroundColor: "#F8F8F8" }}>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              padding: "15px",
                              paddingTop: "25px",
                              width: "45%",
                            }}
                          >
                            <p style={{ marginLeft: "10px", fontSize: "16px" }}>
                              First Name
                            </p>
                            <input
                              style={{ backgroundColor: "#F8F8F8" }}
                              className="txt-input"
                              type="text"
                              placeholder="Jane"
                            />
                          </div>
                          <div
                            style={{
                              padding: "15px",
                              paddingTop: "25px",
                              width: "45%",
                            }}
                          >
                            <p style={{ marginLeft: "10px", fontSize: "16px" }}>
                              First Name
                            </p>
                            <input
                              style={{ backgroundColor: "#F8F8F8" }}
                              className="txt-input"
                              type="text"
                              placeholder="Jane"
                            />
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              padding: "15px",
                              paddingTop: "25px",
                              width: "45%",
                            }}
                          >
                            <p style={{ marginLeft: "10px", fontSize: "16px" }}>
                              First Name
                            </p>
                            <input
                              style={{ backgroundColor: "#F8F8F8" }}
                              className="txt-input"
                              type="text"
                              placeholder="Jane"
                            />
                          </div>
                          <div
                            style={{
                              padding: "15px",
                              paddingTop: "25px",
                              width: "45%",
                            }}
                          >
                            <p style={{ marginLeft: "10px", fontSize: "16px" }}>
                              First Name
                            </p>
                            <input
                              style={{ backgroundColor: "#F8F8F8" }}
                              className="txt-input"
                              type="text"
                              placeholder="Jane"
                            />
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              padding: "15px",
                              paddingTop: "25px",
                              width: "45%",
                            }}
                          >
                            <p style={{ marginLeft: "10px", fontSize: "16px" }}>
                              First Name
                            </p>
                            <input
                              style={{ backgroundColor: "#F8F8F8" }}
                              className="txt-input"
                              type="text"
                              placeholder="Jane"
                            />
                          </div>
                          <div
                            style={{
                              padding: "15px",
                              paddingTop: "25px",
                              width: "45%",
                            }}
                          >
                            <p style={{ marginLeft: "10px", fontSize: "16px" }}>
                              First Name
                            </p>
                            <input
                              style={{ backgroundColor: "#F8F8F8" }}
                              className="txt-input"
                              type="text"
                              placeholder="Jane"
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            marginTop: "20px",
                          }}
                        >
                          <button className="btn1">Check Insurance</button>
                          <button className="btn2">Submit</button>
                        </div>
                        <div style={{ height: "20px" }}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={4} md={4}></Col>
            </Row>
          </Container>
        </MainLayout>
      </div>
      {modalshow && (
        <>
          <CloseIcon
            style={{
              position: "fixed",
              top: "5%",
              fontSize: "30px",
              color: "#fff",
              zIndex: "999999",
              right: "5%",
              cursor: "pointer",
            }}
            onClick={() => {
              setModalshow(false);
            }}
            fontSize="large"
          />
          <div
            style={{
              position: "fixed",
              top: "0",
              height: "100%",
              width: "100%",
              backgroundColor: "#0009",
            }}
          >
            <AfterCarePdfmodal />
          </div>
        </>
      )}
      {modalshow1 && (
        <>
          <CloseIcon
            style={{
              position: "fixed",
              top: "5%",
              fontSize: "30px",
              color: "#fff",
              zIndex: "999999",
              right: "5%",
              cursor: "pointer",
            }}
            onClick={() => {
              setModalshow1(false);
            }}
            fontSize="large"
          />
          <div
            style={{
              position: "fixed",
              top: "0",
              height: "100%",
              width: "100%",
              backgroundColor: "#0009",
            }}
          >
            <ModalComponent />
          </div>
        </>
      )}
    </>
  );
};

export default PrpPage;
