import React, { useState } from "react";
import styled from "styled-components";
import ReuseAbleHeader from "../ReuseAbleHeader/ReuseAbleHeader";
import ReuseAbleSidebar from "../ReuseAbleSidebar/ReuseAbleSidebar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TableComponent from "../TableComponent/TableComponent2";

import close from '../../assets/Group 27.png';
const MainLayout = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
`;
const Table2Wrapperr = styled.div`

  // display: flex;
  // flex: 1;
  // background: #fff9f1;
  padding: 22px 28px;
  background: #fff;
  border-radius: 0 0 14px 14px;

  overflow-y: auto;

  .add-border {
    border: 1px solid #d6d6d6;
    border-radius: 14px;
    overflow: auto;
  }
  .headline-tab  h2{
    font-size: 18px;
  }

  .headline-tab{
    display: flex;
    justify-content: space-between;
    background-color: #F8F8F8;
    padding: 20px;
    border-radius: 5px; 
  }
  .headline-tab1{
    display: flex;
    justify-content: space-between;
    border-bottom:solid 1px;
    padding: 20px;
    border-radius: 5px; 
  }
  .btnty{
    background-color: #36C490;
    height: 30px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
    color: #fff;
    display: block;
    width: 50%;
    padding-top: 5px;
  }
  .btn-ul{
    border-radius: 23px;
    border: solid 1px;
    font-size: 12px;
    background-color: #fff;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .srch{
    padding: 10px;
    border-radius: 20px;
    border: solid 1px #F8F8F8;
    background-color: #F8F8F8;
    width: 70%;
  }
  .mmg{
    display: flex;
    align-items: center;
    gap: 18px;
    width: 35%;
  }
`;

const TableWrapperr = styled.div`
  display: flex;
  flex: 1;
  background: #fff9f1;
  border-bottom: 1px solid #dbdbdc;
  padding: 2.1rem 2.7rem;
  background-color: #fff;
  border-radius: 14px 14px 0 0;
  justify-content: space-between;
  h1 {
  }
`;



const AdminUserManagement = () => {
 
  return (
    <div>
      <ReuseAbleHeader />
      <MainLayout>
        <ReuseAbleSidebar />
        <Container className="main-content">
          <Row>
            <Col xl={12} xs={12}>
              <h1 style={{marginBottom: "0"}} className="after-care-heading">User Management</h1>
              <p style={{marginBottom: "55px"}}>Add or remove users and manage user permissions</p>
              <div style={{overflow:"scroll"}}>
                <div style={{minWidth:"1090px"}}>
                  <TableWrapperr>
                    <h1 style={{fontSize: "19px"}}>Manage Users</h1>
                    <div className="mmg">
                        <input className="srch" type="text" placeholder="Search user"/>
                        <button className="btn-ul">Add User</button>
                    </div>
                  </TableWrapperr>
                  <Table2Wrapperr>
                    <div>
                        <div className="headline-tab">
                            <h2 style={{width: "200px"}}>Name</h2>
                            <h2 style={{width: "200px"}}>Status</h2>
                            <h2 style={{width: "200px"}}>User Role</h2>
                            <h2 style={{width: "200px"}}>Action</h2>
                            <h2 style={{width: "200px"}}>Remove User</h2>
                        </div>
                        <div className="headline-tab1">
                            <p style={{width: "200px",paddingTop: "10px"}}>Jane Doe <br/><spam style={{color:"#C7C7C8",fontSize:"12px"}}>jane@gmail.com</spam></p>
                            <p style={{width: "200px",paddingTop: "10px"}}>Offline</p>
                            <p style={{width: "200px",paddingTop: "10px"}}><spam className="btnty">Practitioner</spam></p>
                            <p style={{width: "200px",paddingTop: "10px"}}>Modify Roles</p>
                            <p style={{width: "200px",paddingTop: "10px"}}>
                            <img style={{width: "30px",marginLeft:"38px",paddingTop: "1px"}} src={close}/></p>
                        </div>
                        <div className="headline-tab1">
                            <p style={{width: "200px",paddingTop: "10px"}}>Jane Doe <br/><spam style={{color:"#C7C7C8",fontSize:"12px"}}>jane@gmail.com</spam></p>
                            <p style={{width: "200px",paddingTop: "10px"}}>Offline</p>
                            <p style={{width: "200px",paddingTop: "10px"}}><spam className="btnty">Practitioner</spam></p>
                            <p style={{width: "200px",paddingTop: "10px"}}>Modify Roles</p>
                            <p style={{width: "200px",paddingTop: "10px"}}>
                            <img style={{width: "30px",marginLeft:"38px",paddingTop: "1px"}} src={close}/></p>
                        </div>
                        <div className="headline-tab1">
                            <p style={{width: "200px",paddingTop: "10px"}}>Jane Doe <br/><spam style={{color:"#C7C7C8",fontSize:"12px"}}>jane@gmail.com</spam></p>
                            <p style={{width: "200px",paddingTop: "10px"}}>Offline</p>
                            <p style={{width: "200px",paddingTop: "10px"}}><spam className="btnty">Practitioner</spam></p>
                            <p style={{width: "200px",paddingTop: "10px"}}>Modify Roles</p>
                            <p style={{width: "200px",paddingTop: "10px"}}>
                            <img style={{width: "30px",marginLeft:"38px",paddingTop: "1px"}} src={close}/></p>
                        </div>
                        <div className="headline-tab1">
                            <p style={{width: "200px",paddingTop: "10px"}}>Jane Doe <br/><spam style={{color:"#C7C7C8",fontSize:"12px"}}>jane@gmail.com</spam></p>
                            <p style={{width: "200px",paddingTop: "10px"}}>Offline</p>
                            <p style={{width: "200px",paddingTop: "10px"}}><spam className="btnty">Practitioner</spam></p>
                            <p style={{width: "200px",paddingTop: "10px"}}>Modify Roles</p>
                            <p style={{width: "200px",paddingTop: "10px"}}>
                            <img style={{width: "30px",marginLeft:"38px",paddingTop: "1px"}} src={close}/></p>
                        </div>
                        <div className="headline-tab1">
                            <p style={{width: "200px",paddingTop: "10px"}}>Jane Doe <br/><spam style={{color:"#C7C7C8",fontSize:"12px"}}>jane@gmail.com</spam></p>
                            <p style={{width: "200px",paddingTop: "10px"}}>Offline</p>
                            <p style={{width: "200px",paddingTop: "10px"}}><spam className="btnty">Practitioner</spam></p>
                            <p style={{width: "200px",paddingTop: "10px"}}>Modify Roles</p>
                            <p style={{width: "200px",paddingTop: "10px"}}>
                            <img style={{width: "30px",marginLeft:"38px",paddingTop: "1px"}} src={close}/></p>
                        </div>
                    </div>
                  </Table2Wrapperr>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </div>
  );
};

export default AdminUserManagement;
