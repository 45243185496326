import React from "react";
import AuthLayout from "../AuthLayout/AuthLayout";
import InputField from "../InputField/InputField";
import Button from "../Button/Button";

import styled from "@emotion/styled";

const SignInWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",

  // alignItems: "flex-start",
  // width: "100%",
  justifyContent: "center",
  // alignItems: "center",
  height: "100vh",
  width: "80%",
  margin: "auto",
  // backgroundColor: "yellow",

  "& h3": {
    color: "green",
    fontSize: "3.6rem",
  },
  "& .sub-heading": {
    color: "green",
    fontSize: "1.4rem",
    fontWeight: 400,
    marginBottom: "4rem",
  },
  "& .label": {
    color: "green",
    fontSize: "1.4rem",
    fontWeight: 500,
    marginBottom: ".8rem",
  },
  "& .submit-btn": {
    fontSize: "1.4rem",
    fontWeight: 500,
    marginTop: "2.7rem",
    marginBottom: "1.7rem",
  },
  "& .sign-in-btn": {
    // color: "green",
    // fontSize: "1.4rem",
    // fontWeight: 500,
    marginBottom: "1.2rem",
  },
});

const Signin = () => {
  return (
    <AuthLayout src="Image1">
      <SignInWrapper>
        <h3>Welcome Back</h3>
        <p className="sub-heading">Welcome back please enter your details</p>
        <p className="label">Email</p>
        <InputField fullWidth placeholder="Please enter your email here" />
        <p className="label">Password</p>
        <InputField fullWidth placeholder="Please enter your email here" />

        <Button fullWidth variant="active" className="submit-btn">
          Sign In
        </Button>
        <Button variant="outlinedvariant" className="sign-in-btn">
          Sign In
        </Button>
        <Button variant="outlinedvariant" className="sign-in-btn">
          Sign In
        </Button>
        <Button variant="outlinedvariant" className="sign-in-btn">
          Sign In
        </Button>
      </SignInWrapper>
    </AuthLayout>
  );
};

export default Signin;
