// import { colors } from "@config/colors";
import { colors } from "../../config/colors";

export const ButtonDark = {
  variants: [
    {
      props: { variant: "contained" },
      style: {
        color: "white",
        backgroundColor: "pink",
        fill: "currentColor",
      },
    },
  ],
};

export const ButtonLight = {
  styleOverrides: {
    root: {
      fontFamily: "Nunito Sans",
      "&.MuiButton-loading": {
        pointerEvents: "none",
      },
    },
  },
  variants: [
    {
      props: { variant: "contained" },
      style: {
        color: colors.greyText[0],
        backgroundColor: colors.primary[500],
        fill: "currentColor",
        // padding: "11.5px 12.5px",
        padding: "11.5px 32px",
        height: "48px",
        alignContent: "center",
        width: "inherit",
        boxShadow: "none",
        borderRadius: "8px",
        textTransform: "inherit",
        "&:hover": {
          backgroundColor: colors.primary[400],
          boxShadow: "none",
        },
        "&:active": {
          boxShadow: "none",
        },
        "&.MuiButtonBase-root.Mui-disabled": {
          backgroundColor: colors.primary[500],
          opacity: "0.2",
          color: colors.greyText[0],
        },
      },
    },
    {
      props: { variant: "outlined" },
      style: {
        color: colors.primary[500],
        padding: "10px 32px",
        textTransform: "inherit",
        letterSpacing: "0.03em",
        backgroundColor: colors.greyText[0],
        border: `1.5px solid ${colors.primary[500]}`,
        borderRadius: "10px",
        "&:hover": {
          backgroundColor: colors.greyText[10],
          border: `1.5px solid ${colors.primary[500]}`,
        },
        "&:active": {
          backgroundColor: colors.greyText[10],
          border: `1.5px solid ${colors.primary[500]}`,
        },
        "&.MuiButtonBase-root.Mui-disabled": {
          backgroundColor: colors.greyText[0],
          border: `1.5px solid ${colors.primary[500]}`,
          opacity: "0.2",
          color: colors.primary[500],
          pointer: "none",
        },
      },
    },
    {
      props: { variant: "outlined-small" },
      style: {
        color: colors.primary[500],
        padding: "6.5px 14px",
        textTransform: "inherit",
        backgroundColor: colors.greyText[0],
        border: `1.5px solid ${colors.primary[500]}`,
        borderRadius: "4px",
        height: "32.5px",
        width: "inherit",
        lineHeight: "1px",
        // width: "100%",
        "&:hover": {
          backgroundColor: colors.greyText[10],
          border: `1.5px solid ${colors.primary[500]}`,
        },
        "&:active": {
          backgroundColor: colors.greyText[10],
          border: `1.5px solid ${colors.primary[500]}`,
        },
        "&.MuiButtonBase-root.Mui-disabled": {
          backgroundColor: colors.greyText[0],
          border: `1.5px solid ${colors.primary[500]}`,
          opacity: "0.2",
          color: colors.primary[500],
          pointer: "none",
        },
        "& .Mui-focusVisible": {
          backgroundColor: "red !important",
        },
      },
    },
    {
      props: { variant: "text" },
      style: {
        color: colors.greyText[900],
        textTransform: "inherit",
        letterSpacing: "0.03em",
        // padding: "10px 32px",
        // height: "48px",
        // backgroundColor: "red",
        "&:hover": {
          color: colors.primary[500],
        },
        "&:active": {
          color: colors.primary[500],
        },
        "&.MuiButtonBase-root.Mui-disabled": {
          backgroundColor: colors.greyText[0],
          opacity: "0.2",
          color: colors.primary[500],
          pointer: "none",
        },
      },
    },
    {
      props: { variant: "small-contained" },
      style: {
        color: colors.greyText[0],
        backgroundColor: colors.primary[500],
        display: "flex",
        alignItems: "center",
        fill: "currentColor",
        padding: "6.5px 14px",
        height: "32.5px",
        alignContent: "center",
        width: "inherit",
        boxShadow: "none",
        borderRadius: "4px",
        textTransform: "inherit",
        justifyContent: "center",
        lineHeight: "1px",
        "& .MuiCircularProgress-root": {
          color: "white",
        },
        "&:hover": {
          backgroundColor: colors.primary[400],
          boxShadow: "none",
        },
        "&:active": {
          boxShadow: "none",
        },
        "&.MuiButtonBase-root.Mui-disabled": {
          backgroundColor: colors.primary[500],
          opacity: "0.2",
          color: colors.greyText[0],
        },
      },
    },
    {
      props: { variant: "active" },
      style: {
        backgroundColor: colors.primary[100],
        padding: "24px auto",
        height: "7.7rem",
        width: "100%",
        borderRadius: "20px",
        "&:hover": { backgroundColor: "rgba(0, 196, 149, 0.9)" },
      },
    },
    {
      props: { variant: "outlinedvariant" },
      style: {
        backgroundColor: "transparent",
        padding: "24px auto !important",
        border: "1px solid rgba(0, 0, 0, 0.5)",
        borderRadius: "20px",
        width: "100%",
        "&:hover": { backgroundColor: "rgba(0, 196, 149, 0.9)" },
      },
    },
  ],
};
