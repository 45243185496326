import React from "react";
import InventoryMng from "../component/InventoryManagement/InventoryManagement";

const InventoryManagementPage = () => {
  return (
    <div>
      <InventoryMng/>
    </div>
  );
};

export default InventoryManagementPage;
