import React from "react";
import Toorak4 from "../component/Toorak4/Toorak4";

const ToorakPage4 = () => {
  return (
    <div>
      <Toorak4 />
    </div>
  );
};

export default ToorakPage4;
