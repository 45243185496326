import AdminHeader from "../AdminHeader/AdminHeader";
import AdminSideMenu from "../AdminSideMenu";
import * as admin_assets from "../../assets/Admin";
import "./style.css";

const AdminLayout = ({ children }) => {
  return (
    <>
      <AdminHeader />
      <div className="AdminLayout">
          <div className="row">
            <AdminSideMenu />
            <main className="">
              {children}
            </main>
          </div>

      </div>
    </>
  );
};
export default AdminLayout;
