import React from "react";
import InvoiceList from "../component/InvoiceList/InvoiceList"; 

const InvoiceListPage = () => {
  return (
    <div>
      <InvoiceList/>
    </div>
  );
};

export default InvoiceListPage;
